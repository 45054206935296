import React, { useState } from "react";
import AvailabilitySettings, {
  Availability,
  DefaultAvailability,
} from "../AvailabilitySettings";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";

import Dialog from "./Dialog";

export function UpdateAvailabilityDialog({
  open,
  onSubmit,
  onClose,
}: {
  open: boolean;
  onSubmit: (value: Availability) => void;
  onClose: () => void;
}) {
  const [availability, setAvailability] = useState(DefaultAvailability);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Update Availability"
      actions={
        <>
          <PrimaryButton onClick={() => onSubmit(availability)}>
            Save
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </SecondaryButton>
        </>
      }
    >
      <AvailabilitySettings
        value={availability}
        onChange={(v) => setAvailability(v)}
      />
    </Dialog>
  );
}

export default UpdateAvailabilityDialog;
