import { SvgIcon } from "@mui/material";
import { IconSize } from "./index";

export function MicrosoftIcon({ size = undefined }: { size?: IconSize }) {
  return (
    <SvgIcon fontSize={size}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" version="1.1">
        <g id="surface1">
          <path
            style={{ fill: "#E64A19" }}
            d="M 7 12 L 29 4 L 41 7 L 41 41 L 29 44 L 7 36 L 29 39 L 29 10 L 15 13 L 15 33 L 7 36 Z "
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
