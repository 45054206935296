import useSWR from "swr";

import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useErrorHandler, useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { ContactWithDetails } from "../types";

interface Filters {
  firstName: string;
  lastName: string;
  email: string;
  account: string;
  tags: number[];
  logicField: string;
  routingField: string;
  unsubscribed: boolean;
  createdAfter: Date;
}

interface Query {
  filter?: Partial<Filters>;
  sort?: {
    field:
      | "firstName"
      | "lastName"
      | "email"
      | "account"
      | "logicField"
      | "routingField"
      | "lastMeetingActivity"
      | "unsubscribed";
    order: "asc" | "desc";
  };
}

export function useContactsV2(limit: number, offset: number, query?: Query) {
  const service = useUserService();
  const accessToken = getUserToken();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const errorHandler = useErrorHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const body = { ...query, paging: { limit, offset } };

  const { data, error } = useSWR([`/api/contacts/search`, body], (u: string) =>
    service
      .post(u)
      .set(headers)
      .send(body)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler)
  );

  return {
    data: data as { total: number; data: ContactWithDetails[] },
    error,
    loading: !error && !data,
  };
}
