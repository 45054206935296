import React, { useState } from "react";

import { QuickLaunchDialog } from "./dialogs";
import PrimaryButton from "./buttons/PrimaryButton";
import { RocketLaunchIcon } from "src/icons";

function QuickLaunch({ disabled }: { disabled?: boolean }) {
  const [showCreateMeetingModal, setShowCreateMeetingModal] = useState(false);

  return (
    <>
      <PrimaryButton
        icon={<RocketLaunchIcon />}
        disabled={disabled}
        onClick={() => setShowCreateMeetingModal(true)}
      >
        Launcher
      </PrimaryButton>

      {showCreateMeetingModal && (
        <QuickLaunchDialog
          dialog
          open={showCreateMeetingModal}
          onClose={() => setShowCreateMeetingModal(false)}
        />
      )}
    </>
  );
}

export default QuickLaunch;
