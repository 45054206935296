import React from "react";
import { Chip, Paper, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";

import useImportExportRepository from "../repository/importExport";
import { useCustomFetchFn } from "../components/fetch";
import { downloadBlob } from "../utils/helpers";
import { DownloadIcon } from "../icons";
import Table from "./tables/Table";
import PrimaryButton from "./buttons/PrimaryButton";
import useGeneralNotifications from "../hooks/useGeneralNotifications";
import {
  colorFromStatus,
  DataExport,
  DownloadStatus,
  ObjectType,
  objectTypeToLabel,
} from "../types";

const TIME_FORMAT = "MM/DD/YYYY h:mma";

export function RecentExports({
  exports,
  loading,
}: {
  exports?: DataExport[];
  loading?: boolean;
}) {
  const { fetch } = useCustomFetchFn();
  const { requestCsvDownload } = useImportExportRepository();
  const { addError } = useGeneralNotifications();

  return (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography>Recent Imports and Exports</Typography>
      </Stack>
      <Paper elevation={0}>
        <Table
          loading={loading}
          minRows={5}
          data={
            exports?.map((item, idx) => ({
              date: dayjs(item.createdAt).format(TIME_FORMAT),
              id: idx + 1,
              objectType: objectTypeToLabel(item.type as ObjectType),
              refId: item.id,
              status: item.status,
            })) || []
          }
          columns={[
            {
              id: "created",
              label: "Date Created",
              component: (row) => <>{row.date}</>,
            },
            {
              id: "status",
              label: "Status",
              component: (row) => {
                return (
                  <Chip
                    label={row.status}
                    color={colorFromStatus(row.status as DownloadStatus)}
                    variant="outlined"
                  />
                );
              },
            },
            {
              id: "type",
              label: "Type",
              component: (row) => <>{row.objectType}</>,
            },
            {
              id: "link",
              label: "Download Link",
              component: (row) => {
                return !row.objectType?.includes("Import") ? (
                  <PrimaryButton
                    disabled={row.status !== "completed"}
                    onClick={() => {
                      requestCsvDownload(row.refId)
                        .then(({ message }) => fetch(message))
                        .then((res) => res?.blob())
                        .then((blob) => downloadBlob(blob, row.objectType))
                        .catch((err) => {
                          addError(
                            "Unable to download CSV. Please try again later"
                          );
                        });
                    }}
                    icon={<DownloadIcon />}
                  >
                    Download
                  </PrimaryButton>
                ) : (
                  <></>
                );
              },
            },
          ]}
        />
      </Paper>
    </Stack>
  );
}
