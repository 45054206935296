import { useRef } from "react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { getUserDetails } from "src/utils/jwtToken";

dayjs.extend(advancedFormat);

export const SYSTEM_FIELDS = {
  GREETING: "greeting",
  GUEST_ACCOUNT: "guest_account",
  GUEST_FIRST_NAME: "guest_first_name",
  GUEST_LAST_NAME: "guest_last_name",
  GUEST_LOGIC_FIELD: "guest_logic_field",
  GUEST_LOGIC_FIELD_2: "guest_logic_field_2",
  GUEST_PHONE: "guest_phone",
  HOST_COMPANY: "host_company",
  HOST_FIRST_NAME: "host_first_name",
  HOST_LAST_NAME: "host_last_name",
  HOST_LOCATION: "host_location",
  HOST_TITLE: "host_title",
  HOST_SIGNATURE: "host_signature",
  MEETING_DATE_TIME: "meeting_date_time",
  MEETING_DAY_OF_WEEK: "meeting_day_of_week",
  MEETING_DURATION: "meeting_duration",
  MEETING_LINK: "meeting_link",
  MEETING_MONTH_DAY: "meeting_month_day",
  MEETING_TIME: "meeting_time",
  TIME_CASUAL_DAY: "time_casual_day",
  TIME_NUMBER_DATE: "time_number_date",
  GUEST_CUSTOM_FIELD_1: "guest_custom_field_1",
  GUEST_CUSTOM_FIELD_2: "guest_custom_field_2",
  GUEST_CUSTOM_FIELD_3: "guest_custom_field_3",
  GUEST_CUSTOM_FIELD_4: "guest_custom_field_4",
  GUEST_CUSTOM_FIELD_5: "guest_custom_field_5",
  GUEST_CUSTOM_FIELD_6: "guest_custom_field_6",
  GUEST_CUSTOM_FIELD_7: "guest_custom_field_7",
  GUEST_CUSTOM_FIELD_8: "guest_custom_field_8",
  GUEST_CUSTOM_FIELD_9: "guest_custom_field_9",
  GUEST_CUSTOM_FIELD_10: "guest_custom_field_10",
};

const SYSTEM_FIELDS_SAMPLES = {
  [SYSTEM_FIELDS.GUEST_ACCOUNT]: "Space X",
  [SYSTEM_FIELDS.GUEST_FIRST_NAME]: "Elon",
  [SYSTEM_FIELDS.GUEST_LAST_NAME]: "Musk",
  [SYSTEM_FIELDS.GUEST_LOGIC_FIELD]: "Variable Field 1",
  [SYSTEM_FIELDS.GUEST_LOGIC_FIELD_2]: "Variable Field 2",
  [SYSTEM_FIELDS.GUEST_PHONE]: "254 840-5771",
  [SYSTEM_FIELDS.GREETING]: "Good evening",
  [SYSTEM_FIELDS.MEETING_DATE_TIME]: "Tuesday 11/5 @ 2:30 pm CST",
  [SYSTEM_FIELDS.MEETING_DAY_OF_WEEK]: "Tuesday",
  [SYSTEM_FIELDS.MEETING_LINK]: "http://url.kronologic.ai",
  [SYSTEM_FIELDS.MEETING_MONTH_DAY]: dayjs().format("MMMM Do"),
  [SYSTEM_FIELDS.MEETING_TIME]: "2:30 pm CST",
  [SYSTEM_FIELDS.TIME_CASUAL_DAY]: "In a few days",
  [SYSTEM_FIELDS.TIME_NUMBER_DATE]: dayjs().format("Do"),
  [SYSTEM_FIELDS.MEETING_DURATION]: "30 minutes",
  [SYSTEM_FIELDS.HOST_COMPANY]: "Kronologic",
  [SYSTEM_FIELDS.HOST_TITLE]: "Manager",
  [SYSTEM_FIELDS.HOST_FIRST_NAME]: "Igor",
  [SYSTEM_FIELDS.HOST_LAST_NAME]: "Lopez",
  [SYSTEM_FIELDS.HOST_LOCATION]: "Austin, TX",
  [SYSTEM_FIELDS.HOST_SIGNATURE]: "Igor Lopez",
  [SYSTEM_FIELDS.GUEST_CUSTOM_FIELD_1]: "Contact Custom Field 1",
  [SYSTEM_FIELDS.GUEST_CUSTOM_FIELD_2]: "Contact Custom Field 2",
  [SYSTEM_FIELDS.GUEST_CUSTOM_FIELD_3]: "Contact Custom Field 3",
  [SYSTEM_FIELDS.GUEST_CUSTOM_FIELD_4]: "Contact Custom Field 4",
  [SYSTEM_FIELDS.GUEST_CUSTOM_FIELD_5]: "Contact Custom Field 5",
  [SYSTEM_FIELDS.GUEST_CUSTOM_FIELD_6]: "Contact Custom Field 6",
  [SYSTEM_FIELDS.GUEST_CUSTOM_FIELD_7]: "Contact Custom Field 7",
  [SYSTEM_FIELDS.GUEST_CUSTOM_FIELD_8]: "Contact Custom Field 8",
  [SYSTEM_FIELDS.GUEST_CUSTOM_FIELD_9]: "Contact Custom Field 9",
  [SYSTEM_FIELDS.GUEST_CUSTOM_FIELD_10]: "Contact Custom Field 10",
};

export function useDynamicVariables() {
  const { firstName, lastName, meetingLink, title } = getUserDetails();
  let fieldsToUse = Object.keys(SYSTEM_FIELDS);
  const values = useRef(
    fieldsToUse.map((key) => {
      const value = SYSTEM_FIELDS[key];
      if (key === "HOST_FIRST_NAME" && firstName) {
        return {
          sample: firstName,
          value,
        };
      }
      if (key === "HOST_LAST_NAME" && lastName) {
        return {
          sample: lastName,
          value,
        };
      }
      if (key === "MEETING_LINK" && meetingLink) {
        return {
          sample: meetingLink,
          value,
        };
      }
      if (key === "HOST_TITLE" && title) {
        return {
          sample: title,
          value,
        };
      }
      return {
        sample: SYSTEM_FIELDS_SAMPLES[value],
        value,
      };
    })
  );
  return values.current;
}

function mapDynamicVariablesWithSampleData(value) {
  if (typeof value !== "string") {
    return null;
  }
  const dynamicFieldsObj = {};
  const regExp = /{{([^}]+)}}/g;
  let curMatch = regExp.exec(value);
  while (curMatch) {
    [, dynamicFieldsObj[curMatch[1]]] = curMatch;
    curMatch = regExp.exec(value);
  }

  if (!dynamicFieldsObj) {
    return {};
  }

  return Object.values(dynamicFieldsObj).reduce((acc, obj) => {
    const originalValue =
      SYSTEM_FIELDS_SAMPLES[SYSTEM_FIELDS[obj.toUpperCase()]];
    return acc.replace(new RegExp(`{{${obj}}}`, "g"), originalValue);
  }, value);
}

export default function useDynamicVariableMappings(value) {
  if (Array.isArray(value)) {
    return value.map(mapDynamicVariablesWithSampleData);
  }
  return mapDynamicVariablesWithSampleData(value);
}

export function getDynamicVariableMappings(value) {
  if (Array.isArray(value)) {
    return value.map(mapDynamicVariablesWithSampleData);
  }
  return mapDynamicVariablesWithSampleData(value);
}
