import React from "react";
import { Link, useLocation } from "react-router-dom";
import cx from "classnames";

import { LargeSpacing } from "../components/spacing";
import { H6 } from "../components/typography";
import { useSelectedMeetingType } from "./context";
import validateMeetingType from "./useValidateMeetingType";
import StepCompletion from "./StepCompletion";
import style from "./style.module.scss";

export default function MeetingTypeSideNavItem({
  name = null,
  to,
  text,
  pathKey,
  validationKey,
}) {
  const [meetingType] = useSelectedMeetingType();
  const meetingTypeValidation = validateMeetingType({
    meetingType,
  });
  const { summary = null } = meetingTypeValidation[validationKey] || {};
  const { pathname } = useLocation();
  const isCurrentItem = pathname.includes(pathKey || text.toLowerCase());
  return (
    <H6 className={style.meetingType__navItem}>
      <div className={style.meetingType__stepNavListItemContainer}>
        {summary && <StepCompletion completion={summary.complete} />}
      </div>
      <LargeSpacing />
      <Link
        className={cx(style.meetingType__navItemLink, {
          [style["meetingType__navItemLink--current"]]: isCurrentItem,
        })}
        name={name}
        to={to}
      >
        {text}
      </Link>
    </H6>
  );
}
