import React from "react";
import { getInitials } from "../../../meetings/instanceDetails/util/helpers";

import Flexbox from "../../flexbox/FlexboxV2";
import InitialsIcon from "../../initials";
import { JumboSpacing } from "../../spacing";
// import { useMeetingInstanceState } from '../state';
// import { getInitials } from '../util/helpers';

const WIDTH = "40px";
const HEIGHT = "40px";
const INITIALS_SIZE = { medium: true };

function MeetingTypeDetailsGuestList({ host, guests }) {
  // const {
  //   meetingInstance: { host, guests },
  // } = useMeetingInstanceState();

  // const guestList = useMemo(() => [...guests.values()], [guests]);

  // const hostInitials = useMemo(() => {
  //   if (host === null) {
  //     return '';
  //   }
  //   return getInitials(host);
  // }, [host]);
  const hostInitials = getInitials(host);
  return (
    <Flexbox.Column>
      <Flexbox.Row height="fit-content" justifyContent="start">
        <JumboSpacing horizontal />
        {/* {host === null && guestList.length === 0 && (
            <InitialsIcon width={WIDTH} height={HEIGHT} />
          )}

          {/* Host always at front with no status */}
        {host !== null && (
          <InitialsIcon
            width={WIDTH}
            height={HEIGHT}
            size={INITIALS_SIZE}
            initials={hostInitials}
          />
        )}
        {guests.length !== 0 && (
          <>
            {guests.map((guest) => (
              <InitialsIcon
                key={guest?.id}
                width={WIDTH}
                height={HEIGHT}
                size={INITIALS_SIZE}
                showStatus
                status={guest?.status}
                initials={getInitials(guest)}
              />
            ))}
          </>
        )}
      </Flexbox.Row>
    </Flexbox.Column>
  );
}

export default MeetingTypeDetailsGuestList;
