import React, { forwardRef, useCallback } from "react";
import Asterik from "../Asterik";
import Flexbox from "../../components/flexbox/FlexboxV2";
import { SmallSpacing } from "../../components/spacing";
import { P1 } from "../../components/typography";
import { useInviteNotes } from "../context";
import style from "../style.module.scss";

const InviteDescription = forwardRef(function InviteLocationWithRef(
  { isEditable },
  ref
) {
  const [, setNotes] = useInviteNotes();
  const updateNotes = useCallback(() => {
    setNotes(ref.current.value);
  }, [ref, setNotes]);
  return (
    <Flexbox.Column data-ref-id="invite.notes" flex={1}>
      <P1>
        Invite Message
        <Asterik />
      </P1>
      <SmallSpacing />
      <textarea
        className={style.meetingType__textArea}
        disabled={!isEditable}
        name="/meetingtypes/configuration/invite/invite_message"
        ref={ref}
        onInput={updateNotes}
        placeholder="Notes"
      />
    </Flexbox.Column>
  );
});

export default InviteDescription;
