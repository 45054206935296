import useSWR from "swr";

import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useDedupeInterval } from "../configuration";
import { useErrorHandler, useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { GuestStatus, Meeting, MeetingStatus } from "../types";

interface MeetingQueryV1 {
  filter: {
    query: string;
    categories: string[];
    guestStatusFilter: GuestStatus[];
    dateFilter?: {
      end?: string; // ex. 2023-03-24T23:59:59
      start?: string; // ex. 2023-03-24T23:59:59
      timezone?: string;
    };
    lastActivityFilter?: {
      end?: string; // ex. 2023-03-24T23:59:59
      start?: string; // ex. 2023-03-24T23:59:59
      timezone?: string;
    };
    tagFilter: number[];
    meetingTypeFilter: number[];
    meetingStatusFilter: MeetingStatus[];
  };
  sort: { field: "meetingTime" | "lastActivity"; order: "asc" | "desc" };
  paging: { limit: number; offset: number };
}

interface MeetingQueryV2 {
  filter: {
    guestEmail: string;
    hostEmail: string;
    notes: string;
    guestStatusFilter: GuestStatus[];
    dateFilter?: {
      end?: string; // ex. 2023-03-24T23:59:59
      start?: string; // ex. 2023-03-24T23:59:59
      timezone?: string;
    };
    lastActivityFilter?: {
      end?: string; // ex. 2023-03-24T23:59:59
      start?: string; // ex. 2023-03-24T23:59:59
      timezone?: string;
    };
    tagFilter: number[];
    meetingTypeFilter: number[];
    meetingStatusFilter: MeetingStatus[];
  };
  sort: { field: "meetingTime" | "lastActivity"; order: "asc" | "desc" };
  paging: { limit: number; offset: number };
}

type MeetingQuery = MeetingQueryV1 | MeetingQueryV2;

export function useMeetingSearchResults(query: Partial<MeetingQuery>) {
  const accessToken = getUserToken();
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const errorHandler = useErrorHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const interval = useDedupeInterval();

  const { data, error } = useSWR(
    [`/api/meetings/instances/search`, query],
    (url: string, body: object) =>
      service
        .post(url)
        .set(headers)
        .send(body)
        .then(tokenRefreshHandler)
        .then((res: Response) => res.body)
        .catch(errorHandler),
    { dedupingInterval: interval }
  );

  return {
    data: data as { total: number; data: Meeting[] } | undefined,
    error,
    loading: !error && !data,
  };
}
