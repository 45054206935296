import React, { useMemo } from "react";
import Flexbox from "../../flexbox";
import { P1 } from "../../typography";
import { cleanNote } from "../util/helpers";
import style from "./messageTypes.module.scss";

function SystemNotePreview({ message }) {
  const cleanedNote = useMemo(() => cleanNote(message), [message]);
  return (
    <Flexbox>
      {({ Column }) => (
        <Column className={style.systemNote} height="fit-content">
          <P1 bold className={style.systemNote__text}>
            {cleanedNote}
          </P1>
        </Column>
      )}
    </Flexbox>
  );
}

export default SystemNotePreview;
