import React, { useLayoutEffect, useMemo, useRef } from "react";
import { FixedSizeList as List } from "react-window";
import { mdiAlertCircle, mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";

import Flexbox from "../components/flexbox/FlexboxV2";
import MeetingTypesListItem from "./MeetingTypesListItem";
import { useSize } from "../components/size";
import { JumboSpacing } from "../components/spacing";
import { P1 } from "../components/typography";

import style from "./style.module.scss";

function Row({ data, index, style: listItemStyle }) {
  const {
    meetingTypes,
    onClickCopy,
    onClickDelete,
    onClickSelect,
    openTags,
    renderIcon,
    selectedMeetingTypeId,
    showCopyButton,
    showDeleteButton,
    updateMeetingType,
  } = data;
  const meetingType = meetingTypes[index];
  return (
    <MeetingTypesListItem
      key={meetingType.id}
      meetingType={meetingType}
      onClickCopy={onClickCopy}
      onClickDelete={onClickDelete}
      onClickSelect={onClickSelect}
      openTags={openTags}
      renderIcon={renderIcon}
      selectedMeetingTypeId={selectedMeetingTypeId}
      showCopyButton={showCopyButton}
      showDeleteButton={showDeleteButton}
      updateMeetingType={updateMeetingType}
      style={listItemStyle}
    />
  );
}

export default function MeetingTypesList({
  data: meetingTypes,
  error,
  loading,
  onClickCopy,
  onClickDelete,
  onClickSelect,
  openTags,
  renderIcon,
  scrollToItem,
  selectedMeetingTypeId,
  setScrollToItem,
  showCopyButton,
  showDeleteButton,
  updateMeetingType, // TODO(Matt): Can this be renamed to update
}) {
  const columnRef = useRef(null);
  const listRef = React.createRef();
  const { height: columnHeight, width: columnWidth } = useSize(columnRef);
  const itemData = useMemo(() => {
    return {
      meetingTypes,
      onClickCopy,
      onClickDelete,
      onClickSelect,
      openTags,
      renderIcon,
      selectedMeetingTypeId,
      showCopyButton,
      showDeleteButton,
      updateMeetingType,
    };
  }, [
    meetingTypes,
    openTags,
    onClickCopy,
    onClickDelete,
    onClickSelect,
    renderIcon,
    selectedMeetingTypeId,
    showCopyButton,
    showDeleteButton,
    updateMeetingType,
  ]);
  useLayoutEffect(() => {
    if (scrollToItem && !loading && meetingTypes?.length > 0) {
      const index = meetingTypes.findIndex(
        (mt) => mt.id === selectedMeetingTypeId
      );
      listRef.current.scrollToItem(index, "center");
      setScrollToItem(false);
    }
  }, [
    listRef,
    meetingTypes,
    loading,
    scrollToItem,
    selectedMeetingTypeId,
    setScrollToItem,
  ]);
  return (
    <Flexbox.Column
      className={style.meetingTypes__list}
      height="100%"
      ref={columnRef}
    >
      {error && (
        <Flexbox.Column
          alignItems="center"
          height="80%"
          justifyContent="center"
        >
          <Icon path={mdiAlertCircle} size={8} />
          <JumboSpacing />
          <P1 className={style.meetingType__listErrorText}>
            Failed to load Meeting Templates. Please try again later.
          </P1>
        </Flexbox.Column>
      )}
      {loading && (
        <Flexbox.Column alignItems="center" flex="1" justifyContent="center">
          <Icon path={mdiLoading} size={8} spin={1} />
          <JumboSpacing />
          <P1>Loading Meeting Templates...</P1>
        </Flexbox.Column>
      )}
      {!loading && error === null && (
        <List
          height={columnHeight - 100}
          itemCount={meetingTypes.length}
          itemData={itemData}
          itemSize={120}
          ref={listRef}
          width={columnWidth - 24}
        >
          {Row}
        </List>
      )}
    </Flexbox.Column>
  );
}
