import { useRoutingLogic, useTeamMembersContext } from "./context";

function getUniqueOrder(userIds) {
  const cache = {};
  const newUsers = [];
  for (let i = 0; i < userIds.length; i += 1) {
    const userId = userIds[i];
    if (!cache[userId]) {
      cache[userId] = true;
      newUsers.push(userId);
    }
  }
  return newUsers;
}

export default function useOrderedUsers() {
  const work = useTeamMembersContext();
  const { areTeamMembersLoading, teamMembers } = work;
  const [routingLogic] = useRoutingLogic();
  if (areTeamMembersLoading) {
    return null;
  }
  if (teamMembers === null) {
    return [];
  }
  const { sequential } = routingLogic;
  const { order } = sequential;
  const uniqueOrder = getUniqueOrder(order || []);
  const remaining = teamMembers.filter((u) => !uniqueOrder.includes(u.id));
  const newOrderedUsers = [
    ...uniqueOrder
      .map((orderUserId) => {
        return teamMembers.find((u) => u.id === orderUserId);
      })
      // Some users may have been deleted
      .filter((user) => user !== undefined),
    ...remaining,
  ];
  const { custom } = routingLogic;
  return newOrderedUsers.map((user) => {
    const { id } = user;
    let isEnabled = true;
    // If sequential routing the user is enabled via whether or not its in the order
    if (custom && custom[id]) {
      isEnabled = custom[id].enabled;
      const mappingLogic = custom[id]?.mapping_logic || [];
      return {
        ...user,
        isEnabled,
        mappingLogic,
      };
    }
    return {
      ...user,
      isEnabled,
      mappingLogic: [],
    };
  });
}
