import React from "react";
import moment from "moment";
import { getInitials } from "../../../meetings/instanceDetails/util/helpers";
import Flexbox from "../../flexbox";
import InitialsIcon from "../../initials";
import { P1 } from "../../typography";
import { GUEST_STATUS_TO_LABEL } from "../../../meetings/props";
import style from "./messageTypes.module.scss";

function GuestMessagePreview({ guest, status, message, occurrence }) {
  const guestEmail = guest.email;
  const guestInitials = getInitials(guest);

  const guestStatus = `${GUEST_STATUS_TO_LABEL[status]} - ${moment(
    occurrence
  ).format("ddd M/DD h:mma")}`;

  return (
    <Flexbox>
      {({ Column, Row }) => (
        <Column height="fit-content" alignItems="flex-start">
          <Column
            className={style.guestMessage}
            width="fit-content"
            alignItems="flex-start"
            style={{ maxWidth: "65%" }}
          >
            <P1 className={style.guestMessage__email}>{guestEmail}</P1>

            <Column
              alignItems="flex-start"
              className={style.guestMessage__body}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </Column>

          <Row
            className={style.guestMessage__icon_row}
            justifyContent="flex-start"
          >
            <InitialsIcon initials={guestInitials} />
            <P1 className={style.guestMessage__status}>{guestStatus}</P1>
          </Row>
        </Column>
      )}
    </Flexbox>
  );
}

export default GuestMessagePreview;
