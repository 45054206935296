import useSWR from "swr";

import { buildQueryString } from "../utils";
import { useUserService } from "../services";
import { useErrorHandler, useTokenRefreshHandler } from "../hooks";

export function useAuthUrl(
  email: string | null,
  redirectUrl?: string,
  scopes?: string[]
) {
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const errorHandler = useErrorHandler();

  const params = buildQueryString({
    email,
    redirectUrl,
    scopes: scopes?.join(","),
  });

  const { data, error } = useSWR(
    email ? `/api/user/sign_in/web/url?${params}` : null,
    (url: string) =>
      service
        .get(url)
        .then(tokenRefreshHandler)
        .then((res: Response) => res.body)
        .catch(errorHandler)
  );

  return {
    data: data as { [index: string]: string } | undefined,
    error,
    loading: !error && !data,
  };
}
