import React from "react";

import Flexbox from "../components/flexbox/FlexboxV2";
import {
  JumboSpacing,
  MediumSpacing,
  SmallSpacing,
  XXLargeSpacing,
} from "../components/spacing";
import { P1 } from "../components/typography";
import Step from "./Step";
import { useDayRange } from "./context";

import Gap from "./sliders/Gap";
import Duration from "./sliders/Duration";
import DayRange from "./sliders/DayRange";
import MinimumSchedulingNotice from "./sliders/MinimumSchedulingNotice";
import SameDay from "./sliders/SameDay";

const SAME_DAY_SCHED_INFO = `
*From the moment of meeting activation, Kronologic will impose an
automatic 3-hour minimum notice in addition to the below Minimum 
Scheduling Notice. This is to avoid sending out multiple new time 
proposals in quick succession to the same contact.`;

const ROLLOVER_INFO = `
In the event that the Same Day Minimum Notice period rolls over 
into the next day when an meeting is activated, Kronologic will 
treat that meeting as if it did not have Same Day Scheduling.
`;

function TimeAttribute({ children, description, stepNumber, warn: isWarn }) {
  return (
    <Flexbox.Row>
      <XXLargeSpacing />
      <Flexbox.Column>
        <Flexbox.Row>
          <Step
            stepNumber={stepNumber}
            description={description}
            warn={isWarn}
          />
        </Flexbox.Row>
        <MediumSpacing />
        <Flexbox.Row>
          <XXLargeSpacing />
          {children}
        </Flexbox.Row>
      </Flexbox.Column>
    </Flexbox.Row>
  );
}

export default function TimeAttributes() {
  const [{ from }] = useDayRange();
  return (
    <Flexbox.Column>
      <TimeAttribute description="Gap" stepNumber={1}>
        <Gap />
      </TimeAttribute>
      <JumboSpacing />
      <TimeAttribute description="Duration" stepNumber={2}>
        <Duration />
      </TimeAttribute>
      <JumboSpacing />
      <TimeAttribute description="Day Range" stepNumber={3}>
        <DayRange />
      </TimeAttribute>
      <JumboSpacing />
      {from === 0 && (
        <>
          <TimeAttribute
            description="Same Day Minimum Scheduling Notice*"
            stepNumber="!"
            warn
          >
            <Flexbox.Column>
              <P1>{SAME_DAY_SCHED_INFO}</P1>
              <SmallSpacing />
              <P1>{ROLLOVER_INFO}</P1>
              <SmallSpacing />
              <SameDay />
            </Flexbox.Column>
          </TimeAttribute>
          <JumboSpacing />
        </>
      )}
      <TimeAttribute description="Minimum Scheduling Notice" stepNumber={4}>
        <MinimumSchedulingNotice />
      </TimeAttribute>
    </Flexbox.Column>
  );
}
