import React, { useMemo } from "react";
import { BLACK, WHITE } from "../../components/colors";
import { useTheme } from "../../components/theme";

export const DropdownIndicator = () => <span />;

const useMeetingTypeSelectTheme = (valueColor) => {
  const { isDark } = useTheme();

  return useMemo(
    () => ({
      container: (base, { isFocused }) => {
        let backgroundColor = "inherit";

        if (isFocused) {
          backgroundColor = isDark ? "#484B68" : WHITE;
        }

        return {
          ...base,
          backgroundColor,
          borderRadius: "3px",
          color: WHITE,
          cursor: "pointer",
          width: "100%",
        };
      },
      control: (base) => ({
        ...base,
        backgroundColor: "inherit",
        border: 0,
        borderRadius: "3px",
        boxShadow: 0,
        cursor: "pointer",
        flexDirection: "row-reverse",
        fontSize: "10px",
        height: "100%",
        padding: "0px",
      }),
      indicatorSeparator: (base) => ({
        ...base,
        backgroundColor: "inherit",
      }),
      indicatorsContainer: (base) => ({
        ...base,
        padding: "0px",
      }),
      input: (base) => ({
        ...base,
        alignItems: "center",
        backgroundColor: "inherit",
        color: isDark ? WHITE : BLACK,
        fontSize: "14px",
      }),
      loadingIndicator: (base) => ({
        ...base,
        color: isDark ? BLACK : WHITE,
        position: "absolute",
        right: "0",
      }),
      menu: (base) => ({
        ...base,
        marginTop: "0",
      }),
      menuList: (base) => ({
        ...base,
        border: `0.5px solid ${isDark ? "#191C3A" : "#A8B1BD"}`,
        borderRadius: 0,
        marginTop: "0px",
        padding: "0px",
      }),
      option: (base, { isFocused }) => {
        let backgroundColor;

        if (isDark) {
          backgroundColor = isFocused ? "rgba(121, 125, 149, 0.7)" : "#414564";
        }

        if (!isDark) {
          backgroundColor = isFocused ? "#F1FAFF" : WHITE;
        }

        return {
          ...base,
          backgroundColor,
          border: `0.5px solid ${isDark ? "#191C3A" : "#A8B1BD"}`,
          color: isDark ? WHITE : BLACK,
          cursor: "pointer",
          fontSize: "10px",
          paddingBottom: "7px",
          paddingTop: "7px",
        };
      },
      placeholder: (base, { isFocused }) => ({
        ...base,
        display: isFocused ? "none" : "flex",
        width: "100%",
      }),
      singleValue: (base) => {
        let color;

        if (valueColor) {
          color = valueColor;
        }

        if (!valueColor) {
          color = isDark ? WHITE : BLACK;
        }

        return {
          ...base,
          color,
          fontSize: "1.2em",
          fontWeight: "bold",
          lineHeight: "16px",
          margin: 0,
          maxWidth: "unset",
          overflow: "unset",
          textAlign: "center",
          whiteSpace: "unset",
        };
      },
      valueContainer: (base) => ({
        ...base,
        cursor: "pointer",
        display: "flex",
        fontSize: "1.2em",
        justifyContent: "center",
        overflow: "visible",
      }),
    }),
    [isDark, valueColor]
  );
};

export default useMeetingTypeSelectTheme;
