import { useMemo } from "react";
import { useDynamicVariables } from "./useDynamicVariableMappings";

export default function useSearchDynamicVariables(dynamicVariableSearchText) {
  const variables = useDynamicVariables();
  return useMemo(() => {
    if (
      dynamicVariableSearchText === null ||
      dynamicVariableSearchText.length < 1
    ) {
      return variables;
    }
    return variables.filter(({ value }) => {
      return value.indexOf(dynamicVariableSearchText) > -1;
    }, []);
  }, [dynamicVariableSearchText, variables]);
}
