import React, { useCallback, useMemo } from "react";
import Icon from "@mdi/react";
import { mdiPlus } from "@mdi/js";
import Flexbox from "../../components/flexbox/FlexboxV2";
import { AsyncSelect, hostsToOptions, hostToOption } from "../../inputs";
import { NO_OPTIONS } from "../../utils/variables";
import { DropdownIndicator } from "../guestSelect/useGuestTheme";
import useHostTheme from "./useHostTheme";
import { useUsersRepository } from "../../repository";
import { P1 } from "../../components/typography";
import { CopyButton } from "../../components/copyButton/CopyButton";
import { useUsers } from "../../queries";
import style from "./hostSelect.module.scss";

const USER_STATUS = {
  ACTIVE: "active",
  PENDING: "pending",
};

function Placeholder({ color }) {
  return (
    <Flexbox.Row>
      <Icon
        className={style.hostSelect__placeholder_text_color}
        style={{ color }}
        path={mdiPlus}
        size={1}
      />
      <P1
        className={style.hostSelect__placeholder_text_color}
        bold
        style={{ color }}
      >
        Host
      </P1>
    </Flexbox.Row>
  );
}

function HostSelect({
  disabled,
  host = null,
  setHost,
  textColor = null,
  forMeetingTransfer = false,
}) {
  const selectTheme = useHostTheme(host !== null, textColor);
  const { getUsers, getUsersForMeetingTransfer } = useUsersRepository();

  const { data: users, loading } = useUsers(100, 0);

  const selectComponents = useMemo(() => ({ DropdownIndicator }), []);

  const initialHostOptions = useMemo(
    () => hostsToOptions(users?.data),
    [users]
  );

  const noOptionsMessage = useCallback(() => NO_OPTIONS.hosts, []);

  const getUserAPI =
    (forMeetingTransfer && getUsersForMeetingTransfer) || getUsers;

  const searchHosts = useCallback(
    (qry) =>
      getUserAPI({
        qry,
        // we only allow routing to active users
        statusFilter: USER_STATUS.ACTIVE,
      }),
    [getUserAPI]
  );

  const onChangeHost = useCallback(
    (h) => {
      setHost(h.value);
    },
    [setHost]
  );

  return (
    <Flexbox.Row alignItems="center">
      <AsyncSelect
        name="/instances/action_bar/add_new_instance/host"
        disabled={disabled}
        components={selectComponents}
        customTheme={selectTheme}
        isLoading={loading}
        isMulti={false}
        load={searchHosts}
        noOptionsMessage={noOptionsMessage}
        onChange={onChangeHost}
        defaultOptions={initialHostOptions}
        placeholder={<Placeholder color={textColor} />}
        toOption={hostToOption}
        value={hostToOption(host)}
      />
      {host && (
        <div
          style={{
            marginLeft: "5px",
            marginRight: "5px",
            marginTop: "2.5px",
          }}
        >
          <CopyButton text={host.email} />
        </div>
      )}
    </Flexbox.Row>
  );
}

export default HostSelect;
