import { useSWRConfig } from "swr";

import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useErrorHandler, useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { CalendarTokenStatus, UserStatus } from "../types";

interface QueryV1 {
  calendarTokenStatusFilter: CalendarTokenStatus[];
  qry: string;
  selectedTeams: number[];
  statusFilter: UserStatus[];
  tagFilter: number[];
  sortBy: {
    field: string;
    order: "asc" | "desc";
  };
}

interface QueryV2 {
  calendarTokenStatusFilter: CalendarTokenStatus[];
  firstName: string;
  lastName: string;
  email: string;
  role: number[];
  selectedTeams: number[];
  statusFilter: UserStatus[];
  tagFilter: number[];
  sortBy: {
    field: string;
    order: "asc" | "desc";
  };
}

// This typing is specified this way because while the backend does not return
// a hard error if the generic search "qry" parameter is specified along with
// firstName, lastName, and email it is still considered an errors as these two
// uses are considered mutually exlusive. The qry parameter will eventually be
// deprecated but both need to be allowed until the old users page id full
// deprecated.
type Query = QueryV1 | QueryV2;

export function useExportUsers() {
  const accessToken = getUserToken();
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const errorHandler = useErrorHandler();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (query: Query): Promise<void> =>
    service
      .post(`/api/users/export/csv`)
      .set(headers)
      .send({
        calendarTokenStatusFilter: query.calendarTokenStatusFilter,
        qry: (query as QueryV1).qry,
        firstName: (query as QueryV2).firstName,
        lastName: (query as QueryV2).lastName,
        email: (query as QueryV2).email,
        role: (query as QueryV2).role,
        team: query.selectedTeams,
        statusFilter: query.statusFilter,
        tag: query.tagFilter,
        sortBy: query.sortBy.field + " " + query.sortBy.order,
      })
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .then((res: any) => {
        // @ts-ignore
        const keys = cache.keys();

        // Invalidate meeting queries and force refresh.
        Array.from(keys)
          .filter((r: any) => r.startsWith("/api/exports"))
          .forEach((k: any) => {
            mutate(k);
          });

        return res;
      })
      .catch(errorHandler);
}
