import React from "react";
import {
  Box,
  Drawer as MuiDrawer,
  Typography,
  Stack,
  useTheme,
  DrawerProps,
  IconButton,
} from "@mui/material";
import { CloseIcon } from "src/icons";

function Drawer({
  anchor,
  title,
  open,
  onClose,
  actions,
  children,
}: {
  title: string;
  actions?: React.ReactNode;
  children?: React.ReactNode;
  onClose?: () => void;
} & Pick<DrawerProps, "anchor" | "open">) {
  const theme = useTheme();

  return (
    <MuiDrawer anchor={anchor} open={open} onClose={onClose}>
      <Box
        sx={{
          padding: "1em",
          width: 360,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: theme.spacing(1) }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {title}
          </Typography>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            {actions}
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
        {children}
      </Box>
    </MuiDrawer>
  );
}

export default Drawer;
