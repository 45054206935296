/* eslint-disable react/jsx-wrap-multilines */
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Channels from "../channels/Channels";
import Flexbox from "../components/flexbox/FlexboxV2";
import { Grid, GridItem } from "../components/grid";
import { LargeSpacing, SuperJumboSpacing } from "../components/spacing";
import MeetingTypeSideNavItem from "./MeetingTypeSideNavItem";
import { useActingAs, useIsDefaultUser } from "../auth";
import { ROLE_LEVELS } from "../auth/roles";
import style from "./style.module.scss";

export default function Recipients({ meetingType }) {
  const isDefaultUser = useIsDefaultUser();
  const [actingAs] = useActingAs();

  return (
    <Grid className={style.meetingType__grid} flex="1 1 100%" maxHeight="100%">
      <GridItem gridArea="nav">
        <Flexbox.Column flex="0 0 0%" alignItems="left" justifyContent="center">
          {!isDefaultUser && actingAs?.role !== ROLE_LEVELS.DEFAULT && (
            <>
              <SuperJumboSpacing />
              <MeetingTypeSideNavItem
                name="/meetingtypes/guests/channels"
                text="Channels"
                to={`/meetingTypes/user/${meetingType?.id}/recipients/channels`}
              />
            </>
          )}

          <LargeSpacing />
        </Flexbox.Column>
      </GridItem>

      <Switch>
        {!isDefaultUser && actingAs?.role !== ROLE_LEVELS.DEFAULT && (
          <Route
            path="/meetingTypes/user/:id/recipients/channels"
            render={() => (
              <GridItem
                className={style.meetingType__contentGridItem}
                gridColumnStart="content1"
                gridColumnEnd="content2"
              >
                <Channels meetingDefinition={meetingType} />
              </GridItem>
            )}
          />
        )}
        <Route
          path="*"
          render={() => (
            <Redirect
              to={`/meetingTypes/user/${meetingType?.id}/recipients/channels`}
            />
          )}
        />
      </Switch>
    </Grid>
  );
}
