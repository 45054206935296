import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useErrorHandler, useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";

function useGenerateOneClickUrl() {
  const accessToken = getUserToken();

  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const errorHandler = useErrorHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (
    name: string,
    meetingDefinitionId: number
  ): Promise<{
    url: string;
    meetingsCreated: number;
    createdAt: string;
    updatedAt: string;
  }> =>
    service
      .post(`/api/meetings/definition/scheduling/url`)
      .set(headers)
      .send({ meetingDefinitionId, name })
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler);
}

export default useGenerateOneClickUrl;
