import { mdiAccountKeyOutline, mdiAccountOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Grid } from "@mui/material";
import { ActionButton } from "../../../componentsV2/ActionButton";
import { PageLayout } from "../../../componentsV2/PageLayout";

function Export() {
  return (
    <PageLayout
      title="Exports"
      breadcrumbs={[
        { name: "Reports", route: "/reports" },
        { name: "Export", route: "/reports/export" },
      ]}
    >
      <Grid
        container
        sx={{ justifyContent: "center", alignItems: "center", gap: 4 }}
      >
        <Grid item>
          <ActionButton
            title="Meetings"
            icon={<Icon path={mdiAccountOutline} size={3} />}
            to="/reports/export/meetings"
          />
        </Grid>
        <Grid item>
          <ActionButton
            title="Contacts"
            icon={<Icon path={mdiAccountOutline} size={3} />}
            to="/reports/export/contacts"
          />
        </Grid>
        <Grid item>
          <ActionButton
            icon={<Icon path={mdiAccountKeyOutline} size={3} />}
            title="Users"
            to="/reports/export/users"
          />
        </Grid>
      </Grid>
    </PageLayout>
  );
}

export default Export;
