import { getUserToken } from "src/utils/jwtToken";
import { ResponseError } from "superagent";
import { useActingAsOverrideHeader } from "../auth";
import { useLogout } from "../hooks/useLogout";
import { useUserService } from "../services";

interface OpenAiResponse {
  text: string;
}

interface OpenAiRequest {
  prompt: string;
}

function useOpenAiCompletion() {
  const service = useUserService();
  const accessToken = getUserToken();
  const override = useActingAsOverrideHeader();
  const logout = useLogout();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (prompt: string): Promise<OpenAiResponse> =>
    service
      .post(`/api/openai/completion`)
      .set(headers)
      .send({ prompt } as OpenAiRequest)
      .then((res: Response) => res.body)
      .catch((err: ResponseError) => {
        if (err) {
          switch (err.status) {
            case 401:
              logout();
              break;
            case 429:
              throw new Error("Too many requests, please try again later");
            default:
              throw new Error("Error");
          }
        }
      });
}

export default useOpenAiCompletion;
