import { useHistory } from "react-router-dom";
import { getUserDetails } from "src/utils/jwtToken";
import { useActingAs } from "../auth";
import { ROLE_LEVELS, supersedes } from "../auth/roles";
import {
  useMeetingTypesForReps,
  useNewMeetingsPage,
  useNewTemplatesPage,
} from "../features";
import {
  ChannelIcon,
  ContactIcon,
  ContactsIcon,
  GraphIcon,
  IntegrationIcon,
  LogoutIcon,
  MeetingIcon,
  MeetingTypeIcon,
  MerlinIcon,
  OrganizationIcon,
  ProvisioningIcon,
  ReportIcon,
  SettingsIcon,
  UserIcon,
} from "../icons";
import { MANUAL_LOGOUT_KEY } from "../logout/Logout";
import { SuperAdminRole, SuperAdminRORole } from "../types";
import { GenericNavbar } from "./GenericNavbar";

export function Navbar() {
  const meetingTypesForReps = useMeetingTypesForReps();
  const newMeetingTemplatesPage = useNewTemplatesPage();
  const newMeetingsPage = useNewMeetingsPage();

  const loggedInUser = getUserDetails();
  const [actingAs] = useActingAs();

  const history = useHistory();

  const pages = [];

  const user = actingAs || loggedInUser;

  if (
    supersedes({
      has: user?.role,
      required: ROLE_LEVELS.ORG_ADMIN,
    })
  ) {
    pages.push({
      icon: <IntegrationIcon />,
      name: "Integrations",
      route: "/integrations",
    });
  }

  if (
    supersedes({
      has: user?.role,
      required: ROLE_LEVELS.TEAM_ADMIN,
    })
  ) {
    pages.push({
      icon: <ChannelIcon />,
      name: "Channels",
      route: "/channels",
    });
  }

  if (
    supersedes({
      has: user?.role,
      required: ROLE_LEVELS.TEAM_ADMIN,
    })
  ) {
    pages.push({
      icon: <ContactIcon />,
      name: "Contacts",
      route: "/contacts",
    });
  }

  if (
    supersedes({
      has: user?.role,
      required: ROLE_LEVELS.TEAM_ADMIN,
    }) ||
    meetingTypesForReps
  ) {
    pages.push({
      icon: <MeetingTypeIcon />,
      name: "Templates",
      route: newMeetingTemplatesPage ? "/meeting-templates" : "/meetingTypes",
    });
  }

  if (
    supersedes({
      has: user?.role,
      required: ROLE_LEVELS.TEAM_ADMIN,
    })
  ) {
    pages.push({
      icon: <UserIcon />,
      name: "Users",
      route: "/users",
    });
  }

  if (
    supersedes({
      has: user?.role,
      required: ROLE_LEVELS.DEFAULT,
    })
  ) {
    pages.push({
      icon: <MeetingIcon />,
      name: "Meetings",
      route: newMeetingsPage ? "/meetings" : "/instances",
    });
  }

  const options = [];

  options.push({
    icon: <ReportIcon />,
    name: "Reports",
    onClick: () => {
      history.push("/reports");
    },
  });

  options.push({
    icon: <SettingsIcon />,
    name: "Settings",
    onClick: () => {
      history.push("/settings/app");
    },
  });

  options.push({
    icon: <LogoutIcon />,
    name: "Logout",
    onClick: () => {
      localStorage.setItem(MANUAL_LOGOUT_KEY, "true");
      history.push("/logout");
    },
  });

  const additionalOptions = [
    {
      icon: <ContactsIcon />,
      name: "Switch Users",
      onClick: () => {
        history.push("/admin/users");
      },
    },
    {
      icon: <GraphIcon />,
      name: "Reporting",
      onClick: () => {
        history.push("/admin/reporting");
      },
    },
    {
      icon: <ProvisioningIcon />,
      name: "Provisioning",
      onClick: () => {
        history.push("/admin/provisioning");
      },
    },
    {
      icon: <OrganizationIcon />,
      name: "Org Settings",
      onClick: () => {
        history.push("/admin/orgSettings");
      },
    },
    {
      icon: <MerlinIcon />,
      name: "Merlin Chat",
      onClick: () => {
        history.push("/admin/merlin");
      },
    },
  ];

  return (
    <GenericNavbar
      pages={pages}
      options={options}
      additionalOptions={
        [SuperAdminRole, SuperAdminRORole].includes(user?.role || 0)
          ? additionalOptions
          : []
      }
      user={user}
    />
  );
}
