import React from "react";
import Icon from "@mdi/react";
import { mdiCheck } from "@mdi/js";

import style from "./style.module.scss";

export default function StepComplete() {
  return (
    <div className={style.meetingType__stepComplete}>
      <Icon
        className={style.meetingType__stepIcon}
        path={mdiCheck}
        size={0.6}
      />
    </div>
  );
}
