import { INTEGRATION_AUTH_STATUS } from "./props";

export function determineAuthStatus(integration) {
  if (integration === null || integration.tokenInfo.tokenType.length < 1) {
    return INTEGRATION_AUTH_STATUS.DISABLED;
  }

  return integration.authValid
    ? INTEGRATION_AUTH_STATUS.CURRENT
    : INTEGRATION_AUTH_STATUS.EXPIRED;
}
