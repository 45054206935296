// TODO: Eventually we will put this in the react lifecycle, but for now we leave it here.
export const USER_SERVICE_ORIGIN = window?.config?.USER_SERVICE;
export const PROVISIONING_SERVICE_ORIGIN = window?.config?.PROVISIONING_SERVICE;
const CHANNEL_SAMPLING_SERVICE_ORIGIN =
  window?.config?.CHANNEL_SAMPLING_SERVICE;
export const REPORTING_SERVICE_ORIGIN = window?.config?.REPORTING_SERVICE;

export const WEB = "web";
export const API = {
  admin: {
    links: `${USER_SERVICE_ORIGIN}/admin/leads`,
    nodes: `${USER_SERVICE_ORIGIN}/admin/nodes`,
  },
  appSettings: {
    bulkSchedulers: `${USER_SERVICE_ORIGIN}/app/settings/schedulers`,
    bulkTimezone: `${USER_SERVICE_ORIGIN}/app/settings/timezone`,
    custom: (id) =>
      `${USER_SERVICE_ORIGIN}/app/settings/user${id ? `/${id}` : ""}`,
  },
  auth: {
    google: `${USER_SERVICE_ORIGIN}/user/google/authorization_url`,
    logout: `${USER_SERVICE_ORIGIN}/user/logout`,
    o365: `${USER_SERVICE_ORIGIN}/user/microsoft/authorization_url`,
    revoke: `${USER_SERVICE_ORIGIN}/user/integrations`,
    signup: `${USER_SERVICE_ORIGIN}/user/sign_up/url`,
    url: `${USER_SERVICE_ORIGIN}/user/sign_in/web/url`,
  },
  calendar: {
    getEvent: (id) => `${USER_SERVICE_ORIGIN}/calendars/events/${id}`,
    listEvents: `${USER_SERVICE_ORIGIN}/calendars/events/list`,
    previewCalendar: (id) => `${USER_SERVICE_ORIGIN}/calendars/preview/${id}`,
    publicCalendars: `${USER_SERVICE_ORIGIN}/calendars`,
    shareCalendarsWithTeams: `${USER_SERVICE_ORIGIN}/calendars/share/teams`,
    shareCalendarsWithUsers: `${USER_SERVICE_ORIGIN}/calendars/share/users`,
  },
  channels: {
    default: (id) =>
      `${USER_SERVICE_ORIGIN}/user/channels${id ? `/${id}` : ""}`,
    import: (id) =>
      `${USER_SERVICE_ORIGIN}/user/channels/import${id ? `/${id}` : ""}`,
    intercept: (id) =>
      `${USER_SERVICE_ORIGIN}/user/channels/intercept${id ? `/${id}` : ""}`,
    update: (id) =>
      `${USER_SERVICE_ORIGIN}/user/channels/update${id ? `/${id}` : ""}`,
  },
  contacts: {
    default: (id) => `${USER_SERVICE_ORIGIN}/contact${id ? `/${id}` : ""}`,
    emails: `${USER_SERVICE_ORIGIN}/contacts/emails`,
    exportCSV: `${USER_SERVICE_ORIGIN}/contact/export/csv`,
    history: (id) => `${USER_SERVICE_ORIGIN}/people/leads/${id}`,
  },
  customerLogs: {
    meeting: (id) => `${USER_SERVICE_ORIGIN}/logs/meeting${id ? `/${id}` : ""}`,
    search: `${USER_SERVICE_ORIGIN}/logs/search`,
  },
  importExport: {
    export: {
      exportByID: (id) => `${USER_SERVICE_ORIGIN}/exports/${id}`,
      exports: `${USER_SERVICE_ORIGIN}/exports`,
    },
    import: {
      uploadContactCSV: `${USER_SERVICE_ORIGIN}/contact/import/csv`,
      uploadUserCSV: `${USER_SERVICE_ORIGIN}/users/import/csv`,
      validateCSV: `${USER_SERVICE_ORIGIN}/imports/validate/csv`,
    },
  },
  integrations: {
    config: (id) => `${USER_SERVICE_ORIGIN}/integrations/${id}/config`,
    default: (id) => `${USER_SERVICE_ORIGIN}/integrations${id ? `/${id}` : ""}`,
    dynamics: {
      authURL: (id) =>
        `${USER_SERVICE_ORIGIN}/dynamics/authorization_url/${id}`,
      oFields: (id, ctype) =>
        `${USER_SERVICE_ORIGIN}/dynamics/object_fields/${id}/${ctype}`,
    },
    hubspot: {
      authURL: (id) => `${USER_SERVICE_ORIGIN}/hubspot/authorization_url/${id}`,
      oFields: (id, ctype) =>
        `${USER_SERVICE_ORIGIN}/hubspot/object_fields/${id}/${ctype}`,
    },
    microsoft_teams: {
      authURL: (id, userId) =>
        `${USER_SERVICE_ORIGIN}/microsoft_teams/authorization_url/${id}/${userId}`,
    },
    org: (id) => `${USER_SERVICE_ORIGIN}/integrations/org/${id}`,
    outreach: {
      authURL: (id) =>
        `${USER_SERVICE_ORIGIN}/outreach/authorization_url/${id}`,
      oFields: (id, ctype) =>
        `${USER_SERVICE_ORIGIN}/outreach/object_fields/${id}/${ctype}`,
    },
    salesforce: {
      authURL: (id) =>
        `${USER_SERVICE_ORIGIN}/salesforce/authorization_url/${id}`,
      oFields: (id, ctype) =>
        `${USER_SERVICE_ORIGIN}/salesforce/object_fields/${id}/${ctype}`,
    },
    sample: {
      sampleChannel: (id) =>
        `${CHANNEL_SAMPLING_SERVICE_ORIGIN}/sample/channels/import/${id}`,
    },
    user: (id) =>
      `${USER_SERVICE_ORIGIN}/integrations/user${id ? `/${id}` : ""}`,
    webex: {
      authURL: (id, userId) =>
        `${USER_SERVICE_ORIGIN}/webex/authorization_url/${id}/${userId}`,
    },
    zoom: {
      authURL: (id, userId) =>
        `${USER_SERVICE_ORIGIN}/zoom/authorization_url/${id}/${userId}`,
    },
    google_meet: {
      authURL: (id, userId) =>
        `${USER_SERVICE_ORIGIN}/google_meet/authorization_url/${id}/${userId}`,
    },
  },
  // Separate or rename meetings to meetingType, and separate them from instances
  meetings: {
    calendarInvite: (id) =>
      `${USER_SERVICE_ORIGIN}/meetings/instance/invite${id ? `/${id}` : ""}`,
    default: (id) =>
      `${USER_SERVICE_ORIGIN}/meetings/definition${id ? `/${id}` : ""}`,
    exportCSV: `${USER_SERVICE_ORIGIN}/meetings/instances/export/csv`,
    global: {
      default: (id) =>
        `${USER_SERVICE_ORIGIN}/meetings/global/definition${
          id ? `/${id}` : ""
        }`,
    },
    guestAction: {
      decline: {
        validate: `${USER_SERVICE_ORIGIN}/meeting/guest/action/decline/validate`,
      },
      default: `${USER_SERVICE_ORIGIN}/meeting/guest/action`,
    },
    guestStatus: `${USER_SERVICE_ORIGIN}/meeting/guest/status`,
    history: (id) =>
      `${USER_SERVICE_ORIGIN}/meetings/instance/history${id ? `/${id}` : ""}`,
    hostAvailability: `${USER_SERVICE_ORIGIN}/meeting/host/availability`,
    hostAvailable: `${USER_SERVICE_ORIGIN}/meetings/host/available`,
    instance: `${USER_SERVICE_ORIGIN}/meetings/instance`,
    instanceSendLater: `${USER_SERVICE_ORIGIN}/meetings/instance/send_later`,
    instances: `${USER_SERVICE_ORIGIN}/meetings/instances`,
    message: `${USER_SERVICE_ORIGIN}/meetings/instance/message`,
    other: `${USER_SERVICE_ORIGIN}/meetings/instances/other`,
    overrideStartTime: `${USER_SERVICE_ORIGIN}/meetings/instance/start_time`,
    queueNow: `${USER_SERVICE_ORIGIN}/meetings/instance/queue`,
    routing: `${USER_SERVICE_ORIGIN}/meetings/instances/routing`,
    scheduling: {
      default: `${USER_SERVICE_ORIGIN}/meeting/scheduling`,
      url: `${USER_SERVICE_ORIGIN}/meetings/definition/scheduling/url`,
      validate: `${USER_SERVICE_ORIGIN}/meeting/scheduling/validate`,
    },
    search: `${USER_SERVICE_ORIGIN}/meetings/instances/search`,
    stats: `${USER_SERVICE_ORIGIN}/meetings/instances/report`,
    timeline: `${USER_SERVICE_ORIGIN}/meetings/instances/timeline`,
    unsubscribeConfig: (id) =>
      `${USER_SERVICE_ORIGIN}/meetings/definition/${id}/unsubscribe`,
  },
  org: {
    default: `${USER_SERVICE_ORIGIN}/org`,
    features: (orgKey) =>
      `${USER_SERVICE_ORIGIN}/org/setting/features${
        orgKey ? `/${orgKey}` : ""
      }`,
    search: `${USER_SERVICE_ORIGIN}/org/search`,
  },
  provisioning: {
    addDomain: (orgId) =>
      `${PROVISIONING_SERVICE_ORIGIN}/provisioning/org/${orgId}/domain`,
    org: `${PROVISIONING_SERVICE_ORIGIN}/provisioning/org`,
  },
  reminder: {
    default: `${USER_SERVICE_ORIGIN}/reminder`,
  },
  reporting: {
    companyWide: `${REPORTING_SERVICE_ORIGIN}/reporting/cw`,
    orgs: `${REPORTING_SERVICE_ORIGIN}/reporting/orgs`,
  },
  tags: {
    channels: `${USER_SERVICE_ORIGIN}/tag/channels`,
    contacts: `${USER_SERVICE_ORIGIN}/tag/contacts`,
    default: (id) => `${USER_SERVICE_ORIGIN}/tags${id ? `/${id}` : ""}`,
    tagInstances: `${USER_SERVICE_ORIGIN}/tag/instances`,
    tagMeetingTypes: `${USER_SERVICE_ORIGIN}/tag/meeting_types`,
    tagTeams: `${USER_SERVICE_ORIGIN}/tag/teams`,
    tagUsers: `${USER_SERVICE_ORIGIN}/tag/users`,
  },
  teams: {
    default: (id) => `${USER_SERVICE_ORIGIN}/teams${id ? `/${id}` : ""}`,
    global: `${USER_SERVICE_ORIGIN}/teams/global`,
  },
  unsubscribe: {
    get: (org, meeting, contact) =>
      `${USER_SERVICE_ORIGIN}/contact/unsubscribe?o=${org}&m=${meeting}&c=${contact}`,
    unsubscribe: `${USER_SERVICE_ORIGIN}/contact/unsubscribe`,
  },
  users: {
    accounts: `${USER_SERVICE_ORIGIN}/users/me/accounts`,
    accountsURL: `${USER_SERVICE_ORIGIN}/users/me/accounts/url`,
    all: `${USER_SERVICE_ORIGIN}/users/all`, // Super Admin only
    default: (id) => `${USER_SERVICE_ORIGIN}/users${id ? `/${id}` : ""}`,
    exportAll: `${USER_SERVICE_ORIGIN}/users/admin/export`,
    exportCSV: `${USER_SERVICE_ORIGIN}/users/export/csv`,
    invite: {
      csvTemplate: `${USER_SERVICE_ORIGIN}/users/invite/csv/template`,
      user: `${USER_SERVICE_ORIGIN}/users/invite/user`,
    },
    meetingDefinitionInfo: `${USER_SERVICE_ORIGIN}/users/definition/info`,
    meetings: `${USER_SERVICE_ORIGIN}/users/meetings`,
    open: `${USER_SERVICE_ORIGIN}/users/openuser`,
    orgVersions: `${PROVISIONING_SERVICE_ORIGIN}/provisioning/orgs/versions`,
    other: `${USER_SERVICE_ORIGIN}/users/other`,
    tagUsers: `${USER_SERVICE_ORIGIN}/tag/users`,
    teams: `${USER_SERVICE_ORIGIN}/users/teams`,
    usersForMeetingTransfer: `${USER_SERVICE_ORIGIN}/users/for_meeting_transfer`,
  },
};
