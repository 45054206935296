import React, { useEffect, useMemo, useRef, useState } from "react";

import Flexbox from "../../components/flexbox/FlexboxV2";
import {
  SmallSpacing,
  XLargeSpacing,
  XXLargeSpacing,
} from "../../components/spacing";
import { P1, H6 } from "../../components/typography";
import DistributionPreviewText from "./DistributionPreviewText";
import DistributionPreviewTick from "./DistributionPreviewTick";

import style from "../style.module.scss";

const Y_BASELINE = 400;
const Y1_TICK = Y_BASELINE - 25;
const Y2_TICK = Y_BASELINE - 35;
const SECONDS_BETWEEN_MEETING = 1;

function DistributionPreviewMeeting({ accepted, column, index, row, refresh }) {
  const setYAnimation = useRef(null);
  const opacityAnimation = useRef(null);
  const yAnimation = useRef(null);
  const x = column * 100 + 60;
  const y = Y_BASELINE - 85 - row * 30;
  const beginTime = `${index * SECONDS_BETWEEN_MEETING + 1}s`;
  const startTime = `${index * SECONDS_BETWEEN_MEETING}s`;
  useEffect(() => {
    if (refresh) {
      if (setYAnimation.current !== null) {
        setYAnimation.current.endElement();
      }
      setTimeout(() => {
        requestAnimationFrame(() => {
          yAnimation.current.beginElement();
          opacityAnimation.current.beginElement();
        });
      }, index * SECONDS_BETWEEN_MEETING * 100);
    }
  }, [index, refresh]);
  return (
    <>
      <rect
        className={style.meetingType__distributionMeeting}
        width={80}
        height={30}
        x={x}
        y={-35}
        stroke="white"
        strokeWidth={1}
      >
        <animate
          ref={yAnimation}
          attributeName="y"
          begin={startTime}
          dur="1.0s"
          from={0}
          id={`m${index}_rect_y`}
          to={y}
        />
        <animate
          ref={opacityAnimation}
          attributeName="opacity"
          begin={startTime}
          dur="1.0s"
          from={0}
          id={`m${index}_rect_opacity`}
          to={1}
        />
        <set
          ref={setYAnimation}
          attributeName="y"
          begin={`m${index}_rect_y.end`}
          dur="infinite"
          to={y}
        />
      </rect>
      {accepted && (
        <path
          d={`M ${x + 35} ${y + 15} l 4 5 l 6  -10`}
          opacity={0}
          stroke="green"
          strokeWidth={3}
          fill="none"
        >
          <animate
            attributeName="opacity"
            begin={beginTime}
            dur="1.0s"
            from={0}
            id={`m${index}_checkmark_opacity`}
            to={1}
          />
          <set
            attributeName="opacity"
            begin={`m${index}_checkmark_opacity.end`}
            dur="infinite"
            to={1}
          />
        </path>
      )}
      {!accepted && (
        <>
          <line
            x1={x + 34}
            y1={y + 10}
            x2={x + 45}
            y2={y + 21}
            opacity={0}
            stroke="#949090"
            strokeWidth={3}
          >
            <animate
              attributeName="opacity"
              begin={beginTime}
              dur="1.0s"
              from={0}
              id={`m${index}_checkmark_opacity`}
              to={1}
            />
            <set
              attributeName="opacity"
              begin={`m${index}_checkmark_opacity.end`}
              dur="infinite"
              to={1}
            />
          </line>
          <line
            x1={x + 45}
            y1={y + 10}
            x2={x + 34}
            y2={y + 21}
            opacity={0}
            stroke="#949090"
            strokeWidth={3}
          >
            <animate
              attributeName="opacity"
              begin={beginTime}
              dur="1.0s"
              from={0}
              id={`m${index}_checkmark_opacity`}
              to={1}
            />
            <set
              attributeName="opacity"
              begin={`m${index}_checkmark_opacity.end`}
              dur="infinite"
              to={1}
            />
          </line>
        </>
      )}
    </>
  );
}

const FRONT_LOAD = [
  {
    accepted: false,
    column: 0,
    row: 0,
  },
  {
    accepted: true,
    column: 1,
    row: 0,
  },
  {
    accepted: false,
    column: 2,
    row: 0,
  },
  {
    accepted: true,
    column: 3,
    row: 0,
  },
  {
    accepted: true,
    column: 4,
    row: 0,
  },
  {
    accepted: true,
    column: 0,
    row: 0,
  },
  {
    accepted: true,
    column: 2,
    row: 0,
  },
];

export default function FrontLoadPreview() {
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);
  const meetings = useMemo(() => {
    const m = [];
    for (let i = 0; i < FRONT_LOAD.length; i += 1) {
      const { accepted, column, row } = FRONT_LOAD[i];
      m.push({
        accepted,
        column,
        index: i,
        key: `${column}-${row}-${i}`,
        row,
      });
    }
    return m;
  }, []);
  return (
    <Flexbox.Column
      className={style.meetingType__distributionPreview}
      flex={1}
      justifyContent="center"
    >
      <XXLargeSpacing />
      <Flexbox.Row>
        <XLargeSpacing />
        <H6>Front Load</H6>
      </Flexbox.Row>
      <SmallSpacing />
      <Flexbox.Row>
        <XLargeSpacing />
        <P1 className={style.meetingType__distributionExplantionText}>
          The meeting time proposed will be the first availability of the user
          within day range.
        </P1>
        <XLargeSpacing />
      </Flexbox.Row>
      <SmallSpacing />
      <svg viewBox="0 0 600 600">
        {meetings.map((props) => (
          <DistributionPreviewMeeting {...props} refresh={refresh} />
        ))}
        <DistributionPreviewText x={60} y={Y_BASELINE} text="11:00AM" />
        <DistributionPreviewText x={160} y={Y_BASELINE} text="11:30AM" />
        <DistributionPreviewText x={260} y={Y_BASELINE} text="12:00PM" />
        <DistributionPreviewText x={365} y={Y_BASELINE} text="12:30PM" />
        <DistributionPreviewText x={470} y={Y_BASELINE} text="1:00PM" />
        <DistributionPreviewTick x={100} y1={Y1_TICK} y2={Y2_TICK} />
        <DistributionPreviewTick x={200} y1={Y1_TICK} y2={Y2_TICK} />
        <DistributionPreviewTick x={300} y1={Y1_TICK} y2={Y2_TICK} />
        <DistributionPreviewTick x={400} y1={Y1_TICK} y2={Y2_TICK} />
        <DistributionPreviewTick x={500} y1={Y1_TICK} y2={Y2_TICK} />
      </svg>
    </Flexbox.Column>
  );
}
