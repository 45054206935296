import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Availability } from "./AvailabilitySettings";
import dayjs from "dayjs";

function Day({ checked, children }: { checked?: boolean; children: string }) {
  const theme = useTheme();
  return (
    <Typography
      variant="caption"
      sx={{
        color: checked
          ? theme.palette.primary.main
          : theme.palette.text.disabled,
      }}
    >
      {children}
    </Typography>
  );
}

export function AvailabilityPreview({ value }: { value?: Availability }) {
  return (
    <Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          gap: 0.5,
        }}
      >
        <Day checked={value?.daysOfWeek.sunday}>Su</Day>
        <Day checked={value?.daysOfWeek.monday}>M</Day>
        <Day checked={value?.daysOfWeek.tuesday}>T</Day>
        <Day checked={value?.daysOfWeek.wednesday}>W</Day>
        <Day checked={value?.daysOfWeek.thursday}>Th</Day>
        <Day checked={value?.daysOfWeek.friday}>F</Day>
        <Day checked={value?.daysOfWeek.saturday}>S</Day>
      </Box>
      {value?.timeOfDay?.[0] && value?.timeOfDay?.[1] ? (
        <Typography variant="caption">
          {dayjs()
            .startOf("day")
            .minute(value?.timeOfDay?.[0])
            .format("h:mm a")}{" "}
          -{" "}
          {dayjs()
            .startOf("day")
            .minute(value?.timeOfDay?.[1])
            .format("h:mm a")}
        </Typography>
      ) : null}
    </Box>
  );
}
