import {
  Box,
  DialogActions,
  DialogContent,
  Divider,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { Editor } from "@tiptap/react";
import classNames from "classnames";
import { useState } from "react";
import {
  AiOutlineLink,
  AiOutlineOrderedList,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { GrRedo, GrUndo } from "react-icons/gr";
import EmailRichtextEditor, {
  cleanRichTextHTML,
} from "src/componentsV2/TipTap/EmailRichtextEditor";
import "src/componentsV2/TipTap/styles.scss";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";
import { useOpenAiIntegration } from "../../../features";
import { Input } from "../../inputs/Input";
import { OpenAiTextInput } from "./OpenAiTextInput";
import { State } from "./State";
import { Step } from "./Step";

function EditMessage({ state, setState, next, previous }: Step<State>) {
  const [tab, setTab] = useState(0);
  const openAiEnabled = useOpenAiIntegration();
  const [editor, setEditor] = useState<Editor>();

  const onEmailBodyChange = (v: string) => {
    setState({
      ...state,
      overrides: {
        ...state.overrides,
        emailBodyTemplate: v || "",
      },
    });
  };

  return (
    <>
      <DialogContent>
        <Stack
          sx={{
            p: 2,
            height: "600px",
          }}
        >
          <Tabs
            value={tab}
            onChange={(event, newValue) => {
              setTab(newValue);
            }}
            centered
          >
            <Tab label="Email" />
            <Tab label="Calendar Invite" />
          </Tabs>
          <Divider flexItem />
          <Stack spacing={2} sx={{ py: 2 }}>
            {tab === 0 && (
              <>
                <Input
                  label="Subject"
                  value={state.overrides.emailSubjectTemplate}
                  onChange={(e) => {
                    setState({
                      ...state,
                      overrides: {
                        ...state.overrides,
                        emailSubjectTemplate: e.target.value,
                      },
                    });
                  }}
                />
                <Box sx={{ px: "1px" }}>
                  <EmailRichtextEditor
                    value={state.overrides.emailBodyTemplate}
                    onChange={onEmailBodyChange}
                    onEditorCreated={(editor) => setEditor(editor)}
                    menuBar={<QLRichtextMenuBar editor={editor} />}
                  />
                </Box>
                {openAiEnabled && (
                  <OpenAiTextInput
                    onAiResponse={(value: string) => {
                      const cleaned = cleanRichTextHTML(value);
                      onEmailBodyChange(cleaned);
                      editor?.commands.setContent(cleaned);
                    }}
                  />
                )}
              </>
            )}
            {tab === 1 && (
              <>
                <Input
                  label="Title"
                  value={state.overrides.inviteTitleTemplate}
                  onChange={(e) => {
                    setState({
                      ...state,
                      overrides: {
                        ...state.overrides,
                        inviteTitleTemplate: e.target.value,
                      },
                    });
                  }}
                />
                <Input
                  label="Description"
                  multiline
                  minRows={14}
                  value={state.overrides.inviteBodyTemplate}
                  onChange={(e) => {
                    setState({
                      ...state,
                      overrides: {
                        ...state.overrides,
                        inviteBodyTemplate: e.target.value,
                      },
                    });
                  }}
                />
                {openAiEnabled && (
                  <OpenAiTextInput
                    onAiResponse={(value: string) => {
                      setState({
                        ...state,
                        overrides: {
                          ...state.overrides,
                          inviteBodyTemplate: value.trimStart(),
                        },
                      });
                    }}
                  />
                )}
              </>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row-reverse"
          justifyContent="space-between"
          sx={{ pt: 2, width: "100%" }}
        >
          <PrimaryButton
            disabled={
              state.overrides.emailSubjectTemplate === "" ||
              state.overrides.emailBodyTemplate === "" ||
              state.overrides.inviteTitleTemplate === "" ||
              state.overrides.inviteBodyTemplate === ""
            }
            onClick={() => next()}
          >
            Next
          </PrimaryButton>
          <PrimaryButton onClick={() => previous()}>Prev</PrimaryButton>
        </Stack>
      </DialogActions>
    </>
  );
}

const QLRichtextMenuBar = ({ editor }: { editor?: Editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="tiptap-menu">
      <div className="tiptap-menu-buttons-wrapper">
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={classNames("tiptap-menu-button", {
            "is-active": editor.isActive("bulletList"),
          })}
        >
          <AiOutlineUnorderedList />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={classNames("tiptap-menu-button", {
            "is-active": editor.isActive("orderedList"),
          })}
        >
          <AiOutlineOrderedList />
        </button>

        <button
          onClick={() => {
            const previousUrl = editor.getAttributes("link").href;
            const url = window.prompt("URL", previousUrl);

            // cancelled
            if (url === null) {
              return;
            }

            // empty
            if (url === "") {
              editor.chain().focus().extendMarkRange("link").unsetLink().run();

              return;
            }

            // update link
            editor
              .chain()
              .focus()
              .extendMarkRange("link")
              .setLink({ href: url })
              .run();
          }}
          className={classNames("tiptap-menu-button", {
            "is-active": editor.isActive("link"),
          })}
        >
          <AiOutlineLink />
        </button>

        <Select
          sx={{ marginLeft: 2 }}
          labelId="merge-tags-select"
          id="merge-tags-select"
          value="placeholder"
          variant="standard"
          onChange={(event) => {
            editor.chain().focus().insertContent(event.target.value).run();
          }}
        >
          <MenuItem value="placeholder" style={{ display: "none" }}>
            Merge Fields
          </MenuItem>
          <MenuItem value="{{greeting}}">Greeting</MenuItem>
          <MenuItem value="{{guest_account}}">Guest Account</MenuItem>
          <MenuItem value="{{guest_first_name}}">Guest First Name</MenuItem>
          <MenuItem value="{{guest_last_name}}">Guest Last Name</MenuItem>
          <MenuItem value="{{guest_logic_field}}">Guest Logic Field</MenuItem>
          <MenuItem value="{{guest_logic_field_2}}">
            Guest Logic Field 2
          </MenuItem>
          <MenuItem value="{{guest_phone}}">Guest Phone</MenuItem>
          <MenuItem value="{{host_company}}">Host Company</MenuItem>
          <MenuItem value="{{host_first_name}}">Host First Name</MenuItem>
          <MenuItem value="{{host_last_name}}">Host Last Name</MenuItem>
          <MenuItem value="{{host_location}}">Host Location</MenuItem>
          <MenuItem value="{{host_title}}">Host Title</MenuItem>
          <MenuItem value="{{host_signature}}">Host Signature</MenuItem>
          <MenuItem value="{{meeting_date_time}}">Meeting Date & Time</MenuItem>
          <MenuItem value="{{meeting_day_of_week}}">
            Meeting Day of Week
          </MenuItem>
          <MenuItem value="{{meeting_duration}}">Meeting Duration</MenuItem>
          <MenuItem value="{{meeting_link}}">Meeing Link</MenuItem>
          <MenuItem value="{{meeting_month_day}}">Meeting Month & Day</MenuItem>
          <MenuItem value="{{meeting_time}}">Meeting Time</MenuItem>
          <MenuItem value="{{time_casual_day}}">Time Casual Day</MenuItem>
          <MenuItem value="{{time_number_date}}">Time Number Date</MenuItem>
          <MenuItem value="{{guest_custom_field_1}}">
            Guest Custom Field 1
          </MenuItem>
          <MenuItem value="{{guest_custom_field_2}}">
            Guest Custom Field 2
          </MenuItem>
          <MenuItem value="{{guest_custom_field_3}}">
            Guest Custom Field 3
          </MenuItem>
          <MenuItem value="{{guest_custom_field_4}}">
            Guest Custom Field 4
          </MenuItem>
          <MenuItem value="{{guest_custom_field_5}}">
            Guest Custom Field 5
          </MenuItem>
          <MenuItem value="{{guest_custom_field_6}}">
            Guest Custom Field 6
          </MenuItem>
          <MenuItem value="{{guest_custom_field_7}}">
            Guest Custom Field 7
          </MenuItem>
          <MenuItem value="{{guest_custom_field_8}}">
            Guest Custom Field 8
          </MenuItem>
          <MenuItem value="{{guest_custom_field_9}}">
            Guest Custom Field 9
          </MenuItem>
          <MenuItem value="{{guest_custom_field_10}}">
            Guest Custom Field 10
          </MenuItem>
        </Select>
      </div>
      <div className="tiptap-menu-buttons-wrapper">
        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
          className="tiptap-menu-button"
        >
          <GrUndo />
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
          className="tiptap-menu-button"
        >
          <GrRedo />
        </button>
      </div>
    </div>
  );
};
export default EditMessage;
