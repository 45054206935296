import { useTheme } from "../../../../components/theme/ThemeProvider";

const LIGHT_THEME = {
  pieChart: {
    fontFamily: "Inter",
    labels: {
      text: {
        fontSize: 12,
      },
    },
    tooltip: {
      container: {
        background: "#222222",
        borderRadius: "5px",
        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.25)",
        color: "#dedede",
        fontSize: "12px",
        padding: "5px 9px",
      },
    },
  },
};

const DARK_THEME = {
  pieChart: {
    fontFamily: "Inter",
    labels: {
      text: {
        fontSize: 12,
      },
    },
    tooltip: {
      container: {
        background: "#111111",
        borderRadius: "5px",
        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.95)",
        color: "#dedede",
        fontSize: "12px",
        padding: "5px 9px",
      },
    },
  },
};

function useVisualizationTheme() {
  const themeState = useTheme();
  return themeState.isDark ? DARK_THEME : LIGHT_THEME;
}

export { useVisualizationTheme };
