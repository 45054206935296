import dynamicsImg from "../assets/dynamics.png";
import hubspotImg from "../assets/hubspot.png";
import salesforceImg from "../assets/salesforce.svg";
import outreachIMG from "../assets/outreach.svg";

export const INTEGRATION_TYPES = {
  DYNAMICS: "dynamics",
  HUBSPOT: "hubspot",
  MICROSOFT_TEAMS: "microsoft_teams",
  OUTREACH: "outreach",
  SALESFORCE: "salesforce",
  SALESFORCE_TEST: "salesforce-test",
  WEBEX: "webex",
  ZOOM: "zoom",
  GOOGLE_MEET: "google",
};

export const INTEGRATION_TYPES_LABELS = {
  [INTEGRATION_TYPES.DYNAMICS]: "Microsoft Dynamics",
  [INTEGRATION_TYPES.HUBSPOT]: "Hubspot",
  [INTEGRATION_TYPES.OUTREACH]: "Outreach",
  [INTEGRATION_TYPES.SALESFORCE]: "Salesforce",
  [INTEGRATION_TYPES.MICROSOFT_TEAMS]: "Microsoft Teams",
  [INTEGRATION_TYPES.WEBEX]: "Webex",
  [INTEGRATION_TYPES.ZOOM]: "Zoom",
  [INTEGRATION_TYPES.GOOGLE_MEET]: "Google Meet",
};

export const INTEGRATION_IMG_SRC = {
  [INTEGRATION_TYPES.DYNAMICS]: dynamicsImg,
  [INTEGRATION_TYPES.HUBSPOT]: hubspotImg,
  [INTEGRATION_TYPES.OUTREACH]: outreachIMG,
  [INTEGRATION_TYPES.SALESFORCE]: salesforceImg,
};

export const INTEGRATION_AUTH_STATUS = {
  CURRENT: "Current",
  DISABLED: "Disabled",
  EXPIRED: "Expired",
};

export const ENVIRONMENT_URLS = {
  [INTEGRATION_TYPES.DYNAMICS]: "https://admin.powerplatform.microsoft.com/",
};
