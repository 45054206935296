import React, { forwardRef, useCallback, useMemo } from "react";
import ReactQuill from "react-quill";
import Quill from "quill";
import ImageResize from "quill-image-resize-module-react";
import BlotFormatter from "quill-blot-formatter";
import "react-quill/dist/quill.snow.css";
import style from "./style.module.scss";
import useGeneralNotifications from "../../hooks/useGeneralNotifications";

Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/blotFormatter", BlotFormatter);

// we use a <div> as the line break character. we do this in order to overwrite
// the default line break character (<p><br><p/>) which produces a larger
// line break than expected
const Block = Quill.import("blots/block");
Block.tagName = "div";
Quill.register(Block);

// method found here: https://github.com/quilljs/quill/issues/262#issuecomment-948890432
const Link = Quill.import("formats/link");
// add overrides to the default list (default: ['http', 'https', 'mailto', 'tel'])
Link.PROTOCOL_WHITELIST = [
  "http",
  "https",
  "mailto",
  "tel",
  "radar",
  "rdar",
  "smb",
  "sms",
];

function isValidUrl(url = "") {
  return Link.PROTOCOL_WHITELIST.some((prefix) => url.startsWith(prefix));
}

// custom sanitize function
Link.sanitize = (url = "") => {
  if (isValidUrl(url)) {
    return url;
  }

  return `http://${url}`;
};

const FORMATS = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "width",
];

const QuillWrapper = forwardRef(function QuillWrapperWithRef(
  { value = "", onChange = () => null, readOnly = false },
  ref
) {
  const { addError } = useGeneralNotifications();

  const canInsertImage = useCallback(
    (ops = []) => {
      if (ops.length < 1) {
        return false;
      }

      for (let i = 0; i < ops.length; i += 1) {
        const currentOp = ops[i];
        if (currentOp.insert && currentOp.insert.image) {
          if (!isValidUrl(currentOp.insert.image)) {
            addError("Image must contain an embedded URL");
            return false;
          }
        }
      }

      return true;
    },
    [addError]
  );

  const imageHandler = useCallback(() => {
    if (!ref || !ref.current) {
      return;
    }

    const editor = ref.current.getEditor();

    const range = editor.getSelection();

    // method found here: https://github.com/quilljs/quill/issues/2044#issuecomment-603630374
    const {
      theme: { tooltip },
    } = editor;

    const originalSaveFn = tooltip.save;
    const originalHideFn = tooltip.hide;

    tooltip.hide = () => {
      tooltip.save = originalSaveFn;
      tooltip.hide = originalHideFn;
      tooltip.hide();
    };

    tooltip.save = () => {
      const {
        textbox: { value: enteredVal },
      } = tooltip;

      if (enteredVal) {
        editor.insertEmbed(
          range.index,
          "image",
          enteredVal,
          Quill.sources.USER
        );
      }

      tooltip.hide();
    };

    tooltip.edit("image");
    tooltip.textbox.placeholder = "Embed Image URL";
  }, [ref]);

  const MODULES = useMemo(
    () => ({
      clipboard: {
        matchVisual: false,
      },

      history: {
        delay: 2000,
        maxStack: 500,
        userOnly: true,
      },
      imageResize: {
        modules: ["Resize", "DisplaySize"],
        parchment: Quill.import("parchment"),
      },
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image"],
          [{ align: [] }],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    [imageHandler]
  );

  const onEditorChange = useCallback(
    (v, delta) => {
      if (!canInsertImage(delta.ops)) {
        // set value back to what we had previously. this is required as Quill will allow addition of text/image even
        // when value does not get updated, so we revert to previous `value` state
        onChange(value);
        return;
      }

      onChange(v);
    },
    [canInsertImage, onChange, value]
  );

  return (
    <span className={style.quill_wrapper}>
      <ReactQuill
        ref={ref}
        value={value}
        readOnly={readOnly}
        onChange={onEditorChange}
        modules={MODULES}
        formats={FORMATS}
        theme="snow"
      />
    </span>
  );
});

export default QuillWrapper;
