/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Helmet from "react-helmet";

import { User } from "../types";

export function Appcues(props: { accountId: string; user: User | null }) {
  const history = useHistory();

  // Let app cues know anytime the page changes.
  useEffect(() => {
    const unlisten = history.listen(() => {
      // @ts-ignore
      window.Appcues?.page();
    });

    return () => {
      unlisten();
    };
  }, [history]);

  // Let app cues know when the logged in user changes.
  useEffect(() => {
    if (props.user) {
      // @ts-ignore
      window.Appcues?.identify(props.user.id, {
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        email: props.user.email,
        org: props.user.org.org_key,
        role: props.user.role,
      });
    }
  }, [props.user]);

  return (
    <>
      <Helmet>
        {props.accountId && (
          <script src={`https://fast.appcues.com/${props.accountId}.js`} />
        )}
      </Helmet>
    </>
  );
}
