import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { HostSelectInput } from "../inputs";
import { useMeetingRepository } from "../../repository/meetings";
import Input from "../inputs/Input";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import Dialog from "./Dialog";
import { User } from "../../types";

type MeetingLinkOption = "transfer" | "current" | "custom";

function MeetingLinkOptions({
  value,
  onChange,
}: {
  value: MeetingLinkOption;
  onChange?: (value: MeetingLinkOption) => void;
}) {
  return (
    <FormControl>
      <RadioGroup
        value={value}
        onChange={(_, v) => {
          if (onChange) {
            onChange(v as MeetingLinkOption);
          }
        }}
      >
        <FormControlLabel
          value="transfer"
          control={<Radio />}
          label="Use New Owner Meeting Link"
        />
        <FormControlLabel
          value="current"
          control={<Radio />}
          label="Use Current Owner Meeting Link"
        />
        <Box flexDirection="row">
          <FormControlLabel
            value="custom"
            control={<Radio />}
            label="Custom Meeting Link"
          />
        </Box>
      </RadioGroup>
    </FormControl>
  );
}

export function TransferHostDialog({
  open,
  onClose,
  onConfirm,
  meetingId,
}: {
  open: boolean;
  onClose?: () => void;
  onConfirm?: (value: {
    host: User;
    meetingLinkOption: MeetingLinkOption;
    customMeetingLink: string;
  }) => void;
  meetingId: number;
}) {
  const [selectedHost, setSelectedHost] = useState<User | null>(null);
  const [error, setError] = useState<string | undefined>(undefined);
  const [meetingLinkOption, setMeetingLinkOption] =
    useState<MeetingLinkOption>("transfer");
  const [customMeetingLink, setCustomMeetingLink] = useState("");

  const { isHostAvailable } = useMeetingRepository();

  return (
    <Dialog
      title="Transfer Host"
      open={open}
      onClose={onClose}
      actions={
        <>
          <PrimaryButton
            onClick={() => {
              if (onConfirm && selectedHost) {
                onConfirm({
                  host: selectedHost,
                  meetingLinkOption,
                  customMeetingLink,
                });
              }
            }}
            disabled={
              error !== undefined ||
              (meetingLinkOption === "custom" && customMeetingLink === "")
            }
          >
            Transfer
          </PrimaryButton>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        </>
      }
    >
      <Stack spacing={2} sx={{ pt: 1, minHeight: "20rem" }}>
        <HostSelectInput
          host={selectedHost}
          error={Boolean(error)}
          helperText={error}
          onHostSelect={(host) => {
            setSelectedHost(host);

            if (host !== null) {
              isHostAvailable({
                meetingId,
                userId: host.id,
              })
                .then((response) => {
                  if (response && response.available) {
                    setError(undefined);
                  } else {
                    setError("Host is not available for this meeting");
                  }
                })
                .catch(() => {
                  setError("Host availability could not be confirmed");
                });
            }
          }}
          forMeetingTransfer={true}
        />
        <Box>
          <MeetingLinkOptions
            value={meetingLinkOption}
            onChange={setMeetingLinkOption}
          />
          {meetingLinkOption === "custom" && (
            <Box sx={{ pl: 4 }}>
              <Input
                value={customMeetingLink}
                onChange={(e) => setCustomMeetingLink(e.target.value)}
              />
            </Box>
          )}
        </Box>
      </Stack>
    </Dialog>
  );
}
