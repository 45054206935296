import { useHistory } from "react-router-dom";

export function useLogout() {
  const history = useHistory();

  const logout = () => {
    history.push("/logout");
  };

  return logout;
}
