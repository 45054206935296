import { useParams } from "react-router-dom";

export default function useMeetingTypeByUri(
  isMeetingTypesLoading,
  meetingTypes
) {
  const { id } = useParams();
  if (isMeetingTypesLoading) {
    return null;
  }
  // TODO: Should be able to remove this
  if (meetingTypes === null) {
    return null;
  }
  const meetingType = meetingTypes.filter((m) => m.id === parseInt(id));
  if (meetingType.length > 0) {
    return meetingType[0];
  }
  return null;
}
