import React from "react";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { TagList } from "./TagList";
import { Tag } from "../types";
import { CopyAll, Delete, Edit } from "@mui/icons-material";
import { CopyIcon } from "src/icons";

export function TeamCard({
  name,
  members = 0,
  tags = [],
  onEdit = () => null,
  onClone = () => null,
  onDelete = () => null,
}: {
  name: string;
  members: number;
  tags?: Tag[];
  onEdit?: () => void;
  onClone?: () => void;
  onDelete?: () => void;
}) {
  const theme = useTheme();

  return (
    <Box sx={{ px: theme.spacing(1), width: "100%" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography>{name}</Typography>

        {/* <Stack direction="row" alignItems="center">
          <EditIconButon />
        </Stack> */}
      </Stack>
      <Stack>
        <Typography variant="caption">
          <strong>Members:</strong> {members}
        </Typography>

        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={0}
        >
          <TagList tags={tags} />
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onClone && onClone();
            }}
          >
            <CopyIcon />
          </IconButton>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onEdit && onEdit();
            }}
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          >
            <Delete />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  );
}

export default TeamCard;
