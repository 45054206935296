import { Stack, Typography } from "@mui/material";
import { DomainAddIcon } from "src/icons";
import { Organization } from "../../types";
import PrimaryButton from "../buttons/PrimaryButton";
import Table, { TableSort } from "./Table";

export function OrganizationTable({
  loading,
  minRows,
  organizations = [],
  sort,
  onSortChange,
  onAddDomain = () => null,
}: {
  loading: boolean;
  minRows: number;
  organizations: Organization[];
  sort: TableSort;
  onSortChange?: (value: TableSort) => void;
  onAddDomain?: (org: Organization) => void;
}) {
  return (
    <Table
      loading={loading}
      getKey={(o) => o.id}
      columns={[
        {
          component: (row) => <>{row.id}</>,
          id: "id",
          label: "Id",
          sortable: false,
        },
        {
          component: (row) => (row.enabled ? <>Yes</> : <>No</>),
          id: "enabled",
          label: "Enabled",
          sortable: true,
        },
        {
          component: (row) => <>{row.name}</>,
          id: "name",
          label: "Name",
          sortable: true,
        },
        {
          component: (row) => <>{row.schemaName}</>,
          id: "schemaName",
          label: "Schema Name",
          sortable: false,
        },
        {
          component: (row) => (
            <Stack
              sx={{
                alignItems: "flex-start",
                paddingBottom: "3px",
                paddingTop: "3px",
              }}
            >
              {row.domains.map(({ domain, preferredClient }, index) => (
                <Stack
                  key={`${domain}-${preferredClient}-${index}`}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography variant="inherit">{domain}</Typography>
                  <Typography variant="caption">
                    {" "}
                    {`(${preferredClient})`}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          ),
          id: "domains",
          label: "Domains",
          sortable: false,
        },
        {
          component: (row) => <>{row.version}</>,
          id: "version",
          label: "DB Version",
          sortable: false,
        },
        {
          align: "right",
          component: (row) => {
            return (
              <PrimaryButton
                icon={<DomainAddIcon />}
                nowrap
                onClick={() => onAddDomain(row)}
              >
                Add Domain
              </PrimaryButton>
            );
          },
          id: "actions",
          label: "Actions",
          sortable: false,
          width: 120,
        },
      ]}
      data={organizations}
      minRows={minRows}
      sort={sort}
      onSortChange={onSortChange}
    />
  );
}
