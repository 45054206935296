import React from "react";
import Range from "rc-slider/lib/Range";

import Flexbox from "../../components/flexbox/FlexboxV2";
import { P1 } from "../../components/typography";
import { useBufferDurationHours, useSameDayBuffer } from "../context";

import { SmallSpacing, XSmallSpacing } from "../../components/spacing";
import useRailColor from "./useRailColor";
import style from "../style.module.scss";

// TODO(Matt): Find out what this does? Rename it somehow
export function secondSlider(buffer, secondSameDay) {
  let s = secondSameDay;
  if (s - buffer >= 3) {
    return s;
  }
  while (s - buffer < 3) {
    if (s === 24) {
      break;
    }
    s += 1;
  }
  return s;
}

export default function SameDay() {
  const [bufferDurationHours] = useBufferDurationHours();
  const [sameDayBuffer, setSameDayBuffer] = useSameDayBuffer();
  const handleChange = (val) => {
    const secondPosition = secondSlider(bufferDurationHours, val[1]);
    setSameDayBuffer(secondPosition);
  };
  const { railColor } = useRailColor();
  // TODO(Matt): Name these cosntatns
  const from = bufferDurationHours < 21 ? bufferDurationHours : 21;
  return (
    <Flexbox.Column>
      <div className={style.meetingType__slider}>
        <Range
          value={[from, sameDayBuffer || bufferDurationHours + 3]}
          step={1}
          min={1}
          allowCross={false}
          max={24}
          // TODO(MATT): Talk to Sam? Why was this here
          // pushable={3}
          onChange={handleChange}
          disabled={bufferDurationHours >= 21}
          railStyle={{ backgroundColor: railColor }}
        />
      </div>
      <SmallSpacing />
      <Flexbox.Row>
        <XSmallSpacing />
        <P1 small>
          {`Minimum Notice On Meeting Activation: ${sameDayBuffer}`}
        </P1>
      </Flexbox.Row>
    </Flexbox.Column>
  );
}
