import React from "react";
import cx from "classnames";
import { Switch } from "../switch";
import {
  INITIALIZING_FAULT_STAGE,
  isFinalMeetingStage,
  isMeetingCancelledStage,
  isMeetingInitFaultStage,
} from "../../meetings/props";
import style from "./style.module.scss";

export function MeetingActivationSwitch({ handleToggle, meeting, name }) {
  const disabled =
    !meeting.guests ||
    !meeting.meetingType?.active ||
    meeting.status === INITIALIZING_FAULT_STAGE ||
    isFinalMeetingStage(meeting.status) ||
    isMeetingCancelledStage(meeting.status) ||
    (!meeting.meetingType?.routeOnActivation && !meeting.host) ||
    (isMeetingInitFaultStage(meeting.status) && !meeting.enabled);

  const readyToActivate = !disabled && !meeting.enabled;

  return (
    <Switch
      isOn={meeting.enabled}
      handleToggle={handleToggle}
      labelClassName={cx({
        [style["activeSwitch__container--readyToActivate"]]: readyToActivate,
      })}
      name={name}
      disabled={disabled}
    />
  );
}
