import React, { useState } from "react";

import { useParams } from "react-router-dom";
import { Typography, Grid, Stack, Box } from "@mui/material";
import QRCode from "react-qr-code";
import { Email, LinkedIn } from "@mui/icons-material";

import DropdownMenuButton from "../../componentsV2/ButtonDropdown";
import useGeneralNotifications from "../../hooks/useGeneralNotifications";
import useGenerateOneClickUrl from "../../mutations/useGenerateOneClickUrl";
import SecondaryButton from "src/componentsV2/buttons/SecondaryButton";
import { CopyIcon, ShareIcon } from "src/icons";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";
import Input from "src/componentsV2/inputs/Input";

function ShareButtonDropdown({ url }: { url: string }) {
  return (
    <DropdownMenuButton
      button={(props) => (
        <PrimaryButton icon={<ShareIcon />} disabled={!url} {...props}>
          Share
        </PrimaryButton>
      )}
      options={[
        {
          icon: <LinkedIn />,
          label: "Linkedin",
          onClick: () => {
            window.open(
              `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
              "_blank"
            );
          },
        },
        {
          icon: <Email />,
          label: "Email",
          onClick: () => {
            window.open(
              `mailto:?subject=1-Click Scheduling&body=${url}`,
              "popup"
            );
          },
        },
      ]}
    />
  );
}

type RouteParams = {
  meetingTypeId?: string;
};

function Scheduling({ meetingType }: any) {
  const { meetingTypeId } = useParams<RouteParams>();

  const [url, setUrl] = useState(
    meetingType?.schedulingUrls?.length && meetingType.schedulingUrls[0].url
  );

  const { addGeneralNotification, addError } = useGeneralNotifications();
  const generateOneClickUrl = useGenerateOneClickUrl();

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={4} width="100%">
        <Grid item xs={12} md={6}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" sx={{ pb: 4 }}>
              1-Click Link
            </Typography>
            {!url && (
              <PrimaryButton
                nowrap
                onClick={() => {
                  generateOneClickUrl("", Number.parseInt(meetingTypeId || "0"))
                    .then((res) => {
                      setUrl(res.url);
                    })
                    .catch(() => {
                      addError("Failed to create 1-Click URL");
                    });
                }}
              >
                Generate 1-Click Link
              </PrimaryButton>
            )}
          </Stack>
          <Stack spacing={2}>
            <Input label="Meeting Link" value={url || ""} disabled />
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              <SecondaryButton
                icon={<CopyIcon />}
                nowrap
                onClick={() => {
                  if (navigator) {
                    navigator.clipboard.writeText(url);
                    addGeneralNotification(
                      "Successfully copied meeting link to clipboard"
                    );
                  }
                }}
              >
                Copy
              </SecondaryButton>
              <ShareButtonDropdown url={url} />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          {url && (
            <Stack justifyContent="center" alignItems="center" spacing={2}>
              <Box sx={{ backgroundColor: "white", p: 2 }}>
                <QRCode size={256} id="qrcode" value={url} />
              </Box>

              <PrimaryButton
                onClick={() => {
                  const svg: any = document.getElementById("qrcode");
                  const canvas: any = document.getElementById("canvas");

                  const ctx = canvas.getContext("2d");
                  const data = new XMLSerializer().serializeToString(svg);
                  const DOMURL = window.URL || window.webkitURL || window;

                  const img = new Image();
                  const svgBlob = new Blob([data], {
                    type: "image/svg+xml;charset=utf-8",
                  });

                  const u = DOMURL.createObjectURL(svgBlob);

                  img.onload = function () {
                    ctx.drawImage(img, 0, 0);
                    DOMURL.revokeObjectURL(u);

                    const imgURI = canvas
                      .toDataURL("image/png")
                      .replace("image/png", "image/octet-stream");

                    const element = document.createElement("a");
                    element.download = "QRCode.png";
                    element.href = imgURI;
                    element.click();
                    element.remove();
                  };

                  img.src = u;
                }}
              >
                Download QR Code
              </PrimaryButton>

              <canvas hidden width={256} height={256} id="canvas" />
            </Stack>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Scheduling;
