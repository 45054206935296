import React from "react";
import moment from "moment";
import DOMPurify from "dompurify";
import { getInitials } from "../../../meetings/instanceDetails/util/helpers";
import { GUEST_STATUS_TO_LABEL } from "../../../meetings/props";
import Flexbox from "../../flexbox/FlexboxV2";
import InitialsIcon from "../../initials";
import { P1 } from "../../typography";
import style from "./messageTypes.module.scss";

const sanitizer = DOMPurify.sanitize;

function UserMessagePreview({ host, message, occurrence, status }) {
  const hostEmail = host.email;
  const hostInitials = getInitials(host);

  const hostStatus = `${GUEST_STATUS_TO_LABEL[status]} - ${moment(
    occurrence
  ).format("ddd M/DD h:mma")}`;

  return (
    <Flexbox.Column height="fit-content" alignItems="flex-end">
      <Flexbox.Column
        alignItems="flex-end"
        className={style.userMessage}
        style={{ maxWidth: "65%" }}
        width="fit-content"
      >
        <P1 className={style.userMessage__email}>{hostEmail}</P1>
        <Flexbox.Column
          alignItems="flex-start"
          className={style.userMessage__body}
          dangerouslySetInnerHTML={{ __html: sanitizer(message) }}
        />
      </Flexbox.Column>
      <Flexbox.Row
        alignItems="center"
        className={style.userMessage__icon_row}
        justifyContent="flex-end"
      >
        <P1 className={style.userMessage__status}>{hostStatus}</P1>
        <InitialsIcon
          className={style.userMessage_icon_row__icon}
          initials={hostInitials}
        />
      </Flexbox.Row>
    </Flexbox.Column>
  );
}

export default UserMessagePreview;
