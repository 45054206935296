import useSWR from "swr";

import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useErrorHandler } from "../hooks";
import { useUserService } from "../services";

export function useOrganizationDomains() {
  const service = useUserService();
  const accessToken = getUserToken();
  const errorHandler = useErrorHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const url = `/api/org/domains`;

  const { data, error } = useSWR(url, (u) =>
    service
      .get(u)
      .set(headers)
      .then((res: Response) => res.body)
      .catch(errorHandler)
  );

  return {
    data: data as string[],
    error,
    loading: !error && !data,
  };
}

export default useOrganizationDomains;
