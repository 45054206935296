import React, { useMemo } from "react";
import {
  GuestMessagePreview,
  SystemNotePreview,
  UserMessagePreview,
} from "../messageTypes";

// Cannot remove ai_reponse because of backward compatibility
const AI_RESPONSES = ["ai_reponse", "ai_response", "touch_sent"];
const GUEST_RESPONSES = ["contact_reply"];
const SYSTEM_RESPONSES = ["system_note"];

function MessagePreview({
  guestStatus,
  host,
  guest,
  occurrence,
  message,
  responseType,
}) {
  const isUserMessage = useMemo(() => {
    return AI_RESPONSES.includes(responseType);
  }, [responseType]);

  const isGuestMessage = useMemo(() => {
    return GUEST_RESPONSES.includes(responseType);
  }, [responseType]);

  const isSystemNote = useMemo(() => {
    return SYSTEM_RESPONSES.includes(responseType);
  }, [responseType]);

  return (
    <>
      {isUserMessage && (
        <UserMessagePreview
          host={host}
          message={message}
          occurrence={occurrence}
          status={guestStatus}
        />
      )}
      {isGuestMessage && (
        <GuestMessagePreview
          guest={guest}
          message={message}
          occurrence={occurrence}
          status={guestStatus}
        />
      )}
      {isSystemNote && <SystemNotePreview message={message} />}
    </>
  );
}

export default MessagePreview;
