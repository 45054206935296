import React, { useCallback, useState } from "react";
import { ButtonContainer } from "../components/button";
import { P1, H6 } from "../components/typography";
import Flexbox from "../components/flexbox/FlexboxV2";
import { Input } from "../components/input";
import {
  XSmallSpacing,
  SmallSpacing,
  MediumSpacing,
  LargeSpacing,
  XXLargeSpacing,
} from "../components/spacing";
import useSearchDynamicVariables from "./useSearchDynamicVariables";
import style from "./style.module.scss";

function DynamicVariableButton({ value, sample, onClick }) {
  const onDynamicFieldClick = useCallback(() => {
    if (onClick) {
      onClick(`{{${value}}}`);
    }
  }, [onClick, value]);
  return (
    <ButtonContainer
      className={style.meetingType__dynamicVariable2}
      name={`/meetingtypes/configuration/invite/show_dynamic_variables/${value}`}
      onClick={onDynamicFieldClick}
      transparent
    >
      <Flexbox.Column>
        <P1 className={style.meetingType__dynamicVariableText} small>
          {`{{${value}}}`}
        </P1>
        <SmallSpacing />
        <P1 className={style.meetingType__dynamicVariableSampleText} small>
          {sample}
        </P1>
      </Flexbox.Column>
    </ButtonContainer>
  );
}

export default function AddDynamicVariable({ onDynamicFieldClick }) {
  const [dynamicVariableSearchText, setDynamicVariableSearchText] =
    useState("");
  const onDynamicVariableSearchTextChange = useCallback((event) => {
    setDynamicVariableSearchText(event.target.value.trim());
  }, []);
  const variables = useSearchDynamicVariables(dynamicVariableSearchText);

  return (
    <Flexbox.Column
      className={style.meetingType__addDynamicVariable}
      height="100%"
      overflow="hidden"
    >
      <LargeSpacing />
      <Flexbox.Row>
        <LargeSpacing />
        <H6>Add Dynamic Variable</H6>
      </Flexbox.Row>
      <MediumSpacing />
      <Flexbox.Row>
        <XSmallSpacing />
        <Input
          large
          placeholder="Search"
          name="/meetingtypes/configuration/invite/show_dynamic_variables/search"
          onChange={onDynamicVariableSearchTextChange}
          value={dynamicVariableSearchText}
        />
        <XSmallSpacing />
      </Flexbox.Row>
      <Flexbox.Row flex={1} overflow="hidden">
        <Flexbox.Row
          className={style.meetingType__dynamicVariables2}
          overflow="scroll"
          wrap="wrap"
        >
          {variables.map((variable) => {
            return (
              <DynamicVariableButton
                key={variable.value}
                {...variable}
                onClick={onDynamicFieldClick}
              />
            );
          })}
        </Flexbox.Row>
      </Flexbox.Row>
      <XXLargeSpacing />
    </Flexbox.Column>
  );
}
