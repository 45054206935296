import React from "react";
import Flexbox from "../../components/flexbox/FlexboxV2";
import {
  LargeSpacing,
  MediumSpacing,
  XLargeSpacing,
  XXLargeSpacing,
} from "../../components/spacing";
import { P1 } from "../../components/typography";
import { useMeetingInviteStyle } from "../context";
import Step from "../Step";
import { RadioButton } from "../../components/radio";
import { INVITE_STYLE, isWebinar } from "./props";
import style from "./style.module.scss";

function InviteStyle({ stepNumber }) {
  const [meetingInviteStyle, setMeetingInviteStyle] = useMeetingInviteStyle();
  const webinarsEnabled = false;
  return (
    <>
      <Flexbox.Row>
        <XXLargeSpacing />
        <Flexbox.Column>
          <Flexbox.Row>
            <Step stepNumber={stepNumber} description="Meeting invite style" />
          </Flexbox.Row>
          <LargeSpacing />
          <Flexbox.Row>
            <XXLargeSpacing />
            <Flexbox.Row>
              <XXLargeSpacing />
              <Flexbox.Row>
                <Flexbox.Column>
                  <Flexbox.Row element="label">
                    <P1 bold>Kronologic Invite</P1>
                    <XLargeSpacing />
                    <RadioButton
                      checked={
                        meetingInviteStyle === INVITE_STYLE.CALENDAR_FIRST
                      }
                      name={`/meetingtypes/configuration/behavior/routing/${INVITE_STYLE.CALENDAR_FIRST}`}
                      onClick={() => {
                        setMeetingInviteStyle(INVITE_STYLE.CALENDAR_FIRST);
                      }}
                    />
                  </Flexbox.Row>
                  <Flexbox.Row>
                    <P1 small>
                      <P1 italic>
                        Calendar invite and email are sent at the same time.
                      </P1>
                    </P1>
                  </Flexbox.Row>
                </Flexbox.Column>
                <MediumSpacing />
                <Flexbox.Column>
                  <Flexbox.Row element="label">
                    <P1 bold>Kronologic Email</P1>
                    <XLargeSpacing />
                    <RadioButton
                      checked={
                        meetingInviteStyle === INVITE_STYLE.CUSTOM_INVITE
                      }
                      name={`/meetingtypes/configuration/behavior/routing/${INVITE_STYLE.CUSTOM_INVITE}`}
                      onClick={() => {
                        setMeetingInviteStyle(INVITE_STYLE.CUSTOM_INVITE);
                      }}
                    />
                  </Flexbox.Row>
                  <Flexbox.Row>
                    <P1 small>
                      <P1 italic>
                        Only an email is sent. The calendar invite is sent after
                        a guest takes one of these actions:
                        <ul className={style.bullets}>
                          <li>
                            Accept button is clicked from the proposed meeting
                            time
                          </li>
                          <li>Guest selects their own meeting time</li>
                          <li>Meeting time negotiated through AI</li>
                        </ul>
                      </P1>
                    </P1>
                  </Flexbox.Row>
                </Flexbox.Column>
                <MediumSpacing />
                {webinarsEnabled && (
                  <Flexbox.Column>
                    <Flexbox.Row element="label">
                      <P1 bold>Webinar</P1>
                      <XLargeSpacing />
                      <RadioButton
                        checked={isWebinar(meetingInviteStyle)}
                        name={`/meetingtypes/configuration/behavior/routing/${INVITE_STYLE.WEBINAR}`}
                        onClick={() => {
                          setMeetingInviteStyle(INVITE_STYLE.WEBINAR);
                        }}
                      />
                    </Flexbox.Row>
                    <Flexbox.Row>
                      <P1 small>
                        <P1 italic>
                          Choose host, start time, and guest(s) for an event
                        </P1>
                      </P1>
                    </Flexbox.Row>
                  </Flexbox.Column>
                )}
              </Flexbox.Row>
              <MediumSpacing />
            </Flexbox.Row>
          </Flexbox.Row>
        </Flexbox.Column>
      </Flexbox.Row>
    </>
  );
}

export default InviteStyle;
