import React, { forwardRef, useCallback } from "react";
import Asterik from "../Asterik";
import Flexbox from "../../components/flexbox/FlexboxV2";
import { SmallSpacing } from "../../components/spacing";
import { P1 } from "../../components/typography";
import { useInviteLocation } from "../context";
import { Input } from "../../components/input";
import style from "../style.module.scss";

const InviteLocation = forwardRef(function InviteLocationWithRef(
  { isEditable },
  ref
) {
  const [, setLocation] = useInviteLocation();
  const updateLocation = useCallback(() => {
    setLocation(ref.current.value);
  }, [setLocation, ref]);
  return (
    <Flexbox.Column data-ref-id="invite.location">
      <P1>
        Location
        <Asterik />
      </P1>
      <SmallSpacing />
      <Input
        data-template-name="invite"
        className={style.meetingType__inviteInput}
        disabled={!isEditable}
        inputRef={ref}
        type="text"
        name="/meetingtypes/configuration/invite/location"
        placeholder="Location"
        onInput={updateLocation}
      />
    </Flexbox.Column>
  );
});

export default InviteLocation;
