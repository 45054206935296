import { useCallback, useLayoutEffect, useRef, useState } from "react";

import { findEditableHost, insertTextV2 } from "./insertText";

function pasteTextInQuillJSEditor(ref, template) {
  const { current } = ref;
  if (current === null) {
    return;
  }
  const editor = current.getEditor();
  const unprivilegedEditor = current.makeUnprivilegedEditor(editor);
  const selection = unprivilegedEditor.getSelection(false);
  if (selection) {
    const { length, index } = selection;
    if (length > 0) {
      editor.deleteText(index, length);
    }
    editor.insertText(index, template);
  }
}

export default function useEmailRefFactory(email, setEmail) {
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const body = useRef(null);
  const subject = useRef(null);
  const update = useCallback(() => {
    const { current } = body;
    // Quill.js fires onEditorChange before it is initialized.
    // Ignore it if the reference is not conencted
    if (current === null) {
      return null;
    }
    const editor = current.getEditor();
    const unprivilegedEditor = current.makeUnprivilegedEditor(editor);
    const newBody = unprivilegedEditor.getHTML();
    setEmail({
      ...email,
      body: newBody,
      title: subject.current.value,
    });
  }, [email, setEmail]);
  const refs = useRef({
    body,
    subject,
  });
  useLayoutEffect(() => {
    if (shouldUpdate) {
      update();
      setShouldUpdate(false);
    }
  }, [shouldUpdate, update]);
  return {
    insertText: useCallback((template) => {
      const host = findEditableHost();
      if (host !== null && host.className.indexOf("ql-editor") > -1) {
        pasteTextInQuillJSEditor(body, template);
      } else {
        insertTextV2(template, {
          "email.subject": subject,
        });
      }
      setShouldUpdate(true);
    }, []),
    refs,
    update,
  };
}
