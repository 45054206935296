import React, { useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import Icon from "@mdi/react";
import {
  mdiMenu,
  mdiLoading,
  mdiPencilOutline,
  mdiClose,
  mdiUploadOutline,
} from "@mdi/js";
import dayjs from "dayjs";
import cx from "classnames";

import { ButtonContainer, ButtonIcon } from "../components/button";
import Flexbox from "../components/flexbox/FlexboxV2";
import { Input } from "../components/input";
import AutoTagging from "./AutoTagging";
import {
  XSmallSpacing,
  JumboSpacing,
  LargeSpacing,
  MediumSpacing,
  SuperJumboSpacing,
  XXLargeSpacing,
  SmallSpacing,
} from "../components/spacing";
import ToggleSwitch from "../components/switch/Switch";
import { TagButton } from "../components/tagButton";
import { TagList } from "../components/tagList";
import { P1, H6 } from "../components/typography";
import { useUsersRepository } from "../repository";
import MeetingTypeTab from "./MeetingTypeTab";
import {
  TeamMembersContext,
  useActive,
  useCanEdit,
  useMeetingInviteStyle,
  useName,
  useSaveMeetingType,
  useTags,
} from "./context";
import useMeetingTypeProgress from "./useMeetingTypeProgress";
import { General } from "./General";
import Recipients from "./Recipients";
import useCreationData from "./useCreationData";
import { Scheduling } from "./schedule";
import { isWebinar } from "./invite/props";
import style from "./style.module.scss";

const GENERAL_PATH = [
  "/meetingTypes/user/:meetingTypeId/general/emails/attempt/:emailId",
  "/meetingTypes/user/:meetingTypeId/general",
];

export function MeetingType({
  isMeetingTypeUpdating,
  meetingType,
  openMenu,
  openTags,
  saveError,
  setMeetingTypes,
  showHamburger,
}) {
  const [active, setActive] = useActive();
  const [meetingInviteStyle] = useMeetingInviteStyle();
  const history = useHistory();
  const { tab } = useParams();
  const { useAllTeamMembers } = useUsersRepository();
  const creationData = useCreationData({ meetingType });
  const { data: teamMembers, loading: areTeamMembersLoading } =
    useAllTeamMembers(meetingType?.team?.id, creationData);
  const complete = useMeetingTypeProgress({ meetingType });
  const [isEditingOn, setIsEditingOn] = useState(false);
  const [name, setName] = useName();
  const [tags] = useTags();
  const saveMeetingType = useSaveMeetingType();
  const canEditMeetingType = useCanEdit();

  return (
    <>
      <Flexbox.Column flex="1 1 100%" maxHeight="100%" width="100%">
        <LargeSpacing />
        <Flexbox.Row alignItems="center">
          {showHamburger && (
            <>
              <XXLargeSpacing />
              <ButtonContainer
                className={style.meetingType__showMenuButton}
                transparent
                name="/meetingtypes/open_list"
                onClick={openMenu}
              >
                <Icon path={mdiMenu} size={1.5} />
              </ButtonContainer>
              <LargeSpacing />
            </>
          )}
          {!showHamburger && <XXLargeSpacing />}
          {!isEditingOn && <H6 whiteSpace="nowrap">{meetingType.name}</H6>}
          {isEditingOn && (
            <Input
              name="/meetingtypes/edit_name/text_box"
              disabled={!canEditMeetingType}
              large
              onChange={(event) => {
                setName(event.target.value);
              }}
              value={name}
            />
          )}
          <LargeSpacing />
          <ButtonContainer
            name={
              isEditingOn
                ? "/meetingtypes/edit_name/text_box/close"
                : "/meetingtypes/edit_name"
            }
            transparent
          >
            <Flexbox.Row>
              <Icon
                className={cx(style.meetingTypes__buttonMenu, {
                  [style.meetingTypes__buttonMenu__disabled]:
                    !canEditMeetingType,
                })}
                path={isEditingOn ? mdiClose : mdiPencilOutline}
                size={1}
                onClick={() => {
                  if (!canEditMeetingType) {
                    return;
                  }
                  return setIsEditingOn((p) => !p);
                }}
              />
            </Flexbox.Row>
          </ButtonContainer>
          <P1 className={style.meetingTypes__createdByData}>
            <div className={style.meetingTypes__createdByData__item}>
              {`Creator: ${creationData?.userFirstName || ""} ${
                creationData?.userLastName || ""
              }`}
            </div>
            <div className={style.meetingTypes__createdByData__item}>
              {`Created: ${dayjs(creationData?.createdAt || null).format(
                "MM/DD/YY, h:mma"
              )}`}
            </div>
          </P1>
          <Flexbox.Row flex="1 1 100%" />
          {saveError && (
            <Flexbox.Column>
              {Array.isArray(saveError) &&
                saveError.map((error) => (
                  <P1 key={error} small>
                    {error}
                  </P1>
                ))}
            </Flexbox.Column>
          )}
          <Flexbox.Row flex={0}>
            {tags.length === 0 && (
              <TagButton
                disabled={!canEditMeetingType}
                name="/meetingtypes/add_tag"
                onClick={openTags}
              />
            )}
            {tags.length > 0 && (
              <TagList
                disabled={!canEditMeetingType}
                maxRows={1}
                onClick={openTags}
                tags={tags}
                size={0.5}
              />
            )}
          </Flexbox.Row>
          <LargeSpacing />
          <Flexbox.Row flex={0}>
            <ToggleSwitch
              disabled={(!active && complete < 100) || !canEditMeetingType}
              isOn={active}
              handleToggle={() => {
                setActive(!active);
              }}
              name="/meetingtypes/toggle_on_off"
              size="small"
            />
          </Flexbox.Row>
          {active && (
            <>
              <LargeSpacing />
              <Flexbox.Row flex={0}>
                <ButtonContainer
                  className={style.meetingType__uploadCsvButton}
                  medium
                  name="/meetingtypes/upload_csv"
                  onClick={() => history.push("/reports/import/contacts")}
                  primary
                >
                  Upload CSV
                  <ButtonIcon path={mdiUploadOutline} />
                </ButtonContainer>
              </Flexbox.Row>
            </>
          )}
          <JumboSpacing horizontal />
        </Flexbox.Row>
        <MediumSpacing />
        <Flexbox.Row className={style.meetingTypes__tabMenu}>
          <JumboSpacing horizontal />
          <MeetingTypeTab
            name="/meetingtypes/configuration"
            onClick={() => {
              history.push(`/meetingTypes/user/${meetingType.id}/general`);
            }}
            selected={tab === "general"}
            text="Configuration"
          />
          {!isWebinar(meetingInviteStyle) && (
            <>
              <SuperJumboSpacing horizontal />
              <MeetingTypeTab
                name="/meetingtypes/guests"
                onClick={() => {
                  history.push(
                    `/meetingTypes/user/${meetingType.id}/recipients`
                  );
                }}
                selected={tab === "recipients"}
                text="Guests"
              />
            </>
          )}
          {!isWebinar(meetingInviteStyle) && (
            <>
              <SuperJumboSpacing horizontal />
              <MeetingTypeTab
                name="/meetingtypes/auto_tagging"
                onClick={() => {
                  history.push(`/meetingTypes/user/${meetingType.id}/autotag`);
                }}
                selected={tab === "autotag"}
                text="Auto-Tagging"
              />
            </>
          )}
          <SuperJumboSpacing horizontal />
          {!isWebinar(meetingInviteStyle) && (
            <MeetingTypeTab
              onClick={() => {
                history.push(`/meetingTypes/user/${meetingType.id}/scheduling`);
              }}
              name="/meetingTypes/one_click_scheduling"
              selected={tab === "scheduling"}
              text="1-Click Scheduling"
            />
          )}
          <div style={{ flex: "1 1 100%" }} />
          <Flexbox.Row width="1000px">
            <JumboSpacing />
            <Flexbox.Column>
              <SmallSpacing />
              <Flexbox.Row>
                <div className={style.meetingType__progressBar}>
                  <div
                    className={style.meetingType__progress}
                    style={{ width: `${complete}%` }}
                  />
                </div>
              </Flexbox.Row>
              <XSmallSpacing />
              <Flexbox.Column className={style.meetingType__progressInfoRow}>
                <Flexbox.Row justifyContent="space-between">
                  <div>Progress</div>
                  <div>{`${complete}%`}</div>
                </Flexbox.Row>
              </Flexbox.Column>
              <XSmallSpacing />
            </Flexbox.Column>
            <JumboSpacing />
          </Flexbox.Row>
          {meetingType.active && (
            <Flexbox.Column justifyContent="center" width="auto">
              <ButtonContainer
                className={style.meetingType__saveButton}
                disabled={complete < 100 || !canEditMeetingType}
                name="/meetingtypes/save"
                primary={!isMeetingTypeUpdating}
                secondary={isMeetingTypeUpdating}
                medium
                onClick={() => {
                  saveMeetingType();
                }}
              >
                {isMeetingTypeUpdating && (
                  <Flexbox.Row alignItems="center">
                    <Icon path={mdiLoading} size={0.75} spin={4} />
                    <MediumSpacing />
                    <P1>Saving...</P1>
                  </Flexbox.Row>
                )}
                {!isMeetingTypeUpdating && <P1>Save</P1>}
              </ButtonContainer>
            </Flexbox.Column>
          )}
          {!meetingType.active && (
            <Flexbox.Column justifyContent="center" width="auto">
              <ButtonContainer
                className={style.meetingType__saveButton}
                secondary
                medium
              >
                {isMeetingTypeUpdating && (
                  <Flexbox.Row alignItems="center">
                    <Icon path={mdiLoading} size={0.75} spin={4} />
                    <MediumSpacing />
                    <P1>Saving...</P1>
                  </Flexbox.Row>
                )}
                {!isMeetingTypeUpdating && <P1>Saved</P1>}
              </ButtonContainer>
            </Flexbox.Column>
          )}
          <JumboSpacing horizontal />
        </Flexbox.Row>
        <LargeSpacing />
        <TeamMembersContext.Provider
          value={{ areTeamMembersLoading, teamMembers }}
        >
          <Switch>
            <Route
              path={GENERAL_PATH}
              render={() => <General meetingType={meetingType} />}
            />
            <Route
              path="/meetingTypes/user/:meetingTypeId/recipients"
              render={() => <Recipients meetingType={meetingType} />}
            />
            <Route
              path="/meetingTypes/user/:meetingTypeId/autotag"
              render={() => (
                <AutoTagging
                  meetingType={meetingType}
                  setMeetingTypes={setMeetingTypes}
                />
              )}
            />
            <Route
              path="/meetingTypes/user/:meetingTypeId/scheduling"
              render={() => <Scheduling meetingType={meetingType} />}
            />
            <Route
              path="*"
              render={() => (
                <Redirect to={`/meetingTypes/user/${meetingType.id}/general`} />
              )}
            />
          </Switch>
        </TeamMembersContext.Provider>
        <LargeSpacing />
      </Flexbox.Column>
    </>
  );
}
