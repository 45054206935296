import { useSWRConfig } from "swr";

import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useErrorHandler } from "../hooks";
import { useUserService } from "../services";

export function useTagUsers() {
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const accessToken = getUserToken();
  const errorHandler = useErrorHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (userIds: number[], tagIds: number[]): Promise<void> =>
    service
      .put(`/api/tag/users`)
      .set(headers)
      .send({ userIds, tagIds })
      .then((res: Response) => {
        // @ts-ignore
        const keys = cache.keys();

        // Invalidate contact queries and force refresh.
        Array.from(keys)
          .filter((r: any) => r.includes("/api/user"))
          .forEach((k: any) => {
            mutate(k);
          });

        return res;
      })
      .then((res: Response) => res.body)
      .catch(errorHandler);
}

export default useTagUsers;
