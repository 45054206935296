import useOrderedUsers from "./useOrderedUsers";

function generateMetadata(newRouting, subTypeField) {
  if (newRouting === "custom" && subTypeField !== null) {
    return {
      subtypeDetails: {
        field: subTypeField,
        subtype: "email",
      },
    };
  }
  return null;
}

export function generateSingleMappingLogic(field, value) {
  return [
    {
      field,
      operator: "is",
      value,
    },
  ];
}

export default function useGenerateRoutingLogic() {
  const orderedUsers = useOrderedUsers();
  return (newRouting, subTypeField = null, preserveLogic = true) => {
    const custom = {};
    const sequential = { order: [] };
    orderedUsers.forEach((user) => {
      // TODO(Matt): I think this can be cleaner right now its a litte clunky
      const enabled = user.isEnabled;
      custom[user.id] = {
        enabled,
        mapping_logic: preserveLogic ? user.mappingLogic : [],
      };
      if (enabled) {
        sequential.order.push(user.id);
      }
      if (enabled && subTypeField !== null && !preserveLogic) {
        custom[user.id].mapping_logic = generateSingleMappingLogic(
          subTypeField,
          user.email
        );
      }
    });
    const metadata = generateMetadata(newRouting, subTypeField);
    if (metadata !== null) {
      return { custom, metadata, sequential };
    }
    return { custom, sequential };
  };
}
