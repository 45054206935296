import { useSWRConfig } from "swr";

import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useErrorHandler, useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { Meeting } from "../types";

export function useCreateMeetings() {
  const accessToken = getUserToken();
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const errorHandler = useErrorHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (
    meetings: {
      meetingType?: number;
      contact?: number;
      overrides?: {
        duration?: number;
        dayRangeFrom?: number;
        dayRangeTo?: number;
        gap?: number;
        bufferDurationMinutes?: number;
        emailSubjectTemplate?: string;
        emailBodyTemplate?: string;
        inviteTitleTemplate?: string;
        inviteBodyTemplate?: string;
      };
      startTime?: string;
      user?: number;
      enabled?: boolean;
      meta: {
        platform: string;
        source: string;
      };
    }[]
  ): Promise<{ failures: null | any[]; instances: Meeting[] }> =>
    service
      .post(`/api/meetings/instance`)
      .set(headers)
      .send(meetings)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .then((res: any) => {
        // @ts-ignore
        const keys = cache.keys();

        // Invalidate meeting queries and force refresh.
        Array.from(keys)
          .filter((r: any) => {
            return (
              r.includes("/api/meetings/instances/other") ||
              r.includes("/api/meetings/instances/timeline") ||
              r.includes("/api/meetings/instances/report") ||
              r.includes("/api/meetings/instances/search")
            );
          })
          .forEach((k: any) => {
            mutate(k);
          });

        return res;
      })
      .catch(errorHandler);
}
