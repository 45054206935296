import React from "react";

import { NEUTRAL_300, NEUTRAL_700, PRIMARY_BLUE } from "../components/colors";
import { useTheme } from "../components/theme/ThemeProvider";
import StepComplete from "./StepComplete";
import StepIncomplete from "./StepIncomplete";

import style from "./style.module.scss";

export default function StepCompletion({ completion }) {
  const { isDark } = useTheme();
  if (completion >= 100) {
    return <StepComplete />;
  }
  if (completion === 0) {
    return <StepIncomplete />;
  }
  const circumference = Math.PI * 2 * 11;
  const offset = circumference - (completion / 100) * circumference;
  return (
    <div className={style.meetingType__stepCompletion}>
      <svg viewBox="0 0 24 24">
        <circle
          cx={12}
          cy={12}
          fill="none"
          r={11}
          stroke={isDark ? NEUTRAL_700 : NEUTRAL_300}
          strokeWidth={2}
        />
        <circle
          className={style.meetingType__stepCompletionProgressRing}
          cx={12}
          cy={12}
          fill="none"
          r={11}
          stroke={PRIMARY_BLUE}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
          strokeWidth={2}
          rotate="-90deg"
        />
      </svg>
    </div>
  );
}
