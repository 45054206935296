import React, { useRef } from "react";
import { IconButton, TextField } from "@mui/material";
import { CloseIcon } from "src/icons";

// docs: https://mui.com/material-ui/react-text-field/
type TextFieldProps = React.ComponentProps<typeof TextField>;

export interface InputProps {
  value?: string | null;
  label?: string | null;
  type?: string;
  disabled?: boolean;
  required?: boolean;
  spellCheck?: boolean;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  clearable?: boolean;
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
  onChange?: TextFieldProps["onChange"];
}

export function Input({
  type,
  value = null,
  label = null,
  disabled = false,
  required = false,
  spellCheck = true,
  error = false,
  helperText = "",
  placeholder = "",
  clearable = false,
  multiline = false,
  minRows = 1,
  maxRows = 99,
  onChange = () => null,
  ...rest
}: InputProps) {
  const ref = useRef<HTMLInputElement>();

  return (
    <TextField
      inputRef={ref}
      fullWidth
      type={type}
      spellCheck={spellCheck}
      value={value}
      label={label}
      disabled={disabled}
      required={required}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      multiline={multiline}
      minRows={minRows}
      maxRows={maxRows}
      onChange={onChange}
      InputProps={{
        endAdornment: clearable && value && (
          <IconButton
            onClick={() => {
              if (ref && ref.current) {
                const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                  window.HTMLInputElement.prototype,
                  "value"
                )?.set;

                if (nativeInputValueSetter) {
                  nativeInputValueSetter.call(ref.current, "");
                  const e = new Event("input", { bubbles: true });
                  ref.current.dispatchEvent(e);
                }
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        ),
      }}
      // The minimum margin required to show the label when its shown at the top of the input.
      sx={{ mt: "5px" }}
      {...rest}
    />
  );
}

export default Input;
