import { Calendar } from "./Calendar";
import { Role } from "./Role";
import { Tag } from "./Tag";

export interface User {
  id: number;
  role: Role;
  firstName: string;
  lastName: string;
  permissions: number;
  email: string;
  integration: string;
  org: {
    org_key: string;
    name: string;
  };
  teams: { id: number; name: string }[] | null;
  title: string;
  profileURL: string;
  settings: {
    isDark: boolean;
  };
  meetingLink: string;
  location: string;
  tokenInfo: {
    tokenExpiresAt: string;
    tokenType: string;
  };
  timezone: string;
  schedulers: {
    from: number;
    to: number;
    weekdays: number[];
  } | null;
  signUpDate: string;
  sharedUserCalendars: Calendar[] | null;
  sharedTeamCalendars: Calendar[] | null;
  tags: Tag[] | null;
  authValid: boolean;
  status: string;
  isOpenUser: boolean;
}

// Exported constants for specific permissions. The value for these constants
// represents the position of the bit that encodes their value.
export const PermissionToChangeHost = 0;

// Permission type is a union of all possible permission values.
export type Permission = typeof PermissionToChangeHost;

// Helper function used to decode the hot bit encoding scheme for user permissions.
export function hasPermission(
  permissions: number,
  permission: Permission
): boolean {
  return (permissions & (1 << permission)) > 0;
}

// Helper function used to add permissions to an existing set of permissions.
export function addPermission(
  permissions: number,
  permission: Permission
): number {
  return permissions | (1 << permission);
}

// Helper function used to remove permissions to an existing set of permissions.
export function removePermission(
  permissions: number,
  permission: Permission
): number {
  return permissions & ~(1 << permission);
}
