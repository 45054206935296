import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popover,
  Skeleton,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { PageLayout } from "src/componentsV2/PageLayout";
import FilterButton from "src/componentsV2/filters/FilterButton";
import {
  useConfirmationDialog,
  useDebounce,
  useLocalStorage,
  useSnackbar,
} from "src/hooks";
import {
  GuestStatus,
  MeetingStatus,
  meetingStatusOptionFromValue,
} from "src/types";

import {
  Delete,
  Download,
  Label,
  ScheduleSend,
  Send,
  Upload,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import {
  MeetingQuery,
  MeetingsFilterBar,
} from "src/componentsV2/MeetingsFilterBar";
import { Pagination } from "src/componentsV2/Pagination";
import { TableSort } from "src/componentsV2/Table";
import ScheduleSendDialog from "src/componentsV2/dialogs/ScheduleSendDialog";
import TagManagementDialog from "src/componentsV2/dialogs/TagManagementDialog";
import { SelectTable } from "src/componentsV2/tables/SelectTable";
import useUrlQueryV2 from "src/hooks/useUrlQueryV2";
import {
  INITIALIZING_FAULT_STAGE,
  isFinalMeetingStage,
  isMeetingCancelledStage,
  isMeetingInitFaultStage,
} from "src/meetings/props";
import {
  useAddMeetingTags,
  useDeleteMeetings,
  useSendLater,
  useUpdateMeetings,
} from "src/mutations";
import ErrorPage from "src/pages/ErrorPage";
import { useMeetingReportV2 } from "src/queries";
import { useMeetingSearchResults } from "src/queries/useMeetingSearchResultsNewPage";

function MeetingStatusVisual(props: { status?: MeetingStatus }) {
  let borderColor = "lightgray";
  let backgroundColor = "lightgray";

  switch (props.status) {
    case "queued":
      borderColor = "#c3a7ba";
      backgroundColor = "#c3a7ba";
      break;
    case "cancelled":
      borderColor = "#8e939f";
      backgroundColor = "#8e939f";
      break;
    case "completed":
      borderColor = "#588072";
      backgroundColor = "#588072";
      break;
    case "init_fault":
      borderColor = "#9fa7eb";
      backgroundColor = "#9fa7eb";
      break;
    case "initializing":
      borderColor = "#c6d5ee";
      backgroundColor = "#c6d5ee";
      break;
    case "initialized":
      borderColor = "#a3bae1";
      backgroundColor = "#a3bae1";
      break;
    case "scheduling":
      borderColor = "#81a1d5";
      backgroundColor = "#81a1d5";
      break;
    case "scheduled":
      borderColor = "#a2a1d0";
      backgroundColor = "#a2a1d0";
      break;
    case "no_quorum":
      borderColor = "#616672";
      backgroundColor = "#616672";
      break;
  }

  return (
    <div
      style={{
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 4,
        paddingBottom: 4,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: "5px",
        borderColor,
        backgroundColor,
        whiteSpace: "nowrap",
        width: "fit-content",
      }}
    >
      {props.status
        ? meetingStatusOptionFromValue(props.status)?.label
        : "Unknown"}
    </div>
  );
}

function GuestStatusIndicator(props: { status?: GuestStatus }) {
  let borderColor = "lightgray";
  let backgroundColor = "lightgray";

  switch (props.status) {
    case "accepted":
      borderColor = "#70AF80";
      backgroundColor = "#70AF80";
      break;
    case "accept_pending_quorum":
      borderColor = "#70AF80";
      backgroundColor = "#70AF80";
      break;
    case "negotiation_in_progress":
      borderColor = "#7fa2bd";
      backgroundColor = "#7fa2bd";
      break;
    case "waiting_for_first_response":
      borderColor = "#9bbdec";
      backgroundColor = "#9bbdec";
      break;
    case "away":
      borderColor = "#d9d4c9";
      backgroundColor = "#d9d4c9";
      break;
    case "cancelled":
      borderColor = "#7e8295";
      backgroundColor = "#7e8295";
      break;
    case "declined":
      borderColor = "#7f92a6";
      backgroundColor = "#7f92a6";
      break;
    case "host_intervened":
      borderColor = "#81a1d5";
      backgroundColor = "#81a1d5";
      break;
    case "no_response":
      borderColor = "#959393";
      backgroundColor = "#959393";
      break;
    case "paused":
      borderColor = "#b4c6e2";
      backgroundColor = "#b4c6e2";
      break;
    case "requires_host_intervention":
      borderColor = "#7aa6ea";
      backgroundColor = "#7aa6ea";
      break;
    case "responded_unknown_intent":
      borderColor = "#61d1e3";
      backgroundColor = "#61d1e3";
      break;
    case "staging":
      borderColor = "#c6d5ee";
      backgroundColor = "#c6d5ee";
      break;
    case "undeliverable":
      borderColor = "#7e8295";
      backgroundColor = "#7e8295";
      break;
  }

  return (
    <Tooltip title={props.status ? props.status : ""} placement="top">
      <div
        style={{
          width: "18px",
          height: "18px",
          borderRadius: "100%",
          borderStyle: "solid",
          borderWidth: 1,
          borderColor,
          backgroundColor,
        }}
      />
    </Tooltip>
  );
}

const defaultMeetingQuery: MeetingQuery = {
  guestEmail: "",
  hostEmail: "",
  notes: "",
  meetingStatus: [],
  guestStatus: [],
  template: [],
  tags: [],
  date: {
    field: "lastActivity",
    range: [null, null],
  },
  sort: {
    field: "lastActivity",
    order: "desc",
  },
  tagFilterSearch: "",
  templateFilterSearch: "",
};

const defaultTableSort: TableSort = {
  columnId: "lastActivity",
  order: "desc",
};

dayjs.extend(relativeTime);

export function SearchBar(props: {
  filters?: { [index: string]: boolean };
  onFilterChange?: (value: { [index: string]: boolean }) => void;
  value?: string;
  onChange?: (value: string) => void;
}) {
  const inputRef = useRef<HTMLInputElement | null>();
  const [open, setOpen] = useState(false);
  const [searchHistory, setSearchHistory] = useLocalStorage<string[]>(
    "searchHistory",
    []
  );
  const filters = props.filters || {};
  const timer = useRef<NodeJS.Timeout>();
  const filterList = Object.entries(filters)
    .reduce((prev, [key, value]) => {
      if (value) {
        return prev.concat(key);
      } else {
        return prev;
      }
    }, [] as string[])
    .join(", ");
  const filterCount = Object.values(filters).reduce(
    (a, b) => (b ? a + 1 : a),
    0
  );

  return (
    <div style={{ width: "400px" }}>
      <TextField
        inputRef={inputRef}
        placeholder={`Search Meetings by ${filterList}`}
        value={props.value}
        onChange={(event) => {
          clearTimeout(timer.current);
          timer.current = setTimeout(() => {
            let h = [event.target.value].concat(searchHistory);

            // Drop any empty values.
            h = h.filter((e) => e.length && e.length > 0);

            // Remove duplicates.
            h = h.filter((value, index, self) => self.indexOf(value) === index);

            // Keep only 5 items.
            if (h.length > 5) {
              h = h.slice(0, 5);
            }

            setSearchHistory(h);
          }, 750);

          if (props.onChange) {
            props.onChange(event.target.value);
          }
        }}
        onClick={() => setOpen(true)}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            setOpen(false);
          }
        }}
        autoComplete="off"
        size="small"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Stack direction="row" spacing={1}>
                {filterCount > 0 && (
                  <Typography>
                    {filterCount}
                    {" filter"}
                    {filterCount > 1 ? "s" : ""}
                  </Typography>
                )}
                <SearchIcon />
              </Stack>
            </InputAdornment>
          ),
        }}
      />

      <Popover
        open={open}
        disableAutoFocus
        onClose={() => {
          setOpen(false);
        }}
        anchorEl={inputRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: { minWidth: "300px", width: inputRef.current?.offsetWidth },
        }}
      >
        <Stack>
          <Box sx={{ p: 1 }}>
            <Typography>Search By</Typography>
            <Grid container rowGap={1} columnGap={1} sx={{ py: 1 }}>
              {Object.keys(filters).map((filterKey) => (
                <Grid item key={filterKey}>
                  <FilterButton
                    onClick={() => {
                      const temp = { ...filters };
                      temp[filterKey] = !filters[filterKey];
                      if (props.onFilterChange) {
                        props.onFilterChange(temp);
                      }
                    }}
                    active={filters[filterKey]}
                  >
                    {filterKey}
                  </FilterButton>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Divider />
          <Box sx={{ p: 1 }}>
            <Typography>Recent Searches</Typography>
            <List>
              {searchHistory.map((item) => (
                <ListItemButton
                  key={item}
                  dense
                  onClick={() => {
                    if (props.onChange) {
                      if (inputRef.current) {
                        inputRef.current.value = item;
                      }

                      props.onChange(item);
                    }
                  }}
                >
                  <ListItemText primary={item} />
                </ListItemButton>
              ))}
            </List>
          </Box>
        </Stack>
      </Popover>
    </div>
  );
}

function Reporting({ query }: { query: MeetingQuery }) {
  const guestEmail = useDebounce(query.guestEmail, 750);
  const hostEmail = useDebounce(query.hostEmail, 750);
  const notes = useDebounce(query.notes, 750);

  const { data } = useMeetingReportV2({
    filter: {
      guestEmail,
      hostEmail,
      notes,
      meetingStatusFilter: query.meetingStatus,
      guestStatusFilter: query.guestStatus,
      meetingTypeFilter: query.template.map((t) => t.id),
      tagFilter: query.tags.map((t) => t.id),
      lastActivityFilter: {
        start: query.date.range[0]
          ? query.date.range[0].format("YYYY-MM-DDTHH:mm:ss")
          : undefined,
        end: query.date.range[1]
          ? query.date.range[1].format("YYYY-MM-DDTHH:mm:ss")
          : undefined,
      },
    },
  });

  if (!data) {
    return (
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Skeleton variant="rectangular" height="100px" width="100%" />
        <Skeleton variant="rectangular" height="100px" width="100%" />
        <Skeleton variant="rectangular" height="100px" width="100%" />
        <Skeleton variant="rectangular" height="100px" width="100%" />
      </Stack>
    );
  }

  return (
    <Stack direction="row" justifyContent="space-between" spacing={2}>
      <Card elevation={0} sx={{ width: "100%" }}>
        <CardHeader
          title={<Typography variant="h6">Action Required</Typography>}
          sx={{ px: 2, pt: 2, pb: 1, m: 0 }}
        />
        <CardContent
          sx={{
            pt: 0,
            pl: 2,
            pr: 2,
            m: 0,
            "&:last-child": {
              paddingBottom: 1,
            },
          }}
        >
          <Typography variant="h6">
            {data?.readyToSend.actionRequired}
          </Typography>
        </CardContent>
      </Card>
      <Card elevation={0} sx={{ width: "100%" }}>
        <CardHeader
          title={<Typography variant="h6">Currently Scheduled</Typography>}
          sx={{ px: 2, pt: 2, pb: 1, m: 0 }}
        />
        <CardContent
          sx={{
            pt: 0,
            pl: 2,
            pr: 2,
            m: 0,
            "&:last-child": {
              paddingBottom: 1,
            },
          }}
        >
          <Typography variant="h6">{data.scheduling.total}</Typography>
        </CardContent>
      </Card>
      <Card elevation={0} sx={{ width: "100%" }}>
        <CardHeader
          title={<Typography variant="h6">Acceptance Rate</Typography>}
          sx={{ px: 2, pt: 2, pb: 1, m: 0 }}
        />
        <CardContent
          sx={{
            pt: 0,
            pl: 2,
            pr: 2,
            m: 0,
            "&:last-child": {
              paddingBottom: 1,
            },
          }}
        >
          <Typography variant="h6">
            {(data.finalized.acceptanceRate * 100).toFixed(2)}%
          </Typography>
        </CardContent>
      </Card>
      <Card elevation={0} sx={{ width: "100%" }}>
        <CardHeader
          title={<Typography variant="h6">Total Completed</Typography>}
          sx={{ px: 2, pt: 2, pb: 1, m: 0 }}
        />
        <CardContent
          sx={{
            pt: 0,
            pl: 2,
            pr: 2,
            m: 0,
            "&:last-child": {
              paddingBottom: 1,
            },
          }}
        >
          <Typography variant="h6">{data?.finalized.total}</Typography>
        </CardContent>
      </Card>
    </Stack>
  );
}

const MEETING_PAGE_TABS = {
  ALL: "all",
  ACTION_REQUIRED: "action_required",
  ERRORED: "errored",
  FINALIZED: "finalized",
  READY_TO_SEND: "ready_to_send",
  SCHEDULED: "scheduled",
  SENT: "sent",
};

function MeetingsTable({
  setTab,
  tab,
  query,
  onQueryChange,
  selected,
  onSelectedChange,
  sort,
}: {
  setTab: (tab: string) => void;
  tab: string;
  query: MeetingQuery;
  onQueryChange: (query: MeetingQuery) => void;
  selected: number[];
  onSelectedChange: (value: number[]) => void;
  sort: TableSort;
}) {
  const [pageSize] = useState(25);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [query]);

  const { data, loading, error } = useMeetingSearchResults({
    filter: {
      guestEmail: query.guestEmail,
      hostEmail: query.hostEmail,
      notes: query.notes,
      meetingStatusFilter: query.meetingStatus,
      guestStatusFilter: query.guestStatus,
      meetingTypeFilter: query.templateIds || [],
      tagFilter: query.tags.map((t) => t.id),
      dateFilter:
        query.date.field === "startTime"
          ? {
              start: query.date.range[0]
                ? query.date.range[0].format("YYYY-MM-DDTHH:mm:ss")
                : undefined,
              end: query.date.range[1]
                ? query.date.range[1].endOf("day").format("YYYY-MM-DDTHH:mm:ss")
                : undefined,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }
          : undefined,
      lastActivityFilter:
        query.date.field === "lastActivity"
          ? {
              start: query.date.range[0]
                ? query.date.range[0].format("YYYY-MM-DDTHH:mm:ss")
                : undefined,
              end: query.date.range[1]
                ? query.date.range[1].endOf("day").format("YYYY-MM-DDTHH:mm:ss")
                : undefined,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }
          : undefined,
    },
    paging: {
      limit: pageSize,
      offset: page,
    },
    sort: {
      field: sort.columnId as "lastActivity" | "meetingTime",
      order: sort.order,
    },
  });

  const updateMeetings = useUpdateMeetings();
  const [openSnackbar] = useSnackbar();

  if (error) {
    return <ErrorPage status={error.status} />;
  }

  const total = data?.total || 0;

  return (
    <Paper elevation={0}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "fit-content", ml: 2 }}>
          <Tabs
            value={tab}
            onChange={(_, value) => {
              switch (value) {
                case MEETING_PAGE_TABS.ALL:
                  onQueryChange(defaultMeetingQuery);
                  break;
                case MEETING_PAGE_TABS.ERRORED:
                  onQueryChange({
                    ...query,
                    meetingStatus: ["init_fault"],
                    guestStatus: [],
                  });
                  break;
                case MEETING_PAGE_TABS.READY_TO_SEND:
                  onQueryChange({
                    ...query,
                    meetingStatus: ["initialized"],
                    guestStatus: [],
                  });
                  break;
                case MEETING_PAGE_TABS.SENT:
                  onQueryChange({
                    ...query,
                    meetingStatus: ["scheduling", "queued"],
                    guestStatus: [],
                  });
                  break;
                case MEETING_PAGE_TABS.ACTION_REQUIRED:
                  onQueryChange({
                    ...query,
                    meetingStatus: [],
                    guestStatus: ["requires_host_intervention"],
                  });
                  break;
                case MEETING_PAGE_TABS.SCHEDULED:
                  onQueryChange({
                    ...query,
                    meetingStatus: ["scheduled"],
                    guestStatus: [],
                  });
                  break;
                case MEETING_PAGE_TABS.FINALIZED:
                  onQueryChange({
                    ...query,
                    meetingStatus: ["cancelled", "completed", "no_quorum"],
                    guestStatus: [],
                  });
                  break;
              }
              setTab(value);
              // Errored: init_fault
              // Ready to Send: initialized
              // Sent: scheduling (any guest status in this meeting stage), and queued
              // Action required: requires host intervention
              // Scheduled: scheduled
              // Finalized: cancelled, completed, no_quorum
            }}
          >
            <Tab
              label="All"
              value={MEETING_PAGE_TABS.ALL}
              sx={{ textTransform: "none" }}
            />
            <Tab
              label="Errored"
              value={MEETING_PAGE_TABS.ERRORED}
              sx={{ textTransform: "none" }}
            />
            <Tab
              label="Ready to Send"
              value={MEETING_PAGE_TABS.READY_TO_SEND}
              sx={{ textTransform: "none" }}
            />
            <Tab
              label="Sent"
              value={MEETING_PAGE_TABS.SENT}
              sx={{ textTransform: "none" }}
            />
            <Tab
              label="Action Required"
              value={MEETING_PAGE_TABS.ACTION_REQUIRED}
              sx={{ textTransform: "none" }}
            />
            <Tab
              label="Scheduled"
              value={MEETING_PAGE_TABS.SCHEDULED}
              sx={{ textTransform: "none" }}
            />
            <Tab
              label="Finalized"
              value={MEETING_PAGE_TABS.FINALIZED}
              sx={{ textTransform: "none" }}
            />
          </Tabs>
          <Divider flexItem />
        </Box>
        <SelectTable
          loading={loading}
          selected={selected}
          onSelect={(checked, meetings) => {
            if (!checked) {
              onSelectedChange(
                selected.concat(meetings.map((meeting) => meeting.id))
              );
            } else {
              onSelectedChange(
                selected.filter(
                  (s) => !meetings.map((meeting) => meeting.id).includes(s)
                )
              );
            }
          }}
          columns={[
            {
              id: "id",
              label: "Id",
              component: (row) => (
                <Link component={RouterLink} to={`/meetings/${row.id}`}>
                  {row.id}
                </Link>
              ),
            },
            {
              id: "active",
              label: "Active",
              component: (row) => (
                <Switch
                  disabled={
                    !row.guests ||
                    !row.meetingType?.active ||
                    row.status === INITIALIZING_FAULT_STAGE ||
                    isFinalMeetingStage(row.status) ||
                    isMeetingCancelledStage(row.status) ||
                    (!row.meetingType?.routeOnActivation && !row.host) ||
                    (isMeetingInitFaultStage(row.status) && !row.enabled)
                  }
                  checked={row.enabled || false}
                  onClick={(event) => {
                    event.stopPropagation();
                    updateMeetings([{ id: row.id, enabled: !row.enabled }])
                      .then(({ failures }) => {
                        if (failures?.length > 0) {
                          openSnackbar("Failed to activate meeting");
                        }
                      })
                      .catch(() => {
                        openSnackbar("Failed to activate meeting");
                      });
                  }}
                />
              ),
            },
            {
              id: "meetingTime",
              label: `Time (${dayjs().format("z")})`,
              component: (row) => {
                const startTime = row.startTime ? dayjs(row.startTime) : null;
                return (
                  startTime && (
                    <Stack>
                      <Typography variant="caption">
                        {startTime.format("ddd, MMM D")}
                      </Typography>
                      <Typography variant="caption">
                        {startTime.format("h:mm A")}
                      </Typography>
                    </Stack>
                  )
                );
              },
              width: "150px",
            },
            {
              id: "template",
              label: "Template",
              component: (row) => <>{row.meetingType?.name}</>,
            },
            {
              id: "host",
              label: "Host",
              component: (row) => (
                <Stack>
                  <Typography variant="caption">
                    {row.host?.firstName} {row.host?.lastName}
                  </Typography>
                  <Typography variant="caption">{row.host?.email}</Typography>
                </Stack>
              ),
            },
            {
              id: "guest",
              label: "Guest",
              component: (row) => (
                <Stack direction="row" spacing={1} alignItems="center">
                  <GuestStatusIndicator status={row.guests?.[0]?.status} />
                  <Stack>
                    <Typography variant="caption">
                      {row.guests?.[0]?.firstName} {row.guests?.[0]?.lastName}
                    </Typography>
                    <Typography variant="caption">
                      {row.guests?.[0]?.email}
                    </Typography>
                  </Stack>
                </Stack>
              ),
            },
            {
              id: "meetingStage",
              label: "Meeting Stage",
              component: (row) => <MeetingStatusVisual status={row.status} />,
            },
          ]}
          data={data?.data || []}
          minRows={pageSize}
        />
      </Box>
      <Pagination
        pages={Math.ceil(total / pageSize)}
        currentPage={page + 1}
        onPageChange={(value) => setPage(value - 1)}
      />
    </Paper>
  );
}

export default function MeetingsPage() {
  const history = useHistory();
  const urlQuery = useUrlQueryV2();
  const [templateFilter, setTemplateFilter] = useState<number[]>([]);

  useEffect(() => {
    const templateQuery = urlQuery.getAll("templateId");
    setTemplateFilter(
      templateQuery.map((templateQueryItem) => Number(templateQueryItem))
    );
  }, [urlQuery]);

  const [query, setQuery] = useState<MeetingQuery>(defaultMeetingQuery);
  const [selected, setSelected] = useState<number[]>([]);
  const [sort, setSort] = useState<TableSort>(defaultTableSort);

  const openConfirmationDialog = useConfirmationDialog();
  const sendLater = useSendLater();
  const deleteMeetings = useDeleteMeetings();
  const updateMeetings = useUpdateMeetings();
  const addMeetingTags = useAddMeetingTags();
  const [openSnackbar] = useSnackbar();
  const [showTagManagementDialog, setShowTagManagementDialog] = useState(false);
  const [showScheduleSendDialog, setShowScheduleSendDialog] = useState(false);
  const [tab, setTab] = useState(MEETING_PAGE_TABS.ALL);

  return (
    <>
      {showScheduleSendDialog && (
        <ScheduleSendDialog
          open
          onSubmit={async (date) => {
            try {
              const { failures, instances } = await sendLater(
                selected.map((id) => ({
                  id,
                  enabled: true,
                  executeAt: date.toDate(),
                }))
              );
              setShowScheduleSendDialog(false);
              if (failures.length === 0) {
                openSnackbar("Meetings successfully scheduled for send");
              } else if (instances.length > 0) {
                openSnackbar(
                  `Some meetings activated were scheduled successfully but there were errors with ${failures
                    .map((failure) => failure.meetingId)
                    .join(", ")}`
                );
              } else {
                openSnackbar("Failed to schedule meetings to be sent later");
              }
            } catch {
              openSnackbar("Failed to schedule meetings to be sent later");
            }
          }}
          onClose={() => {
            setShowScheduleSendDialog(false);
          }}
        />
      )}
      {showTagManagementDialog && (
        <TagManagementDialog
          label="Bulk Add Tags"
          open
          onClose={() => {
            setShowTagManagementDialog(false);
          }}
          onSubmit={async (tags) => {
            if (tags.length === 0) {
              return;
            }
            try {
              await addMeetingTags(
                selected,
                tags.map((tag) => tag.id)
              );

              setShowTagManagementDialog(false);
              openSnackbar(
                `Added ${tags.length} tag${tags.length > 1 ? "s" : ""} to ${
                  selected.length
                } meeting${selected.length > 1 ? "s" : ""}`
              );
            } catch {
              openSnackbar(`Failed to add tags to meetings`);
            }
          }}
        />
      )}
      <PageLayout
        title="Meetings"
        actions={
          <>
            <IconButton
              disabled={selected.length < 1}
              onClick={async () => {
                try {
                  const { failures, instances } = await updateMeetings(
                    selected.map((meetingId) => ({
                      enabled: true,
                      id: meetingId,
                    }))
                  );

                  if (failures.length === 0) {
                    openSnackbar("Meetings activated");
                  } else if (instances.length > 0) {
                    openSnackbar(
                      `Some meetings activated were activated successfully but there were errors with ${failures
                        .map((failure) => failure.meetingId)
                        .join(", ")}`
                    );
                  } else {
                    openSnackbar("Failed to activate meetings");
                  }
                } catch {
                  openSnackbar("Failed to activate meetings");
                }
              }}
            >
              <Send />
            </IconButton>
            <IconButton
              disabled={selected.length < 1}
              onClick={() => {
                setShowScheduleSendDialog(true);
              }}
            >
              <ScheduleSend />
            </IconButton>
            <IconButton
              disabled={selected.length < 1}
              onClick={() => {
                setShowTagManagementDialog(true);
              }}
            >
              <Label />
            </IconButton>
            <IconButton
              onClick={() => {
                history.push("/reports/import/contacts");
              }}
            >
              <Upload />
            </IconButton>
            <IconButton
              onClick={() => {
                history.push(
                  `/reports/export/meetings?guestEmail=${
                    query.guestEmail
                  }&hostEmail=${query.hostEmail}&notes=${
                    query.notes
                  }&filterBy=${query.date.field}&start=${
                    query.date.range?.[0]?.toISOString() || ""
                  }&end=${
                    query.date.range?.[1]?.toISOString() || ""
                  }&templates=${JSON.stringify(
                    query.template.map((m) => ({
                      id: m.id,
                      name: m.name,
                    }))
                  )}&tags=${JSON.stringify(
                    query.tags
                  )}&guestStatuses=${query.guestStatus.join(
                    ","
                  )}&meetingStatuses=${query.meetingStatus.join(",")}`
                );
              }}
            >
              <Download />
            </IconButton>
            <IconButton
              disabled={selected.length < 1}
              onClick={() => {
                openConfirmationDialog(
                  "Delete Confirmation",
                  `Are you sure you want to delete ${selected.length} meetings?`,
                  () => {
                    deleteMeetings(selected)
                      .then(() => {
                        openSnackbar("Meeting successfully deleted");
                        setSelected([]);
                      })
                      .catch(() => {
                        openSnackbar("Failed to delete selected meetings");
                      });
                  }
                );
              }}
            >
              <Delete />
            </IconButton>
          </>
        }
      >
        <Stack spacing={2}>
          <Paper elevation={0} sx={{ p: 2 }}>
            <MeetingsFilterBar
              filters={query}
              sort={sort}
              templateFilter={templateFilter}
              onFilterUpdate={(newFilters) => setQuery(newFilters)}
              onSortUpdate={(newSort) => setSort(newSort)}
              onTemplateFilterUpdate={(templates) => {
                urlQuery.delete("templateId");
                templates.forEach((templateItem) =>
                  urlQuery.append("templateId", templateItem.id.toString())
                );
                history.push({
                  search: `?${urlQuery.toString()}`,
                });
              }}
              onReset={() => {
                setQuery(defaultMeetingQuery);
                setSort(defaultTableSort);
                setTab(MEETING_PAGE_TABS.ALL);
                history.push({
                  search: "",
                });
              }}
            />
          </Paper>

          <Reporting query={query} />

          <MeetingsTable
            setTab={setTab}
            tab={tab}
            onQueryChange={setQuery}
            query={{
              ...query,
              templateIds: templateFilter,
            }}
            sort={sort}
            selected={selected}
            onSelectedChange={setSelected}
          />
        </Stack>
      </PageLayout>
    </>
  );
}
