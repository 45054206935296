import { useMemo } from "react";
import { WHITE, BLACK } from "../colors";
import { useTheme } from "../theme";

const useTimezoneAbbrTheme = () => {
  const { isDark } = useTheme();

  return useMemo(
    () => ({
      container: (base, { isFocused }) => {
        const backgroundColor = isDark ? "#2d3855" : "#f8f9fb";

        return {
          ...base,
          backgroundColor,
          borderRadius: "4px",
          color: isFocused ? WHITE : BLACK,
          height: "30px",
          justifyContent: "flex-start",
        };
      },
      control: (base) => ({
        ...base,
        alignItems: "center",
        backgroundColor: isDark ? "#2d3855" : "#f8f9fb",
        border: 0,
        borderRadius: "2px",
        boxShadow: 0,
        color: "#d5dbe9",
        cursor: "pointer",
        display: "flex",
        fontSize: "10px",
        height: "30px",
        justifyContent: "center",
        padding: "0px",
        width: "140px",
      }),
      indicatorSeparator: (base) => ({
        ...base,
        backgroundColor: "inherit",
      }),
      indicatorsContainer: (base) => ({
        ...base,
        position: "absolute",
        right: "0",
        visibility: "hidden",
      }),
      input: (base) => ({
        ...base,
        alignItems: "center",
        backgroundColor: "inherit",
        color: isDark ? WHITE : BLACK,
      }),
      loadingIndicator: (base) => ({
        ...base,
        color: isDark ? WHITE : BLACK,
        position: "absolute",
        right: "0",
      }),
      menu: (base) => ({
        ...base,
        alignItems: "center",
        justifyContent: "center",
        marginTop: "0",
        width: "140px",
      }),
      menuList: (base) => ({
        ...base,
        alignItems: "center",
        border: `0.5px solid ${isDark ? "#191C3A" : "#A8B1BD"}`,
        borderRadius: 0,
        justifyContent: "center",
        marginTop: "0px",
        padding: "0px",
      }),
      option: (base, { isFocused }) => {
        let backgroundColor;

        if (isDark) {
          backgroundColor = isFocused ? "rgba(121, 125, 149, 0.7)" : "#414564";
        }

        if (!isDark) {
          backgroundColor = isFocused ? "#F1FAFF" : WHITE;
        }

        return {
          ...base,
          alignItems: "center",
          backgroundColor,
          color: isDark ? WHITE : BLACK,
          cursor: "pointer",
          justifyContent: "flex-start",
          overflow: "hidden",
          paddingBottom: "7px",
          paddingLeft: "2px",
          paddingTop: "7px",
          textOverflow: "ellipsis",
          whitespace: "nowrap",
        };
      },
      singleValue: (base) => ({
        ...base,
        color: isDark ? WHITE : BLACK,
        justifyContent: "flex-start",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whitespace: "nowrap",
        width: "140px",
      }),
      valueContainer: (base) => ({
        ...base,
        alignItems: "center",
        color: isDark ? WHITE : BLACK,
        fontSize: "10px",
        fontWeight: "500",
        height: "50%",
        justifyContent: "flex-start",
        paddingLeft: "7px",
        width: "140px",
      }),
    }),
    [isDark]
  );
};

export default useTimezoneAbbrTheme;
