import { MeetingStatus, MeetingStatusOptions } from "src/types";
import FilterDropdown from "./FilterDropdown";

export function MeetingStatusFilter(props: {
  value?: MeetingStatus[];
  onChange?: (value: MeetingStatus[]) => void;
}) {
  return (
    <FilterDropdown
      label="Meeting Status"
      value={props.value}
      onChange={props.onChange}
      options={MeetingStatusOptions}
    />
  );
}

export default MeetingStatusFilter;
