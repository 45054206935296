import React from "react";

import Flexbox from "../../components/flexbox/FlexboxV2";
import { useTheme } from "../../components/theme";
import kLogoMain from "../../assets/K.png";
import kLogoWhite from "../../assets/K-white.png";
import style from "../style.module.scss";

export default function KronologicIcon() {
  const { isDark } = useTheme();
  const libraryLogo = isDark ? kLogoWhite : kLogoMain;
  return (
    <Flexbox.Row>
      <div style={{ flex: 1 }} />
      <img
        src={libraryLogo}
        alt="Kronologic"
        className={style.meetingTypes__k_logo}
        data-rh="Created by Kronologic"
        data-rh-at="left"
      />
    </Flexbox.Row>
  );
}
