import useSWRImmutable from "swr/immutable";

import { useUserService } from "../services";
import { useErrorHandler, useTokenRefreshHandler } from "../hooks";
import { API } from "../props";
import { InviteStyle } from "../meetingTypes/invite/props";

export function useValidateOneClickToken(
  token: string,
  meetingId?: string | null
) {
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const errorHandler = useErrorHandler();

  const { data, error } = useSWRImmutable(
    token ? [API.meetings.scheduling.validate, { token, meetingId }] : null,
    (url: string, body: object) =>
      service
        .post(url)
        .send(body)
        .then(tokenRefreshHandler)
        .then((res: Response) => res.body)
        .catch(errorHandler)
  );

  return {
    data: data as
      | {
          valid: boolean | undefined;
          meetingId: string | undefined;
          meetingInviteStyle: InviteStyle;
        }
      | undefined,
    error,
    loading: !error && !data,
  };
}
