/* eslint-disable react/jsx-wrap-multilines */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
} from "react-router-dom";
import dayjs from "dayjs";

import { useIsDefaultUser } from "../auth";
import Flexbox from "../components/flexbox/FlexboxV2";
import { Grid, GridItem } from "../components/grid";
import {
  LargeSpacing,
  SuperJumboSpacing,
  XLargeSpacing,
} from "../components/spacing";
import { useUsersRepository } from "../repository";
import Behavior from "./Behavior";
import { AttemptEmails, DeclineEmail } from "./Emails";
import Invite from "./invite/Invite";
import InvitePreview from "./preview/InvitePreview";
import PreviewHeader from "./preview/PreviewHeader";
import {
  MeetingTypeRefsContext,
  useCanEdit,
  useDeclineEmail,
  useEmails,
  useInvite,
  useMeetingInviteStyle,
  useName,
  useRouting,
} from "./context";
import useInviteRefFactory from "./useInviteRefFactory";
import TeamList from "./TeamList";
import DistributionPreview from "./preview/DistributionPreview";
import {
  DeclineEmailPreview,
  ReminderEmailPreview,
} from "./preview/EmailPreview";
import Messenger from "../components/messenger";
import Monetization from "./Monetization";
import { useTeamRepository } from "../repository/teams";
import UserFilters from "./UserFilters";
import useDeclineEmailRefFactory from "./useDeclineEmailRefFactory";
import MeetingTypeSideNavItem from "./MeetingTypeSideNavItem";
import Reminders from "./Reminders";
import useSelectedEmailRefFactory from "./useSelectedEmailRefFactory";
import useReminderEmailRefFactory from "./useReminderEmailRefFactory";
import Bcc from "./Bcc";
import { AWAITING_RESPONSE_STATUS } from "../meetings/props";
import useDynamicVariableMappings from "./useDynamicVariableMappings";
import { ButtonContainer } from "../components/button";
import { P1 } from "../components/typography";
import AddDynamicVariable from "./AddDynamicVariable";
import useCreationData from "./useCreationData";
import { isWebinar } from "./invite/props";
import style from "./style.module.scss";
import { getUserDetails } from "src/utils/jwtToken";

const TEAM_LIST_PATH = [
  "/meetingTypes/user/:id/general/behavior/routing/:userId",
  "/meetingTypes/user/:id/general/behavior/routing",
];

const ATTEMPT_EMAIL_PATH = [
  "/meetingTypes/user/:id/general/emails/attempt/:emailId",
  "/meetingTypes/user/:id/general/emails/attempt",
];

const ELON_MUSK_GUESTS = [
  {
    firstName: "Elon",
    id: 1,
    lastName: "Musk",
    status: AWAITING_RESPONSE_STATUS,
  },
];

function MessengerOption({ children, onClick }) {
  return (
    <ButtonContainer
      className={style.messenger_option}
      transparent
      name="/meetingtypes/configuration/attempts/quick_response"
      onClick={onClick}
    >
      <P1 small>{children}</P1>
    </ButtonContainer>
  );
}

function SimulatedInstance({ children, host }) {
  const { emailId = "1" } = useParams();
  const [routing] = useRouting();
  const [isAccepted, setIsAccepted] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);
  const [attempts, setAttempts] = useState(1);
  const [declineEmail] = useDeclineEmail();
  const [emails] = useEmails();
  const emailBodies = emails.map((email) => email.body);
  const emailSubjects = emails.map((email) => email.title);
  const emailBodyMappings = useDynamicVariableMappings(emailBodies);
  const emailSubjectMappings = useDynamicVariableMappings(emailSubjects);
  const declineEmailBody = useDynamicVariableMappings(declineEmail?.body);
  useEffect(() => {
    const numberOfAttempts = parseInt(emailId);
    if (Number.isInteger(numberOfAttempts)) {
      setAttempts(numberOfAttempts);
      setIsAccepted(false);
      setIsDeclined(false);
    }
  }, [emailId]);
  const messages = useMemo(() => {
    const datetime = dayjs();
    const initial = [
      {
        guest_id: 1,
        guest_status: "staging",
        note: "created",
        note_type: "system_note",
        occurrence: datetime.toISOString(),
        status: "initializing",
      },
      {
        guest_id: 1,
        guest_status: "staging",
        note: `${routing} routing to ${host.email}`,
        note_type: "system_note",
        occurrence: datetime.add(1, "minutes").toISOString(),
        status: "initialized",
      },
      {
        guest_id: 1,
        guest_status: "staging",
        note: "meeting activated",
        note_type: "system_note",
        occurrence: datetime.add(2, "minutes").toISOString(),
        status: "queued",
      },
      {
        guest_id: 1,
        guest_status: "waiting_for_first_response",
        note: emailBodyMappings[0],
        note_type: "touch_sent",
        occurrence: datetime.add(3, "minutes").toISOString(),
        status: "scheduling",
      },
    ];
    for (let i = 1; i < attempts; i += 1) {
      initial.push({
        guest_id: 1,
        guest_status: "waiting_for_first_response",
        note: emailBodyMappings[i],
        note_type: "touch_sent",
        occurrence: datetime.add(3 + i, "minutes").toISOString(),
        status: "scheduling",
      });
    }
    if (isAccepted) {
      initial.push({
        guest_id: 1,
        guest_status: "waiting_for_first_response",
        note: `Subject: Accepted: ${emailSubjectMappings[attempts - 1]} (${
          host.email
        })<br />Body: Sounds Good!`,
        note_type: "contact_reply",
        occurrence: datetime.add(3 + attempts + 1, "minutes").toISOString(),
        status: "scheduling",
      });
      initial.push({
        note: "guest responded to invite as: accepted",
        note_type: "system_note",
        occurrence: datetime.add(3 + attempts + 2, "minutes").toISOString(),
        status: "scheduling",
      });
      initial.push({
        note: `quorom reached`,
        note_type: "system_note",
        occurrence: datetime.add(3 + attempts + 3, "minutes").toISOString(),
        status: "scheduled",
      });
      initial.push({
        note: `meeting completed`,
        note_type: "system_note",
        occurrence: datetime.add(3 + attempts + 3, "minutes").toISOString(),
        status: "completed",
      });
    }
    if (isDeclined) {
      initial.push({
        guest_id: 1,
        guest_status: "waiting_for_first_response",
        note: `Subject: Declined: ${emailSubjectMappings[attempts - 1]} (${
          host.email
        })<br />Body: No, Thank you!`,
        note_type: "contact_reply",
        occurrence: datetime.add(3 + attempts + 1, "minutes").toISOString(),
        status: "scheduling",
      });
      initial.push({
        note: "guest responded to invite as: declined",
        note_type: "system_note",
        occurrence: datetime.add(3 + attempts + 2, "minutes").toISOString(),
        status: "scheduling",
      });
      initial.push({
        note: `no quorum`,
        note_type: "system_note",
        occurrence: datetime.add(3 + attempts + 3, "minutes").toISOString(),
        status: "no_quorum",
      });
      if (declineEmailBody !== null) {
        initial.push({
          guest_status: "declined",
          note: declineEmailBody,
          note_type: "touch_sent",
          occurrence: datetime.add(3 + attempts + 4, "minutes").toISOString(),
          status: "scheduling",
        });
      }
    }
    return initial;
  }, [
    attempts,
    declineEmailBody,
    emailBodyMappings,
    emailSubjectMappings,
    host.email,
    isAccepted,
    isDeclined,
    routing,
  ]);
  const options = useMemo(() => {
    const arr = [];
    if (isDeclined || isAccepted) {
      arr.push({
        onClick: () => {
          setIsAccepted(false);
          setIsDeclined(false);
          setAttempts(1);
        },
        text: "Reset",
      });
      return arr;
    }
    if (emails.length > attempts) {
      arr.push({
        onClick: () => setAttempts((prev) => prev + 1),
        text: "Wait for next attempt",
      });
    }
    if (!isDeclined) {
      arr.push({
        onClick: () => {
          setIsAccepted(false);
          setIsDeclined(true);
        },
        text: "No, Thank you!",
      });
    }
    arr.push({
      onClick: () => {
        setIsAccepted(true);
        setIsDeclined(false);
      },
      text: "Sounds good!",
    });
    return arr;
  }, [attempts, emails, isAccepted, isDeclined]);
  return children({ messages, options });
}

function MessengerPreview() {
  const [name] = useName();
  const [invite] = useInvite();
  const host = getUserDetails();
  return (
    <SimulatedInstance host={host}>
      {({ messages, options }) => {
        return (
          <Flexbox.Column
            className={style.meetingType__messengerPreview}
            flex={1}
            overflow="hidden"
          >
            <Messenger
              guests={ELON_MUSK_GUESTS}
              host={host}
              invite={invite}
              messages={messages}
              name={name}
              options={options}
            />
            <Flexbox.Row
              className={style.messenger_options}
              justifyContent="space-around"
            >
              {options &&
                options.length > 0 &&
                options.map((option) => {
                  return (
                    <MessengerOption key={option.text} onClick={option.onClick}>
                      {option.text}
                    </MessengerOption>
                  );
                })}
            </Flexbox.Row>
          </Flexbox.Column>
        );
      }}
    </SimulatedInstance>
  );
}

export function General({ meetingType }) {
  const { pathname } = useLocation();
  const { useAllTeamMembers } = useUsersRepository();
  const { useTeamMetadata } = useTeamRepository();
  const creationData = useCreationData({ meetingType });
  const [meetingInviteStyle] = useMeetingInviteStyle();
  const isDefaultUser = useIsDefaultUser();
  const {
    data: teamMembers,
    error: teamMembersError,
    loading: areTeamMembersLoading,
  } = useAllTeamMembers(meetingType?.team?.id, creationData);
  const { data: teamMetadata } = useTeamMetadata(meetingType?.team?.id);
  const [isAddingDynamicVariable, setIsAddingDynamicVariable] = useState(false);
  const toggleIsAddingDynamicVariable = useCallback(() => {
    setIsAddingDynamicVariable((t) => !t);
  }, []);
  const { refs: emailRefs, insertText: insertEmailText } =
    useSelectedEmailRefFactory();
  const { refs: reminderRefs, insertText: insertReminderText } =
    useReminderEmailRefFactory();
  const { refs: inviteRefs, insertText: insertInviteText } =
    useInviteRefFactory();
  const { refs: declineEmailRefs, insertText: insertDeclineText } =
    useDeclineEmailRefFactory();
  const refs = useRef({
    decline: declineEmailRefs,
    emails: emailRefs,
    invite: inviteRefs,
    reminder: reminderRefs,
  });
  const onInsertReminderEmailText = useCallback(
    (text) => {
      insertReminderText(text);
      setIsAddingDynamicVariable(false);
    },
    [insertReminderText]
  );
  const onInsertDeclineEmailText = useCallback(
    (text) => {
      insertDeclineText(text);
      setIsAddingDynamicVariable(false);
    },
    [insertDeclineText]
  );
  const onInsertEmailText = useCallback(
    (text) => {
      insertEmailText(text);
      setIsAddingDynamicVariable(false);
    },
    [insertEmailText]
  );
  const onInsertInviteText = useCallback(
    (text) => {
      insertInviteText(text);
      setIsAddingDynamicVariable(false);
    },
    [insertInviteText]
  );
  const [invite] = useInvite();
  const canEditMeetingType = useCanEdit();
  return (
    <Grid className={style.meetingType__grid} flex="1 1 100%" maxHeight="100%">
      <GridItem gridArea="nav">
        <Flexbox.Column flex="0 0 0%" alignItems="left" justifyContent="center">
          <SuperJumboSpacing />
          <MeetingTypeSideNavItem
            name="/meetingtypes/configuration/invite"
            text="Invite"
            to={`/meetingTypes/user/${meetingType?.id}/general/invite`}
            validationKey="invite"
          />
          <LargeSpacing />
          <MeetingTypeSideNavItem
            name="/meetingtypes/configuration/attempts"
            text={isWebinar(meetingInviteStyle) ? "Email" : "Attempts"}
            to={`/meetingTypes/user/${meetingType?.id}/general/emails/attempt/1`}
            validationKey="emails"
          />
          {!isWebinar(meetingInviteStyle) && (
            <>
              <LargeSpacing />
              <MeetingTypeSideNavItem
                name="/meetingtypes/configuration/bcc"
                text="Bcc"
                to={`/meetingTypes/user/${meetingType?.id}/general/bcc`}
              />
            </>
          )}
          {!isWebinar(meetingInviteStyle) && (
            <>
              <LargeSpacing />
              <MeetingTypeSideNavItem
                name="/meetingtypes/configuration/decline"
                text="Decline"
                to={`/meetingTypes/user/${meetingType?.id}/general/emails/decline`}
                validationKey="decline"
              />
            </>
          )}
          <LargeSpacing />
          <MeetingTypeSideNavItem
            name="/meetingtypes/configuration/reminders"
            text="Reminders"
            to={`/meetingTypes/user/${meetingType?.id}/general/reminders`}
            validationKey="reminder"
          />
          {!isWebinar(meetingInviteStyle) && (
            <>
              <LargeSpacing />
              <MeetingTypeSideNavItem
                name="/meetingtypes/configuration/behavior"
                text="Behavior"
                to={`/meetingTypes/user/${meetingType?.id}/general/behavior`}
              />
            </>
          )}
          {!isWebinar(meetingInviteStyle) && (
            <>
              <LargeSpacing />
              <MeetingTypeSideNavItem
                name="/meetingtypes/configuration/monetization"
                text="Monetization"
                to={`/meetingTypes/user/${meetingType?.id}/general/monetization`}
              />
            </>
          )}
        </Flexbox.Column>
      </GridItem>
      <MeetingTypeRefsContext.Provider value={refs}>
        <GridItem
          className={style.meetingType__contentGridItem}
          gridArea={
            !pathname.includes("monetization")
              ? "content1"
              : "content1 / content1 / content2 / content2"
          }
        >
          <Switch>
            {!isWebinar(meetingInviteStyle) && (
              <Route
                path="/meetingTypes/user/:id/general/emails/decline"
                render={() => (
                  <DeclineEmail
                    isAddingDynamicVariable={isAddingDynamicVariable}
                    onAddDynamicFieldClick={toggleIsAddingDynamicVariable}
                  />
                )}
              />
            )}
            <Route
              path={ATTEMPT_EMAIL_PATH}
              render={() => (
                <AttemptEmails
                  isEditable={canEditMeetingType}
                  isAddingDynamicVariable={isAddingDynamicVariable}
                  onAddDynamicFieldClick={toggleIsAddingDynamicVariable}
                  path={`/meetingTypes/user/${meetingType.id}/general/emails/attempt`}
                />
              )}
            />

            <Route
              path="/meetingTypes/user/:id/general/bcc"
              render={() => <Bcc />}
            />
            <Route
              path="/meetingTypes/user/:id/general/invite"
              render={() => (
                <Invite
                  isEditable={canEditMeetingType}
                  invite={invite}
                  isAddingDynamicVariable={isAddingDynamicVariable}
                  meetingTypeId={meetingType?.Id}
                  onAddDynamicFieldClick={toggleIsAddingDynamicVariable}
                />
              )}
            />
            {canEditMeetingType && !isWebinar(meetingInviteStyle) && (
              <Route
                path="/meetingTypes/user/:id/general/behavior/routing/:userId"
                render={() => <UserFilters />}
              />
            )}
            {!isWebinar(meetingInviteStyle) && (
              <Route
                path="/meetingTypes/user/:id/general/behavior/:tab"
                render={() => (
                  <Behavior
                    areTeamMembersLoading={areTeamMembersLoading}
                    teamMembers={teamMembers}
                    teamMetadata={teamMetadata}
                  />
                )}
              />
            )}
            {!isWebinar(meetingInviteStyle) && (
              <Route
                path="/meetingTypes/user/:id/general/monetization"
                render={() => <Monetization isEditable={canEditMeetingType} />}
              />
            )}
            <Route
              path="/meetingTypes/user/:id/general/reminders"
              render={() => (
                <Reminders
                  isEditable={canEditMeetingType}
                  isAddingDynamicVariable={isAddingDynamicVariable}
                  onAddDynamicFieldClick={toggleIsAddingDynamicVariable}
                />
              )}
            />
            {!isWebinar(meetingInviteStyle) && (
              <Route
                path="/meetingTypes/user/:id/general/behavior"
                render={() => (
                  <Redirect
                    to={`/meetingTypes/user/${meetingType?.id}/general/behavior/routing`}
                  />
                )}
              />
            )}
            <Route
              path="*"
              render={() => (
                <Redirect
                  to={`/meetingTypes/user/${meetingType?.id}/general/invite`}
                />
              )}
            />
          </Switch>
        </GridItem>
        <GridItem
          className={style.meetingType__contentGridItem}
          gridArea="content2"
        >
          <Flexbox.Column height="100%" overflow="scroll">
            <Flexbox.Row flex="0 1 0%">
              {!isAddingDynamicVariable && !pathname.includes("distribution") && (
                <>
                  <PreviewHeader
                    areTeamMembersLoading={areTeamMembersLoading}
                    teamError={teamMembersError}
                    teamMembers={teamMembers}
                    teamName={teamMetadata?.name}
                  />
                </>
              )}
            </Flexbox.Row>
            <XLargeSpacing />
            <Flexbox.Row flex="1 1 0%" overflow="scroll">
              <Switch>
                <Route
                  path="/meetingTypes/user/:id/general/invite"
                  render={() => (
                    <>
                      {!isAddingDynamicVariable && <InvitePreview />}
                      {isAddingDynamicVariable && (
                        <AddDynamicVariable
                          onDynamicFieldClick={onInsertInviteText}
                        />
                      )}
                    </>
                  )}
                />

                <Route
                  path={TEAM_LIST_PATH}
                  render={() => (
                    <Flexbox.Column>
                      {!isDefaultUser && <TeamList />}
                    </Flexbox.Column>
                  )}
                />

                <Route
                  path="/meetingTypes/user/:id/general/behavior/distribution"
                  render={() => <DistributionPreview />}
                />
                <Route
                  path="/meetingTypes/user/:id/general/emails/decline"
                  render={() => (
                    <>
                      {!isAddingDynamicVariable && <DeclineEmailPreview />}
                      {isAddingDynamicVariable && (
                        <AddDynamicVariable
                          onDynamicFieldClick={onInsertDeclineEmailText}
                        />
                      )}
                    </>
                  )}
                />
                <Route
                  path="/meetingTypes/user/:id/general/reminders"
                  render={() => (
                    <>
                      {!isAddingDynamicVariable && <ReminderEmailPreview />}
                      {isAddingDynamicVariable && (
                        <AddDynamicVariable
                          onDynamicFieldClick={onInsertReminderEmailText}
                        />
                      )}
                    </>
                  )}
                />

                <Route
                  path="/meetingTypes/user/:id/general/emails/attempt/:emailId"
                  render={() => (
                    <>
                      {!isAddingDynamicVariable && <MessengerPreview />}
                      {isAddingDynamicVariable && (
                        <AddDynamicVariable
                          onDynamicFieldClick={onInsertEmailText}
                        />
                      )}
                    </>
                  )}
                />
              </Switch>
            </Flexbox.Row>
          </Flexbox.Column>
        </GridItem>
      </MeetingTypeRefsContext.Provider>
    </Grid>
  );
}
