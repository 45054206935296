import { useCallback } from "react";
import {
  // guest statuses
  ACCEPTED_STATUS,
  ACCEPT_PENDING_QUORUM_STATUS,
  AI_NEGOTIATING_STATUS,
  AWAITING_RESPONSE_STATUS,
  AWAY_STATUS,
  CANCELLED_STATUS,
  DECLINED_STATUS,
  HOST_INTERVENED_STATUS,
  NO_RESPONSE_STATUS,
  PAUSED_STATUS,
  REQUIRES_HOST_INTERVENTION_STATUS,
  RESPONDED_UNKNOWN_INTENT_STATUS,
  UNDELIVERABLE_STATUS,

  // meeting statuses
  COMPLETED_STAGE,
  NO_QUORUM_STAGE,
  SCHEDULED_STAGE,
  SCHEDULING_STAGE,
} from "../props";

// OVERRIDE_RULES contains the rules of from/to guest status overrides
// depending on meeting stage, the guest status override rules can change
// if this gets changed, please change the corresponding object in services repo
// app/domain/bo/meeting_guest_status.go
const OVERRIDE_RULES = {
  // when guest status is ACCEPTED...
  [ACCEPTED_STATUS]: {},
  // when guest status is ACCEPT PENDING QUORUM
  [ACCEPT_PENDING_QUORUM_STATUS]: {
    // ...and meeting status is NO QUORUM
    [NO_QUORUM_STAGE]: [DECLINED_STATUS],
    // ...and meeting status is SCHEDULING
    [SCHEDULING_STAGE]: [DECLINED_STATUS],
  },
  // when guest status is AI NEGOTIATING...
  [AI_NEGOTIATING_STATUS]: {
    // ...and meeting status is SCHEDULED
    [SCHEDULED_STAGE]: [ACCEPTED_STATUS, DECLINED_STATUS],
    // ...and meeting status is SCHEDULING
    [SCHEDULING_STAGE]: [ACCEPT_PENDING_QUORUM_STATUS, DECLINED_STATUS],
  },
  // when guest status is AWAITING RESPONSE...
  [AWAITING_RESPONSE_STATUS]: {
    // ...and meeting status is NO QUORUM
    [NO_QUORUM_STAGE]: [DECLINED_STATUS],
    // ...and meeting status is SCHEDULED
    [SCHEDULED_STAGE]: [ACCEPTED_STATUS, DECLINED_STATUS],
    // ...and meeting status is SCHEDULING
    [SCHEDULING_STAGE]: [ACCEPT_PENDING_QUORUM_STATUS, DECLINED_STATUS],
  },
  // when guest status is AWAY...
  [AWAY_STATUS]: {
    // ...and meeting status is NO QUORUM
    [NO_QUORUM_STAGE]: [DECLINED_STATUS],
    // ...and meeting status is SCHEDULED
    [SCHEDULED_STAGE]: [ACCEPTED_STATUS, DECLINED_STATUS],
    // ...and meeting status is SCHEDULING
    [SCHEDULING_STAGE]: [ACCEPT_PENDING_QUORUM_STATUS, DECLINED_STATUS],
  },
  // when guest status is CANCELLED...
  [CANCELLED_STATUS]: {},
  // when guest status is DECLINED...
  [DECLINED_STATUS]: {
    // ...and meeting status is COMPLETED
    [COMPLETED_STAGE]: [
      ACCEPTED_STATUS,
      NO_RESPONSE_STATUS,
      RESPONDED_UNKNOWN_INTENT_STATUS,
    ],
    // ...and meeting status is NO QUORUM
    [NO_QUORUM_STAGE]: [
      RESPONDED_UNKNOWN_INTENT_STATUS,
      NO_RESPONSE_STATUS,
      ACCEPTED_STATUS,
    ],
    // ...and meeting status is SCHEDULED
    [SCHEDULED_STAGE]: [ACCEPTED_STATUS],
  },
  // when guest status is HOST INTERVENED...
  [HOST_INTERVENED_STATUS]: {
    // ...and meeting status is NO QUORUM
    [NO_QUORUM_STAGE]: [DECLINED_STATUS],
    // ...and meeting status is SCHEDULED
    [SCHEDULED_STAGE]: [ACCEPTED_STATUS, DECLINED_STATUS],
    // ...and meeting status is SCHEDULING
    [SCHEDULING_STAGE]: [
      ACCEPT_PENDING_QUORUM_STATUS,
      DECLINED_STATUS,
      UNDELIVERABLE_STATUS,
    ],
  },
  // when guest status is NO RESPONSE...
  [NO_RESPONSE_STATUS]: {
    // ...and meeting status is COMPLETED
    [COMPLETED_STAGE]: [
      ACCEPTED_STATUS,
      DECLINED_STATUS,
      RESPONDED_UNKNOWN_INTENT_STATUS,
    ],
    // ...and meeting status is NO QUORUM
    [NO_QUORUM_STAGE]: [
      ACCEPTED_STATUS,
      RESPONDED_UNKNOWN_INTENT_STATUS,
      DECLINED_STATUS,
    ],
  },
  // when guest status is PAUSED...
  [PAUSED_STATUS]: {},
  // when guest status is REQUIRES HOST INTERVENTION...
  [REQUIRES_HOST_INTERVENTION_STATUS]: {
    // ...and meeting status is NO QUORUM
    [NO_QUORUM_STAGE]: [DECLINED_STATUS],
    // ...and meeting status is SCHEDULED
    [SCHEDULED_STAGE]: [ACCEPTED_STATUS, DECLINED_STATUS],
    // ...and meeting status is SCHEDULING
    [SCHEDULING_STAGE]: [
      ACCEPT_PENDING_QUORUM_STATUS,
      DECLINED_STATUS,
      UNDELIVERABLE_STATUS,
    ],
  },
  // when guest status is RESPONDED UNKNOWN INTENT...
  [RESPONDED_UNKNOWN_INTENT_STATUS]: {
    // ...and meeting status is COMPLETED
    [COMPLETED_STAGE]: [ACCEPTED_STATUS, DECLINED_STATUS, NO_RESPONSE_STATUS],
    // ...and meeting status is NO QUORUM
    [NO_QUORUM_STAGE]: [ACCEPTED_STATUS, NO_RESPONSE_STATUS, DECLINED_STATUS],
  },

  // when guest status is UNDELIVERABLE...
  [UNDELIVERABLE_STATUS]: {},
};

export default function useOverrideStatusRules() {
  const overrideOptions = useCallback(({ fromStatus, meetingStatus }) => {
    if (!(fromStatus in OVERRIDE_RULES)) {
      return null;
    }

    const stageMap = OVERRIDE_RULES[fromStatus];

    if (!(meetingStatus in stageMap)) {
      return null;
    }

    const allowableStatuses = stageMap[meetingStatus];

    if (allowableStatuses.length < 1) {
      return null;
    }

    return allowableStatuses;
  }, []);

  const overridable = useCallback(
    ({ fromStatus, meetingStatus }) =>
      overrideOptions({ fromStatus, meetingStatus }) !== null,
    [overrideOptions]
  );

  return {
    overridable,
    overrideOptions,
  };
}
