import React, { useCallback, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { ButtonContainer } from "../../components/button";
import Flexbox from "../../components/flexbox/FlexboxV2";
import {
  LargeSpacing,
  MediumSpacing,
  SmallSpacing,
  XLargeSpacing,
  XXLargeSpacing,
} from "../../components/spacing";
import { P1, H6 } from "../../components/typography";
import StepIncomplete from "../StepIncomplete";
import StepComplete from "../StepComplete";
import { useMeetingTypeId, useInvite, useInviteRefs } from "../context";
import { validateInvite } from "../useValidateMeetingType";
import Step from "../Step";
import InviteLocation from "./InviteLocation";
import InviteStyle from "./InviteStyle";
import InviteTitle from "./InviteTitle";
import InviteDescription from "./InviteDescription";
import style from "../style.module.scss";
import MeetingLinkProvider from "./MeetingLinkProvider";

export default function Invite({
  isAddingDynamicVariable,
  isEditable = true,
  onAddDynamicFieldClick,
}) {
  const [shouldReloadInvite, setShouldReloadInvite] = useState(false);
  const meetingTypeId = useMeetingTypeId();
  const [invite] = useInvite();
  const {
    title: titleRef,
    location: locationRef,
    notes: notesRef,
  } = useInviteRefs();
  const reset = useCallback(() => {
    titleRef.current.value = invite.title;
    locationRef.current.value = invite.location;
    notesRef.current.value = invite.notes;
  }, [invite, locationRef, notesRef, titleRef]);

  useEffect(() => {
    if (shouldReloadInvite) {
      reset();
      setShouldReloadInvite(false);
    }
  }, [reset, shouldReloadInvite]);
  useEffect(() => {
    setShouldReloadInvite(true);
  }, [meetingTypeId]);
  const { summary } = validateInvite(invite);
  const { complete: inviteComplete, description: inviteDescription } = summary;

  const { pathname } = useLocation();
  const isLibrary = pathname.includes("library");
  const showInviteStyle = !isLibrary;
  const staticIdxStart = useRef(0);
  if (showInviteStyle) {
    staticIdxStart.current = 1;
  }

  return (
    <Flexbox.Column height="100%" overflow="scroll">
      <LargeSpacing />
      <Flexbox.Row>
        <H6>Calendar Invite</H6>
        {isEditable && (
          <Flexbox.Row flex={1} justifyContent="flex-end">
            {isAddingDynamicVariable && (
              <ButtonContainer
                large
                name="/meetingtypes/configuration/invite/hide_dynamic_variables"
                onClick={onAddDynamicFieldClick}
                primary
              >
                Hide Dynamic Variables
              </ButtonContainer>
            )}
            {!isAddingDynamicVariable && (
              <ButtonContainer
                large
                name="/meetingtypes/configuration/invite/show_dynamic_variables"
                onClick={onAddDynamicFieldClick}
                primary
              >
                Show Dynamic Variables
              </ButtonContainer>
            )}
          </Flexbox.Row>
        )}
      </Flexbox.Row>
      <XXLargeSpacing />
      {showInviteStyle && (
        <>
          <InviteStyle stepNumber={1} />
          <LargeSpacing />
        </>
      )}
      <Flexbox.Row alignItems="center" justifyContent="space-between">
        <XXLargeSpacing />
        <Step
          stepNumber={staticIdxStart.current + 1}
          description="What will guests see in their calendars?"
        />
        <Flexbox.Row alignItems="center" justifyContent="flex-end">
          <P1 small>{inviteDescription}</P1>
          <MediumSpacing />
          {inviteComplete === 100 && <StepComplete />}
          {inviteComplete < 100 && <StepIncomplete />}
        </Flexbox.Row>
      </Flexbox.Row>
      <XLargeSpacing />

      <XXLargeSpacing />
      <XXLargeSpacing />
      <Flexbox.Row className={style.meetingType__invite} height="100%">
        <LargeSpacing />
        <Flexbox.Column>
          <XLargeSpacing />
          <InviteTitle isEditable={isEditable} ref={titleRef} />
          <SmallSpacing />
          <InviteLocation isEditable={isEditable} ref={locationRef} />
          <MeetingLinkProvider disabled={!isEditable} />
          <LargeSpacing />
          <InviteDescription isEditable={isEditable} ref={notesRef} />
          <XLargeSpacing />
        </Flexbox.Column>
        <LargeSpacing />
      </Flexbox.Row>
    </Flexbox.Column>
  );
}
