import React from "react";
import { Box, useTheme } from "@mui/material";

import Confirmation from "./Confirmation";
import { ErrorIcon } from "../../icons";

export function Declined({ onOkClicked }: { onOkClicked: () => void }) {
  const theme = useTheme();
  return (
    <Confirmation
      title="Declined"
      message="Your meeting has been declined."
      icon={
        <Box
          sx={{
            color: theme.palette.success.main,

            fontSize: theme.typography.fontSize * 5,
          }}
        >
          <ErrorIcon
            sx={{
              color: theme.palette.error.main,
              height: "5em",
              width: "5em",
            }}
          />
        </Box>
      }
      onOkClicked={onOkClicked}
    />
  );
}
