import React, { useState } from "react";
import {
  Typography,
  TextField,
  Autocomplete,
  Checkbox,
  ListItem,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { Tag } from "../../../types";
import { useTags } from "../../../queries";

export function TagFilter({
  value,
  onChange,
  disabled,
}: {
  value?: Tag[];
  onChange?: (v: Tag[]) => void;
  disabled?: boolean;
}) {
  const [input, setInput] = useState("");

  const { data, loading } = useTags(20, 0, input);

  return (
    <Autocomplete
      fullWidth
      multiple
      disablePortal
      inputValue={input}
      onInputChange={(_, newValue) => {
        setInput(newValue);
      }}
      value={value}
      onChange={(_, newValue) => {
        if (value && onChange) {
          onChange(newValue);
        }
      }}
      options={data?.data || []}
      loading={loading}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <ListItem {...props} dense>
          <Checkbox
            size="small"
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          <Typography>{option.name}</Typography>
        </ListItem>
      )}
      renderInput={(params) => <TextField {...params} label="Tags" />}
    />
  );
}

export default TagFilter;
