/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useRef, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import Flexbox from "../../components/flexbox/FlexboxV2";
import { Grid, GridItem } from "../../components/grid";
import { LargeSpacing, SuperJumboSpacing } from "../../components/spacing";
import { AttemptEmails } from "../Emails";
import Invite from "../invite/Invite";
import InvitePreview from "../preview/InvitePreview";
import { MeetingTypeRefsContext, useMeetingInviteStyle } from "../context";
import useInviteRefFactory from "../useInviteRefFactory";
import {
  CampaignEmailPreview,
  ReminderEmailPreview,
} from "../preview/EmailPreview";
import MeetingTypeSideNavItem from "../MeetingTypeSideNavItem";
import Monetization from "../Monetization";
import Reminders from "../Reminders";
import useSelectedEmailRefFactory from "../useSelectedEmailRefFactory";
import useReminderEmailRefFactory from "../useReminderEmailRefFactory";
import { useHasSuperAdminPermissions } from "../../auth";
import AddDynamicVariable from "../AddDynamicVariable";
import { isWebinar } from "../invite/props";
import style from "./style.module.scss";

const ATTEMPT_EMAIL_PATH = [
  "/meetingTypes/library/:id/general/emails/attempt/:emailId",
  "/meetingTypes/library/:id/general/emails/attempt",
];

export function General({ meetingTemplate }) {
  const { pathname } = useLocation();
  const hasSuperAdminPermissions = useHasSuperAdminPermissions();
  const [meetingInviteStyle] = useMeetingInviteStyle();
  const [isAddingDynamicVariable, setIsAddingDynamicVariable] = useState(false);
  const toggleIsAddingDynamicVariable = useCallback(() => {
    setIsAddingDynamicVariable((t) => !t);
  }, []);
  const { refs: emailRefs, insertText: insertEmailText } =
    useSelectedEmailRefFactory();
  const { refs: reminderRefs, insertText: insertReminderText } =
    useReminderEmailRefFactory();
  const { refs: inviteRefs, insertText: insertInviteText } =
    useInviteRefFactory();
  const refs = useRef({
    emails: emailRefs,
    invite: inviteRefs,
    reminder: reminderRefs,
  });
  const onInsertReminderEmailText = useCallback(
    (text) => {
      insertReminderText(text);
      setIsAddingDynamicVariable(false);
    },
    [insertReminderText]
  );
  const onInsertEmailText = useCallback(
    (text) => {
      insertEmailText(text);
      setIsAddingDynamicVariable(false);
    },
    [insertEmailText]
  );
  const onInsertInviteText = useCallback(
    (text) => {
      insertInviteText(text);
      setIsAddingDynamicVariable(false);
    },
    [insertInviteText]
  );
  return (
    <Grid className={style.meetingType__grid} flex="1 1 100%" maxHeight="100%">
      <GridItem gridArea="nav">
        <Flexbox.Column flex="0 0 0%" alignItems="left" justifyContent="center">
          <SuperJumboSpacing />
          <MeetingTypeSideNavItem
            meetingType={meetingTemplate}
            text="Invite"
            to={`/meetingTypes/library/${meetingTemplate?.id}/general/invite`}
            validationKey="invite"
          />
          <LargeSpacing />
          <MeetingTypeSideNavItem
            meetingType={meetingTemplate}
            text={isWebinar(meetingInviteStyle) ? "Email" : "Attempts"}
            to={`/meetingTypes/library/${meetingTemplate?.id}/general/emails/attempt/1`}
            validationKey="emails"
          />
          {!isWebinar(meetingInviteStyle) && (
            <>
              <LargeSpacing />
              <MeetingTypeSideNavItem
                meetingType={meetingTemplate}
                text="Monetization"
                to={`/meetingTypes/library/${meetingTemplate?.id}/general/monetization`}
              />
            </>
          )}
          <LargeSpacing />
          <MeetingTypeSideNavItem
            meetingType={meetingTemplate}
            text="Reminders"
            to={`/meetingTypes/library/${meetingTemplate?.id}/general/reminders`}
            validationKey="reminder"
          />
        </Flexbox.Column>
      </GridItem>
      <MeetingTypeRefsContext.Provider value={refs}>
        <GridItem
          className={style.meetingType__contentGridItem}
          gridArea={
            !pathname.includes("monetization")
              ? "content1"
              : "content1 / content1 / content2 / content2"
          }
        >
          <Switch>
            <Route
              path={ATTEMPT_EMAIL_PATH}
              render={() => (
                <AttemptEmails
                  isAddingDynamicVariable={isAddingDynamicVariable}
                  isEditable={hasSuperAdminPermissions}
                  onAddDynamicFieldClick={toggleIsAddingDynamicVariable}
                  path={`/meetingTypes/library/${meetingTemplate.id}/general/emails/attempt`}
                />
              )}
            />

            <Route
              path="/meetingTypes/library/:id/general/invite"
              render={() => (
                <Invite
                  isAddingDynamicVariable={isAddingDynamicVariable}
                  isEditable={hasSuperAdminPermissions}
                  meetingTypeId={meetingTemplate?.id}
                  onAddDynamicFieldClick={toggleIsAddingDynamicVariable}
                />
              )}
            />

            {!isWebinar(meetingInviteStyle) && (
              <Route
                path="/meetingTypes/library/:id/general/monetization"
                render={() => (
                  <Monetization isEditable={hasSuperAdminPermissions} />
                )}
              />
            )}
            <Route
              path="/meetingTypes/library/:id/general/reminders"
              render={() => (
                <Reminders
                  isAddingDynamicVariable={isAddingDynamicVariable}
                  isEditable={hasSuperAdminPermissions}
                  onAddDynamicFieldClick={toggleIsAddingDynamicVariable}
                />
              )}
            />

            <Route
              path="*"
              render={() => (
                <Redirect
                  to={`/meetingTypes/library/${meetingTemplate.id}/general/invite`}
                />
              )}
            />
          </Switch>
        </GridItem>
        <GridItem
          className={style.meetingType__contentGridItem}
          gridArea="content2"
        >
          <Flexbox.Column height="100%" overflow="scroll">
            <Flexbox.Row flex={1}>
              <Flexbox.Column flex="1 1 0%">
                <Switch>
                  <Route
                    path="/meetingTypes/library/:id/general/invite"
                    render={() => (
                      <>
                        <LargeSpacing />
                        {!isAddingDynamicVariable && <InvitePreview />}
                        {isAddingDynamicVariable && (
                          <AddDynamicVariable
                            onDynamicFieldClick={onInsertInviteText}
                          />
                        )}
                      </>
                    )}
                  />
                  <Route
                    path="/meetingTypes/library/:id/general/reminders"
                    render={() => (
                      <>
                        <LargeSpacing />
                        {!isAddingDynamicVariable && <ReminderEmailPreview />}
                        {isAddingDynamicVariable && (
                          <AddDynamicVariable
                            onDynamicFieldClick={onInsertReminderEmailText}
                          />
                        )}
                      </>
                    )}
                  />

                  <Route
                    path="/meetingTypes/library/:id/general/emails/attempt/:emailId"
                    render={() => (
                      <>
                        <LargeSpacing />
                        {!isAddingDynamicVariable && <CampaignEmailPreview />}
                        {isAddingDynamicVariable && (
                          <AddDynamicVariable
                            onDynamicFieldClick={onInsertEmailText}
                          />
                        )}
                      </>
                    )}
                  />
                </Switch>
              </Flexbox.Column>
            </Flexbox.Row>
          </Flexbox.Column>
        </GridItem>
      </MeetingTypeRefsContext.Provider>
    </Grid>
  );
}
