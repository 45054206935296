import React from "react";

import Flexbox from "../components/flexbox/FlexboxV2";
import {
  JumboSpacing,
  MediumSpacing,
  XXLargeSpacing,
} from "../components/spacing";
import { P1 } from "../components/typography";
import Step from "./Step";
import { useCanEdit, useDistribution } from "./context";
import { Select } from "../inputs";
import { useDefaultSelectTheme } from "../components/select";
import style from "./style.module.scss";

const DISTRIBUTION = {
  RANDOM_HIGH_VOLUME: "random_high_volume",
  SEQUENTIAL: "sequential",
};

const DISTRIBUTION_OPTIONS = [
  { label: "Front Load (Default)", value: DISTRIBUTION.SEQUENTIAL },
  {
    label: "High Density",
    value: DISTRIBUTION.RANDOM_HIGH_VOLUME,
  },
];

export default function Distribution() {
  const [components, theme] = useDefaultSelectTheme();
  const [distribution, setDistribution] = useDistribution();
  const distributionOption = DISTRIBUTION_OPTIONS.find(
    (dt) => dt.value === distribution
  );
  const onDistributionOptionChange = (selection) => {
    const { value } = selection;
    setDistribution(value);
  };
  const canEditMeetingType = useCanEdit();
  return (
    <Flexbox.Column>
      <Flexbox.Row>
        <XXLargeSpacing />
        <Flexbox.Column>
          <Flexbox.Row>
            <Step
              stepNumber={1}
              description="How will meetings be distributed on hosts' calendars?"
            />
          </Flexbox.Row>
          <MediumSpacing />
          <Flexbox.Row>
            <XXLargeSpacing />
            <Flexbox.Column element="label" htmlFor="distribution">
              <div className={style.behavior__labelText}>
                <P1 small bold>
                  Calendar Distribution
                </P1>
              </div>
              <MediumSpacing />
              <Select
                disabled={!canEditMeetingType}
                components={components}
                customTheme={theme}
                name="/meetingtypes/configuration/behavior/distribution/calendar_distribution"
                onChange={onDistributionOptionChange}
                value={distributionOption}
                options={DISTRIBUTION_OPTIONS}
                placeholder={<div>Select Distribution</div>}
              />
            </Flexbox.Column>
            <JumboSpacing />
            <Flexbox.Column />
          </Flexbox.Row>
        </Flexbox.Column>
      </Flexbox.Row>
    </Flexbox.Column>
  );
}
