import React from "react";
import { List, ListItem, ListItemButton } from "@mui/material";

import { Team } from "../types";
import TeamCard from "./TeamCard";

export function TeamList({
  teams,
  onSelect = () => null,
  onEdit = () => null,
  onClone = () => null,
  onDelete = () => null,
}: {
  teams?: Team[];
  onSelect?: (selected: Team) => void;
  onEdit?: (selected: Team) => void;
  onClone?: (selected: Team) => void;
  onDelete?: (selected: Team) => void;
}) {
  return (
    <List disablePadding>
      {teams
        ? teams.map((t) => (
            <ListItem key={t.id} disableGutters disablePadding divider>
              <ListItemButton
                disableGutters
                onClick={() => {
                  onSelect(t);
                }}
              >
                <TeamCard
                  name={t.name}
                  members={t.users?.length}
                  onEdit={() => onEdit(t)}
                  onClone={() => onClone(t)}
                  onDelete={() => onDelete(t)}
                />
              </ListItemButton>
            </ListItem>
          ))
        : undefined}
    </List>
  );
}

export default TeamList;
