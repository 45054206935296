import React from "react";
import Slider from "rc-slider";

import Flexbox from "../../components/flexbox/FlexboxV2";
import { P1 } from "../../components/typography";
import { useCanEdit, useDuration } from "../context";

import { SmallSpacing, XSmallSpacing } from "../../components/spacing";
import useRailColor from "./useRailColor";
import style from "../style.module.scss";

// TODO(Matt): Make all these sliders the same base class
export default function Duration() {
  const [duration, setDuration] = useDuration();
  const canEditMeetingType = useCanEdit();
  const { railColor } = useRailColor();
  return (
    <Flexbox.Column>
      <div className={style.meetingType__slider}>
        <Slider
          disabled={!canEditMeetingType}
          onChange={setDuration}
          step={15}
          min={15}
          max={60}
          defaultValue={duration}
          railStyle={{ backgroundColor: railColor }}
        />
      </div>
      <SmallSpacing />
      <Flexbox.Row>
        <XSmallSpacing />
        <P1 small>{`${duration} minutes`}</P1>
      </Flexbox.Row>
    </Flexbox.Column>
  );
}
