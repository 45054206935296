import React, { useMemo } from "react";

import { buildRange } from "../utils/helpers";
import {
  BLACK,
  NEUTRAL_500,
  NEUTRAL_700,
  PRIMARY_BLUE,
  WHITE,
} from "../components/colors";
import Flexbox from "../components/flexbox/FlexboxV2";
import { useDefaultSelectTheme } from "../components/select";
import {
  JumboSpacing,
  LargeSpacing,
  MediumSpacing,
  SmallSpacing,
  XXLargeSpacing,
} from "../components/spacing";
import { Switch } from "../components/switch";
import { useTheme } from "../components/theme";
import { P1, H6 } from "../components/typography";
import { Select } from "../inputs";
import { useMeetingReminder, useMeetingReminderEmail } from "./context";
import Step from "./Step";
import { ButtonContainer } from "../components/button";
import { ReminderEmailContent } from "./Emails";
import { validateEmail } from "./useValidateMeetingType";
import StepComplete from "./StepComplete";
import StepIncomplete from "./StepIncomplete";
import style from "./style.module.scss";

const DAY_RANGE = [0, 10, 1];
const HOUR_RANGE = [0, 23, 1];
const ZERO_MINUTE_START_MINUTE_RANGE = [0, 45, 15];
const FIFTEEN_MINUTE_START_MINUTE_RANGE = [15, 45, 15];

const timeToOption = (time) => {
  if (time === null) {
    return null;
  }

  return { label: time, value: time };
};

const useDurationSelectionSelectTheme = () => {
  const { isDark, useThemeShadows } = useTheme();
  const { MD } = useThemeShadows();
  const defaultBlack = isDark ? BLACK : WHITE;
  const defaultWhite = isDark ? WHITE : BLACK;

  return useMemo(
    () => ({
      container: (provided) => ({
        ...provided,
        backgroundColor: defaultBlack,
        border: `1px solid ${NEUTRAL_500}`,
        width: "100%",
      }),
      control: (provided) => ({
        ...provided,
        backgroundColor: defaultBlack,
        border: 0,
        height: "40px",
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        margin: "5px 0",
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: null,
        boxShadow: MD,
        marginTop: "10px",
      }),
      menuList: (provided) => ({
        ...provided,
        backgroundColor: defaultBlack,
        borderRadius: "4px",
        boxShadow: "none",
        padding: "0px",
        width: "100%",
      }),
      option: (provided, { isFocused }) => ({
        ...provided,
        backgroundColor: isFocused ? PRIMARY_BLUE : defaultBlack,
        color: isFocused ? WHITE : defaultWhite,
        cursor: "pointer",
        overflow: "hidden",
        padding: "7px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }),
      placeholder: (provided) => ({
        ...provided,
        color: isDark ? NEUTRAL_700 : NEUTRAL_500,
      }),
      singleValue: (provided) => ({
        ...provided,
        color: defaultWhite,
        maxWidth: "100%",
        padding: "0 12px",
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: "100%",
        justifyContent: "center",
        padding: "0px",
      }),
    }),
    [MD, isDark, defaultBlack, defaultWhite]
  );
};

export default function Reminders({
  isAddingDynamicVariable,
  isEditable = true,
  onAddDynamicFieldClick,
}) {
  const [meetingReminder, setMeetingReminder] = useMeetingReminder();
  const [email] = useMeetingReminderEmail();
  const {
    body,
    days = 0,
    enabled = false,
    hours = 0,
    minutes = 15,
    title = "",
  } = meetingReminder || {
    body: "",
    days: 0,
    enabled: false,
    hours: 0,
    minutes: 15,
    title: "",
  };
  const setMeetingReminderWithMinimum = (newReminder) => {
    if (
      newReminder.hours === 0 &&
      newReminder.minutes === 0 &&
      newReminder.days === 0
    ) {
      const [fifteenMinutes] = FIFTEEN_MINUTE_START_MINUTE_RANGE;
      newReminder.minutes = fifteenMinutes;
    }
    setMeetingReminder(newReminder);
  };
  const [components] = useDefaultSelectTheme();
  const theme = useDurationSelectionSelectTheme();
  const dayOptions = buildRange(DAY_RANGE, timeToOption);
  const hourOptions = buildRange(HOUR_RANGE, timeToOption);
  let minuteOptions = null;
  if (hours > 0 || days > 0) {
    minuteOptions = buildRange(ZERO_MINUTE_START_MINUTE_RANGE, timeToOption);
  } else {
    minuteOptions = buildRange(FIFTEEN_MINUTE_START_MINUTE_RANGE, timeToOption);
  }
  const {
    summary: { complete: emailCompletetion, description: emailDescription },
  } = validateEmail(email);
  return (
    <Flexbox.Column height="100%">
      <LargeSpacing />
      <Flexbox.Row>
        <H6>Reminders</H6>
        {enabled && (
          <Flexbox.Row flex={1} justifyContent="flex-end">
            {isAddingDynamicVariable && (
              <ButtonContainer
                large
                name="/meetingtypes/configuration/reminders/hide_dynamic_variables"
                onClick={onAddDynamicFieldClick}
                primary
              >
                Hide Dynamic Variables
              </ButtonContainer>
            )}
            {!isAddingDynamicVariable && (
              <ButtonContainer
                large
                name="/meetingtypes/configuration/reminders/show_dynamic_variables"
                onClick={onAddDynamicFieldClick}
                primary
              >
                Show Dynamic Variables
              </ButtonContainer>
            )}
          </Flexbox.Row>
        )}
      </Flexbox.Row>
      <XXLargeSpacing />
      <Flexbox.Column flex={1} overflow="scroll">
        <Flexbox.Row>
          <XXLargeSpacing />
          <Step
            description="Will guests see a reminder for their meetings?"
            stepNumber={1}
          />
        </Flexbox.Row>
        <XXLargeSpacing />
        <Flexbox.Row alignItems="center" justifyContent="center">
          <P1 small>No</P1>
          <LargeSpacing />
          <Switch
            disabled={!isEditable}
            isOn={enabled}
            handleToggle={() => {
              setMeetingReminderWithMinimum({
                body,
                days,
                enabled: !enabled,
                hours,
                minutes,
                title,
              });
            }}
            name="/meetingtypes/configuration/reminders/toggle_yes_no"
          />
          <LargeSpacing />
          <P1 small>Yes</P1>
        </Flexbox.Row>
        <XXLargeSpacing />
        {enabled && (
          <>
            <Flexbox.Row>
              <XXLargeSpacing />
              <Step
                description="When should they be reminded?*"
                stepNumber={2}
              />
            </Flexbox.Row>
            <XXLargeSpacing />
            <Flexbox.Row justifyContent="center">
              <JumboSpacing />
              <Flexbox.Column width="150px">
                <P1>Days</P1>
                <MediumSpacing />
                <Select
                  components={components}
                  customTheme={theme}
                  disabled={!isEditable}
                  name="/meetingtypes/configuration/reminders/select_days"
                  onChange={(option) => {
                    setMeetingReminderWithMinimum({
                      ...meetingReminder,
                      body,
                      days: option.value,
                      enabled,
                      hours,
                      minutes,
                      title,
                    });
                  }}
                  options={dayOptions}
                  value={dayOptions.find((option) => option.value === days)}
                />
              </Flexbox.Column>
              <MediumSpacing />
              <Flexbox.Column width="150px">
                <P1>Hours</P1>
                <MediumSpacing />
                <Select
                  components={components}
                  customTheme={theme}
                  disabled={!isEditable}
                  name="/meetingtypes/configuration/reminders/select_hours"
                  onChange={(option) => {
                    setMeetingReminderWithMinimum({
                      ...meetingReminder,
                      body,
                      days,
                      enabled,
                      hours: option.value,
                      minutes,
                      title,
                    });
                  }}
                  options={hourOptions}
                  value={hourOptions.find((option) => option.value === hours)}
                />
              </Flexbox.Column>
              <MediumSpacing />
              <Flexbox.Column width="150px">
                <P1>Minutes</P1>
                <MediumSpacing />
                <Select
                  components={components}
                  customTheme={theme}
                  disabled={!isEditable}
                  name="/meetingtypes/configuration/reminders/select_minutes"
                  onChange={(option) => {
                    setMeetingReminderWithMinimum({
                      ...meetingReminder,
                      body,
                      days,
                      enabled,
                      hours,
                      minutes: option.value,
                      title,
                    });
                  }}
                  options={minuteOptions}
                  value={minuteOptions.find(
                    (option) => option.value === minutes
                  )}
                />
              </Flexbox.Column>
            </Flexbox.Row>
            <SmallSpacing />
            <Flexbox.Column className={style.reminder_rules}>
              <P1 extraSmall>
                *Meeting reminders will not be sent in the following scenarios:
              </P1>
              <P1 extraSmall>
                1. The guest accepted the meeting on the same day of the
                meeting.
              </P1>
              <P1 extraSmall>
                2. The guest would have been reminded within 5 hours of
                accepting the meeting.
              </P1>
            </Flexbox.Column>
            <XXLargeSpacing />
            <Flexbox.Row>
              <XXLargeSpacing />
              <Step
                description="What should be in the reminder email?"
                stepNumber={3}
              />
              <Flexbox.Row alignItems="center" justifyContent="flex-end">
                <MediumSpacing />
                <P1 small>{emailDescription}</P1>
                <MediumSpacing />
                {emailCompletetion === 100 && <StepComplete />}
                {emailCompletetion < 100 && <StepIncomplete />}
                <MediumSpacing />
              </Flexbox.Row>
            </Flexbox.Row>
            <XXLargeSpacing />
            <Flexbox.Row>
              <XXLargeSpacing />
              <ReminderEmailContent
                isAddingDynamicVariable={isAddingDynamicVariable}
                isEditable={isEditable}
                onAddDynamicFieldClick={onAddDynamicFieldClick}
              />
            </Flexbox.Row>
            <XXLargeSpacing />
          </>
        )}
      </Flexbox.Column>
    </Flexbox.Column>
  );
}
