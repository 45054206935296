import { useCallback, useMemo } from "react";
import { useTeamRepository } from "../repository/teams";
import { useUsersRepository } from "../repository";
import { useIsDefaultUser } from "../auth";

const INITIAL_BUFFER_DURATION_MINS = 180;
const DEFAULT_PER_MEETING_VALUE = 1000;

export const DEFAULT_MEETING_REMINDER_BODY = `
  <p>Hey {{guest_first_name}},</p>
  <p><br/></p>
  <p>Looking forward to speaking with you soon.</p>
  <p><br/></p>
  <p>Thanks,</p>
  <p>{{host_first_name}}</p>
`;

export default function useNewMeetingType() {
  const { useAllTeamMembers } = useUsersRepository();
  const { useGlobalTeamId } = useTeamRepository();
  const { data: globalId, loading: globalIdLoading } = useGlobalTeamId();
  const { data: teamMembers, loading: teamMembersLoading } =
    useAllTeamMembers(globalId);

  const loading = useMemo(
    () => globalIdLoading || teamMembersLoading,
    [globalIdLoading, teamMembersLoading]
  );
  const isDefaultUser = useIsDefaultUser();
  const build = useCallback(() => {
    return {
      active: false,
      buffer_duration_mins: INITIAL_BUFFER_DURATION_MINS,
      cost: DEFAULT_PER_MEETING_VALUE,
      distribution: "sequential",
      emailTemplates: [
        {
          body: "",
          order: 1,
          title: "",
        },
      ],
      enabled: false,
      inviteTemplates: [
        {
          id: 0,
          location: "",
          notes: "",
          title: "",
        },
      ],
      metadata: {
        creationTimestamp: Date.now(),
      },
      name: "new meeting template",
      oooProcessingEnabled: true,
      properties: {
        bcc: "",
        dayRange: {
          // normal day range is 1-30 days
          // day range for a default user's meeting template is 1-5 days, per PM + marketing
          from: 1,
          to: isDefaultUser ? 5 : 30,
        },
        duration: 30,
        gap: 0, // 15, 30, 45, 60
        meetingReminder: {
          body: DEFAULT_MEETING_REMINDER_BODY,
          days: 0,
          enabled: false,
          hours: 3,
          minutes: 0,
          title: "",
        },
        routeOnActivation: false,
        // default to buffer_duration_mins + 3 hrs (buffer_duration_mins defaults to 3hrs, so here we use 6hrs)
        sameDayBuffer: 6,
      },
      routing: "sequential",
      routing_logic: {
        custom: teamMembers.reduce((acc, teamMember) => {
          acc[teamMember.id] = {
            enabled: true,
            mapping_logic: [],
          };
          return acc;
        }, {}),
        sequential: {
          order: teamMembers.map((t) => t.id),
        },
      },
      tagAutomation: {
        tagContacts: [],
        tagMeetings: [],
        tagUsers: [],
      },
      tags: [],
      team: {
        id: globalId,
      },
      timeNegotiationEnabled: true,
    };
  }, [isDefaultUser, globalId, teamMembers]);
  return {
    build,
    loading,
  };
}
