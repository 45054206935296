import React from "react";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronRight } from "@mdi/js";

import { ButtonContainer } from "../components/button";
import Flexbox from "../components/flexbox/FlexboxV2";
import { LargeSpacing, XXLargeSpacing } from "../components/spacing";
import { H6 } from "../components/typography";

import style from "./style.module.scss";

export default function AccordionKey({
  children,
  isOpen,
  name = null,
  onClick,
  text,
}) {
  return (
    <Flexbox.Column>
      <Flexbox.Row>
        <ButtonContainer
          className={style.meetingType__accordionButton}
          name={name}
          onClick={onClick}
          transparent
        >
          <H6>{text}</H6>
          <LargeSpacing />
          {isOpen && <Icon path={mdiChevronDown} size={1} />}
          {!isOpen && <Icon path={mdiChevronRight} size={1} />}
        </ButtonContainer>
        <div style={{ flex: 1 }} />
      </Flexbox.Row>
      <XXLargeSpacing />
      {isOpen && children}
    </Flexbox.Column>
  );
}
