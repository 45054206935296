import React from "react";
import cx from "classnames";

import style from "./style.module.scss";

export default function GridItem({
  className,
  children,
  gridArea,
  gridColumnStart,
  gridColumnEnd,
}) {
  const gridStyles = {};
  if (gridArea) {
    gridStyles.gridArea = gridArea;
  }
  if (gridColumnEnd) {
    gridStyles.gridColumnEnd = gridColumnEnd;
  }
  if (gridColumnStart) {
    gridStyles.gridColumnStart = gridColumnStart;
  }
  return (
    <div className={cx(style.gridItem, className)} style={gridStyles}>
      {children}
    </div>
  );
}
