import { useMemo } from "react";

import {
  BLACK,
  NEUTRAL_500,
  NEUTRAL_700,
  PRIMARY_BLUE,
  WHITE,
} from "../colors";
import { useTheme } from "../theme";
import { ClearIndicator } from "./ClearIndicator";
import { DropdownIndicator } from "./DropdownIndicator";

const LARGE = "large";
const MEDIUM = "medium";
const SMALL = "small";

function getMinHeight(size) {
  switch (size) {
    case LARGE:
      return "40px";
    case MEDIUM:
      return "32px";
    case SMALL:
      return "22px";
    default:
      return "40px";
  }
}

export function useDefaultSelectTheme({ size = LARGE } = {}) {
  const { isDark, useThemeShadows } = useTheme();
  const { MD } = useThemeShadows();
  return useMemo(() => {
    const optionBackgroundColor = isDark ? BLACK : WHITE;
    const optionTextColor = isDark ? WHITE : BLACK;
    const additionalContainerProps = {};
    if (!isDark) {
      additionalContainerProps.border = `1px solid ${NEUTRAL_500}`;
    }

    const opacity = isDark ? "0.1" : "0.4";

    return [
      { ClearIndicator, DropdownIndicator },
      {
        container: (base, { isDisabled }) => ({
          ...base,
          ...additionalContainerProps,
          backgroundColor: isDark ? BLACK : WHITE,
          borderRadius: "4px",
          minWidth: "225px",
          opacity: isDisabled ? opacity : null,
          width: "100%",
        }),
        control: (base) => ({
          ...base,
          backgroundColor: isDark ? BLACK : WHITE,
          border: 0,
          borderRadius: "4px",
          minHeight: getMinHeight(size),
        }),
        indicatorSeparator: (base) => ({
          ...base,
          marginBottom: "10px",
          marginTop: "10px",
        }),
        menu: (base) => ({
          ...base,
          backgroundColor: null,
          boxShadow: MD,
          marginTop: "10px",
        }),
        menuList: (base) => ({
          ...base,
          backgroundColor: optionBackgroundColor,
          borderRadius: "4px",
          boxShadow: "none",
          padding: "0px",
          width: "100%",
        }),
        option: (base, { isFocused }) => ({
          ...base,
          backgroundColor: isFocused ? PRIMARY_BLUE : optionBackgroundColor,
          color: isFocused ? WHITE : optionTextColor,
          cursor: "pointer",
          overflow: "hidden",
          padding: "7px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }),
        placeholder: (base) => ({
          ...base,
          color: isDark ? NEUTRAL_700 : NEUTRAL_500,
        }),
        singleValue: (base) => ({
          ...base,
          color: isDark ? WHITE : BLACK,
          maxWidth: "100%",
          padding: "0 12px",
        }),
        valueContainer: (base) => ({
          ...base,
          height: "100%",
          justifyContent: "center",
          padding: "0px",
        }),
      },
    ];
  }, [isDark, MD, size]);
}
