export const MEETING_TYPES_FOR_REPS = "meetingTypesForReps";
export const QUICK_LAUNCH = "quickLaunch";
export const CHANGE_MEETING_OWNER = "changeMeetingOwner";
export const OPENAI_INTEGRATION = "openAiIntegration";
export const RESCHEDULING_FROM_REMINDER = "reschedulingFromReminder";
export const DEFAULT_USER_DISABLE_MEETINGS_DELETE =
  "defaultUserDisableMeetingsDelete";
export const NEW_MEETINGS_PAGE = "newMeetingsPage";
export const NEW_TEMPLATES_PAGE = "newTemplatesPage";
export const API_PAGE = "apiPage";
export const LINK_FIRST_INVITE = "linkFirstInvite";

export const DEFAULTS = {
  [CHANGE_MEETING_OWNER]: false,
  [MEETING_TYPES_FOR_REPS]: true,
  [RESCHEDULING_FROM_REMINDER]: false,
  [OPENAI_INTEGRATION]: false,
  [DEFAULT_USER_DISABLE_MEETINGS_DELETE]: false,
  [NEW_MEETINGS_PAGE]: false,
  [NEW_TEMPLATES_PAGE]: false,
  [API_PAGE]: false,
  [LINK_FIRST_INVITE]: false,
};

export const FEATURE_DISPLAY_NAMES = {
  [CHANGE_MEETING_OWNER]: "Transfer meeting ownership to another host",
  [MEETING_TYPES_FOR_REPS]: "Meeting Templates For Reps",
  [QUICK_LAUNCH]: "Quick Launch",
  [RESCHEDULING_FROM_REMINDER]: "Allow Rescheduling From Meeting Reminder",
  [OPENAI_INTEGRATION]: "Open AI Integration",
  [DEFAULT_USER_DISABLE_MEETINGS_DELETE]:
    "Default User - Disable Meetings Delete",
  [NEW_MEETINGS_PAGE]: "New meetings page",
  [NEW_TEMPLATES_PAGE]: "New Templates Page",
  [API_PAGE]: "API Page",
  [LINK_FIRST_INVITE]: "Kronologic Link Meeting Type",
};

export const FEATURE_LIST = [
  MEETING_TYPES_FOR_REPS,
  CHANGE_MEETING_OWNER,
  RESCHEDULING_FROM_REMINDER,
  OPENAI_INTEGRATION,
  DEFAULT_USER_DISABLE_MEETINGS_DELETE,
  QUICK_LAUNCH,
  NEW_MEETINGS_PAGE,
  NEW_TEMPLATES_PAGE,
  API_PAGE,
  LINK_FIRST_INVITE,
];
