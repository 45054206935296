/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiMenu, mdiLoading, mdiPencilOutline, mdiClose } from "@mdi/js";
import dayjs from "dayjs";

import { ButtonContainer } from "../../components/button";
import Flexbox from "../../components/flexbox/FlexboxV2";
import { Input } from "../../components/input";
import {
  JumboSpacing,
  LargeSpacing,
  MediumSpacing,
  XXLargeSpacing,
} from "../../components/spacing";
import ToggleSwitch from "../../components/switch/Switch";
import { P1, H6 } from "../../components/typography";
import MeetingTypeTab from "../MeetingTypeTab";
import { useActive, useName } from "../context";
import { General } from "./General";
import { useHasSuperAdminPermissions } from "../../auth";
import useCreationData from "../useCreationData";
import style from "./style.module.scss";

const GENERAL_PATH = [
  "/meetingTypes/library/:meetingTypeId/general/emails/attempt/:emailId",
  "/meetingTypes/library/:meetingTypeId/general",
];

export default function MeetingTemplate({
  isMeetingTemplateUpdating,
  meetingTemplate,
  openMenu,
  saveError,
  showHamburger,
}) {
  const hasSuperAdminPermissions = useHasSuperAdminPermissions();
  const [active, setActive] = useActive();
  const history = useHistory();
  const [isEditingOn, setIsEditingOn] = useState(false);
  const [name, setName] = useName();
  const creationData = useCreationData({
    meetingType: meetingTemplate,
  });
  return (
    <Flexbox.Column flex="1 1 100%" maxHeight="100%" width="100%">
      <LargeSpacing />
      <Flexbox.Row alignItems="center">
        {showHamburger && (
          <>
            <XXLargeSpacing />
            <ButtonContainer
              className={style.meetingType__showMenuButton}
              transparent
              onClick={openMenu}
            >
              <Icon path={mdiMenu} size={1.5} />
            </ButtonContainer>
            <LargeSpacing />
          </>
        )}
        {!showHamburger && <XXLargeSpacing />}
        {!isEditingOn && <H6 whiteSpace="nowrap">{meetingTemplate.name}</H6>}
        {isEditingOn && (
          <Input
            large
            onChange={(event) => {
              setName(event.target.value);
            }}
            value={name}
          />
        )}
        <LargeSpacing />
        {hasSuperAdminPermissions && (
          <ButtonContainer transparent>
            <Flexbox.Row>
              <Icon
                className={style.meetingTypes__buttonMenu}
                path={isEditingOn ? mdiClose : mdiPencilOutline}
                size={1}
                onClick={() => setIsEditingOn((p) => !p)}
              />
            </Flexbox.Row>
          </ButtonContainer>
        )}
        <P1 className={style.meetingTypes__createdByData}>
          <div className={style.meetingTypes__createdByData__item}>
            {`Created: ${dayjs(creationData?.createdAt || null).format(
              "MM/DD/YY, h:mma"
            )}`}
          </div>
        </P1>
        <Flexbox.Row flex="1 1 100%" />
        {saveError && (
          <Flexbox.Column>
            {Array.isArray(saveError) &&
              saveError.map((e) => <P1 small>{e}</P1>)}
          </Flexbox.Column>
        )}
        <LargeSpacing />
        <Flexbox.Row flex={0}>
          <ToggleSwitch
            disabled={!hasSuperAdminPermissions}
            isOn={active}
            handleToggle={() => {
              setActive(!active);
            }}
            size="small"
          />
        </Flexbox.Row>
        <JumboSpacing horizontal />
      </Flexbox.Row>
      <MediumSpacing />
      <Flexbox.Row className={style.meetingTypes__tabMenu}>
        <JumboSpacing horizontal />
        <MeetingTypeTab
          onClick={() => {
            history.push(`/meetingTypes/library/${meetingTemplate.id}/general`);
          }}
          selected
          text="Configuration"
        />
        <div style={{ flex: "1 1 100%" }} />
        <Flexbox.Column justifyContent="center" width="auto">
          <Flexbox.Row alignItems="center">
            <ButtonContainer
              className={style.meetingType__saveButton}
              primary
              medium
              onClick={() => {
                history.push(
                  `/meetingTypes/library/${meetingTemplate.id}/toMeetingType`
                );
              }}
            >
              <P1 whiteSpace="nowrap">Copy to My Templates</P1>
            </ButtonContainer>
            {hasSuperAdminPermissions && (
              <>
                <MediumSpacing />
                <ButtonContainer
                  className={style.meetingType__saveButton}
                  secondary
                  medium
                >
                  {isMeetingTemplateUpdating && (
                    <Flexbox.Row alignItems="center">
                      <Icon path={mdiLoading} size={0.75} spin={4} />
                      <MediumSpacing />
                      <P1>Saving...</P1>
                    </Flexbox.Row>
                  )}
                  {!isMeetingTemplateUpdating && <P1>Saved</P1>}
                </ButtonContainer>
              </>
            )}
          </Flexbox.Row>
        </Flexbox.Column>
        <JumboSpacing horizontal />
      </Flexbox.Row>
      <Switch>
        <Route
          exact
          path={`/meetingTypes/library/${meetingTemplate.id}`}
          render={() => (
            <Redirect
              to={`/meetingTypes/library/${meetingTemplate.id}/general`}
            />
          )}
        />
        <Route
          path={GENERAL_PATH}
          render={() => <General meetingTemplate={meetingTemplate} />}
        />
      </Switch>
      <LargeSpacing />
    </Flexbox.Column>
  );
}
