import React from "react";
import Slider from "rc-slider";

import Flexbox from "../../components/flexbox/FlexboxV2";
import { P1 } from "../../components/typography";
import {
  useBufferDurationHours,
  useCanEdit,
  useDayRange,
  useSameDayBuffer,
} from "../context";

import { SmallSpacing, XSmallSpacing } from "../../components/spacing";
import { secondSlider } from "./SameDay";
import useRailColor from "./useRailColor";
import style from "../style.module.scss";

export default function MinimumSchedulingNotice() {
  const [bufferDurationHours, setBufferDurationHours] =
    useBufferDurationHours();
  const [{ from }] = useDayRange();
  const [sameDayBuffer] = useSameDayBuffer();
  const suffix = bufferDurationHours > 1 ? "Hours" : "Hour";
  const onChange = (newBufferDurationHours) => {
    if (from === 0) {
      setBufferDurationHours(
        newBufferDurationHours,
        secondSlider(newBufferDurationHours, sameDayBuffer)
      );
    } else {
      setBufferDurationHours(
        newBufferDurationHours,
        secondSlider(newBufferDurationHours, null)
      );
    }
  };
  const { railColor } = useRailColor();
  const canEditMeetingType = useCanEdit();
  return (
    <Flexbox.Column>
      <div className={style.meetingType__slider}>
        <Slider
          disabled={!canEditMeetingType}
          onChange={onChange}
          min={1}
          max={24}
          value={bufferDurationHours}
          railStyle={{ backgroundColor: railColor }}
        />
      </div>
      <SmallSpacing />
      <Flexbox.Row>
        <XSmallSpacing />
        <P1 small>{`${bufferDurationHours} ${suffix}`}</P1>
      </Flexbox.Row>
    </Flexbox.Column>
  );
}
