import React, { useMemo } from "react";
import { BLACK, WHITE } from "../../components/colors";
import { useTheme } from "../../components/theme";

function DropdownIndicator() {
  return <span />;
}

export function useDropdownTheme() {
  const { isDark } = useTheme();

  return useMemo(
    () => [
      { DropdownIndicator },
      {
        container: (base) => ({
          ...base,
          backgroundColor: isDark ? "#191C3A" : "#e6e6e6",
          height: "100%",
          paddingLeft: "0px",
          paddingRight: "0px",
          width: "100%",
        }),
        control: (base) => ({
          ...base,
          backgroundColor: "inherit",
          border: 0,
          borderRadius: 0,
          boxShadow: 0,
          fontSize: "12px",
          height: "100%",
          minHeight: "27px",
          padding: "0px",
          width: "100%",
        }),
        indicatorSeparator: (base) => ({
          ...base,
          backgroundColor: "inherit",
          width: 0,
        }),
        indicatorsContainer: (base) => ({
          ...base,
          padding: "0px",
        }),
        input: (base) => ({
          ...base,
          color: isDark ? WHITE : BLACK,
          fontSize: "12px",
        }),
        menu: (base) => ({
          ...base,
          backgroundColor: WHITE,
          boxShadow: "none",
          marginTop: 2,
        }),
        menuList: (base) => ({
          ...base,
          border: `0.5px solid ${isDark ? "#191C3A" : "#A8B1BD"}`,
          borderRadius: 0,
          padding: "0px",
        }),
        multiValue: (base) => ({
          ...base,
          backgroundColor: WHITE,
          borderRadius: "2px",
          color: BLACK,
          margin: "2px",
        }),
        option: (base, { isFocused }) => {
          let backgroundColor;

          if (isDark) {
            backgroundColor = isFocused
              ? "rgba(121, 125, 149, 0.7)"
              : "#414564";
          }

          if (!isDark) {
            backgroundColor = isFocused ? "#F1FAFF" : WHITE;
          }

          return {
            ...base,
            backgroundColor,
            border: `0.5px solid ${isDark ? "#191C3A" : "#A8B1BD"}`,
            color: isDark ? WHITE : BLACK,
            cursor: "pointer",
            padding: "7px",
          };
        },
        singleValue: (base) => ({
          ...base,
          color: isDark ? WHITE : BLACK,
        }),
        valueContainer: (base) => ({
          ...base,
          alignItems: "center",
          display: "flex",
          fontSize: "12px",
          height: "100%",
          justifyContent: "center",
          padding: "0px",
        }),
      },
    ],
    [isDark]
  );
}
