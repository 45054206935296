import "./styles.scss";

import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import Paragraph from "@tiptap/extension-paragraph";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { Editor, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

import { MenuItem, Select } from "@mui/material";
import classNames from "classnames";
import {
  AiOutlineAlignCenter,
  AiOutlineAlignLeft,
  AiOutlineAlignRight,
  AiOutlineBold,
  AiOutlineItalic,
  AiOutlineLink,
  AiOutlineOrderedList,
  AiOutlineStrikethrough,
  AiOutlineUnderline,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { GrBlockQuote, GrImage, GrRedo, GrUndo } from "react-icons/gr";
import { useEffect } from "react";

export const cleanRichTextHTML = (msg: string) => {
  return (
    msg
      .trimStart()
      // replace double new-lines with single new-lines
      .replace(/(\n\n)/gm, "\n")
      // do the same for double carriage returns
      .replace(/(\r\n\r\n)/gm, "\r\n")
      // replace new-lines with rich-text-editor specific html tags
      .replace(
        /(\r\n|\n|\r)/gm,
        '<p style="margin: 0px"><br class="kronologic-tiptap-editor--hidden"></p>'
      )
  );
};

const MenuBar = ({ editor }: { editor: Editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="tiptap-menu">
      <div className="tiptap-menu-buttons-wrapper">
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          className={classNames("tiptap-menu-button", {
            "is-active": editor.isActive("heading", { level: 1 }),
          })}
        >
          H1
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          className={classNames("tiptap-menu-button", {
            "is-active": editor.isActive("heading", { level: 2 }),
          })}
        >
          H2
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          className={classNames("tiptap-menu-button", {
            "is-active": editor.isActive("heading", { level: 3 }),
          })}
        >
          H3
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={classNames("tiptap-menu-button", {
            "is-active": editor.isActive("bold"),
          })}
        >
          <AiOutlineBold />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={classNames("tiptap-menu-button", {
            "is-active": editor.isActive("italic"),
          })}
        >
          <AiOutlineItalic />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={classNames("tiptap-menu-button", {
            "is-active": editor.isActive("underline"),
          })}
        >
          <AiOutlineUnderline />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={classNames("tiptap-menu-button", {
            "is-active": editor.isActive("strike"),
          })}
        >
          <AiOutlineStrikethrough />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={classNames("tiptap-menu-button", {
            "is-active": editor.isActive("bulletList"),
          })}
        >
          <AiOutlineUnorderedList />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={classNames("tiptap-menu-button", {
            "is-active": editor.isActive("orderedList"),
          })}
        >
          <AiOutlineOrderedList />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={classNames("tiptap-menu-button", {
            "is-active": editor.isActive("blockquote"),
          })}
        >
          <GrBlockQuote />
        </button>

        <button
          onClick={() => {
            const previousUrl = editor.getAttributes("link").href;
            const url = window.prompt("URL", previousUrl);

            // cancelled
            if (url === null) {
              return;
            }

            // empty
            if (url === "") {
              editor.chain().focus().extendMarkRange("link").unsetLink().run();

              return;
            }

            // update link
            editor
              .chain()
              .focus()
              .extendMarkRange("link")
              .setLink({ href: url })
              .run();
          }}
          className={classNames("tiptap-menu-button", {
            "is-active": editor.isActive("link"),
          })}
        >
          <AiOutlineLink />
        </button>
        <button
          className="tiptap-menu-button"
          onClick={() => {
            const url = window.prompt("URL");

            if (url) {
              editor.chain().focus().setImage({ src: url }).run();
            }
          }}
        >
          <GrImage />
        </button>

        <button
          onClick={() => editor.chain().focus().setTextAlign("left").run()}
          className={classNames("tiptap-menu-button", {
            "is-active": editor.isActive({ textAlign: "left" }),
          })}
        >
          <AiOutlineAlignLeft />
        </button>
        <button
          onClick={() => editor.chain().focus().setTextAlign("center").run()}
          className={classNames("tiptap-menu-button", {
            "is-active": editor.isActive({ textAlign: "center" }),
          })}
        >
          <AiOutlineAlignCenter />
        </button>
        <button
          onClick={() => editor.chain().focus().setTextAlign("right").run()}
          className={classNames("tiptap-menu-button", {
            "is-active": editor.isActive({ textAlign: "right" }),
          })}
        >
          <AiOutlineAlignRight />
        </button>

        <Select
          sx={{ marginLeft: 2 }}
          labelId="merge-tags-select"
          id="merge-tags-select"
          value="placeholder"
          variant="standard"
          onChange={(event) => {
            editor.chain().focus().insertContent(event.target.value).run();
          }}
        >
          <MenuItem value="placeholder" style={{ display: "none" }}>
            Merge Fields
          </MenuItem>
          <MenuItem value="{{greeting}}">Greeting</MenuItem>
          <MenuItem value="{{guest_account}}">Guest Account</MenuItem>
          <MenuItem value="{{guest_first_name}}">Guest First Name</MenuItem>
          <MenuItem value="{{guest_last_name}}">Guest Last Name</MenuItem>
          <MenuItem value="{{guest_logic_field}}">Guest Logic Field</MenuItem>
          <MenuItem value="{{guest_logic_field_2}}">
            Guest Logic Field 2
          </MenuItem>
          <MenuItem value="{{guest_phone}}">Guest Phone</MenuItem>
          <MenuItem value="{{host_company}}">Host Company</MenuItem>
          <MenuItem value="{{host_first_name}}">Host First Name</MenuItem>
          <MenuItem value="{{host_last_name}}">Host Last Name</MenuItem>
          <MenuItem value="{{host_location}}">Host Location</MenuItem>
          <MenuItem value="{{host_title}}">Host Title</MenuItem>
          <MenuItem value="{{host_signature}}">Host Signature</MenuItem>
          <MenuItem value="{{meeting_date_time}}">Meeting Date & Time</MenuItem>
          <MenuItem value="{{meeting_day_of_week}}">
            Meeting Day of Week
          </MenuItem>
          <MenuItem value="{{meeting_duration}}">Meeting Duration</MenuItem>
          <MenuItem value="{{meeting_link}}">Meeing Link</MenuItem>
          <MenuItem value="{{meeting_month_day}}">Meeting Month & Day</MenuItem>
          <MenuItem value="{{meeting_time}}">Meeting Time</MenuItem>
          <MenuItem value="{{time_casual_day}}">Time Casual Day</MenuItem>
          <MenuItem value="{{time_number_date}}">Time Number Date</MenuItem>
          <MenuItem value="{{guest_custom_field_1}}">
            Guest Custom Field 1
          </MenuItem>
          <MenuItem value="{{guest_custom_field_2}}">
            Guest Custom Field 2
          </MenuItem>
          <MenuItem value="{{guest_custom_field_3}}">
            Guest Custom Field 3
          </MenuItem>
          <MenuItem value="{{guest_custom_field_4}}">
            Guest Custom Field 4
          </MenuItem>
          <MenuItem value="{{guest_custom_field_5}}">
            Guest Custom Field 5
          </MenuItem>
          <MenuItem value="{{guest_custom_field_6}}">
            Guest Custom Field 6
          </MenuItem>
          <MenuItem value="{{guest_custom_field_7}}">
            Guest Custom Field 7
          </MenuItem>
          <MenuItem value="{{guest_custom_field_8}}">
            Guest Custom Field 8
          </MenuItem>
          <MenuItem value="{{guest_custom_field_9}}">
            Guest Custom Field 9
          </MenuItem>
          <MenuItem value="{{guest_custom_field_10}}">
            Guest Custom Field 10
          </MenuItem>
        </Select>
      </div>
      <div className="tiptap-menu-buttons-wrapper">
        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
          className="tiptap-menu-button"
        >
          <GrUndo />
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
          className="tiptap-menu-button"
        >
          <GrRedo />
        </button>
      </div>
    </div>
  );
};

type EmailRichtextEditor = {
  value?: string | null;
  onChange?: (html: string) => void;
  onEditorCreated?: (editor: Editor) => void;
  menuBar?: React.ReactNode;
};

const EmailRichtextEditor = (props: EmailRichtextEditor) => {
  const initialValue = props.value
    ?.replaceAll(
      `<p style="margin: 0px"><br class="kronologic-tiptap-editor--hidden"></p>`,
      `<p style="margin: 0px"></p>`
    )
    .replaceAll(`<div><br></div>`, `<p style="margin: 0px"></p>`);

  const editor = useEditor({
    onUpdate: ({ editor }) => {
      const htmlWithBreaks = editor
        .getHTML()
        .replaceAll(
          '<p style="margin: 0px"></p>',
          `<p style="margin: 0px"><br class="kronologic-tiptap-editor--hidden"></p>`
        )
        .replaceAll(
          '<p style="text-align: center; margin: 0px"></p>',
          `<p style="margin: 0px"><br class="kronologic-tiptap-editor--hidden"></p>`
        )
        .replaceAll(
          '<p style="text-align: right; margin: 0px"></p>',
          `<p style="margin: 0px"><br class="kronologic-tiptap-editor--hidden"></p>`
        );

      props.onChange?.(editor.isEmpty ? "" : htmlWithBreaks);
    },
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        hardBreak: {
          HTMLAttributes: {
            class: "kronologic-tiptap-editor--hidden",
          },
        },
      }),
      Paragraph.extend({
        addAttributes() {
          return {
            style: {
              default: null,
              renderHTML() {
                return {
                  style: `margin: 0px`,
                };
              },
            },
          };
        },
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Link.configure({
        openOnClick: false,
      }),
      Underline,
      Image,
    ],
    content: initialValue,
  });

  useEffect(() => {
    if (!editor) {
      return;
    }

    props.onEditorCreated?.(editor);
  }, [editor, props]);

  if (!editor) {
    return null;
  }

  return (
    <div className="tiptap-wrapper">
      {props.menuBar || <MenuBar editor={editor} />}
      <EditorContent className="tiptap-editor" editor={editor} />
    </div>
  );
};

export default EmailRichtextEditor;
