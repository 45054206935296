import useSWR from "swr";

import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useErrorHandler, useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { User } from "../types";
import { buildQueryString } from "../utils";

export function useUsersAll(
  limit: number,
  offset: number,
  org: string | null,
  query: {
    query?: string;
    sort?: {
      field: "firstName" | "lastName" | "email" | "role";
      order: "desc" | "asc";
    };
  }
) {
  const accessToken = getUserToken();
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const errorHandler = useErrorHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const q = buildQueryString({
    limit,
    offset,
    org,
    qry: query?.query,
    sortBy: query?.sort?.field,
    sortOrder: query?.sort?.order,
  });

  const url = `/api/admin/users/all?${q}`;

  const { data, error } = useSWR(org === null ? null : url, (u) =>
    service
      .get(u)
      .set(headers)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler)
  );

  return {
    data: data as
      | {
          total: number;
          data: User[];
        }
      | undefined,
    error,
    loading: !error && !data,
  };
}
