import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Container, Stack, Grid, Link, Typography } from "@mui/material";

import { Logo } from "../componentsV2/Logo";

function NavButton({
  children,
  href,
  to,
}: {
  children: React.ReactNode;
  href?: string;
  to?: string;
}) {
  if (to) {
    return (
      <Link
        variant="body2"
        component={RouterLink}
        to={to}
        underline="none"
        style={{ color: "gray" }}
      >
        {children}
      </Link>
    );
  }

  return (
    <Link
      variant="body2"
      href={href}
      underline="none"
      style={{ color: "gray" }}
    >
      {children}
    </Link>
  );
}

function Spacer() {
  return (
    <Stack justifyContent="center" alignItems="center">
      <Box sx={{ background: "#CCC", height: "1px", width: "0.7em" }} />
    </Stack>
  );
}

function Links() {
  return (
    <Stack direction="row" justifyContent="space-around">
      <NavButton to="/">Home</NavButton>
      <Spacer />
      <NavButton href="https://academy.kronologic.ai/ticket?_ga=2.43698528.45411818.1659624951-1780552135.1659624951">
        Support
      </NavButton>
    </Stack>
  );
}

export function ErrorPage(props: { status: number }) {
  const message: { [index: number]: string } = {
    400: "Bad Request",
    402: "Payment Required",
    401: "Unauthorized",
    403: "Access Forbidden",
    404: "Not Found",
    408: "Request Timeout",
    500: "Server Error",
    501: "Not Implemented",
    502: "Bad Gateway",
    504: "Gateway Timeout",
  };

  const statusCode = props.status || 500;
  const title = message[statusCode];

  return (
    <Grid
      sx={{ height: "calc(100vh - 4em)" }}
      container
      direction="column"
      alignContent="center"
      alignItems="center"
      justifyContent="space-around"
    >
      <Stack>
        <Logo width={350} spacing={2} />
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ userSelect: "none" }}
        >
          <Typography variant="h2">{statusCode}</Typography>
          <Typography variant="h4">{title}</Typography>
        </Stack>
        <Box sx={{ height: "3em" }} />
        <Container maxWidth="xs">
          <Links />
        </Container>
      </Stack>
    </Grid>
  );
}

export default ErrorPage;
