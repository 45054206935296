/* eslint-disable react/jsx-wrap-multilines */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { mdiAccountPlusOutline, mdiLoading, mdiSignCaution } from "@mdi/js";

import {
  EmptyViewContainer,
  EmptyViewRow,
  EmptyViewIcon,
} from "../components/emptyView/EmptyView";
import { Grid, GridItem } from "../components/grid";
import { useMeetingTypes } from "../repository/meetingTypes";
import MeetingTypeMenuAndList from "./MeetingTypeMenuAndList";
import { MeetingType } from "./MeetingType";
import { MeetingTypesContext } from "./context";
import useMeetingTypeByUri from "./useMeetingTypeByUri";
import CreateMeetingType from "./CreateMeetingType";
import { useSize } from "../components/size";
import { DRAWER, useModalV2 } from "../components/modal";
import DeleteMeetingType from "./DeleteMeetingType";
import CopyMeetingType from "./CopyMeetingType";
import MeetingTypeTagManager from "./MeetingTypesTagManager";
import { useHasOrgAdminPermissions } from "../auth";
import MeetingTypesMenuAndListDrawer from "./MeetingTypesMenuAndListDrawer";
import { ButtonContainer } from "../components/button";
import { LargeSpacing } from "../components/spacing";
import useCreationData from "./useCreationData";
import { ROLE_LEVELS } from "../auth/roles";
import CreatorRoleIcon from "./CreatorRoleIcon";
import style from "./style.module.scss";
import { useTagsRepository } from "../repository";

const MEETING_TYPES_PATH = [
  "/meetingTypes/user/:id/:tab",
  "/meetingTypes/user/:id",
];

export default function MeetingTypes() {
  const history = useHistory();
  const [query, setQuery] = useState("");
  const {
    create: createMeetingType,
    deleting: isMeetingTypeDeleting,
    deleteMeetingItem: deleteMeetingType,
    data: meetingTypes,
    error: meetingTypesError,
    loading: isMeetingTypesLoading,
    save: saveMeetingType,
    saveError: meetingTypesSaveError,
    setSaveError: setMeetingTypesSaveError,
    saving: isUpdating,
    setData: setMeetingTypes,
    update: updateMeetingType,
  } = useMeetingTypes({
    query,
  });
  const { id: meetingTypeId } = useParams();
  useEffect(() => {
    setMeetingTypesSaveError(null);
  }, [meetingTypeId, setMeetingTypesSaveError]);
  const hasOrgAdminPermissions = useHasOrgAdminPermissions();
  const isSuccessfullyLoaded =
    meetingTypesError === null && !isMeetingTypesLoading;
  const selectedMeetingType = useMeetingTypeByUri(
    isMeetingTypesLoading,
    meetingTypes
  );
  const context = useMemo(() => {
    return {
      meetingType: selectedMeetingType,
      saveMeetingType,
      updateMeetingType,
    };
  }, [selectedMeetingType, updateMeetingType, saveMeetingType]);
  const gridRef = useRef(null);
  const { width } = useSize(gridRef);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isListInitiallyOpen = params.has("open");
  const [MeetingTypeMenuAndListModal, openMenu, closeMenu] = useModalV2(
    MeetingTypesMenuAndListDrawer,
    DRAWER,
    {
      open: isListInitiallyOpen,
    }
  );
  const [TagManager, openTags, close] = useModalV2(
    MeetingTypeTagManager,
    DRAWER
  );
  const isBelowWidthThreshold = width < 1780;
  const creationData = useCreationData({
    meetingType: selectedMeetingType,
  });

  const menuProps = {
    create: () => {
      history.push("/meetingTypes/user/create");
    },
    error: meetingTypesError,
    loading: isMeetingTypesLoading,
    meetingTypes,
    onClickCopy: (id) => {
      history.push(`/meetingTypes/user/${id}/copy`);
    },
    onClickDelete: (id) => {
      history.push(`/meetingTypes/user/${id}/delete`);
    },
    onClickSelect: (id) => {
      history.push(`/meetingTypes/user/${id}/general/invite`);
    },
    renderIcon: (meetingType) => {
      return <CreatorRoleIcon meetingType={meetingType} />;
    },
    search: query,
    selectedMeetingTypeId: selectedMeetingType?.id,
    setSearch: setQuery,
    showDeleteButton:
      hasOrgAdminPermissions ||
      // if this is a meeting template created by a default user,
      // the default user can delete their own meeting template
      (creationData !== null && creationData.userRole === ROLE_LEVELS.DEFAULT),
    update: updateMeetingType,
  };

  const openTagManager = useCallback(() => {
    closeMenu();
    openTags();
  }, [closeMenu, openTags]);

  const repository = useTagsRepository();

  return (
    <MeetingTypesContext.Provider value={context}>
      <TagManager
        close={close}
        initialSelectedTags={[]}
        setMeetingTypes={setMeetingTypes}
        meetingTypeId={selectedMeetingType?.id}
        tags={selectedMeetingType?.tags}
        repository={repository}
      />
      <Grid className={style.meetingTypes} ref={gridRef}>
        <GridItem className={style.meetingTypes__content} gridArea="content">
          <Grid className={style.meetingTypeAndList}>
            <Switch>
              {width >= 1780 && (
                <GridItem
                  className={style.meetingTypes__listGridItem}
                  gridArea="list"
                >
                  <MeetingTypeMenuAndList
                    {...menuProps}
                    showAddButton
                    showCopyButton
                    openTags={openTags}
                  />
                </GridItem>
              )}
              {width < 1779 && (
                <MeetingTypeMenuAndListModal
                  {...menuProps}
                  close={closeMenu}
                  showAddButton
                  showCopyButton
                  openTags={openTagManager}
                  isListInitiallyOpen={isListInitiallyOpen}
                  MenuAndList={MeetingTypeMenuAndList}
                  showCloseButton
                />
              )}
            </Switch>
            <GridItem
              className={style.meetingTypes__meetingTypeGridItem}
              gridArea="meetingType"
            >
              <div className={style.meetingType}>
                <Switch>
                  <Route
                    exact
                    path="/meetingTypes/user"
                    render={() => (
                      <>
                        {isSuccessfullyLoaded && meetingTypes.length === 0 && (
                          <EmptyViewContainer
                            className={style.meetingType__emptyView}
                          >
                            <EmptyViewRow>
                              <EmptyViewIcon icon={mdiAccountPlusOutline} />
                            </EmptyViewRow>
                            <EmptyViewRow>
                              No Meeting Templates found
                            </EmptyViewRow>
                            <LargeSpacing />
                            <EmptyViewRow>
                              <ButtonContainer
                                primary
                                large
                                onClick={menuProps.create}
                              >
                                Add New Meeting Template
                              </ButtonContainer>
                            </EmptyViewRow>
                          </EmptyViewContainer>
                        )}
                        {isSuccessfullyLoaded && meetingTypes.length > 0 && (
                          <Redirect
                            to={`/meetingTypes/user/${meetingTypes[0].id}/general`}
                          />
                        )}
                        {meetingTypesError !== null && (
                          <EmptyViewContainer
                            className={style.meetingType__emptyView}
                          >
                            <EmptyViewRow>
                              <EmptyViewIcon icon={mdiSignCaution} />
                            </EmptyViewRow>
                            <EmptyViewRow>
                              Failed to load meeting templates. Please try again
                              later.
                            </EmptyViewRow>
                          </EmptyViewContainer>
                        )}
                      </>
                    )}
                  />

                  <Route
                    path="/meetingTypes/user/create"
                    render={() => (
                      <CreateMeetingType onCreate={createMeetingType} />
                    )}
                  />
                  <Route
                    path="/meetingTypes/user/:id/copy"
                    render={() => (
                      <CopyMeetingType
                        meetingType={selectedMeetingType}
                        onCreate={createMeetingType}
                      />
                    )}
                  />
                  <Route
                    path="/meetingTypes/user/:id/delete"
                    render={() => (
                      <DeleteMeetingType
                        deleting={isMeetingTypeDeleting}
                        onDelete={deleteMeetingType}
                      />
                    )}
                  />
                  <Route
                    path={MEETING_TYPES_PATH}
                    render={() => (
                      <>
                        {isMeetingTypesLoading && (
                          <EmptyViewContainer
                            className={style.meetingType__emptyView}
                          >
                            <EmptyViewRow>
                              <EmptyViewIcon icon={mdiLoading} spin={4} />
                            </EmptyViewRow>
                            <EmptyViewRow>
                              Loading meeting templates...
                            </EmptyViewRow>
                          </EmptyViewContainer>
                        )}
                        {isSuccessfullyLoaded &&
                          selectedMeetingType !== null && (
                            <MeetingType
                              isMeetingTypeUpdating={isUpdating}
                              meetingType={selectedMeetingType}
                              openMenu={openMenu}
                              openTags={openTags}
                              saveError={meetingTypesSaveError}
                              showHamburger={isBelowWidthThreshold}
                            />
                          )}
                        {isSuccessfullyLoaded &&
                          selectedMeetingType === null && (
                            <Redirect to="/meetingTypes/user" />
                          )}
                      </>
                    )}
                  />
                  <Route
                    path="*"
                    render={() => <Redirect to="/meetingTypes/user" />}
                  />
                </Switch>
              </div>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </MeetingTypesContext.Provider>
  );
}
