import { hot } from "react-hot-loader/root";
import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactHintFactory from "react-hint";
import ReactModal from "react-modal";
import { LicenseInfo } from "@mui/x-license-pro";

import NotificationCenter from "./notifications/NotificationCenter";
import { Configuration } from "./configuration/Configuration";

import { ActingAsProvider } from "./auth";

import Modal from "./components/modal";
import { ThemeProvider } from "./componentsV2/ThemeProvider";
import Pages from "./pages";
import { ConfirmationDialogProvider, SnackbarProvider } from "./hooks";
import { Monitoring } from "./monitoring";

LicenseInfo.setLicenseKey(
  "91739c69e5242ea1d714e32c3b3b7c87Tz01NTUxNCxFPTE3MDE2Mjk2MzU5OTksUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const ReactHint = ReactHintFactory(React);

const App = () => {
  useEffect(() => {
    ReactModal.setAppElement("#kronologic-ai-app");
  }, []);

  return (
    <>
      <ReactHint events delay={{ hide: 0, show: 0 }} />

      <Configuration>
        <ThemeProvider>
          <SnackbarProvider>
            <ConfirmationDialogProvider>
              <NotificationCenter>
                <ActingAsProvider>
                  <Router>
                    <Modal>
                      <Monitoring />
                      <Pages />
                    </Modal>
                  </Router>
                </ActingAsProvider>
              </NotificationCenter>
            </ConfirmationDialogProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </Configuration>
    </>
  );
};

export default hot(App);
