/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  mdiPencil,
  mdiTrashCanOutline,
  mdiFilterOutline,
  mdiAlertOutline,
  mdiChevronDown,
  mdiContentDuplicate,
  mdiAlert,
} from "@mdi/js";
import Icon from "@mdi/react";
import cx from "classnames";
import { API } from "../props";
import style from "./style.module.scss";
import { del, patch } from "../utils/fetch";
import Card from "../components/card/Card";
import Switch from "../components/switch/Switch";
import Button from "../components/button/Button";
import Tag from "../components/tag/Tag";
import { ChannelsStateContext, ModalStateContext } from "./ChannelsState";
import { IMPORT_CONDITION_TYPES_MAPPINGS } from "./Modals/cards/cardProps";
import { CHANNEL_TYPES } from "./props";
import { INTEGRATION_IMG_SRC, INTEGRATION_TYPES } from "../integrations/props";
import { TagButton } from "../components/tagButton";
import { TagList } from "../components/tagList";
import { useHasOrgAdminPermissions } from "../auth";
import { cleanOutreachFilterText } from "./util";
import { useConfirmationDialog } from "../hooks";

const MAX_CHAR_ROW = 45;
const MAX_NUM_ROWS = 1;
const EMPTY_LIST = [];

const ChannelInfo = ({ fieldObjectTypeData, filters, type, integration }) => {
  const isOutreach = useMemo(() => {
    if (integration !== null) {
      return integration.name === INTEGRATION_TYPES.OUTREACH;
    }

    return false;
  }, [integration]);

  const integrationInfo = useMemo(
    () => (
      <div
        className={cx(style.channelInfoItem, {
          [style.alert]: integration.name === "",
        })}
      >
        {INTEGRATION_IMG_SRC[integration.name] && (
          <img
            className={style.channelInfoItemImg}
            src={INTEGRATION_IMG_SRC[integration.name]}
            alt={integration.name}
          />
        )}

        {!INTEGRATION_IMG_SRC[integration.name] && (
          <div className={style.channelInfoItemImg}>
            <Icon path={mdiAlert} size={1} />
          </div>
        )}

        <span className={style.channelInfoItemSpan}>
          {integration?.name || "No integration found!"}
        </span>
      </div>
    ),
    [integration]
  );

  if (filters && filters.length) {
    const isNewFormat = Array.isArray(filters[0]);

    return (
      <div className={style.channelInfo}>
        {integrationInfo}
        {filters.map((group) => {
          if (isNewFormat) {
            return group.map((filter) => {
              if (type === CHANNEL_TYPES.IMPORT) {
                const { field, comparison, value } = filter;
                let newLabel = field.name;
                let val = value.label;

                if (fieldObjectTypeData) {
                  const fieldObject = fieldObjectTypeData.find(
                    ({ name }) => name === field.name
                  );

                  if (fieldObject) {
                    newLabel = fieldObject.label;
                  }
                }

                if (isOutreach) {
                  newLabel = cleanOutreachFilterText(newLabel);
                  val = cleanOutreachFilterText(value.label);
                }

                return (
                  <div
                    key={`condition-${field.name}-${comparison.key}-${value.label}`}
                    className={style.channelInfoItem}
                  >
                    <Icon path={mdiFilterOutline} size={1} />
                    <Tag className={style.channelInfoItemSpan}>{newLabel}</Tag>
                    <span className={style.channelInfoItemSpan}>
                      {IMPORT_CONDITION_TYPES_MAPPINGS[comparison.key]}
                    </span>
                    <Tag className={style.channelInfoItemSpan}>{val}</Tag>
                  </div>
                );
              }

              if (type === CHANNEL_TYPES.INTERCEPT) {
                const {
                  event: { field, value },
                } = filter;
                let newLabel = field.label || field;

                if (fieldObjectTypeData) {
                  const fieldObject = fieldObjectTypeData.find(
                    ({ name }) => name === field
                  );

                  if (fieldObject) {
                    newLabel = fieldObject.label;
                  }
                }

                return (
                  <div
                    key={`condition-${field}-${value}`}
                    className={style.channelInfoItem}
                  >
                    <Icon path={mdiFilterOutline} size={1} />
                    <Tag className={style.channelInfoItemSpan}>{newLabel}</Tag>
                    <span className={style.channelInfoItemSpan}>is</span>
                    <Tag className={style.channelInfoItemSpan}>{value}</Tag>
                  </div>
                );
              }

              if (type === CHANNEL_TYPES.UPDATE) {
                const {
                  action: { field, value },
                } = filter;
                let newLabel = field.label || field;

                if (fieldObjectTypeData) {
                  const fieldObject = fieldObjectTypeData.find(
                    ({ name }) => name === field
                  );

                  if (fieldObject) {
                    newLabel = fieldObject.label;
                  }
                }

                if (isOutreach) {
                  newLabel = cleanOutreachFilterText(newLabel);
                }

                return (
                  <div
                    key={`condition-${field}-${value}`}
                    className={style.channelInfoItem}
                  >
                    <Icon path={mdiFilterOutline} size={1} />
                    <Tag className={style.channelInfoItemSpan}>{newLabel}</Tag>
                    {!isOutreach && (
                      <>
                        <span className={style.channelInfoItemSpan}>is</span>
                        <Tag className={style.channelInfoItemSpan}>{value}</Tag>
                      </>
                    )}
                  </div>
                );
              }

              return null;
            });
          }

          if (type === CHANNEL_TYPES.IMPORT) {
            const {
              field: { name },
              comparison,
            } = group;
            let {
              value: { label },
            } = group;
            let newLabel = name;

            if (fieldObjectTypeData) {
              const fieldObject = fieldObjectTypeData.find(
                ({ name: n }) => n === name
              );

              if (fieldObject) {
                newLabel = fieldObject.label;
              }
            }

            if (isOutreach) {
              label = cleanOutreachFilterText(label);
              newLabel = cleanOutreachFilterText(newLabel);
            }

            return (
              <div
                className={style.channelInfoItem}
                key={`condition-${name}-${comparison.key}-${label}`}
              >
                <Icon path={mdiFilterOutline} size={1} />
                <Tag className={style.channelInfoItemSpan}>{newLabel}</Tag>
                <span className={style.channelInfoItemSpan}>
                  {IMPORT_CONDITION_TYPES_MAPPINGS[comparison.key]}
                </span>
                <Tag className={style.channelInfoItemSpan}>{label}</Tag>
              </div>
            );
          }

          if (type === CHANNEL_TYPES.INTERCEPT) {
            const {
              event: { field, value },
            } = group;
            let newLabel = field.label || field;

            if (fieldObjectTypeData) {
              const fieldObject = fieldObjectTypeData.find(
                ({ name }) => name === field
              );

              if (fieldObject) {
                newLabel = fieldObject.label;
              }
            }

            return (
              <div
                key={`condition-${field}-${value}`}
                className={style.channelInfoItem}
              >
                <Icon path={mdiFilterOutline} size={1} />
                <Tag className={style.channelInfoItemSpan}>{newLabel}</Tag>
                <span className={style.channelInfoItemSpan}>is</span>
                <Tag className={style.channelInfoItemSpan}>{value}</Tag>
              </div>
            );
          }

          if (type === CHANNEL_TYPES.UPDATE) {
            const {
              action: { field, value },
            } = group;
            let newLabel = field.label || field;

            if (fieldObjectTypeData) {
              const fieldObject = fieldObjectTypeData.find(
                ({ name }) => name === field
              );

              if (fieldObject) {
                newLabel = fieldObject.label;
              }
            }

            if (isOutreach) {
              newLabel = cleanOutreachFilterText(newLabel);
            }

            return (
              <div
                key={`condition-${field}-${value}`}
                className={style.channelInfoItem}
              >
                <Icon path={mdiFilterOutline} size={1} />
                <Tag className={style.channelInfoItemSpan}>{newLabel}</Tag>
                {!isOutreach && (
                  <>
                    <span className={style.channelInfoItemSpan}>is</span>
                    <Tag className={style.channelInfoItemSpan}>{value}</Tag>
                  </>
                )}
              </div>
            );
          }

          return null;
        })}
      </div>
    );
  }

  return (
    <div className={style.channelInfo}>
      {integrationInfo}
      <div className={cx(style.channelInfoItem, style.alert)}>
        <Icon path={mdiAlertOutline} size={1} />
        <span className={style.channelInfoItemSpan}>No filters found!</span>
      </div>
    </div>
  );
};

function useIsMeetingTypesPage() {
  const location = useLocation();
  const { pathname } = location;
  return pathname.indexOf("meetingTypes") > -1;
}

ChannelInfo.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.any).isRequired,
  integration: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired,
};

const Channel = ({ data, onClone, onEdit, onRemove, openTagManager }) => {
  const hasOrgAdminPermissions = useHasOrgAdminPermissions();
  const [, dispatchModalState] = useContext(ModalStateContext);
  const [state, dispatch] = useContext(ChannelsStateContext);
  const isMeetingTypesPage = useIsMeetingTypesPage();
  const [isOpen, setIsOpen] = useState(
    localStorage.getItem("CHANNELS_IS_OPEN_STATE") && !isMeetingTypesPage
      ? JSON.parse(localStorage.getItem("CHANNELS_IS_OPEN_STATE"))[data.id]
      : false
  );

  const fieldObjectTypeData = useMemo(() => {
    const {
      type,
      config: { object },
      integration: { name },
    } = data;

    if (state[`${type}Fields`]) {
      if (name === INTEGRATION_TYPES.HUBSPOT) {
        if (state[`${type}Fields`].get(INTEGRATION_TYPES.HUBSPOT)) {
          return state[`${type}Fields`].get(INTEGRATION_TYPES.HUBSPOT)[object];
        }
      }

      if (name === INTEGRATION_TYPES.DYNAMICS) {
        if (state[`${type}Fields`].get(INTEGRATION_TYPES.DYNAMICS)) {
          return state[`${type}Fields`].get(INTEGRATION_TYPES.DYNAMICS)[object];
        }
      }

      if (name === INTEGRATION_TYPES.SALESFORCE) {
        if (state[`${type}Fields`].get(INTEGRATION_TYPES.SALESFORCE)) {
          return state[`${type}Fields`].get(INTEGRATION_TYPES.SALESFORCE)[
            object
          ];
        }
      }
    }
    return null;
  }, [data, state]);

  useEffect(() => {
    const isOpenState = JSON.parse(
      localStorage.getItem("CHANNELS_IS_OPEN_STATE")
    );
    if (isMeetingTypesPage) {
      return;
    }
    localStorage.setItem(
      "CHANNELS_IS_OPEN_STATE",
      JSON.stringify({
        ...isOpenState,
        [data.id]: isOpen,
      })
    );
  }, [data, isOpen, isMeetingTypesPage]);

  const toggleActive = useCallback(() => {
    const { id, integration, meeting, tagAutomation, tags, ...rest } = data;
    const toggle = !data.enabled;

    dispatch({
      payload: {
        channel: {
          ...data,
          enabled: toggle,
        },
        channelType: data.type,
        id,
      },
      type: "UPDATE_CHANNEL",
    });

    patch(API.channels[rest.type](data.id), null, {
      ...rest,
      enabled: toggle,
      integration: integration.id,
      meeting: meeting.id,
      tagAutomation: {
        tagContacts: tagAutomation.tagContacts.map((tc) => tc.id),
        tagMeetings: tagAutomation.tagMeetings.map((tm) => tm.id),
      },
      tags: tags.map((t) => t.id),
    }).then((res) => res.json());
  }, [data, dispatch]);

  const toggleIsOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const handleOnEditClick = useCallback(() => {
    dispatchModalState({
      payload: {
        channelType: data.type,
        value: data,
      },
      type: "SET_SELECTED_CHANNEL",
    });

    onEdit();
  }, [onEdit, data, dispatchModalState]);

  const handleOnCloneClick = useCallback(() => onClone(data), [onClone, data]);

  const openConfirmation = useConfirmationDialog();

  const handleOnRemoveClick = useCallback(() => {
    openConfirmation(
      "Delete Confirmation",
      `You are about to delete the following channel: ${data.name}. Would you like to proceed?`,
      async () => {
        try {
          const response = await del(API.channels.default(data.id));

          if (response.ok) {
            onRemove(data.name);

            dispatch({
              payload: {
                channelId: data.id,
                channelType: data.type,
              },
              type: "DELETE_CHANNEL",
            });
          }
        } catch (error) {
          return null;
        }
      }
    );
  }, [data, onRemove, dispatch, openConfirmation]);

  const handleOnOpenTagsManager = useCallback(() => {
    openTagManager({
      channelIds: [data.id],
      channelType: data.type,
      tags: data.tags,
    });
  }, [openTagManager, data]);

  const Header = () => {
    const [open, setOpen] = useState(false);

    const toggle = (e) => {
      e.preventDefault();
      setOpen(!open);
    };

    return (
      <div className={style.modalHeaderContainer}>
        <div className={style.modalHeader}>
          {hasOrgAdminPermissions && (
            <div className={style.header_actions}>
              <ul className={style.header_actions__navigation_wrapper}>
                <li>
                  <Button
                    className={style.header_actions__navigation_wrapper_toggle}
                    type="text"
                    name={`/channels/${data.type}/channel/options`}
                    onClick={toggle}
                  >
                    <Icon
                      rotate={open ? 180 : 0}
                      path={mdiChevronDown}
                      size={1}
                    />
                  </Button>
                  <ul
                    className={cx(
                      style.header_actions__navigation_wrapper_actions,
                      {
                        [style[
                          "header_actions__navigation_wrapper_actions--is_close"
                        ]]: !open,
                      }
                    )}
                  >
                    <li
                      className={
                        style.header_actions__navigation_wrapper_actions_li
                      }
                    >
                      <Button
                        className={
                          style.header_actions__navigation_wrapper_actions_edit
                        }
                        type="text"
                        icon={mdiPencil}
                        onClick={handleOnEditClick}
                        data-rh={`Edit ${data.name}`}
                        data-rh-at="bottom"
                        name={`/channels/${data.type}/channel/edit`}
                      />
                      <Button
                        className={
                          style.header_actions__navigation_wrapper_actions_clone
                        }
                        disabled={data.meeting.name === ""}
                        icon={mdiContentDuplicate}
                        type="tertiary"
                        onClick={handleOnCloneClick}
                        data-rh={`Clone ${data.name}`}
                        data-rh-at="bottom"
                        name={`/channels/${data.type}/channel/clone`}
                      />
                      <Button
                        className={
                          style.header_actions__navigation_wrapper_actions_delete
                        }
                        type="text"
                        icon={mdiTrashCanOutline}
                        onClick={handleOnRemoveClick}
                        data-rh={`Remove ${data.name}`}
                        data-rh-at="bottom"
                        name={`/channels/${data.type}/channel/remove`}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          )}

          {INTEGRATION_IMG_SRC[data.integration.name] && (
            <img
              className={style.modal_title__icon}
              src={INTEGRATION_IMG_SRC[data.integration.name]}
              alt={data.integration.name}
            />
          )}

          {!INTEGRATION_IMG_SRC[data.integration.name] && (
            <div className={style.modal_title__icon}>
              <Icon path={mdiAlert} size={2} />
            </div>
          )}

          <div
            className={style.modalTitle}
            onClick={hasOrgAdminPermissions ? handleOnEditClick : null}
            role="button"
            tabIndex={1}
            style={{
              cursor: hasOrgAdminPermissions ? "pointer" : "auto",
            }}
          >
            <div className={style.modal__titleAndMeetingType}>
              <span className={style.modal__titleAndMeetingType_title}>
                {data.name}
              </span>

              <span className={style.modal__titleAndMeetingType_dash}>-</span>

              <span className={style.modal__titleAndMeetingType_meetingType}>
                {data.meeting.name}
              </span>
            </div>
          </div>
          {hasOrgAdminPermissions ? (
            <Switch
              labelClassName={style.channelEnableSwitchLabel}
              name={`/channels/${data.type}/channel/toggle_on_off`}
              size="small"
              isOn={data.enabled}
              handleToggle={toggleActive}
            />
          ) : (
            <div className={style.channelStateText}>
              {data.enabled ? "Active" : "Inactive"}
            </div>
          )}
        </div>

        {data.tags.length === 0 && (
          <div className={style.tagButton}>
            <TagButton
              name={`/channels/${data.type}/channel/add_tag`}
              onClick={handleOnOpenTagsManager}
            />
          </div>
        )}

        {data.tags.length > 0 && (
          <div className={style.tagList}>
            <TagList
              tags={data.tags || EMPTY_LIST}
              onClick={handleOnOpenTagsManager}
              size={0.5}
              maxCharPerRow={MAX_CHAR_ROW}
              maxRows={MAX_NUM_ROWS}
            />
          </div>
        )}

        <div className={style.modalCollapse}>
          <Button
            className={style.modalCollapseButton}
            type="text"
            name={`/channels/${data.type}/channel/toggle_open`}
            onClick={toggleIsOpen}
          >
            <Icon rotate={isOpen ? 180 : 0} path={mdiChevronDown} size={1} />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Card
      type="dark"
      header={<Header />}
      headerClassName={style.channelHeader}
      className={[style.channel]}
      contentClassName={cx(style.cardContent, {
        [style.closeChannelCollapse]: !isOpen,
        [style.openChannelCollapse]: isOpen,
      })}
    >
      <ChannelInfo
        fieldObjectTypeData={fieldObjectTypeData}
        filters={data.config.filters || data.config.triggers}
        integration={data.integration}
        type={data.type}
      />
    </Card>
  );
};

Channel.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onClone: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};

Channel.defaultProps = {
  onClone: () => {},
  onEdit: () => {},
  onRemove: () => {},
};

export default Channel;
