import { useParams } from "react-router-dom";

export default function useMeetingTemplateByUri(
  isMeetingTemplatesLoading,
  meetingTemplates
) {
  const { id } = useParams();
  if (isMeetingTemplatesLoading) {
    return null;
  }
  // TODO: Should be able to remove this
  if (meetingTemplates === null) {
    return null;
  }
  const meetingTemplate = meetingTemplates.filter((m) => m.id === parseInt(id));
  if (meetingTemplate.length > 0) {
    return meetingTemplate[0];
  }
  return null;
}
