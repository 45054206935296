import React from "react";
import { useHistory, useParams } from "react-router-dom";

import Flexbox from "../components/flexbox/FlexboxV2";
import AccordionKey from "./AccordionKey";
import Routing from "./Routing";
import TimeAttributes from "./TimeAttributes";
import {
  JumboSpacing,
  LargeSpacing,
  XLargeSpacing,
} from "../components/spacing";
import { useIsDefaultUserMeetingType, useMeetingTypeId } from "./context";
import { useActingAs, useIsDefaultUser } from "../auth";
import Distribution from "./Distribution";
import AI from "./AI";
import Unsubscribe from "./Unsubscribe";
import DefaultUserRouting from "./DefaultUserRouting";
import { ROLE_LEVELS } from "../auth/roles";

export default function Behavior({
  areTeamMembersLoading,
  teamMembers,
  teamMetadata,
}) {
  const meetingTypeId = useMeetingTypeId();
  const { tab } = useParams();
  const history = useHistory();
  const isDefaultUser = useIsDefaultUser();
  const isDefaultUserMeetingType = useIsDefaultUserMeetingType();
  const [actingAs] = useActingAs();

  // TODO(Matt): Scroll center content when clicked
  return (
    <Flexbox.Column height="100%" overflow="scroll">
      <LargeSpacing />
      {!isDefaultUser &&
        actingAs?.role !== ROLE_LEVELS.DEFAULT &&
        !isDefaultUserMeetingType && (
          <AccordionKey
            isOpen={tab === "routing"}
            name="/meetingtypes/configuration/behavior/routing"
            onClick={() =>
              history.push(
                `/meetingTypes/user/${meetingTypeId}/general/behavior/routing`
              )
            }
            text="Routing"
          >
            <Routing
              areTeamMembersLoading={areTeamMembersLoading}
              teamMembers={teamMembers}
              teamMetadata={teamMetadata}
            />
            <JumboSpacing />
          </AccordionKey>
        )}
      {(isDefaultUser ||
        actingAs?.role === ROLE_LEVELS.DEFAULT ||
        isDefaultUserMeetingType) && <DefaultUserRouting />}
      <AccordionKey
        isOpen={tab === "distribution"}
        name="/meetingtypes/configuration/behavior/distribution"
        onClick={() =>
          history.push(
            `/meetingTypes/user/${meetingTypeId}/general/behavior/distribution`
          )
        }
        text="Distribution"
      >
        <Distribution />
        <JumboSpacing />
      </AccordionKey>
      <AccordionKey
        isOpen={tab === "timeAttributes"}
        name="/meetingtypes/configuration/behavior/time_attributes"
        onClick={() =>
          history.push(
            `/meetingTypes/user/${meetingTypeId}/general/behavior/timeAttributes`
          )
        }
        text="Time Attributes"
      >
        <TimeAttributes />
      </AccordionKey>
      <AccordionKey
        isOpen={tab === "ai"}
        name="/meetingtypes/configuration/behavior/ai"
        onClick={() =>
          history.push(
            `/meetingTypes/user/${meetingTypeId}/general/behavior/ai`
          )
        }
        text="A.I."
      >
        <AI />
      </AccordionKey>
      <AccordionKey
        isOpen={tab === "unsubscribe"}
        name="/meetingtypes/configuration/behavior/unsubscribe"
        onClick={() =>
          history.push(
            `/meetingTypes/user/${meetingTypeId}/general/behavior/unsubscribe`
          )
        }
        text="Unsubscribe"
      >
        <Unsubscribe />
      </AccordionKey>
      <XLargeSpacing />
    </Flexbox.Column>
  );
}
