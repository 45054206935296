import React, { useCallback, useMemo } from "react";
import cx from "classnames";
import { mdiContentDuplicate, mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";

import Amount from "../components/amount";
import { ButtonContainer } from "../components/button";
import Flexbox from "../components/flexbox/FlexboxV2";
import { SmallSpacing, XSmallSpacing } from "../components/spacing";
import { Switch } from "../components/switch";
import { TagButton } from "../components/tagButton";
import { TagList } from "../components/tagList";
import { P1 } from "../components/typography";
import useMeetingTypeProgress from "./useMeetingTypeProgress";
import useCreationData from "./useCreationData";
import { ROLE_LEVELS } from "../auth/roles";
import { useActingAs, useIsDefaultUser } from "../auth";
import style from "./style.module.scss";

export default function MeetingTypesListItem({
  meetingType,
  onClickCopy,
  onClickDelete,
  onClickSelect,
  openTags,
  renderIcon,
  selectedMeetingTypeId,
  showCopyButton,
  showDeleteButton,
  updateMeetingType,
  style: listItemStyle,
}) {
  const complete = useMeetingTypeProgress({ meetingType });
  const tags = useMemo(() => {
    return meetingType.tags;
  }, [meetingType.tags]);
  const isActive = useMemo(() => {
    return meetingType.active;
  }, [meetingType.active]);
  const isSelectedMeetingType = useMemo(() => {
    return selectedMeetingTypeId === meetingType.id;
  }, [selectedMeetingTypeId, meetingType]);
  const creationData = useCreationData({ meetingType });
  const isDefaultUser = useIsDefaultUser();
  const [actingAs] = useActingAs();

  const tagsDisabled = useMemo(
    () =>
      (isDefaultUser || actingAs?.role === ROLE_LEVELS.DEFAULT) &&
      creationData.userRole !== ROLE_LEVELS.DEFAULT,
    [actingAs, creationData, isDefaultUser]
  );
  // Only disable activation if for types by checking tag function
  const disabled = useMemo(() => {
    return (
      (!isActive && complete < 100 && openTags !== null) ||
      // default users cannot toggle meeting templates that do not belong to them
      tagsDisabled
    );
  }, [complete, isActive, openTags, tagsDisabled]);

  const toggleActive = useCallback(() => {
    if (!isSelectedMeetingType) {
      return;
    }
    // TODO(Matt): This could use some cleanup, what is the point of that third field for example?
    updateMeetingType(
      meetingType.id,
      {
        ...meetingType,
        active: !isActive,
      },
      false
    );
  }, [isActive, isSelectedMeetingType, meetingType, updateMeetingType]);

  const setMeetingType = useCallback(() => {
    onClickSelect(meetingType.id);
  }, [meetingType, onClickSelect]);
  const listItemProps = {
    onClick: setMeetingType,
    onKeyDown: setMeetingType,
    role: "button",
    tabIndex: 0,
  };
  if (isSelectedMeetingType) {
    listItemProps.onClick = null;
    listItemProps.onKeyDown = null;
    listItemProps.role = null;
    listItemProps.tabIndex = null;
  }

  return (
    <div style={listItemStyle}>
      <div
        className={cx(style.meetingTypes__listItem, {
          [style["meetingTypes__listItem--highlighted"]]: isSelectedMeetingType,
        })}
        {...listItemProps}
      >
        <SmallSpacing />
        <Flexbox.Row alignItems="center" justifyContent="space-between">
          <P1 className={style.meetingTypes__listItemName} bold small>
            {meetingType.name}
          </P1>
          <Switch
            disabled={disabled}
            name="/meetingtypes/my_types/toggle_on_off"
            size="xsmall"
            isOn={isActive}
            handleToggle={toggleActive}
          />
        </Flexbox.Row>
        <SmallSpacing />
        {renderIcon(meetingType)}
        <XSmallSpacing />
        <Flexbox.Row alignItems="center">
          {openTags !== null && (
            <>
              {tags && tags.length > 0 && (
                <TagList
                  disabled={tagsDisabled}
                  tags={tags}
                  maxRows={1}
                  onClick={openTags}
                  size={0.5}
                />
              )}
              {!tags ||
                (tags.length === 0 && (
                  <TagButton
                    disabled={tagsDisabled}
                    name="/meetingtypes/my_types/add_tag"
                    onClick={openTags}
                    tags={tags}
                  />
                ))}
            </>
          )}
          <div style={{ flex: 1 }} />
          <P1 bold small>
            <Amount value={meetingType.cost} />
          </P1>
          {isSelectedMeetingType && (
            <>
              {showCopyButton && (
                <>
                  <SmallSpacing />
                  <ButtonContainer
                    className={style.meetingType__listItemButton}
                    name="/meetingtypes/my_types/clone"
                    onClick={() => {
                      onClickCopy(meetingType.id);
                    }}
                    transparent
                  >
                    <Icon path={mdiContentDuplicate} size={1} />
                  </ButtonContainer>
                </>
              )}
              {showDeleteButton && !tagsDisabled && (
                <>
                  <XSmallSpacing />
                  <ButtonContainer
                    className={style.meetingType__listItemButton}
                    name="/meetingtypes/my_types/trash"
                    onClick={() => {
                      onClickDelete(meetingType.id);
                    }}
                    transparent
                    disabled={tagsDisabled}
                  >
                    <Icon path={mdiTrashCan} size={1} />
                  </ButtonContainer>
                </>
              )}
            </>
          )}
        </Flexbox.Row>
        <SmallSpacing />
      </div>
    </div>
  );
}
