import React, { useState } from "react";

import Dialog from "./Dialog";
import Input from "../inputs/Input";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";

export function CloneTeamDialog({
  team,
  open,
  onSubmit,
  onClose,
}: {
  team: { id: number; name: string };
  open: boolean;
  onSubmit?: (v: { id: number; name: string }) => void;
  onClose?: () => void;
}) {
  const [name, setName] = useState(team.name + " (copy)");

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Clone Team"
      actions={
        <>
          <PrimaryButton
            onClick={() => onSubmit && onSubmit({ id: team.id, name })}
          >
            Save
          </PrimaryButton>
          <SecondaryButton onClick={() => onClose && onClose()}>
            Cancel
          </SecondaryButton>
        </>
      }
    >
      <Input
        label="Team Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </Dialog>
  );
}

export default CloneTeamDialog;
