import DeleteIcon from "@mui/icons-material/Delete";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import HighEmphasisButton from "src/componentsV2/buttons/HighEmphasisButton";
import {
  GoogleIcon,
  MicrosoftIcon,
  SettingsIcon,
  WarningIcon,
} from "src/icons";
import { INTEGRATION_AUTH_STATUS } from "src/integrations/props";
import { determineAuthStatus } from "src/integrations/util";
import { Spinner } from "../../../components/Spinner";
import AccountDeleteModal from "../../../componentsV2/AccountDeleteModal";
import AccountDisconnectModal from "../../../componentsV2/AccountDisconnectModal";
import AccountReconnectModal from "../../../componentsV2/AccountReconnectModal";
import PrimaryButton from "../../../componentsV2/buttons/PrimaryButton";
import SecondaryButton from "../../../componentsV2/buttons/SecondaryButton";
import useGeneralNotifications from "../../../hooks/useGeneralNotifications";
import { useUsersRepository } from "../../../repository";

const MAX_USER_ACCOUNTS = 20;

export type Account = {
  email: string;
  userId: number;
  tokenInfo: {
    tokenType: string;
  };
};

function Accounts() {
  const history = useHistory();
  const { getUserAccounts, getAddUserAccountURL, disconnectUserAccount } =
    useUsersRepository();
  const { addError, addGeneralNotification } = useGeneralNotifications();

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<Account>();
  const [loading, setLoading] = useState(false);
  const [openDisconnect, setOpenDisconnect] = useState(false);
  const [openReconnect, setOpenReconnect] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState<Account | null>(null);

  const fetchAccounts = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getUserAccounts();
      setAccounts(response);
    } catch {
      addError("Unable to retrieve user accounts at this time");
    }

    setLoading(false);
  }, [addError, getUserAccounts]);

  useEffect(() => {
    fetchAccounts();
  }, [fetchAccounts]);

  return (
    <>
      <AccountDeleteModal
        open={deleteAccount !== null}
        account={deleteAccount}
        fetchAccounts={fetchAccounts}
        onClose={() => {
          setDeleteAccount(null);
        }}
      />
      {loading && <Spinner />}
      {!loading && (
        <Card>
          <CardHeader
            title={
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4">Accounts</Typography>

                {/* Need to separate the Tooltip rendering like this. Newer versions of MUI support not showing tooltip if a falsy is returned, but the current one doesn't */}
                {accounts?.length < MAX_USER_ACCOUNTS && (
                  <PrimaryButton
                    onClick={() => history.push("/settings/accounts/add")}
                  >
                    + New Account
                  </PrimaryButton>
                )}

                {accounts?.length >= MAX_USER_ACCOUNTS && (
                  <Tooltip
                    title={`Maximum number of accounts (${MAX_USER_ACCOUNTS}) reached`}
                  >
                    <span>
                      <PrimaryButton disabled={true}>
                        + New Account
                      </PrimaryButton>
                    </span>
                  </Tooltip>
                )}
              </Stack>
            }
          />
          <CardContent>
            {(!accounts || accounts.length < 1) && (
              <Typography>No authorized accounts found</Typography>
            )}

            <Stack sx={{ gap: 2 }}>
              {accounts &&
                accounts.length > 0 &&
                accounts.map((account, idx) => {
                  const authStatus = determineAuthStatus(account);
                  const isParentAccount = idx === 0;

                  return (
                    <Stack
                      key={`account-${idx + 1}`}
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Stack
                        sx={{
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 3,
                        }}
                      >
                        <Stack direction="row">
                          <SecondaryButton
                            onClick={() =>
                              history.push(
                                `/settings/accounts/${account.userId}`
                              )
                            }
                            size="small"
                            icon={<SettingsIcon />}
                          >
                            Manage
                          </SecondaryButton>

                          <Tooltip
                            title={
                              isParentAccount
                                ? "Cannot delete main account"
                                : "Delete Account"
                            }
                            placement="left"
                          >
                            {/* per MUI: https://mui.com/material-ui/react-tooltip/#disabled-elements, 
                          we must wrap a potentially disabled <Button /> in a <span /> in order to trigger the Tooltip */}
                            <span>
                              <IconButton
                                onClick={() => {
                                  setDeleteAccount(account);
                                }}
                                size="small"
                                sx={{
                                  border: isParentAccount
                                    ? "1px solid rgba(0, 0, 0, 0.26)"
                                    : "1px solid rgba(255, 0, 0, 0.5)",
                                  marginLeft: 1,
                                }}
                                // cannot delete parent account
                                disabled={idx === 0}
                              >
                                <DeleteIcon
                                  fontSize="small"
                                  color={isParentAccount ? undefined : "error"}
                                />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Stack>
                        <Stack
                          sx={{
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          {authStatus !== INTEGRATION_AUTH_STATUS.DISABLED && (
                            <>
                              {account?.tokenInfo?.tokenType ===
                                "microsoft" && <MicrosoftIcon size="large" />}
                              {account?.tokenInfo?.tokenType === "google" && (
                                <GoogleIcon size="large" />
                              )}
                            </>
                          )}

                          {authStatus === INTEGRATION_AUTH_STATUS.DISABLED && (
                            <WarningIcon color="warning" fontSize="large" />
                          )}
                          <Stack direction="column">
                            <Typography>{account.email}</Typography>
                          </Stack>
                        </Stack>
                      </Stack>

                      {authStatus === INTEGRATION_AUTH_STATUS.DISABLED && (
                        <Stack
                          sx={{
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Typography
                            sx={{ color: "error.main" }}
                            variant="body2"
                          >
                            Disconnected
                          </Typography>

                          {/* Can we please provide the account provider in the `account` object? */}
                          <SecondaryButton
                            onClick={() => {
                              setSelectedAccount(account);
                              setOpenReconnect(true);
                            }}
                            size="small"
                          >
                            Reconnect
                          </SecondaryButton>
                        </Stack>
                      )}
                      {authStatus === INTEGRATION_AUTH_STATUS.CURRENT && (
                        <Stack
                          sx={{
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Typography>Connected</Typography>
                          <HighEmphasisButton
                            onClick={() => {
                              setSelectedAccount(account);
                              setOpenDisconnect(true);
                            }}
                            size="small"
                          >
                            Disconnect
                          </HighEmphasisButton>
                        </Stack>
                      )}
                      {authStatus === INTEGRATION_AUTH_STATUS.EXPIRED && (
                        <Stack
                          sx={{
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Typography variant="body2">Expired</Typography>
                          <SecondaryButton
                            size="small"
                            onClick={async () => {
                              // reauthorize is for updating authorization on a currently connected (but expired) account
                              try {
                                const response = await getAddUserAccountURL({
                                  client: account.tokenInfo.tokenType,
                                  email: account.email,
                                });
                                if (response) {
                                  window.location.href = response;
                                }
                              } catch {
                                addError(
                                  "An error occured while trying to reauthorize the account."
                                );
                              }
                            }}
                          >
                            Reauthorize
                          </SecondaryButton>

                          <HighEmphasisButton
                            onClick={() => {
                              setSelectedAccount(account);
                              setOpenDisconnect(true);
                            }}
                            size="small"
                          >
                            Disconnect
                          </HighEmphasisButton>
                        </Stack>
                      )}
                    </Stack>
                  );
                })}
            </Stack>
          </CardContent>
        </Card>
      )}
      <AccountDisconnectModal
        account={selectedAccount}
        open={openDisconnect}
        onDisconnect={async () => {
          try {
            await disconnectUserAccount({
              userId: selectedAccount?.userId,
            });
            addGeneralNotification("Authorization successfully removed");
            fetchAccounts();
          } catch {
            addError(
              "Unable to disconnect account at this time, please try again later"
            );
          }
          setOpenDisconnect(false);
        }}
        onClose={() => setOpenDisconnect(false)}
      />

      <AccountReconnectModal
        account={selectedAccount}
        open={openReconnect}
        onClose={() => setOpenReconnect(false)}
        onReconnect={async (client) => {
          try {
            const response = await getAddUserAccountURL({
              client,
              email: selectedAccount?.email,
            });
            if (response) {
              window.location.href = response;
            }
          } catch {
            addError(
              "An error occured while attempting to reconnect the account."
            );
          }
          setOpenReconnect(false);
        }}
      />
    </>
  );
}

export default Accounts;
