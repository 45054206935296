import React from "react";

import Flexbox from "../components/flexbox/FlexboxV2";
import { useModalV2 } from "../components/modal";
import {
  JumboSpacing,
  LargeSpacing,
  SmallSpacing,
  XXLargeSpacing,
} from "../components/spacing";
import { TagButton } from "../components/tagButton";
import { TagList } from "../components/tagList";
import { H6 } from "../components/typography";
import AutomationContactsTagManager from "./AutomationContactsTagManager";
import AutomationMeetingsTagManager from "./AutomationMeetingsTagManager";
import AutomationUsersTagManager from "./AutomationUsersTagManager";
import { useCanEdit } from "./context";

export default function AutoTagging({ meetingType, setMeetingTypes }) {
  const {
    tagContacts = [],
    tagMeetings = [],
    tagUsers = [],
  } = meetingType.tagAutomation || {};
  const [AutomationContactsTagManagerModal, openContactsTagManager] =
    useModalV2(AutomationContactsTagManager);
  const [AutomationMeetingsTagManagerModal, openMeetingsTagManager] =
    useModalV2(AutomationMeetingsTagManager);
  const [AutomationUsersTagManagerModal, openUsersTagManager] = useModalV2(
    AutomationUsersTagManager
  );
  const canEditMeetingType = useCanEdit();

  const openContactsTags = () => {
    if (!canEditMeetingType) {
      return;
    }

    openContactsTagManager();
  };

  const openMeetingsTags = () => {
    if (!canEditMeetingType) {
      return;
    }

    openMeetingsTagManager();
  };

  const openUsersTags = () => {
    if (!canEditMeetingType) {
      return;
    }

    openUsersTagManager();
  };

  return (
    <Flexbox.Column>
      <XXLargeSpacing />
      <Flexbox.Row>
        <JumboSpacing />
        <H6>Auto Tag Meetings, Contacts, and Users*</H6>
      </Flexbox.Row>
      <LargeSpacing />
      <Flexbox.Row>
        <JumboSpacing />
        <Flexbox.Row alignItems="center">
          <AutomationMeetingsTagManagerModal
            setMeetingTypes={setMeetingTypes}
          />
          <H6>Meetings</H6>
          <SmallSpacing />
          {tagMeetings.length === 0 && (
            <TagButton
              name="/meetingtypes/auto_tagging/instances"
              onClick={openMeetingsTags}
              disabled={!canEditMeetingType}
            />
          )}
          {tagMeetings.length > 0 && (
            <TagList
              disabled={!canEditMeetingType}
              tags={tagMeetings}
              maxRows={2}
              onClick={openMeetingsTags}
              size={0.5}
            />
          )}
        </Flexbox.Row>
        <Flexbox.Row alignItems="center">
          <AutomationContactsTagManagerModal
            setMeetingTypes={setMeetingTypes}
          />
          <H6>Contacts</H6>
          <SmallSpacing />
          {tagContacts.length === 0 && (
            <TagButton
              name="/meetingtypes/auto_tagging/contacts"
              onClick={openContactsTags}
              disabled={!canEditMeetingType}
            />
          )}
          {tagContacts.length > 0 && (
            <TagList
              disabled={!canEditMeetingType}
              tags={tagContacts}
              maxRows={2}
              onClick={openContactsTags}
              size={0.5}
            />
          )}
        </Flexbox.Row>
      </Flexbox.Row>
      <Flexbox.Row>
        <JumboSpacing />
        <Flexbox.Row alignItems="center">
          <AutomationUsersTagManagerModal setMeetingTypes={setMeetingTypes} />
          <H6>Users</H6>
          <SmallSpacing />
          {tagUsers.length === 0 && (
            <TagButton
              name="/meetingtypes/auto_tagging/users"
              onClick={openUsersTags}
              disabled={!canEditMeetingType}
            />
          )}
          {tagUsers.length > 0 && (
            <TagList
              disabled={!canEditMeetingType}
              tags={tagUsers}
              maxRows={2}
              onClick={openUsersTags}
              size={0.5}
            />
          )}
        </Flexbox.Row>
      </Flexbox.Row>
    </Flexbox.Column>
  );
}
