import React from "react";
import { useDistribution } from "../context";
import FrontLoadPreview from "./FrontLoadPreview";
import RandomHighVolumePreview from "./RandomHighVolumePreview";

export default function DistributionPreview() {
  const [distribution] = useDistribution();
  switch (distribution) {
    case "sequential":
      return <FrontLoadPreview />;
    case "random_high_volume":
      return <RandomHighVolumePreview />;
    default:
      return <div>No preview for distribution type available.</div>;
  }
}
