import React, { useMemo } from "react";
import { Box, Stack, Switch } from "@mui/material";
import { useCanEdit, useUnsubscribeConfig } from "./context";
import {
  MediumSpacing,
  SmallSpacing,
  XLargeSpacing,
  XSmallSpacing,
  XXLargeSpacing,
} from "../components/spacing";
import Step from "./Step";
import Input from "../componentsV2/inputs/Input";
import { useCurrentOrgName } from "../auth";
import style from "./style.module.scss";

const LINK_DYNAMIC_VAR = "{{link}}";

function Unsubscribe() {
  const [unsubscribeConfig, setUnsubscribeConfig] = useUnsubscribeConfig();
  const canEditMeetingType = useCanEdit();
  const orgName = useCurrentOrgName();

  const defaultUnsubscribeBody = useMemo(
    () => `If you do not wish to receive meeting requests from ${orgName}`,
    [orgName]
  );

  const defaultUnsubscribeClause = useMemo(
    () => (
      <div className={style.unsubscribeInfo}>
        *If both fields are left blank, the default Unsubscribe clause will be
        used:
        <XSmallSpacing />
        {`${defaultUnsubscribeBody}, `}
        <span className={style.linkText}>Click here</span>
      </div>
    ),
    [defaultUnsubscribeBody]
  );

  const buildPreview = useMemo(() => {
    if (!unsubscribeConfig.enabled) {
      return;
    }

    if (
      unsubscribeConfig.hyperlinkText.length < 1 &&
      unsubscribeConfig.body.length < 1
    ) {
      // in this case, unsubscribe is enabled, but the user has not customized the message, so we use the default
      return defaultUnsubscribeClause;
    }

    // build link text
    let linkText = "Click here";
    // check to see if user customized hyperlink text
    if (unsubscribeConfig.hyperlinkText.trim().length > 0) {
      // if so, use that
      linkText = unsubscribeConfig.hyperlinkText;
    }

    // check to see if user customized the body
    let bodyText = "";
    if (unsubscribeConfig.body.length > 0) {
      // if so, use that
      bodyText = unsubscribeConfig.body;
    } else {
      // if not, we are done here
      return (
        <div className={style.unsubscribeInfo}>
          {`${defaultUnsubscribeBody}, `}
          <span className={style.linkText}>{linkText}</span>
        </div>
      );
    }

    // if we are here, user has customized both parts of the unsubscribe clause
    // check to see if they've utilized the link dynamic variable `{{link}}`
    if (!unsubscribeConfig.body.includes(LINK_DYNAMIC_VAR)) {
      // if not, we put their custom link text at the end of the custom body and are done
      return (
        <div className={style.unsubscribeInfo}>
          {`${bodyText} `}
          <span className={style.linkText}>{linkText}</span>
        </div>
      );
    }

    // in the event they have used the `{{link}}` variable, we replace it in all locations
    const fullText = bodyText.replaceAll(
      LINK_DYNAMIC_VAR,
      // for dangerously setting inner HTML, we must use `class` instead of `className` in order to attach an SCSS class
      `<span class=${style.linkText}>${linkText}</span>`
    );

    return (
      <div
        className={style.unsubscribeInfo}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: fullText }}
      />
    );
  }, [defaultUnsubscribeBody, defaultUnsubscribeClause, unsubscribeConfig]);

  return (
    <Box>
      <XXLargeSpacing />
      <Stack>
        <Step
          description="Should Kronologic include an Unsubscribe clause on emails of this Meeting Template?"
          stepNumber={1}
        />
        <MediumSpacing />
        <Stack direction="row" alignItems="center">
          <XXLargeSpacing />
          No
          <SmallSpacing />
          <Switch
            checked={unsubscribeConfig.enabled}
            disabled={!canEditMeetingType}
            name="/meetingtypes/configuration/behavior/unsubscribe/toggle_on_off"
            onChange={() => {
              setUnsubscribeConfig({
                ...unsubscribeConfig,
                enabled: !unsubscribeConfig.enabled,
              });
            }}
          />
          <SmallSpacing />
          Yes
        </Stack>
        <XXLargeSpacing />
        {unsubscribeConfig.enabled && (
          <>
            Optional:*
            <SmallSpacing />
            {defaultUnsubscribeClause}
            <XLargeSpacing />
            <Step
              description="What should the body of the Unsubscribe clause say?"
              stepNumber={2}
            />
            <div className={style.unsubscribeHelperText}>
              {`Use ${LINK_DYNAMIC_VAR} to signify where you'd like the hyperlink text to appear (Default: End of body)`}
            </div>
            <MediumSpacing />
            <Input
              label="Body"
              value={unsubscribeConfig.body}
              clearable
              onChange={(e) => {
                setUnsubscribeConfig({
                  ...unsubscribeConfig,
                  body: e.target.value,
                });
              }}
            />
            <XXLargeSpacing />
            <Step
              description="What should the hyperlink say? (Default: Click here)"
              stepNumber={3}
            />
            <MediumSpacing />
            <Input
              label="Hyperlink Text"
              value={unsubscribeConfig.hyperlinkText}
              clearable
              onChange={(e) => {
                setUnsubscribeConfig({
                  ...unsubscribeConfig,
                  hyperlinkText: e.target.value,
                });
              }}
            />
            {(unsubscribeConfig.body.length > 0 ||
              unsubscribeConfig.hyperlinkText.length > 0) && (
              <div>
                <XXLargeSpacing />
                <>Preview:</>
                {buildPreview}
              </div>
            )}
          </>
        )}
      </Stack>
    </Box>
  );
}

export default Unsubscribe;
