import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";

import Flexbox from "../components/flexbox/FlexboxV2";
import { H2 } from "../components/typography";
import { JumboSpacing } from "../components/spacing";
import useNewMeetingType from "./useNewMeetingType";

export default function CreateMeetingType({ onCreate }) {
  const history = useHistory();
  const { loading, build } = useNewMeetingType();
  const createNewMeetingType = useCallback(async () => {
    if (loading) {
      return;
    }
    const newMeetingType = build();
    const createdMeetingType = await onCreate(newMeetingType);
    history.push(`/meetingTypes/user/${createdMeetingType.id}/general/invite`);
  }, [build, history, loading, onCreate]);
  useEffect(() => {
    createNewMeetingType();
  }, [createNewMeetingType]);
  return (
    <Flexbox.Column alignItems="center" justifyContent="center">
      <Icon path={mdiLoading} size={8} spin={1} />
      <JumboSpacing />
      <H2>Creating new meeting template...</H2>
    </Flexbox.Column>
  );
}
