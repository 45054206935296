import dayjs from "dayjs";

// this is used to determine if the current message is a future queue message
const SEND_LATER_NOTE = "Queued via Send Later to execute";
const STARTING_INDEX_OF_TIMESTAMP = SEND_LATER_NOTE.length + 1;

export function cleanNote(note) {
  if (!note.includes(SEND_LATER_NOTE)) {
    return note;
  }

  // if the note is a future queue note, convert timestamp to user tz
  const timestamp = note.slice(STARTING_INDEX_OF_TIMESTAMP);

  const inUserTz = dayjs(timestamp).format("dddd, MMMM D YYYY [at] hh:mmA");

  return `${SEND_LATER_NOTE} ${inUserTz}`;
}
