import { FormLabel, Stack } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { CalendarTokenStatusFilter } from "src/importExport/export/filters/CalendarTokenStatusFilter";
import RoleFilter from "src/importExport/export/filters/RoleFilter";
import { UserStatusFilter } from "src/importExport/export/filters/UserStatusFilter";
import { Loading } from "../../../../componentsV2/Loading";
import { PageLayout } from "../../../../componentsV2/PageLayout";
import PrimaryButton from "../../../../componentsV2/buttons/PrimaryButton";
import Input from "../../../../componentsV2/inputs/Input";
import { useUrlQuery } from "../../../../hooks";
import useGeneralNotifications from "../../../../hooks/useGeneralNotifications";
import TagFilter from "../../../../importExport/export/filters/TagFilter";
import TeamSelectInput from "../../../../importExport/export/filters/TeamSelectFilter";
import { useExportUsers } from "../../../../mutations";
import {
  CalendarTokenStatus,
  DefaultUserRole,
  OrgAdminRole,
  Role,
  Tag,
  Team,
  TeamAdminRole,
  UserStatus,
} from "../../../../types";

// ExportUsers manages state for the entire export process
function ExportUsers() {
  const query = useUrlQuery();

  let initialTags;
  try {
    initialTags = JSON.parse(query.tags);
  } catch {
    // do nothing
  }

  let initialTeams;
  try {
    initialTeams = JSON.parse(query.teams);
  } catch {
    // do nothing
  }

  // Make sure the status query parameter is one of the few allowed values.
  const initialStatus: UserStatus[] = [];
  if (query.status?.includes("active")) {
    initialStatus.push("active");
  }
  if (query.status?.includes("pending")) {
    initialStatus.push("pending");
  }

  // Make sure the token status query parameter is one of the few allowed values.
  const initialTokenStatus: CalendarTokenStatus[] = [];
  if (query.calendarTokenStatus?.includes("current")) {
    initialTokenStatus.push("current");
  }
  if (query.calendarTokenStatus?.includes("expired")) {
    initialTokenStatus.push("expired");
  }
  if (query.calendarTokenStatus?.includes("disabled")) {
    initialTokenStatus.push("disabled");
  }

  // Make sure the token status query parameter is one of the few allowed values.
  const initialRoles: Role[] = [];
  if (query.roles?.split(",").includes(DefaultUserRole.toString())) {
    initialRoles.push(DefaultUserRole);
  }
  if (query.roles?.split(",").includes(TeamAdminRole.toString())) {
    initialRoles.push(TeamAdminRole);
  }
  if (query.roles?.split(",").includes(OrgAdminRole.toString())) {
    initialRoles.push(OrgAdminRole);
  }

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(query.email || "");
  const [firstName, setFirstName] = useState(query.firstName || "");
  const [lastName, setLastName] = useState(query.lastName || "");
  const [teams, setTeams] = useState<Team[]>(initialTeams || []);
  const [tags, setTags] = useState<Tag[]>(initialTags || []);
  const [status, setStatus] = useState<UserStatus[]>(initialStatus);
  const [roles, setRoles] = useState<Role[]>(initialRoles || []);
  const [tokenStatus, setTokenStatus] =
    useState<CalendarTokenStatus[]>(initialTokenStatus);

  const history = useHistory();
  const exportUsers = useExportUsers();
  const { addError, addGeneralNotification } = useGeneralNotifications();

  const startProcess = async () => {
    setLoading(true);
    try {
      await exportUsers({
        calendarTokenStatusFilter: tokenStatus,
        firstName,
        lastName,
        email,
        role: roles,
        selectedTeams: teams.map((team) => team.id),
        statusFilter: status,
        tagFilter: tags.map((tag) => tag.id),
        sortBy: {
          field: "lastName",
          order: "asc",
        },
      });

      addGeneralNotification("Request submitted successfully");
      history.push("/reports");
    } catch {
      addError("We are unable to process this request. Please try again later");
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageLayout
      title="Export Users"
      breadcrumbs={[
        { name: "Reports", route: "/reports" },
        { name: "Export", route: "/reports/export" },
        { name: "Users", route: "/reports/export/users" },
      ]}
    >
      <Stack spacing={2}>
        <FormLabel>Search</FormLabel>
        <Input
          label="First Name"
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
        />
        <Input
          label="Last Name"
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
        />
        <Input
          label="Email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />

        <FormLabel>Filter</FormLabel>
        <UserStatusFilter value={status} onChange={setStatus} />
        <TeamSelectInput value={teams} onChange={setTeams} />
        <RoleFilter value={roles} onChange={setRoles} />
        <CalendarTokenStatusFilter
          value={tokenStatus}
          onChange={setTokenStatus}
        />
        <TagFilter value={tags} onChange={setTags} />
        {loading ? (
          <Loading />
        ) : (
          <PrimaryButton onClick={startProcess} disabled={loading}>
            Export
          </PrimaryButton>
        )}
      </Stack>
    </PageLayout>
  );
}

export default ExportUsers;
