import moment from "moment";
import useSWR from "swr";

import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useDedupeInterval } from "../configuration";
import { useErrorHandler, useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { MeetingStatistics } from "../types";

export function useMeetingStats(search: any, { tags }: any) {
  const accessToken = getUserToken();
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const errorHandler = useErrorHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const { filter } = search;
  const {
    dateFilter = null,
    meetingStatusFilter,
    meetingTypeFilter,
    tagFilter,
    query,
    ...remaining
  } = filter;
  const formattedFetchSetting = {
    ...search,
    filter: {
      ...remaining,
    },
  };
  // dateFilter is null for timeline searches
  if (dateFilter !== null) {
    const { by = "startTime" } = dateFilter;
    let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (tz === undefined) {
      tz = "UTC";
    }
    formattedFetchSetting.filter[
      by === "startTime" ? "dateFilter" : "lastActivityFilter"
    ] = {
      end: dateFilter.end
        ? moment(dateFilter.end).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      start: dateFilter.start
        ? moment(dateFilter.start).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      timezone: tz,
    };
  }
  if (tagFilter.length > 0) {
    formattedFetchSetting.filter.tagFilter = tagFilter.map(
      (tag: any) => tag.id
    );
  }
  if (meetingTypeFilter.length > 0) {
    formattedFetchSetting.filter.meetingTypeFilter = meetingTypeFilter.map(
      (meetingType: any) => meetingType.id
    );
  }
  if (meetingStatusFilter.length > 0) {
    formattedFetchSetting.filter.meetingStatusFilter = meetingStatusFilter;
  }
  if (query.length > 0) {
    formattedFetchSetting.filter.query = query;
  }
  // `id` is a field only used by the UI
  delete formattedFetchSetting.id;

  const interval = useDedupeInterval();

  const { data, error } = useSWR(
    [`/api/meetings/instances/report`, formattedFetchSetting],
    (url: string, body: object) =>
      service
        .post(url)
        .set(headers)
        .send(body)
        .then(tokenRefreshHandler)
        .then((res: Response) => res.body)
        .catch(errorHandler),
    { dedupingInterval: interval, refreshInterval: 60000 }
  );

  return {
    data: data as MeetingStatistics | undefined,
    error,
    loading: !error && !data,
  };
}
