import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { mdiLoading, mdiTextBox } from "@mdi/js";
import Icon from "@mdi/react";

import Flexbox from "../components/flexbox/FlexboxV2";
import { P1, H2 } from "../components/typography";
import { JumboSpacing, MediumSpacing } from "../components/spacing";
import { ButtonContainer } from "../components/button";
import { useSelectedMeetingType } from "./context";

export default function DeleteMeetingType({ onDelete }) {
  const { id } = useParams();
  const history = useHistory();
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedMeetingType] = useSelectedMeetingType();
  const deleteMeetingType = useCallback(async () => {
    await onDelete(parseInt(id));
    history.push(`/meetingTypes/user`);
  }, [history, id, onDelete]);
  useEffect(() => {
    async function run() {
      deleteMeetingType();
    }
    if (isDeleting) {
      run();
    }
  }, [deleteMeetingType, isDeleting]);
  if (isDeleting) {
    return (
      <Flexbox.Column alignItems="center" justifyContent="center">
        <Icon path={mdiLoading} size={8} spin={1} />
        <JumboSpacing />
        <H2>Deleting meeting template...</H2>
      </Flexbox.Column>
    );
  }
  return (
    <Flexbox.Column alignItems="center" justifyContent="center">
      <Icon path={mdiTextBox} size={8} />
      <JumboSpacing />
      <P1>You are about to delete the following meeting template:</P1>
      <MediumSpacing />
      <H2>{`${selectedMeetingType?.name}`}</H2>
      <MediumSpacing />
      <P1>Would you like to proceed?</P1>
      <JumboSpacing />
      <Flexbox.Row justifyContent="center">
        <ButtonContainer
          medium
          primary
          name="/meetingtypes/my_types/trash/proceed"
          onClick={() => {
            setIsDeleting(true);
          }}
        >
          Proceed
        </ButtonContainer>
        <JumboSpacing />
        <ButtonContainer
          medium
          secondary
          name="/meetingtypes/my_types/trash/decline"
          onClick={() => {
            history.push(`/meetingTypes/user/${id}/general/invite`);
          }}
        >
          Decline
        </ButtonContainer>
      </Flexbox.Row>
    </Flexbox.Column>
  );
}
