import React from "react";
import Range from "rc-slider/lib/Range";

import Flexbox from "../../components/flexbox/FlexboxV2";
import { P1 } from "../../components/typography";
import { useCanEdit, useDayRange } from "../context";

import { SmallSpacing, XSmallSpacing } from "../../components/spacing";
import useRailColor from "./useRailColor";
import style from "../style.module.scss";

export default function DayRange() {
  const [{ from, to }, setDayRange] = useDayRange();
  const canEditMeetingType = useCanEdit();
  const { railColor } = useRailColor();
  return (
    <Flexbox.Column>
      <div className={style.meetingType__slider}>
        <Range
          disabled={!canEditMeetingType}
          onAfterChange={(values) => {
            setDayRange({
              from: values[0],
              to: values[1],
            });
          }}
          allowCross={false}
          step={1}
          min={0}
          max={30}
          defaultValue={[from, to]}
          railStyle={{ backgroundColor: railColor }}
        />
      </div>
      <SmallSpacing />
      <Flexbox.Row>
        <XSmallSpacing />
        <P1 small>{`${from} to ${to} days out`}</P1>
      </Flexbox.Row>
    </Flexbox.Column>
  );
}
