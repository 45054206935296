import React from "react";
import DOMPurify from "dompurify";

import Flexbox from "../../components/flexbox/FlexboxV2";
import {
  JumboSpacing,
  XXLargeSpacing,
  LargeSpacing,
  MediumSpacing,
  SmallSpacing,
} from "../../components/spacing";
import { P1, H6 } from "../../components/typography";
import { useInviteLocation, useInviteTitle, useInviteNotes } from "../context";
import useDynamicVariableMappings from "../useDynamicVariableMappings";
import style from "../style.module.scss";

const sanitizer = DOMPurify.sanitize;

export default function InvitePreview() {
  const [title] = useInviteTitle();
  const [location] = useInviteLocation();
  const [notes] = useInviteNotes();
  const compiledTitle = useDynamicVariableMappings(title);
  const compiledLocation = useDynamicVariableMappings(location);
  const compiledNotes = useDynamicVariableMappings(notes);
  return (
    <Flexbox.Row
      className={style.meetingType__invitePreview}
      height="fit-content"
      flex={1}
    >
      <LargeSpacing />
      <Flexbox.Column>
        <MediumSpacing />
        <Flexbox.Row justifyContent="center">
          <H6>Preview</H6>
        </Flexbox.Row>
        <JumboSpacing />
        <H6>{compiledTitle}</H6>
        <SmallSpacing />
        <P1 className={style.meetingType__locatiton} small bold>
          {compiledLocation}
        </P1>
        <XXLargeSpacing />
        <P1>
          <div
            className={style.meetingType__inviteNotesPreview}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: sanitizer(compiledNotes),
            }}
          />
        </P1>
        <XXLargeSpacing />
      </Flexbox.Column>
      <LargeSpacing />
    </Flexbox.Row>
  );
}
