import React, { useEffect, useMemo, useState } from "react";
import { Box, Stack, TextField, Typography } from "@mui/material";

import { Contact, ContactWithDetails } from "../../types";
import { useContact } from "../../queries";
import { Loading } from "../Loading";
import ContactCard from "../ContactCard";
import { useTagContacts, useUpdateContact } from "../../mutations";
import { useSnackbar } from "../../hooks";
import { TagList } from "../TagList";
import Drawer from "./Drawer";
import SecondaryButton from "../buttons/SecondaryButton";
import PrimaryButton from "../buttons/PrimaryButton";

function ContactDetailHeader({ contact }: { contact: Contact }) {
  return (
    <Stack justifyContent="center">
      <ContactCard
        firstName={contact.firstName}
        lastName={contact.lastName}
        email={contact.email}
        image=""
      />
    </Stack>
  );
}

function ActionButtonArea({ children }: { children: React.ReactNode }) {
  return (
    <Stack
      flexGrow={1}
      direction="row-reverse"
      justifyContent="space-between"
      alignItems="center"
    >
      {children}
    </Stack>
  );
}

export function ContactDetailsDrawer({
  contact,
  open,
  onClose,
}: {
  contact: Contact;
  open?: boolean;
  onClose?: () => void;
}) {
  const defaultValues: ContactWithDetails = useMemo(
    () => ({
      account: "",
      customField1: "",
      customField10: "",
      customField2: "",
      customField3: "",
      customField4: "",
      customField5: "",
      customField6: "",
      customField7: "",
      customField8: "",
      customField9: "",
      email: "",
      firstName: "",
      id: 0,
      lastMeetingActivity: "",
      lastName: "",
      leadScore: "",
      logicField: "",
      meetingDefinitions: null,
      meetings: null,
      phone: "",
      profilePic: "",
      routingField: "",
      state: "",
      tags: [],
      timezone: "",
      unsubscribed: false,
      zip: "",
    }),
    []
  );

  // Should be true if we are in edit mode.
  const [edit, setEdit] = useState(false);

  // Should be true if the form has changes that can be saved.
  const [changed, setChanged] = useState(false);

  // Lets set the state of the form to the default values and the contact's
  // basic information while the details are loading.
  const [form, setForm] = useState<ContactWithDetails>({
    ...defaultValues,
    ...contact,
  });

  const [openSnackbar] = useSnackbar();
  const tagContacts = useTagContacts();
  const updateContact = useUpdateContact();

  const { data, loading } = useContact(contact.id);

  // Update the form data once we have loaded all the contact information.
  useEffect(() => {
    setForm({ ...defaultValues, ...data });
  }, [data, defaultValues]);

  // Reset the form data back to the original data or the default data with
  // basic info
  const resetForm = () => {
    const dataToSet = data || { ...defaultValues, ...contact };

    setForm({
      ...defaultValues,
      ...dataToSet,
    });
    setChanged(false);
  };

  return (
    <Drawer
      anchor="right"
      title="Contact Details"
      open={open}
      onClose={onClose}
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <Stack spacing={3}>
            <ContactDetailHeader contact={data || contact} />
            {data.tags && (
              <Box>
                <Typography paragraph>Tags</Typography>
                <TagList
                  tags={data.tags}
                  onDelete={(t) => {
                    tagContacts(
                      [data.id],
                      data.tags.map((tt) => tt.id).filter((id) => id !== t.id)
                    );
                  }}
                />
              </Box>
            )}
            <Box>
              <Typography paragraph>Details</Typography>
              <Stack flexGrow={0} spacing={2}>
                <TextField
                  size="small"
                  label="First Name"
                  value={form.firstName}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      firstName: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
                <TextField
                  size="small"
                  label="Last Name"
                  value={form.lastName}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      lastName: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
                <TextField
                  size="small"
                  label="Email"
                  value={form.email}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      email: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
                <TextField
                  size="small"
                  label="Account"
                  value={form.account}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      account: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
                <TextField
                  size="small"
                  label="Logic Field 1"
                  value={form.logicField}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      logicField: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
                <TextField
                  size="small"
                  label="Logic Field 2"
                  value={form.leadScore}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      leadScore: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
                <TextField
                  size="small"
                  label="Phone"
                  value={form.phone}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      phone: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
                <TextField
                  size="small"
                  label="Routing Field"
                  value={form.routingField}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      routingField: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
                <TextField
                  size="small"
                  label="State"
                  value={form.state}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      state: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
                <TextField
                  size="small"
                  label="Timezone"
                  value={form.timezone}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      timezone: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
                <TextField
                  size="small"
                  label="Zip"
                  value={form.zip}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      zip: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
                <TextField
                  size="small"
                  label="Custom Field 1"
                  value={form.customField1}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      customField1: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
                <TextField
                  size="small"
                  label="Custom Field 2"
                  value={form.customField2}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      customField2: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
                <TextField
                  size="small"
                  label="Custom Field 3"
                  value={form.customField3}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      customField3: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
                <TextField
                  size="small"
                  label="Custom Field 4"
                  value={form.customField4}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      customField4: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
                <TextField
                  size="small"
                  label="Custom Field 5"
                  value={form.customField5}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      customField5: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
                <TextField
                  size="small"
                  label="Custom Field 6"
                  value={form.customField6}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      customField6: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
                <TextField
                  size="small"
                  label="Custom Field 7"
                  value={form.customField7}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      customField7: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
                <TextField
                  size="small"
                  label="Custom Field 8"
                  value={form.customField8}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      customField8: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
                <TextField
                  size="small"
                  label="Custom Field 9"
                  value={form.customField9}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      customField9: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
                <TextField
                  size="small"
                  label="Custom Field 10"
                  value={form.customField10}
                  disabled={!edit}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      customField10: event.target.value,
                    });
                    setChanged(true);
                  }}
                />
              </Stack>
            </Box>
            <ActionButtonArea>
              {!edit && (
                <PrimaryButton
                  onClick={() => {
                    setEdit(true);
                  }}
                >
                  Edit
                </PrimaryButton>
              )}
              {edit && (
                <SecondaryButton
                  onClick={() => {
                    setEdit(false);
                    resetForm();
                  }}
                >
                  Cancel
                </SecondaryButton>
              )}
              {edit && (
                <PrimaryButton
                  disabled={!changed}
                  onClick={() => {
                    updateContact(form).then(() => {
                      setEdit(false);
                      setChanged(false);
                      openSnackbar("Successfully updated contact");
                    });
                  }}
                >
                  Save
                </PrimaryButton>
              )}
            </ActionButtonArea>
            {data.source && (
              <Typography align="right" variant="caption">
                Sourced from {data.source}
              </Typography>
            )}
          </Stack>
        </>
      )}
    </Drawer>
  );
}
