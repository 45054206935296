import React, { useMemo } from "react";
import { useActingAs, useIsDefaultUser } from "../auth";
import { ROLE_LEVELS } from "../auth/roles";
import Flexbox from "../components/flexbox/FlexboxV2";
import { LargeSpacing, XXLargeSpacing } from "../components/spacing";
import { P1, H6 } from "../components/typography";
import { useIsDefaultUserMeetingType, useSelectedMeetingType } from "./context";
import useCreationData from "./useCreationData";
import { getUserDetails } from "src/utils/jwtToken";

export default function DefaultUserRouting() {
  const [meetingType] = useSelectedMeetingType();
  const isDefaultUser = useIsDefaultUser();
  const { firstName, lastName, email: userEmail } = getUserDetails();
  const creationData = useCreationData({ meetingType });
  const isDefaultUserMeetingType = useIsDefaultUserMeetingType();
  const [actingAs] = useActingAs();

  const hostDisplay = useMemo(() => {
    if (isDefaultUser || creationData === null) {
      return `Host: ${firstName} ${lastName} -- ${userEmail} (or other connected accounts)`;
    }

    if (isDefaultUserMeetingType) {
      return `Host: ${creationData.userFirstName} ${creationData.userLastName} -- ${creationData.userEmail}`;
    }

    if (actingAs?.role === ROLE_LEVELS.DEFAULT) {
      return `Host: ${actingAs?.firstName} ${actingAs?.lastName} -- ${actingAs?.email}`;
    }

    return `No Host Info Available`;
  }, [
    actingAs,
    creationData,
    firstName,
    isDefaultUser,
    isDefaultUserMeetingType,
    lastName,
    userEmail,
  ]);

  return (
    <>
      <H6>Routing</H6>
      <LargeSpacing />
      <Flexbox.Column justifyContent="center">
        <Flexbox.Row>
          <XXLargeSpacing />
          <P1>{hostDisplay}</P1>
        </Flexbox.Row>
        <LargeSpacing />
      </Flexbox.Column>
    </>
  );
}
