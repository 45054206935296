import useSWR from "swr";

import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import {
  Guest,
  Host,
  Meeting,
  MeetingDefinition,
  MeetingHistory,
  MeetingStatus,
  Tag,
} from "../types";
import { InviteStyle } from "src/meetingTypes/invite/props";

export interface EmailEvent {
  type: "email";
  sender: { firstName: string; lastName: string; email: string; ai: boolean };
  subject: string;
  body: string;
  date: string;
}

export interface SystemNote {
  type: "system_note";
  note: string;
  date: string;
}

export interface MeetingDetails {
  id: number;
  enabled: boolean;
  tags: Tag[];
  name: string;
  template: {
    enabled: boolean;
    id: number;
    name: string;
    type: InviteStyle;
    invite: {
      body: string;
      location: string;
      subject: string;
    };
    emails: {
      subject: string;
      body: string;
    }[];
  };
  attempts: { current: number; total: number };
  guest?: Guest;
  host?: Host;
  location: string;
  reminder: { enabled: boolean; executionTime: string | null };
  status: MeetingStatus;
  meetingTime?: string;
  startTimeOverridden?: boolean;
  history: (EmailEvent | SystemNote)[];
}

export function useMeetingDetails(id?: number | string | null) {
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();

  const accessToken = getUserToken();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const { data, error } = useSWR(
    [
      `/api/meetings/instances/other?ids=${id}`,
      `/api/meetings/instance/history/${id}`,
      `api/reminder?meetingId=${id}`,
    ],
    async () => {
      const meetings = (await service
        .get(`/api/meetings/instances/other?ids=${id}`)
        .set(headers)
        .then(tokenRefreshHandler)
        .then((res: Response) => res.body)) as Meeting[];
      const meeting = meetings?.[0];

      let template;
      if (meeting.meetingType) {
        template = (await service
          .get(`/api/meetings/definition/${meeting.meetingType.id}`)
          .set(headers)
          .then(tokenRefreshHandler)
          .then((res: Response) => res.body)) as MeetingDefinition;
      }

      const history = (await service
        .get(`/api/meetings/instance/history/${id}`)
        .set(headers)
        .then(tokenRefreshHandler)
        .then((res: Response) => res.body)) as MeetingHistory;

      const invite = (await service
        .get(`/api/meetings/instance/invite/${id}`)
        .set(headers)
        .then(tokenRefreshHandler)
        .then((res: Response) => res.body)) as {
        body: string;
        location: string;
        subject: string;
      };

      const reminder = (await service
        .get(`/api/reminder?meetingId=${id}`)
        .set(headers)
        .then(tokenRefreshHandler)
        .then((res: Response) => res.body)) as {
        meetingId: number;
        executionTime: string;
        OrgKey: string;
        UserId: number;
      };

      return {
        id: meeting.id,
        name: meeting.meetingType?.name || "",
        enabled: meeting.enabled,
        tags: meeting.tags,
        template: template
          ? {
              enabled: template.active,
              id: template.id,
              name: template.name,
              attempts: template.emailTemplates.length,
              type: template.inviteStyle,
              invite,
              emails: template.emailTemplates.map((template) => ({
                subject: template.title,
                body: template.body,
              })),
            }
          : null,
        attempts: {
          current: meeting.attempts?.current || 0,
          total:
            meeting.attempts?.total ?? template?.emailTemplates?.length ?? 0,
        },
        guest: meeting.guests?.[0],
        host: meeting.host,
        status: meeting.status,
        meetingTime: meeting.startTime,
        startTimeOverridden: meeting.startTimeOverridden,
        location: template?.inviteTemplates?.[0].location || "",
        reminder: {
          enabled: template?.properties.meetingReminder.enabled,
          executionTime:
            reminder.executionTime === "0001-01-01T00:00:00Z"
              ? null
              : reminder.executionTime,
        },
        history:
          history?.map((item) => {
            if (
              ["touch_sent", "contact_reply", "ai_response"].includes(
                item.note_type
              )
            ) {
              const sender =
                item.note_type === "touch_sent" ||
                item.note_type === "ai_response"
                  ? {
                      firstName: meeting.host?.firstName || "",
                      lastName: meeting.host?.lastName || "",
                      email: meeting.host?.email || "",
                      ai: item.note_type === "ai_response",
                    }
                  : {
                      firstName: meeting.guests?.[0].firstName || "",
                      lastName: meeting.guests?.[0].lastName || "",
                      email: meeting.guests?.[0].email || "",
                    };

              return {
                type: "email",
                date: item.occurrence,
                subject: "",
                body: item.note,
                sender,
              };
            } else {
              return {
                type: "system_note",
                note: item.note,
                date: item.occurrence,
              };
            }
          }) || [],
      };
    }
  );

  return {
    data: data as MeetingDetails,
    error,
    loading: !error && !data,
  };
}
