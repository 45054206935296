import React, { forwardRef } from "react";
import cx from "classnames";

import style from "./style.module.scss";

function Grid({ className, children }, ref) {
  return (
    <div className={cx(style.grid, className)} ref={ref}>
      {children}
    </div>
  );
}

const GridWithRef = forwardRef(Grid);

export default GridWithRef;
