import React from "react";

import style from "../style.module.scss";

export default function DistributionPreviewText({ x, y, text }) {
  return (
    <text className={style.meetingType__distributionPreviewText} x={x} y={y}>
      {text}
    </text>
  );
}
