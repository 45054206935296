import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LinkIcon from "@mui/icons-material/Link";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slider,
  Stack,
  Step,
  StepButton,
  Stepper,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid-pro";
import { Editor } from "@tiptap/react";
import { useEffect, useRef, useState } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import QRCode from "react-qr-code";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { TipTapPreview } from "src/componentsV2/RichText";
import EmailRichtextEditor, {
  cleanRichTextHTML,
} from "src/componentsV2/TipTap/EmailRichtextEditor";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";
import SecondaryButton from "src/componentsV2/buttons/SecondaryButton";
import useGeneralNotifications from "src/hooks/useGeneralNotifications";
import useUrlQueryV2 from "src/hooks/useUrlQueryV2";
import { getDynamicVariableMappings } from "src/meetingTypes/useDynamicVariableMappings";
import { useActivateTemplate, useDeactivateTemplate } from "src/mutations";
import { useDeleteMeetingTemplate } from "src/mutations/useDeleteMeetingTemplate";
import useGenerateOneClickUrl from "src/mutations/useGenerateOneClickUrl";
import { useUpdateMeetingTemplate } from "src/mutations/useUpdateMeetingTemplate";
import { useUpdateRoutingLogicUser } from "src/mutations/useUpdateRoutingLogicUser";
import { useMeetingDefinition, useTeams } from "src/queries";
import { RoutingLogic, useRoutingLogic } from "src/queries/useRoutingLogic";
import { formatDateTime } from "src/services/formatDateTime";
import { Calendar, CreationData, MeetingDefinition } from "src/types";
import { ROLE_LEVELS } from "../../../auth/roles";
import TemplateAIModal from "../../../componentsV2/TemplateAIModal";
import { useOpenAiIntegration } from "../../../features";
import { useLinkFirstInvite } from "../../../features/useLinkFirstInvite";
import { INVITE_STYLE, isLinkFirst } from "../../../meetingTypes/invite/props";
import { buildRange } from "../../../utils/helpers";

// Datafetching imports
import {
  useActingAs,
  useActingAsOverrideHeader,
  useIsDefaultUser,
} from "src/auth";
import {
  useDebounce,
  useErrorHandler,
  useTokenRefreshHandler,
} from "src/hooks";
import { useUserService } from "src/services";
import { getUserDetails, getUserToken } from "src/utils/jwtToken";

export default function MeetingTemplateEditPage() {
  const [activeStep, setActiveStep] = useState(0);
  const { addGeneralNotification, addError } = useGeneralNotifications();

  const { id }: { id: string } = useParams();
  const { data: routingLogic } = useRoutingLogic(id);

  const query = useUrlQueryV2();
  const history = useHistory();
  const [disableSave, setDisableSave] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);
  const deleteMeetingTemplate = useDeleteMeetingTemplate();
  const activateTemplate = useActivateTemplate();
  const deactivateTemplate = useDeactivateTemplate();
  const updateRoutingLogic = useUpdateRoutingLogicUser();
  const isDefaultUser = useIsDefaultUser();

  const methods = useForm<MeetingDefinition>({
    mode: "all",
  });
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const updateMeetingTemplate = useUpdateMeetingTemplate();
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [warningTeamChangeDialogOpen, setWarningTeamChangeDialogOpen] =
    useState(false);
  const { data: meetingDefinition, error } = useMeetingDefinition(id);

  const userDetails = getUserDetails();
  const actingAsDetails = useActingAs();

  // ---------------- Default routing logic fetching.
  // We have to do all of this here, because we need to invoke this API call on team change, and wait for the result.
  const accessToken = getUserToken();
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const errorHandler = useErrorHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const fetchDefaultRoutingLogic = (teamId: number | string) =>
    service
      .get(`/api/meetings/definition/default_routing_logic/${teamId}`)
      .set(headers)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .then((data?: RoutingLogic[]) => data)
      .catch(errorHandler);

  // ---------------- Default routing logic fetching END.

  useEffect(() => {
    setActiveStep(Number(query.get("step") || 0));
  }, [query]);

  useEffect(() => {
    if (!meetingDefinition) {
      return;
    }

    // If the user is the default user, and they haven't created the template, they shouldn't be able to edit it.
    let ableToEdit = true;

    if (actingAsDetails[0]?.role === ROLE_LEVELS.DEFAULT) {
      ableToEdit =
        actingAsDetails[0].id ===
        meetingDefinition?.creationData.creatorData.userId;
    } else if (userDetails?.role === ROLE_LEVELS.DEFAULT) {
      ableToEdit =
        userDetails?.id === meetingDefinition?.creationData.creatorData.userId;
    }

    if (!ableToEdit) {
      history.replace("/meeting-templates");
    }

    reset(meetingDefinition);
    setInitialDataLoaded(true);
  }, [meetingDefinition, reset]);

  if (error) {
    console.error(error);
    return <Redirect to="/404" />;
  }

  if (!initialDataLoaded) {
    return (
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size="10vh" />
      </Stack>
    );
  }

  const onSubmit = async (
    data: MeetingDefinition,
    _: React.BaseSyntheticEvent<object, any, any> | undefined,
    newRoutingLogic?: RoutingLogic[]
  ) => {
    setDisableSave(true);
    try {
      const templateUpdateBody = {
        ...data,
        enabled: data.active,
        team: data.team.id,
        tags: data.tags.map((tag) => tag.id),
      };

      // We have to keep the code below for now until we get a new endpoint for updating Meeting Templates.
      // If the team has changed, newRoutingLogic is passed in as a third param, and we need to use it to reset the sequential and custom props, in the routing_logic prop.
      if (newRoutingLogic) {
        if (templateUpdateBody.routing_logic) {
          templateUpdateBody.routing_logic.sequential.order =
            newRoutingLogic.map((routingLogicItem) => routingLogicItem.user_id);

          templateUpdateBody.routing_logic.custom = {};
          newRoutingLogic.forEach((routingLogicItem) => {
            if (templateUpdateBody.routing_logic) {
              templateUpdateBody.routing_logic.custom[
                routingLogicItem.user_id
              ] = {
                enabled: routingLogicItem.enabled,
                mapping_logic: routingLogicItem.mapping_logic,
              };
            }
          });
        }

        delete templateUpdateBody.routing_logic?.metadata;

        // If newRoutingLogic isn't passed in, we need to populate the routing_logic properties with the data from the routing logic endpoint. We populate these differently, depending on if the user selected the "email" or "sequential" routing logic.
      } else if (data.routing === "email") {
        if (templateUpdateBody.routing_logic) {
          const subtypeDetailsField =
            templateUpdateBody.routing_logic.metadata?.subtypeDetails.field ??
            "routing_field";

          templateUpdateBody.routing = "custom";
          templateUpdateBody.routing_logic.metadata = {
            subtypeDetails: {
              field: subtypeDetailsField,
              subtype: "email",
            },
          };

          routingLogic?.forEach((item) => {
            if (templateUpdateBody.routing_logic) {
              templateUpdateBody.routing_logic.custom[item.user_id] = {
                enabled: item.enabled,
                mapping_logic: [
                  {
                    field: subtypeDetailsField,
                    operator: "is",
                    value: item.email,
                  },
                ],
              };
            }
          });
        }
      } else if (data.routing === "sequential") {
        if (isDefaultUser) {
          delete templateUpdateBody.routing_logic;
        }

        delete templateUpdateBody.routing_logic?.metadata;

        // For both the sequential and custom routing logic we have to set the `enabled` flag.
        // We do not touch the sequential property, just this one in both cases.
        routingLogic?.forEach((item) => {
          if (templateUpdateBody.routing_logic) {
            templateUpdateBody.routing_logic.custom[item.user_id] = {
              enabled: item.enabled,
              mapping_logic: [],
            };
          }
        });
      }
      // We have to delete this because otherwise the patch fails.
      delete templateUpdateBody.routing_logic?.sequential.last_processed;

      await updateMeetingTemplate(data.id, templateUpdateBody);

      addGeneralNotification(
        `Template ${templateUpdateBody.name} updated succesfully!`
      );
    } catch (error) {
      addError(`Template update failed: ${getErrorMessage(error)}`);

      console.error(error);
    } finally {
      setDisableSave(false);
    }
  };
  return (
    <Container maxWidth="xl">
      <Dialog
        open={deleteDialogVisible}
        onClose={() => setDeleteDialogVisible(false)}
      >
        <DialogTitle>
          <Typography
            sx={{ color: "primary.dark", fontWeight: "bold" }}
            variant="h5"
            component="span"
          >
            Delete Template
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the template?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start", px: 3 }}>
          <SecondaryButton
            disabled={actionInProgress}
            onClick={() => setDeleteDialogVisible(false)}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            disabled={actionInProgress}
            onClick={async () => {
              if (!meetingDefinition?.id) {
                setDeleteDialogVisible(false);
                return;
              }
              try {
                setActionInProgress(true);
                await deleteMeetingTemplate(meetingDefinition.id);

                history.push("/meeting-templates");
                addGeneralNotification(
                  `Succesfully deleted template ${meetingDefinition.name}`
                );
                setDeleteDialogVisible(false);
              } catch (error) {
                addError(
                  `Failed to delete the template: ${getErrorMessage(error)}`
                );
                console.error(error);
              } finally {
                setActionInProgress(false);
              }
            }}
          >
            Delete
          </PrimaryButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={warningDialogOpen}
        onClose={() => setWarningDialogOpen(false)}
      >
        <DialogTitle>
          <Typography
            sx={{ color: "primary.dark", fontWeight: "bold" }}
            variant="h5"
            component="span"
          >
            Important: Saving will overwrite custom logic
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            We're phasing out the current format of custom logic in Kronologic.
            Should you decide to save this template with different routing
            rules, please note the existing custom rules cannot be reinstated.
            To ensure you don't lose this custom logic we advise making a copy
            of this template before making any changes to the routing. Please
            contact our customer support team with any questions or concerns.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start", px: 3 }}>
          <SecondaryButton
            disabled={actionInProgress}
            onClick={() => setWarningDialogOpen(false)}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            disabled={actionInProgress}
            onClick={async () => {
              try {
                setActionInProgress(true);

                // If routing is set to custom at this point, we should switch it out to something else.
                if (getValues("routing") === "custom") {
                  setValue("routing", "sequential");
                }

                await handleSubmit(onSubmit)();

                setWarningDialogOpen(false);
              } catch (error) {
                addError(
                  `Failed to update the template: ${getErrorMessage(error)}`
                );
              } finally {
                setActionInProgress(false);
              }
            }}
          >
            Save Template
          </PrimaryButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={warningTeamChangeDialogOpen}
        onClose={() => setWarningTeamChangeDialogOpen(false)}
      >
        <DialogTitle>
          <Typography
            sx={{ color: "primary.dark", fontWeight: "bold" }}
            variant="h5"
            component="span"
          >
            Important: Saving will overwrite custom logic
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            We're phasing out the current format of custom logic in Kronologic.
            Should you decide to save this template with different routing
            rules, please note the existing custom rules cannot be reinstated.
            To ensure you don't lose this custom logic we advise making a copy
            of this template before making any changes to the routing. Please
            contact our customer support team with any questions or concerns.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start", px: 3 }}>
          <SecondaryButton
            disabled={actionInProgress}
            onClick={() => setWarningTeamChangeDialogOpen(false)}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            disabled={actionInProgress}
            onClick={async () => {
              try {
                setActionInProgress(true);
                const newRoutingLogic = await fetchDefaultRoutingLogic(
                  getValues("team.id")
                );
                setValue("routing", "sequential");
                await handleSubmit((data, event) =>
                  onSubmit(data, event, newRoutingLogic)
                )();

                setWarningTeamChangeDialogOpen(false);
              } catch (error) {
                addError(
                  `Failed to update the template: ${getErrorMessage(error)}`
                );
                console.error(error);
              } finally {
                setActionInProgress(false);
              }
            }}
          >
            Save Template
          </PrimaryButton>
        </DialogActions>
      </Dialog>

      <FormProvider {...methods}>
        <Card sx={{ mt: 4, mb: 4 }}>
          <CardContent>
            <Stack
              sx={{ mb: 3, flexDirection: "row", gap: 4, alignItems: "center" }}
            >
              <Stack>
                <TextField
                  {...register("name", {
                    required: "Template name is required.",
                  })}
                  InputProps={{
                    sx: { fontWeight: "bold", fontSize: "22px" },
                  }}
                  sx={{ width: "400px" }}
                  variant="standard"
                  placeholder="New Template Name"
                  label="Template name"
                />
                {errors.name && (
                  <Typography sx={{ color: "red" }}>
                    {errors.name.message}
                  </Typography>
                )}
              </Stack>
              <Box>
                <Typography fontSize={"14px"}>
                  Created By:{" "}
                  {`${getValues(
                    "creationData.creatorData.userFirstName"
                  )} ${getValues("creationData.creatorData.userLastName")}`}
                </Typography>
                <Typography fontSize={"14px"}>
                  Created At:{" "}
                  {formatDateTime(getValues("creationData.createdAt"))}
                </Typography>
              </Box>
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: "auto",
                  gap: 2,
                }}
              >
                <IconButton
                  sx={{ borderRadius: "9999px", backgroundColor: "#E1E6EB" }}
                  aria-label="delete"
                  size="small"
                  onClick={() => setDeleteDialogVisible(true)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
                <Controller
                  control={control}
                  name="active"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      checked={field.value}
                      disabled={disableSave}
                      onChange={async (event) => {
                        setDisableSave(true);

                        try {
                          field.onChange(event.target.checked);

                          if (event.target.checked) {
                            await activateTemplate(getValues("id"));
                            addGeneralNotification(
                              `Template ${getValues(
                                "name"
                              )} successfully activated!`
                            );
                          } else {
                            await deactivateTemplate(getValues("id"));
                            addGeneralNotification(
                              `Template ${getValues(
                                "name"
                              )} successfully deactivated!`
                            );
                          }
                        } catch (error) {
                          addError(
                            `Template activation failed: ${getErrorMessage(
                              error
                            )}`
                          );

                          field.onChange(!event.target.checked);
                        } finally {
                          setDisableSave(false);
                        }
                      }}
                    />
                  )}
                />
                <PrimaryButton
                  // Forms isValid flag is not working properly so had to do it like this.
                  disabled={Object.keys(errors).length > 0 || disableSave}
                  onClick={() => {
                    // Check if routing was set to custom (not email_based)
                    // If it was, we need to show the warning message to the user
                    if (
                      meetingDefinition?.routing === "custom" &&
                      meetingDefinition.routing_logic?.metadata?.subtypeDetails
                        .subtype !== "email" &&
                      (getValues(
                        "routing_logic.metadata.subtypeDetails.subtype"
                      ) === "email" ||
                        getValues("routing") !== "custom")
                    ) {
                      setWarningDialogOpen(true);
                      return;
                    }

                    handleSubmit(onSubmit)();
                  }}
                >
                  Save
                </PrimaryButton>
              </Stack>
            </Stack>
            <Stepper nonLinear activeStep={activeStep}>
              <Step>
                <StepButton
                  color="inherit"
                  onClick={() =>
                    history.push({
                      search: `?step=0`,
                    })
                  }
                >
                  Template Settings
                </StepButton>
              </Step>
              <Step>
                <StepButton
                  icon={
                    (errors.emailTemplates ||
                      errors.inviteTemplates ||
                      errors.properties?.cleanDeclineRule ||
                      errors.properties?.meetingReminder) && (
                      <WarningIcon sx={{ color: "red" }} />
                    )
                  }
                  color="inherit"
                  onClick={() =>
                    history.push({
                      search: `?step=1`,
                    })
                  }
                >
                  Email & Invite
                </StepButton>
              </Step>
              <Step>
                <StepButton
                  color="inherit"
                  onClick={() =>
                    history.push({
                      search: `?step=2`,
                    })
                  }
                >
                  Meeting Distribution
                </StepButton>
              </Step>
              <Step>
                <StepButton
                  color="inherit"
                  onClick={() =>
                    history.push({
                      search: `?step=3`,
                    })
                  }
                >
                  Time Attributes
                </StepButton>
              </Step>
            </Stepper>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            {/* We have to show/hide the steps through css, otherwise the validation doesn't work properly, because the elements are not present on the page */}
            <Box sx={{ display: activeStep === 0 ? "block" : "none" }}>
              <Box sx={{ mb: 5 }}>
                <MeetingTemplateSettings />
              </Box>
              <PrimaryButton
                onClick={() => {
                  history.push({
                    search: `?step=${activeStep + 1}`,
                  });
                  window.scrollTo(0, 0);
                }}
              >
                Next
              </PrimaryButton>
            </Box>
            <Box sx={{ display: activeStep === 1 ? "block" : "none" }}>
              <Box sx={{ mb: 5 }}>
                <MeetingTemplateInviteAndEmail />
              </Box>
              <Stack sx={{ flexDirection: "row", gap: 2 }}>
                <SecondaryButton
                  onClick={() => {
                    history.push({
                      search: `?step=${activeStep - 1}`,
                    });
                    window.scrollTo(0, 0);
                  }}
                >
                  Previous
                </SecondaryButton>
                <PrimaryButton
                  onClick={() => {
                    history.push({
                      search: `?step=${activeStep + 1}`,
                    });
                    window.scrollTo(0, 0);
                  }}
                >
                  Next
                </PrimaryButton>
              </Stack>
            </Box>
            <Box sx={{ display: activeStep === 2 ? "block" : "none" }}>
              <Box sx={{ mb: 5 }}>
                <MeetingTemplateDistribution
                  currentTeam={meetingDefinition?.team}
                  routingLogic={routingLogic}
                  showCustom={
                    meetingDefinition?.routing === "custom" &&
                    meetingDefinition.routing_logic?.metadata?.subtypeDetails
                      .subtype !== "email"
                  }
                  onTeamChange={async () => {
                    if (
                      meetingDefinition?.routing === "custom" &&
                      meetingDefinition.routing_logic?.metadata?.subtypeDetails
                        .subtype !== "email"
                    ) {
                      setWarningTeamChangeDialogOpen(true);
                      return;
                    }

                    const newRoutingLogic = await fetchDefaultRoutingLogic(
                      getValues("team.id")
                    );

                    // When changing teams, always default to the "sequential" routing logic first.
                    setValue("routing", "sequential");
                    handleSubmit((data, event) =>
                      onSubmit(data, event, newRoutingLogic)
                    )();
                  }}
                  onUpdateRoutingLogicUser={(routingLogic: RoutingLogic) =>
                    updateRoutingLogic(id, routingLogic.user_id, {
                      enabled: routingLogic.enabled,
                      mapping_logic: routingLogic.mapping_logic,
                    })
                  }
                />
              </Box>
              <Stack sx={{ flexDirection: "row", gap: 2 }}>
                <SecondaryButton
                  onClick={() => {
                    history.push({
                      search: `?step=${activeStep - 1}`,
                    });
                    window.scrollTo(0, 0);
                  }}
                >
                  Previous
                </SecondaryButton>
                <PrimaryButton
                  onClick={() => {
                    history.push({
                      search: `?step=${activeStep + 1}`,
                    });
                    window.scrollTo(0, 0);
                  }}
                >
                  Next
                </PrimaryButton>
              </Stack>
            </Box>
            <Box sx={{ display: activeStep === 3 ? "block" : "none" }}>
              <Box sx={{ mb: 5 }}>
                <MeetingTemplateTimeAttributes />
              </Box>
              <SecondaryButton
                onClick={() => {
                  history.push({
                    search: `?step=${activeStep - 1}`,
                  });
                  window.scrollTo(0, 0);
                }}
              >
                Previous
              </SecondaryButton>
            </Box>
          </CardContent>
        </Card>
      </FormProvider>
    </Container>
  );
}

const MeetingTemplateSettings = () => {
  const theme = useTheme();
  const generateOneClick = useGenerateOneClickUrl();
  const { control, getValues, watch } = useFormContext<MeetingDefinition>();
  const { addGeneralNotification, addError } = useGeneralNotifications();
  const qrCodeRef = useRef(null);
  const linkFirstInviteEnabled = useLinkFirstInvite();

  return (
    <Box>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" fontWeight="bold">
          Template Settings
        </Typography>
        <Typography>
          Set up which style of template you will be using and enable 1-click
          scheduling.
        </Typography>
      </Box>
      <Typography variant="h5" fontWeight="bold">
        Invite Style
      </Typography>
      <Typography sx={{ mb: 3 }}>
        Select which Kronologic product you wish to use for this template.
      </Typography>

      <Controller
        control={control}
        name="inviteStyle"
        render={({ field }) => (
          <Stack
            sx={{ flexDirection: "row", gap: 5, mb: 6, minHeight: "150px" }}
          >
            <Box sx={{ flex: "1 1 0", maxWidth: "320px" }}>
              <SelectableBox
                selected={field.value === "calendar_first"}
                onClick={() => field.onChange("calendar_first")}
              >
                <Stack
                  sx={{
                    mb: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    color: "primary.dark",
                    gap: 2,
                    cursor: "pointer",
                  }}
                >
                  <CalendarMonthIcon />
                  <Typography variant="h5" fontWeight="bold">
                    Kronologic Invite
                  </Typography>
                </Stack>
                <Typography>
                  Calendar invite and email are sent at the same time.
                  <br />
                  <br />
                  Best for: Contact Us, Demo/Pricing Requests, Event Booth
                  Leads, Renewals, Direct Mail
                </Typography>
              </SelectableBox>
            </Box>

            <Box sx={{ flex: "1 1 0", maxWidth: "320px" }}>
              <SelectableBox
                selected={field.value === "custom"}
                onClick={() => field.onChange("custom")}
              >
                <Stack
                  sx={{
                    mb: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    color: "primary.dark",
                    gap: 2,
                  }}
                >
                  <MailOutlineIcon />
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    color="primary.dark"
                  >
                    Kronologic Email
                  </Typography>
                </Stack>
                <Typography>
                  Email sent first, calendar invite sent after acceptance.
                  <br />
                  <br />
                  Best for: Content Downloads, Webinars, Event Opt-in Lists, ABM
                  Campaigns, Nurture Campaigns
                </Typography>
              </SelectableBox>
            </Box>

            {linkFirstInviteEnabled && (
              <Box sx={{ flex: "1 1 0", maxWidth: "320px" }}>
                <SelectableBox
                  selected={isLinkFirst(field.value)}
                  onClick={() => field.onChange(INVITE_STYLE.LINK_FIRST)}
                >
                  <Stack
                    sx={{
                      mb: 1,
                      flexDirection: "row",
                      alignItems: "center",
                      color: "primary.dark",
                      gap: 2,
                    }}
                  >
                    <LinkIcon />
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      color="primary.dark"
                    >
                      Kronologic Link
                    </Typography>
                  </Stack>
                  <Typography>
                    Person uses the link from email or website to schedule a
                    meeting with you in a few clicks.
                    <br />
                    <br />
                    Best for: Embedding in external content such as emails and
                    websites.
                    <br />
                    <br />
                    Note: meetings of this type cannot be launched by Kronologic
                    user, they can only be initiated by external contact who
                    uses the link to schedule a meeting.
                  </Typography>
                </SelectableBox>
              </Box>
            )}
          </Stack>
        )}
      />
      {watch("inviteStyle") !== INVITE_STYLE.LINK_FIRST && (
        <>
          {/* TODO(vovan): selecting link first should disable 1-click control switch */}
          <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
            <Controller
              control={control}
              name="schedulingUrls"
              render={({ field }) => (
                <>
                  {!field.value && (
                    <Switch
                      checked={false}
                      onChange={async () => {
                        const oneClickData = await generateOneClick(
                          getValues("name"),
                          getValues("id")
                        );
                        field.onChange([oneClickData]);
                      }}
                    />
                  )}
                </>
              )}
            />
            <Typography variant="h5" fontWeight="bold">
              1 Click Scheduling
            </Typography>
          </Stack>
          <Typography sx={{ mb: 3 }}>
            Enabling one click scheduling will generate a link and QR code you
            can share with guests so that they can schedule a meeting.
          </Typography>
          <Controller
            control={control}
            name="schedulingUrls"
            render={({ field }) => (
              <>
                {field.value?.[0] && (
                  <Stack sx={{ flexDirection: "row", gap: 10 }}>
                    <Box>
                      <Typography sx={{ mb: 2 }} variant="h6" fontWeight="bold">
                        Meeting Link
                      </Typography>

                      <Stack sx={{ flexDirection: "row", gap: 2 }}>
                        <a
                          style={{
                            color: theme.palette.primary.main,
                            textDecoration: 0,
                            backgroundColor: "#F8FBFF",
                            padding: "10px 20px",
                            fontWeight: "bold",
                          }}
                          href={field.value[0].url}
                        >
                          {field.value[0].url}
                        </a>

                        <IconButton
                          sx={{ color: theme.palette.primary.main }}
                          onClick={async () => {
                            try {
                              if (!field.value?.[0]) {
                                return;
                              }
                              await navigator.clipboard.writeText(
                                field.value[0].url
                              );
                              addGeneralNotification(
                                "1 Click link copied to clipboard!"
                              );
                            } catch (err) {
                              addError(
                                "Failed to copy the 1 click link to clipboard"
                              );
                              console.error("Failed to copy: ", err);
                            }
                          }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Stack>
                    </Box>

                    <Box>
                      <Typography sx={{ mb: 2 }} variant="h6" fontWeight="bold">
                        QR Code
                      </Typography>
                      <Box sx={{ mb: 2 }}>
                        <QRCode
                          ref={qrCodeRef}
                          size={128}
                          id="qrcode"
                          value={field.value[0].url}
                        />
                        <canvas hidden width={128} height={128} id="canvas" />
                      </Box>
                      <PrimaryButton
                        onClick={() => {
                          const svg: any = document.getElementById("qrcode");
                          const canvas: any = document.getElementById("canvas");

                          const ctx = canvas.getContext("2d");
                          const data = new XMLSerializer().serializeToString(
                            svg
                          );
                          const DOMURL =
                            window.URL || window.webkitURL || window;

                          const img = new Image();
                          const svgBlob = new Blob([data], {
                            type: "image/svg+xml;charset=utf-8",
                          });

                          const u = DOMURL.createObjectURL(svgBlob);

                          img.onload = function () {
                            ctx.drawImage(img, 0, 0);
                            DOMURL.revokeObjectURL(u);

                            const imgURI = canvas
                              .toDataURL("image/png")
                              .replace("image/png", "image/octet-stream");

                            const element = document.createElement("a");
                            element.download = "QRCode.png";
                            element.href = imgURI;
                            element.click();
                            element.remove();
                          };

                          img.src = u;
                        }}
                      >
                        Download QR Code
                      </PrimaryButton>
                    </Box>
                  </Stack>
                )}
              </>
            )}
          />
        </>
      )}
    </Box>
  );
};

const MeetingTemplateInviteAndEmail = () => {
  const { control, register, setValue, getValues, watch, clearErrors } =
    useFormContext<MeetingDefinition>();

  return (
    <Box>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" fontWeight="bold">
          Invite & Email Content
        </Typography>
        <Typography>
          {!isLinkFirst(getValues("inviteStyle"))
            ? `Enter the copy for your calendar invite and email. Set any additional attempts, decline messages, and unsubscribe settings.`
            : `Enter the copy for your calendar invite and email.`}
        </Typography>
      </Box>

      <Box sx={{ mb: 5 }}>
        <Typography variant="h5" fontWeight="bold">
          Calendar Invite
        </Typography>

        {getValues("inviteStyle") === "calendar_first" && (
          <Typography sx={{ mb: 3 }}>
            Because you have selected{" "}
            <Typography fontWeight="bold" component="span">
              Kronologic Invite
            </Typography>
            , this will be sent at the same time as the calendar invite.
          </Typography>
        )}

        {getValues("inviteStyle") === "custom" && (
          <Typography sx={{ mb: 3 }}>
            Because you have selected{" "}
            <Typography fontWeight="bold" component="span">
              Kronologic Email
            </Typography>{" "}
            this will only show on the guest's calendar once they have accepted
            via the first email.
          </Typography>
        )}

        <MeetingTemplateCalendarInvite />
        <Divider sx={{ my: 5 }} />
      </Box>

      <Typography variant="h5" fontWeight="bold">
        Email
      </Typography>

      {getValues("inviteStyle") === "calendar_first" && (
        <Typography sx={{ mb: 3 }}>
          Because you have selected{" "}
          <Typography fontWeight="bold" component="span">
            Kronologic Invite
          </Typography>
          , this will be sent at the same time as the calendar invite.
        </Typography>
      )}

      {getValues("inviteStyle") === "custom" && (
        <Typography sx={{ mb: 3 }}>
          Because you have selected{" "}
          <Typography fontWeight="bold" component="span">
            Kronologic Email
          </Typography>{" "}
          this will be sent to the customer first, the calendar invite will be
          sent once they have accepted the meeting request
        </Typography>
      )}

      <Box sx={{ mb: 5 }}>
        {!isLinkFirst(getValues("inviteStyle")) && <EmailTemplatesAccordion />}

        {isLinkFirst(getValues("inviteStyle")) && (
          <Box sx={{ mb: 5 }}>
            <EmailTemplateInvite emailIndex={0} />
          </Box>
        )}
      </Box>

      {!isLinkFirst(getValues("inviteStyle")) && (
        <Box sx={{ mb: 5 }}>
          <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
            <Switch
              checked={!!getValues("properties.cleanDeclineRule")}
              onChange={(event) => {
                clearErrors("properties.cleanDeclineRule");

                if (!event.target.checked) {
                  return setValue("properties.cleanDeclineRule", null);
                }

                setValue("properties.cleanDeclineRule", {
                  action: {
                    meta: {
                      body: "",
                      title: "",
                    },
                    type: "sendEmail",
                  },
                  trigger: {
                    field: "status",
                    object: "meeting",
                    values: ["declined"],
                  },
                });
              }}
            />
            <Typography variant="h5" fontWeight="bold">
              Decline Message
            </Typography>
          </Stack>
          <Typography sx={{ mb: 2 }}>
            If enabled, the guest will receive an additional message after
            declining the meeting
          </Typography>

          {!!getValues("properties.cleanDeclineRule") && (
            <MeetingTemplateDeclineEmail />
          )}
        </Box>
      )}

      <Box sx={{ mb: 5 }}>
        <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
          <Switch
            checked={getValues("properties.meetingReminder.enabled")}
            onChange={(event) => {
              clearErrors("properties.meetingReminder");

              // do NOT null out the meeting reminder, we can keep what the user has saved
              if (!event.target.checked) {
                return setValue("properties.meetingReminder", {
                  ...getValues("properties.meetingReminder"),
                  enabled: false,
                });
              }

              setValue("properties.meetingReminder", {
                ...getValues("properties.meetingReminder"),
                enabled: true,
              });
            }}
          />
          <Typography variant="h5" fontWeight="bold">
            Meeting Reminder
          </Typography>
        </Stack>
        <Typography sx={{ mb: 2 }}>
          If enabled, the guest will receive a reminder email at the configured
          interval before the start of the meeting
        </Typography>

        {getValues("properties.meetingReminder.enabled") && (
          <>
            <MeetingTemplateReminderOptions />
            <MeetingTemplateReminderEmail />
          </>
        )}
      </Box>

      {!isLinkFirst(getValues("inviteStyle")) && (
        <Box sx={{ mb: 5 }}>
          <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
            <Controller
              control={control}
              name="unsubscribeConfig.enabled"
              render={({ field }) => (
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={(event) => {
                    field.onChange(event.target.checked);
                  }}
                />
              )}
            />
            <Typography variant="h5" fontWeight="bold">
              Unsubscribe Message
            </Typography>
          </Stack>
          <Typography sx={{ mb: 2 }}>
            If enabled, all emails from this template will include an
            unsubscribe link
          </Typography>

          {watch("unsubscribeConfig.enabled") && (
            <Stack sx={{ gap: 2, mt: 2 }}>
              <Box>
                <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                  What should the body of the unsubscribe clause say
                </Typography>
                <Typography>
                  Use {"{{link}}"} to signify where you’d like to hyperlink text
                  to appear
                </Typography>
              </Box>
              <TextField
                {...register("unsubscribeConfig.body")}
                size="small"
                placeholder={`If you do not wish to receive meeting requests from Kronologic, {{link}}`}
                sx={{ width: "100%", mb: 2 }}
              />
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                What should the hyperlink say?
              </Typography>
              <TextField
                {...register("unsubscribeConfig.hyperlinkText")}
                size="small"
                placeholder="Click Here"
                sx={{ width: "100%", mb: 2 }}
              />
            </Stack>
          )}
        </Box>
      )}
    </Box>
  );
};

const EmailTemplatesAccordion = () => {
  const [expanded, setExpanded] = useState("email-invite-0");

  const {
    control,
    formState: { errors },
  } = useFormContext<MeetingDefinition>();
  const {
    fields: emailTemplates,
    append,
    remove,
  } = useFieldArray({
    control: control, // control props comes from useForm (optional: if you are using FormContext)
    name: "emailTemplates", // unique name for your Field Array
  });

  return (
    <>
      <Box sx={{ mb: 5 }}>
        {emailTemplates.map((emailItem, index) => (
          <Accordion
            key={emailItem.id}
            expanded={expanded === `email-invite-${index}`}
            onChange={() => {
              if (expanded === `email-invite-${index}`) {
                return setExpanded("");
              }
              setExpanded(`email-invite-${index}`);
            }}
          >
            <AccordionSummary
              expandIcon={<GridExpandMoreIcon />}
              aria-controls={`email-invite-${index}-content`}
              id={`email-invite-${index}-header`}
            >
              <Stack
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  {errors.emailTemplates?.[index] && (
                    <WarningIcon sx={{ color: "red" }} />
                  )}

                  <Typography
                    sx={{
                      width: "100%",
                      flexShrink: 0,
                      color: errors.emailTemplates?.[index] ? "red" : "inherit",
                    }}
                  >
                    Email Attempt #{index + 1}
                  </Typography>
                </Stack>

                {index > 0 && (
                  <IconButton sx={{ ml: "auto" }} onClick={() => remove(index)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <EmailTemplateInvite emailIndex={index} />
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      {emailTemplates.length < 3 && (
        <>
          <SecondaryButton
            onClick={() =>
              append({
                body: "",
                order: emailTemplates.length + 1,
                title: "",
              })
            }
          >
            Add Email Template
          </SecondaryButton>
        </>
      )}
    </>
  );
};

type Team = {
  id: number;
  name: string;
  users?: number[];
  isDefault?: boolean;
  calendars?: Array<Calendar> | null;
  tags?: Array<{
    id: number;
    name: string;
  }>;
};

function MeetingTemplateTeamRouting({
  onTeamChange,
  showCustom,
}: {
  showCustom?: boolean;
  onTeamChange: () => void;
}) {
  const { control, getValues, setValue, watch } =
    useFormContext<MeetingDefinition>();

  const [teamSearchQuery, setTeamSearchQuery] = useState("");
  const debouncedTeamSearch = useDebounce(
    encodeURIComponent(teamSearchQuery),
    1000
  );
  const { data: teams } = useTeams(100, 0, { qry: debouncedTeamSearch });

  useEffect(() => {
    setTeamSearchQuery(getValues("team.name"));
  }, [getValues]);
  return (
    <Stack
      sx={{
        flexDirection: { xs: "column", lg: "row" },
        gap: 4,
        mb: 7,
      }}
    >
      <FormControl>
        <Controller
          control={control}
          name="team"
          rules={{
            required: "Please select a Team",
            validate: (newValue) =>
              (Number.isInteger(newValue?.id) &&
                !!teams?.data?.find((team) => team.id === newValue.id)) ||
              "Please select a Team",
          }}
          render={({ field }) => (
            <Autocomplete
              value={field.value}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, newValue: Team | null) => {
                if (!newValue) {
                  return;
                }
                field.onChange({
                  id: newValue?.id,
                  name: newValue?.name,
                });
                onTeamChange();
              }}
              inputValue={teamSearchQuery}
              onInputChange={(event, newInputValue) => {
                setTeamSearchQuery(newInputValue);
              }}
              id="team-select"
              options={teams?.data ?? []}
              sx={{ width: 300 }}
              getOptionLabel={(option) => option.name ?? ""}
              renderInput={(params) => (
                <TextField {...params} label="Select A Team" />
              )}
            />
          )}
        />
      </FormControl>
      {!isLinkFirst(getValues("inviteStyle")) && (
        <Stack sx={{ flexDirection: { lg: "row", xs: "column" }, gap: 4 }}>
          <FormControl>
            <InputLabel id="select-routing-label">Select Routing</InputLabel>
            <Controller
              control={control}
              name="routing"
              render={({ field }) => (
                <Select
                  {...field}
                  sx={{ width: 300 }}
                  labelId="select-routing-label"
                  id="select-routing"
                  label="Select Routing"
                  onChange={(event) => {
                    field.onChange(event);
                    if (event.target.value === "email") {
                      setValue(
                        "routing_logic.metadata.subtypeDetails.field",
                        "routing_field"
                      );
                    }
                  }}
                >
                  <MenuItem value="sequential">Sequential</MenuItem>
                  <MenuItem value="email">Email Based</MenuItem>
                  {/* Only show this value if the user set their routing logic to custom in the old UI. */}
                  {showCustom && <MenuItem value="custom">Custom</MenuItem>}
                </Select>
              )}
            />
          </FormControl>
          {watch("routing") === "email" && (
            <FormControl>
              <InputLabel id="select-logic-field-label">
                Host Email Field
              </InputLabel>
              <Controller
                control={control}
                name="routing_logic.metadata.subtypeDetails.field"
                render={({ field }) => (
                  <Select
                    {...field}
                    sx={{ width: 300 }}
                    labelId="select-logic-field-label"
                    id="select-logic-field"
                    label="Host Email Field"
                  >
                    <MenuItem value="routing_field">Routing Field</MenuItem>
                    <MenuItem value="logic_field">Logic Field</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          )}
        </Stack>
      )}
    </Stack>
  );
}

function DefaultUserRouting() {
  const { getValues } = useFormContext<MeetingDefinition>();
  const loggedInUser = getUserDetails();
  const creationData: CreationData = getValues("creationData");
  const [actingAs] = useActingAs();

  if (!loggedInUser) {
    return <Typography>No Host Info Available</Typography>;
  }

  if (!creationData) {
    return (
      <Typography>
        Host: {loggedInUser.firstName} {loggedInUser.lastName} --{" "}
        {loggedInUser.email} (or other connected accounts)
      </Typography>
    );
  }

  if (creationData.creatorData.userRole === ROLE_LEVELS.DEFAULT) {
    return (
      <Typography>
        Host: {creationData.creatorData.userFirstName}{" "}
        {creationData.creatorData.userLastName} --{" "}
        {creationData.creatorData.userEmail}
      </Typography>
    );
  }

  if (actingAs !== null && actingAs.role === ROLE_LEVELS.DEFAULT) {
    return (
      <Typography>
        Host: {actingAs.firstName} {actingAs.lastName} -- {actingAs.email}
      </Typography>
    );
  }

  return (
    <Typography>
      Host: {loggedInUser.firstName} {loggedInUser.lastName} --{" "}
      {loggedInUser.email} (or other connected accounts)
    </Typography>
  );
}

type MeetingTemplateDistributionProps = {
  currentTeam?: Team;
  routingLogic?: RoutingLogic[];
  showCustom?: boolean;
  onTeamChange: () => void;
  onUpdateRoutingLogicUser: (routingLogic: RoutingLogic) => Promise<void>;
};

const MeetingTemplateDistribution = (
  props: MeetingTemplateDistributionProps
) => {
  const { control, register, getValues, setValue } =
    useFormContext<MeetingDefinition>();

  const isDefaultUser = useIsDefaultUser();

  // We have to manually set the routing value in the form, based on these 2 fields, because that is how our weird BE works.
  // routing === "custom" can mean that routing should actually be custom, but if the routing_logic.metadata.subtypeDetails.subtype is set to "email" then it should be Email based.

  // When we're actually submitting the form to the BE, we have to do the same thing in reverse. So if the "routing" prop is set to "email", we change it to "custom", and set the corresponding `routing_logic.metadata`.
  if (
    getValues("routing") === "custom" &&
    getValues("routing_logic.metadata.subtypeDetails.subtype") === "email"
  ) {
    setValue("routing", "email");
  }

  return (
    <Box>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" fontWeight="bold">
          Meeting Distribution
        </Typography>
        <Typography>
          {!isLinkFirst(getValues("inviteStyle"))
            ? `Set hosts and calendar distribution for this template.`
            : `Set hosts for this template.`}
        </Typography>
      </Box>

      <Stack
        sx={{
          gap: 2,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Stack>
          <Typography variant="h5" fontWeight="bold">
            Meeting Hosts
          </Typography>

          <Typography sx={{ mb: 3 }}>
            {!isLinkFirst(getValues("inviteStyle"))
              ? `Select which team will handle this meeting and how it will be routed among the hosts on the team.`
              : `Select which team will handle this meeting.`}
          </Typography>

          {isDefaultUser && (
            <Stack sx={{ mb: 7 }}>
              <DefaultUserRouting />
            </Stack>
          )}

          {!isDefaultUser && (
            <MeetingTemplateTeamRouting
              onTeamChange={props.onTeamChange}
              showCustom={props.showCustom}
            />
          )}

          <Box sx={{ mb: 7 }}>
            <Typography variant="h5" fontWeight="bold">
              BCC
            </Typography>
            <Typography sx={{ mb: 3 }}>
              Enter email address of the recipeient you wish to be BCC'd on
              meeting invites.
            </Typography>
            <TextField
              {...register("properties.bcc")}
              size="small"
              label="BCC"
              sx={{ width: "50%", mb: 2 }}
            />
          </Box>

          {!isLinkFirst(getValues("inviteStyle")) && (
            <Box sx={{ mb: 7 }}>
              <Typography variant="h5" fontWeight="bold">
                Calendar Distribution
              </Typography>
              <Typography sx={{ mb: 3 }}>
                Select how meetings should be scheduled on Hosts calendar.
              </Typography>
              <Controller
                control={control}
                name="distribution"
                render={({ field }) => (
                  <Stack sx={{ flexDirection: "row", gap: 5 }}>
                    <Box sx={{ flex: "1 1 0", maxWidth: "260px" }}>
                      <SelectableBox
                        selected={field.value === "sequential"}
                        onClick={() => field.onChange("sequential")}
                      >
                        <Stack
                          sx={{
                            mb: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            color: "primary.dark",
                            gap: 2,
                          }}
                        >
                          <Typography variant="h5" fontWeight="bold">
                            Front Load
                          </Typography>
                        </Stack>
                        <Typography>
                          The meeting time proposed will be the first
                          availability of the guest within day range.
                        </Typography>
                      </SelectableBox>
                    </Box>

                    <Box sx={{ flex: "1 1 0", maxWidth: "260px" }}>
                      <SelectableBox
                        selected={field.value === "random_high_volume"}
                        onClick={() => field.onChange("random_high_volume")}
                      >
                        <Stack
                          sx={{
                            mb: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            color: "primary.dark",
                            gap: 2,
                          }}
                        >
                          <Typography
                            variant="h5"
                            fontWeight="bold"
                            color="primary.dark"
                          >
                            High Density
                          </Typography>
                        </Stack>
                        <Typography>
                          The meetings are distributed randomly and are stacked.
                          This type of calendar distribution can stack up to 14
                          meetings at the same time slot.
                        </Typography>
                        <br />
                        <br />
                        <Typography>
                          In case of a double booking a user may have to move
                          the meetings.
                        </Typography>
                      </SelectableBox>
                    </Box>
                  </Stack>
                )}
              />
            </Box>
          )}
        </Stack>

        {!isDefaultUser && (
          <Stack
            sx={{
              flexShrink: 0,
              backgroundColor: "#F8FBFF",
              padding: 2.5,
              maxHeight: "800px",
              overflow: "auto",
            }}
          >
            <Box>
              <Typography>Meeting Hosts:</Typography>
              <Typography
                sx={{ fontWeight: "bold", fontSize: 22, marginBottom: 4 }}
              >
                {props.currentTeam?.name}
              </Typography>
            </Box>
            <Stack sx={{ gap: 4 }}>
              {props.routingLogic?.map((routingLogicItem) => (
                <Stack
                  key={routingLogicItem.email}
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 2,
                    }}
                  >
                    <Switch
                      checked={routingLogicItem.enabled}
                      onChange={(event) => {
                        props.onUpdateRoutingLogicUser({
                          ...routingLogicItem,
                          enabled: event.target.checked,
                        });
                      }}
                    />
                    <Typography
                      title={routingLogicItem.email}
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whitespace: "nowrap",
                        width: "260px",
                      }}
                    >
                      {routingLogicItem.email}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

const getErrorMessage = (error: unknown) => {
  let errorMessage = "Unkown error";

  if (error instanceof Error && JSON.parse(error.message).message) {
    errorMessage = JSON.parse(error.message).message;
  }

  // Sometimes the BE returns the errors as an array, so we show the first one.
  if (error instanceof Error && JSON.parse(error.message).errors[0]) {
    errorMessage = JSON.parse(error.message).errors[0];
  }

  return errorMessage;
};

const MeetingTemplateTimeAttributes = () => {
  const { control, setValue, getValues } = useFormContext<MeetingDefinition>();

  return (
    <Box>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" fontWeight="bold">
          Time Attributes
        </Typography>
        <Typography>
          {!isLinkFirst(getValues("inviteStyle"))
            ? `Set duration of meeting, gap between meetings, scheduling range, and minimum scheduling notice.`
            : `Set duration of meeting and gap between meetings.`}
        </Typography>
      </Box>

      <Grid sx={{ mb: 7 }} container spacing={10}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" fontWeight="bold">
            Meeting Duration
          </Typography>
          <Typography sx={{ mb: 3 }}>
            How long should the meeting last for?
          </Typography>

          <Controller
            control={control}
            name="properties.duration"
            render={({ field }) => (
              <>
                <Slider
                  {...field}
                  getAriaLabel={() => "Meeting Duration"}
                  defaultValue={30}
                  valueLabelDisplay="auto"
                  step={15}
                  marks
                  min={15}
                  max={60}
                />
                <Typography>{field.value} Minutes</Typography>
              </>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h5" fontWeight="bold">
            Gap
          </Typography>
          <Typography sx={{ mb: 3 }}>
            What is the minimum amount of time between meetings?
          </Typography>

          <Controller
            control={control}
            name="properties.gap"
            render={({ field }) => (
              <>
                <Slider
                  {...field}
                  defaultValue={0}
                  getAriaLabel={() => "Gap"}
                  value={field.value}
                  valueLabelDisplay="auto"
                  step={15}
                  marks
                  min={0}
                  max={45}
                />
                <Typography>{field.value} Minutes</Typography>
              </>
            )}
          />
        </Grid>

        {!isLinkFirst(getValues("inviteStyle")) && (
          <>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5" fontWeight="bold">
                Minimum Scheduling Notice
              </Typography>
              <Typography sx={{ mb: 3 }}>
                What is the minimum scheduling notice needed before a meeting?
              </Typography>

              <Controller
                control={control}
                name="buffer_duration_mins"
                render={({ field }) => (
                  <>
                    <Slider
                      {...field}
                      getAriaLabel={() => "Minimum Scheduling Notice"}
                      defaultValue={60 * 3}
                      valueLabelDisplay="auto"
                      step={60}
                      marks
                      min={60}
                      max={60 * 24}
                      onChange={(_, value) => {
                        if (Array.isArray(value)) {
                          return;
                        }
                        field.onChange(value);
                        const bufferHours = Math.ceil(value / 60);
                        setValue(
                          "properties.sameDayBuffer",
                          Math.min(bufferHours + 3, 24)
                        );
                      }}
                      valueLabelFormat={(minutes) => Math.floor(minutes / 60)}
                    />
                    <Typography>
                      {Math.floor(field.value / 60)} hours
                    </Typography>
                  </>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="h5" fontWeight="bold">
                Day Range
              </Typography>
              <Typography sx={{ mb: 3 }}>
                How far ahead of time should Kronologic start scheduling these
                meetings?{" "}
              </Typography>

              <Controller
                control={control}
                name="properties.dayRange"
                render={({ field }) => (
                  <>
                    <Slider
                      value={[field.value?.from ?? 1, field.value?.to ?? 30]}
                      onChange={(_, newValue) => {
                        if (!Array.isArray(newValue)) {
                          return;
                        }
                        field.onChange({
                          ...field.value,
                          from: newValue[0],
                          to: newValue[1],
                        });
                      }}
                      getAriaLabel={() => "Day Range"}
                      defaultValue={[1, 30]}
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={0}
                      max={30}
                    />
                    <Typography>
                      {field.value?.from} days to {field.value?.to} days
                    </Typography>
                  </>
                )}
              />
            </Grid>
          </>
        )}
      </Grid>

      {!isLinkFirst(getValues("inviteStyle")) && (
        <>
          <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
            <Controller
              control={control}
              name="timeNegotiationEnabled"
              render={({ field }) => (
                <Switch {...field} checked={field.value} />
              )}
            />
            <Typography variant="h5" fontWeight="bold">
              AI Negotiation
            </Typography>
          </Stack>
          <Typography sx={{ mb: 3 }}>
            Enabling will allow Kronologic’s AI to automatically reschedule
            meetings with guests if they propose a new time
          </Typography>

          <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
            <Controller
              control={control}
              name="oooProcessingEnabled"
              render={({ field }) => (
                <Switch {...field} checked={field.value} />
              )}
            />
            <Typography variant="h5" fontWeight="bold">
              AI Out of Office Detection
            </Typography>
          </Stack>

          <Typography sx={{ mb: 3 }}>
            Enabling will allow Kronologic’s AI to detect if an out of office
            response is recieved and reschedule the meeting upon the guests
            stated return{" "}
          </Typography>
        </>
      )}
    </Box>
  );
};

type SelectableBoxProps = {
  selected: boolean;
  children: React.ReactNode;
  onClick: () => void;
};

const SelectableBox = (props: SelectableBoxProps) => {
  const theme = useTheme();
  return (
    <Box
      onClick={props.onClick}
      sx={{
        height: "100%",
        padding: "10px",
        borderRadius: "5px",
        border: `1px solid ${props.selected ? "transparent" : "#E1E6EB"}`,
        outline: `2px solid ${
          props.selected ? theme.palette.primary.main : "transparent"
        }`,
        cursor: "pointer",
        "&:hover": {
          outline: `2px solid ${theme.palette.primary.main}`,
          border: "1px solid transparent",
        },
      }}
    >
      {props.children}
    </Box>
  );
};

const MeetingTemplateCalendarInvite = () => {
  const [selectedTab, setSelectedTab] = useState<"draft" | "preview">("draft");
  const [aiModalOpen, setAIModalOpen] = useState<boolean>(false);
  const openAIEnabled = useOpenAiIntegration();

  const {
    register,
    control,
    getValues,
    formState: { errors },
  } = useFormContext<MeetingDefinition>();

  const previewTitle = getDynamicVariableMappings(
    getValues("inviteTemplates.0.title")
  );
  const previewLocation = getDynamicVariableMappings(
    getValues("inviteTemplates.0.location")
  );
  const previewNotes = getDynamicVariableMappings(
    getValues("inviteTemplates.0.notes")
  );

  return (
    <>
      <Tabs
        sx={{ mb: 3 }}
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
        aria-label="basic tabs example"
      >
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Draft"
          value="draft"
        />
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Preview"
          value="preview"
        />
      </Tabs>
      {selectedTab === "draft" && (
        <>
          <TextField
            {...register(`inviteTemplates.0.title`, {
              required: {
                value: true,
                message: "Invite Title is required",
              },
              minLength: {
                value: 5,
                message: "Invite Title must be at least 5 characters.",
              },
            })}
            size="small"
            label="Invite Title"
            sx={{ width: "100%", mb: 2 }}
          />
          {errors.inviteTemplates?.[0]?.title && (
            <Box sx={{ color: "red", mb: 3 }}>
              {errors.inviteTemplates?.[0]?.title.message}
            </Box>
          )}

          <TextField
            {...register(`inviteTemplates.0.location`, {
              required: {
                value: true,
                message: "Invite Location is required",
              },
            })}
            size="small"
            label="Invite Location"
            sx={{ width: "100%", mb: 2 }}
          />
          {errors.inviteTemplates?.[0]?.location && (
            <Box sx={{ color: "red", mb: 3 }}>
              {errors.inviteTemplates?.[0]?.location.message}
            </Box>
          )}

          <Controller
            control={control}
            name="inviteTemplates.0.notes"
            rules={{
              required: {
                value: true,
                message: "Invite Message is required",
              },
              validate: (value) =>
                value.trim().length > 0 || "Invite Message is required",
            }}
            render={({ field }) => (
              <>
                <TemplateAIModal
                  open={aiModalOpen}
                  onClose={() => {
                    setAIModalOpen(false);
                  }}
                  onAIResponse={(text: string) => {
                    field.onChange(text.trimStart());
                  }}
                />

                <TextField
                  {...field}
                  size="small"
                  label="Invite Message"
                  multiline
                  rows={10}
                  sx={{ width: "100%", mb: 2 }}
                />
              </>
            )}
          />
          {errors.inviteTemplates?.[0]?.notes && (
            <Box sx={{ color: "red", mb: 3 }}>
              {errors.inviteTemplates?.[0]?.notes.message}
            </Box>
          )}
        </>
      )}
      {selectedTab === "preview" && (
        <>
          <Typography sx={{ mb: 1 }} variant="h6" fontWeight="bold">
            {previewTitle}
          </Typography>
          <Typography sx={{ mb: 4 }} color="primary">
            {previewLocation}
          </Typography>
          <Typography component="div">
            <TipTapPreview content={previewNotes} />
          </Typography>
        </>
      )}
      {openAIEnabled && (
        <SecondaryButton
          onClick={() => {
            setAIModalOpen(true);
          }}
        >
          Use AI to write your invite
        </SecondaryButton>
      )}
    </>
  );
};

type MeetingTemplateEmailInviteProps = {
  emailIndex: number;
};

const EmailTemplateInvite = (props: MeetingTemplateEmailInviteProps) => {
  const [selectedTab, setSelectedTab] = useState<"draft" | "preview">("draft");
  const [aiModalOpen, setAIModalOpen] = useState<boolean>(false);
  const [editor, setEditor] = useState<Editor>();
  const theme = useTheme();
  const openAIEnabled = useOpenAiIntegration();

  const {
    register,
    control,
    getValues,
    formState: { errors },
  } = useFormContext<MeetingDefinition>();

  const previewTitle = getDynamicVariableMappings(
    getValues(`emailTemplates.${props.emailIndex}.title`)
  );
  const previewBody = getDynamicVariableMappings(
    getValues(`emailTemplates.${props.emailIndex}.body`)
  );

  return (
    <>
      <Tabs
        sx={{ mb: 3 }}
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
        aria-label="basic tabs example"
      >
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Draft"
          value="draft"
        />
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Preview"
          value="preview"
        />
      </Tabs>
      <Box sx={{ mb: 5 }}>
        {selectedTab === "draft" && (
          <>
            <TextField
              {...register(`emailTemplates.${props.emailIndex}.title`, {
                required: {
                  value: true,
                  message: "Email title is required",
                },
              })}
              size="small"
              label="Email Title"
              sx={{ width: "100%", mb: 2 }}
            />
            {errors.emailTemplates?.[props.emailIndex]?.title && (
              <Box sx={{ color: "red", mb: 3 }}>
                {errors.emailTemplates?.[props.emailIndex]?.title?.message}
              </Box>
            )}

            <Controller
              control={control}
              name={`emailTemplates.${props.emailIndex}.body`}
              rules={{
                validate: (value) =>
                  value
                    .replaceAll(" ", "")
                    .replaceAll("<div><br></div>", "")
                    .replaceAll("<div></div>", "")
                    .replaceAll('<p style="margin: 0"></p>', "").length > 0 ||
                  "Email Body is required",
              }}
              render={({ field }) => (
                <>
                  <TemplateAIModal
                    open={aiModalOpen}
                    onClose={() => {
                      setAIModalOpen(false);
                    }}
                    onAIResponse={(text: string) => {
                      const cleaned = cleanRichTextHTML(text);

                      editor?.commands.setContent(cleaned);
                      field.onChange(cleaned);
                    }}
                  />

                  <Box sx={{ mb: 2 }}>
                    <EmailRichtextEditor
                      value={field.value ?? ""}
                      onEditorCreated={(editor) => setEditor(editor)}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                    />
                  </Box>
                </>
              )}
            />
            {errors.emailTemplates?.[props.emailIndex]?.body && (
              <Box sx={{ color: "red", mb: 3 }}>
                {errors.emailTemplates?.[props.emailIndex]?.body?.message}
              </Box>
            )}
          </>
        )}
        {selectedTab === "preview" && (
          <>
            <Typography sx={{ mb: 4 }} variant="h6" fontWeight="normal">
              <Typography component="span" fontWeight="bold" fontSize="inherit">
                Subject:
              </Typography>{" "}
              {previewTitle}
            </Typography>
            {/* Only show this if "Kronologic Email" is selected */}
            {getValues("inviteStyle") === "custom" && (
              <Stack
                sx={{
                  borderRadius: "5px",
                  border: "1px solid #E1E6EB",
                  flexDirection: "row",
                  mb: 3,
                }}
              >
                <Stack
                  sx={{
                    py: 9,
                    px: 4,
                    borderRight: "1px solid #E1E6EB",
                    backgroundColor: "#E1E6EB",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Paper elevation={1}>
                    <Box
                      sx={{
                        py: 1,
                        px: 2,
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: "5px 5px 0 0",
                      }}
                    >
                      <Typography
                        sx={{
                          textTransform: "uppercase",
                          textAlign: "center",
                          color: "white",
                        }}
                      >
                        MONTH
                      </Typography>
                    </Box>
                    <Stack
                      sx={{
                        backgroundColor: "white",
                        py: 1,
                        px: 2,
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "0 0 5px 5px",
                      }}
                    >
                      <Typography sx={{ fontSize: "30px", fontWeight: "bold" }}>
                        ##
                      </Typography>
                      <Typography sx={{ fontSize: "12px" }}>
                        00:00 AM PST
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: theme.palette.primary.dark,
                        }}
                      >
                        Day
                      </Typography>
                    </Stack>
                  </Paper>
                </Stack>
                <Box sx={{ py: 3, px: 4 }}>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6">
                      Calendar Invite Subject Line
                    </Typography>
                    <Typography>## Minutes</Typography>
                    <Typography>CST</Typography>
                  </Box>

                  <Box sx={{ mb: 3 }}>
                    <Typography variant="h6">
                      [DOW] [Month] [##] [YYYY] 00:00am CST{" "}
                    </Typography>
                    <Typography>
                      Location:
                      https://us02web.zoom.us/j/sdfalkjwerlkjwerlkjlkjlkj
                    </Typography>
                  </Box>

                  <Stack sx={{ mb: 1, flexDirection: "row", gap: 1 }}>
                    <Box
                      sx={{
                        borderRadius: "5px",
                        color: "#014A74",
                        border: "1px solid #014A74",
                        p: 1,
                        fontSize: "12px",
                      }}
                    >
                      Accept
                    </Box>
                    <Box
                      sx={{
                        borderRadius: "5px",
                        color: "#014A74",
                        border: "1px solid #014A74",
                        p: 1,
                        fontSize: "12px",
                      }}
                    >
                      Decline
                    </Box>
                  </Stack>

                  <Typography
                    component="span"
                    sx={{ color: theme.palette.primary.main }}
                  >
                    See more available times
                  </Typography>
                </Box>
              </Stack>
            )}
            <Typography component="div">
              <TipTapPreview content={previewBody} />
            </Typography>
          </>
        )}
        {openAIEnabled && (
          <SecondaryButton
            onClick={() => {
              setAIModalOpen(true);
            }}
          >
            Use AI to write your email
          </SecondaryButton>
        )}
      </Box>
    </>
  );
};

const MeetingTemplateDeclineEmail = () => {
  const [selectedTab, setSelectedTab] = useState<"draft" | "preview">("draft");

  const { control, getValues } = useFormContext<MeetingDefinition>();

  const previewTitle = getDynamicVariableMappings(
    getValues("properties.cleanDeclineRule.action.meta.title")
  );
  const previewBody = getDynamicVariableMappings(
    getValues("properties.cleanDeclineRule.action.meta.body")
  );

  return (
    <>
      <Tabs
        sx={{ mb: 3 }}
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
        aria-label="Decline Email Tabs"
      >
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Draft"
          value="draft"
        />
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Preview"
          value="preview"
        />
      </Tabs>
      <Box sx={{ mb: 5 }}>
        {selectedTab === "draft" && (
          <>
            <Controller
              control={control}
              name={"properties.cleanDeclineRule.action.meta.title"}
              rules={{
                required: "Decline Email Title is required",
                minLength: {
                  value: 5,
                  message: "Decline Email Title must be at least 5 characters.",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <TextField
                    {...field}
                    size="small"
                    label="Decline Email Title"
                    sx={{ width: "100%", mb: 2 }}
                  />
                  {error && (
                    <Box sx={{ color: "red", mb: 3 }}>{error?.message}</Box>
                  )}
                </>
              )}
            />
            <Controller
              control={control}
              name={"properties.cleanDeclineRule.action.meta.body"}
              rules={{
                validate: (value) =>
                  value
                    .replaceAll(" ", "")
                    .replaceAll("<div><br></div>", "")
                    .replaceAll("<div></div>", "")
                    .replaceAll('<p style="margin: 0"></p>', "").length > 0 ||
                  "Decline Email Body is required",
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Box sx={{ mb: 3 }}>
                    <EmailRichtextEditor
                      value={field.value ?? ""}
                      onChange={(value) => field.onChange(value)}
                    />
                  </Box>
                  {error && (
                    <Box sx={{ color: "red", mb: 3 }}>{error.message}</Box>
                  )}
                </>
              )}
            />
          </>
        )}
        {selectedTab === "preview" && (
          <>
            <Typography sx={{ mb: 4 }} variant="h6" fontWeight="bold">
              {previewTitle}
            </Typography>
            <Typography component="div">
              <TipTapPreview content={previewBody} />
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

const MeetingTemplateReminderEmail = () => {
  const [selectedTab, setSelectedTab] = useState<"draft" | "preview">("draft");

  const { control, getValues } = useFormContext<MeetingDefinition>();

  const previewTitle = getDynamicVariableMappings(
    getValues("properties.meetingReminder.title")
  );
  const previewBody = getDynamicVariableMappings(
    getValues("properties.meetingReminder.body")
  );

  return (
    <>
      <Tabs
        sx={{ mb: 3 }}
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
        aria-label="Meeting Reminder Email Tabs"
      >
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Draft"
          value="draft"
        />
        <Tab
          sx={{ borderBottom: 1, borderColor: "divider" }}
          label="Preview"
          value="preview"
        />
      </Tabs>
      <Box sx={{ mb: 1 }}>
        {selectedTab === "draft" && (
          <>
            <Controller
              control={control}
              name="properties.meetingReminder.title"
              rules={{
                required: "Reminder Email Title is required",
                minLength: {
                  value: 5,
                  message:
                    "Reminder Email Title must be at least 5 characters.",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <TextField
                    {...field}
                    size="small"
                    label="Reminder Email Title"
                    sx={{ width: "100%", mb: 2 }}
                  />
                  {error && (
                    <Box sx={{ color: "red", mb: 3 }}>{error?.message}</Box>
                  )}
                </>
              )}
            />
            <Controller
              control={control}
              name="properties.meetingReminder.body"
              rules={{
                validate: (value) =>
                  value
                    .replaceAll(" ", "")
                    .replaceAll("<div><br></div>", "")
                    .replaceAll("<div></div>", "")
                    .replaceAll('<p style="margin: 0"></p>', "").length > 0 ||
                  "Reminder Email Body is required",
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Box sx={{ mb: 3 }}>
                    <EmailRichtextEditor
                      value={field.value ?? ""}
                      onChange={(value) => field.onChange(value)}
                    />
                  </Box>
                  {error && (
                    <Box sx={{ color: "red", mb: 3 }}>{error.message}</Box>
                  )}
                </>
              )}
            />
          </>
        )}
        {selectedTab === "preview" && (
          <>
            <Typography sx={{ mb: 4 }} variant="h6" fontWeight="bold">
              {previewTitle}
            </Typography>
            <Typography component="div">
              <TipTapPreview content={previewBody} />
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

const MeetingTemplateReminderOptions = () => {
  const { control } = useFormContext<MeetingDefinition>();

  const dayOptions: number[] = buildRange([0, 10, 1]);
  const hourOptions: number[] = buildRange([0, 23, 1]);
  const minuteOptions: number[] = buildRange([0, 45, 15]);

  return (
    <>
      <Typography
        sx={{
          marginBottom: 2,
        }}
      >
        Meeting reminders will not be sent if either the guest has accepted the
        meeting on day of the meeting or if the guest would have received a
        reminder email within 5 hours of accepting the meeting
      </Typography>

      <Stack direction="row" sx={{ width: "25%" }}>
        <Controller
          control={control}
          name="properties.meetingReminder.days"
          render={({ field }) => (
            <FormControl fullWidth sx={{ marginRight: "10px" }}>
              <InputLabel id="reminder-days-select">Days</InputLabel>
              <Select
                sx={{ marginRight: "5px", width: "100%" }}
                label="Days"
                value={field.value}
                onChange={(event) => {
                  field.onChange(event.target.value as number);
                }}
              >
                {dayOptions.map((day) => (
                  <MenuItem key={`day-${day}`} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="properties.meetingReminder.hours"
          render={({ field }) => (
            <FormControl fullWidth sx={{ marginRight: "10px" }}>
              <InputLabel id="reminder-hours-select">Hours</InputLabel>
              <Select
                sx={{ width: "100%" }}
                label="Hours"
                value={field.value}
                onChange={(event) => {
                  field.onChange(event.target.value as number);
                }}
              >
                {hourOptions.map((hour) => (
                  <MenuItem key={`hour-${hour}`} value={hour}>
                    {hour}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="properties.meetingReminder.minutes"
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel id="reminder-minutes-select">Minutes</InputLabel>
              <Select
                sx={{ width: "100%" }}
                label="Minutes"
                value={field.value}
                onChange={(event) => {
                  field.onChange(event.target.value as number);
                }}
              >
                {minuteOptions.map((minutes) => (
                  <MenuItem key={`minutes-${minutes}`} value={minutes}>
                    {minutes}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Stack>
    </>
  );
};
