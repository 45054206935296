import React from "react";
import cx from "classnames";

import style from "./radio.module.scss";

export function RadioButton({
  dataCy = null,
  dataGtm = null,
  disabled: isDisabled,
  checked: isChecked,
  name = null,
  onClick,
  className,
}) {
  const dataProps = {};
  if (dataGtm == null) {
    dataProps["data-gtm"] = name;
  }
  if (dataCy == null) {
    dataProps["data-cy"] = name;
  }
  return (
    <button
      className={cx(
        style.button,
        style["button--standard"],
        {
          [style["button--inactive"]]: !isChecked && !isDisabled,
          [style["button--active"]]: isChecked && !isDisabled,
          [style["button--disabled"]]: isDisabled,
        },
        className
      )}
      disabled={isDisabled}
      type="button"
      name={name}
      onClick={onClick}
      {...dataProps}
    >
      {isChecked && !isDisabled && (
        <div
          className={cx(
            style.button__activeInnerCircle,
            style["button--innerCircle--standard"]
          )}
        />
      )}
      {isChecked && isDisabled && (
        <div
          className={cx(
            style.button__disabledInnerCircle,
            style["button--innerCircle--standard"]
          )}
        />
      )}
    </button>
  );
}
