import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";

import { useUserEmailAddress } from "../../auth";
import {
  LinkFirstMeetingRequestData,
  useOneClickScheduling,
  useValidateOneClickToken,
} from "../../queries";
import { useLocalStorage } from "../../hooks";
import { InvalidUrlError } from "../../componentsV2/confirmation";
import { OneClickLogin } from "../../componentsV2/OneClickLogin";
import { OneClickLoading } from "../../componentsV2/OneClickLoading";
import { OneClickBackground } from "./OneClickBackground";
import dayjs from "dayjs";
import { isLinkFirst } from "../../meetingTypes/invite/props";
import { Card } from "@mui/material";

type RouteProps = {
  schedulingToken?: string;
  header?: string;
  data?: string;
  signature?: string;
};

function OneClickHandling() {
  const { header, data, signature, schedulingToken } = useParams<RouteProps>();

  const token = schedulingToken || `${header}.${data}.${signature}`;

  const history = useHistory();

  const [storedEmail] = useLocalStorage("VALID_EMAIL_ADDRESS", null);
  const [email, setEmail] = useState(
    useUserEmailAddress() || storedEmail || ""
  );
  const [meetingStartTime, setMeetingStartTime] = useState<dayjs.Dayjs | null>(
    null
  );

  const {
    data: validationData,
    error: validationError,
    loading: validationLoading,
  } = useValidateOneClickToken(token);

  let linkFirstInviteData: LinkFirstMeetingRequestData | null = null;
  const isLinkFirstType = isLinkFirst(validationData?.meetingInviteStyle);

  if (isLinkFirstType) {
    const meetingId = validationData?.meetingId;
    const startTime = meetingStartTime;

    linkFirstInviteData = {
      meetingId,
      startTime,
      isValid: () => {
        return !!(meetingId && startTime?.isValid());
      },
    };
  }

  const { loading, error } = useOneClickScheduling(
    email,
    validationData?.valid ? token : null,
    linkFirstInviteData
  );

  // The following section will help ensure the processing screen is shown for a minimum amount of time.
  const [minLoadingTimeElapsed, setMinLoadingTimeElapsed] = useState(false);
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (email) {
      setMinLoadingTimeElapsed(false);
      timer = setTimeout(() => {
        setMinLoadingTimeElapsed(true);
      }, 2750);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [email]);

  return (
    <Card
      sx={{
        zIndex: 10,
        px: "50px",
        py: "30px",
        maxWidth: isLinkFirstType ? "748px" : "448px",
        width: "100%",
        minHeight: "440px",
      }}
    >
      {error?.response?.status === 400 && (
        <InvalidUrlError
          referenceId={error.response?.body?.traceID}
          onOkClicked={() => history.push("/")}
        />
      )}
      {((error &&
        error?.response?.status !== 409 &&
        error?.response?.status !== 400) ||
        validationError) && (
        <Redirect
          to={{
            pathname: "/error",
            state: {
              refId: error?.response?.body?.traceID,
              url: window.location.href,
            },
          }}
        />
      )}

      {!(loading || !minLoadingTimeElapsed) &&
        error?.response?.status === 409 && (
          <Redirect
            to={{ pathname: "/pending", state: { url: window.location.href } }}
          />
        )}
      {!(loading || !minLoadingTimeElapsed) && email.length > 0 && !error && (
        <Redirect
          to={{
            pathname: "/success",
            state: { email, url: window.location.href },
          }}
        />
      )}

      {!email && (
        <OneClickLogin
          onSubmit={(
            newEmail: string | null,
            newMeetingStartTime: dayjs.Dayjs | null
          ) => {
            newEmail && setEmail(newEmail);
            newMeetingStartTime && setMeetingStartTime(newMeetingStartTime);
          }}
          meetingId={validationData?.meetingId}
          oneClickToken={schedulingToken}
        />
      )}

      {email.length > 0 &&
        (loading || validationLoading || !minLoadingTimeElapsed) && (
          <OneClickLoading />
        )}
    </Card>
  );
}

export default function Page() {
  return (
    <OneClickBackground>
      <OneClickHandling />
    </OneClickBackground>
  );
}
