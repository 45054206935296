import { useSWRConfig } from "swr";

import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useErrorHandler } from "../hooks";
import { useUserService } from "../services";

export function useUpdateSchedulers() {
  const accessToken = getUserToken();
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const errorHandler = useErrorHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (
    schedulers: {
      from_time_minutes: number;
      until_time_minutes: number;
      weekdays: number[];
    },
    userIds: number[]
  ): Promise<void> =>
    service
      .patch(`/api/app/settings/schedulers`)
      .set(headers)
      .send({
        schedulers,
        userIds,
      })
      .then((res: Response) => res.body)
      .then((res: any) => {
        // @ts-ignore
        const keys = cache.keys();

        // Invalidate meeting queries and force refresh.
        Array.from(keys)
          .filter((r: any) => r.includes("/api/users"))
          .forEach((k: any) => {
            mutate(k);
          });

        return res;
      })
      .catch(errorHandler);
}

export default useUpdateSchedulers;
