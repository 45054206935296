import React from "react";
import Flexbox from "../../components/flexbox/FlexboxV2";
import { TinySpacing, XXLargeSpacing } from "../../components/spacing";
import { P1, H6 } from "../../components/typography";
import { formatCurrency } from "../../utils/format";

function PreviewHeaderDefaultUser({ cost = 1000 }) {
  return (
    <Flexbox.Row justifyContent="center">
      <Flexbox.Column flex={0}>
        <XXLargeSpacing />
        <P1 small whiteSpace="nowrap">
          Meeting Value
        </P1>
        <TinySpacing />
        <H6>{formatCurrency(cost)}</H6>
        <XXLargeSpacing />
      </Flexbox.Column>
    </Flexbox.Row>
  );
}

export default PreviewHeaderDefaultUser;
