import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";

import Drawer from "./Drawer";

import { useDebounce, useDeleteConfirmationDialog } from "../../hooks";

import TeamList from "../TeamList";
import { useTeams } from "../../queries";
import EditTeamDialog from "../dialogs/EditTeamDialog";
import { CloneTeamDialog } from "../dialogs";
import { Team } from "src/types";
import { AddCircleRounded } from "@mui/icons-material";
import Input from "../inputs/Input";

export function TeamDrawer({
  open,
  onClose = () => null,
}: {
  open?: boolean;
  onClose?: () => void;
}) {
  const theme = useTheme();
  const history = useHistory();
  const openDeleteConfirmationDialog = useDeleteConfirmationDialog();

  const [search, setSearch] = useState("");
  const [showEditTeamDialog, setShowEditTeamDialog] = useState<Team | null>(
    null
  );
  const [showCloneTeamDialog, setShowCloneTeamDialog] = useState<Team | null>(
    null
  );
  const s = useDebounce(search, 750);

  const { data, loading, error } = useTeams(50, 0, {
    qry: s,
    sortBy: "name+asc",
  });

  return (
    <>
      {showEditTeamDialog && (
        <EditTeamDialog
          open
          team={showEditTeamDialog}
          onSubmit={() => alert("Not yet implemented")}
          onClose={() => setShowEditTeamDialog(null)}
        />
      )}
      {showCloneTeamDialog && (
        <CloneTeamDialog
          open
          team={showCloneTeamDialog}
          onClose={() => setShowCloneTeamDialog(null)}
        />
      )}
      <Drawer
        anchor="left"
        title="Teams"
        open={open}
        onClose={onClose}
        actions={
          <IconButton onClick={() => null}>
            <AddCircleRounded />
          </IconButton>
        }
      >
        <Box sx={{ pb: 2 }}>
          <Input
            label="Search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Box>
        <Box
          sx={{
            border: 1,
            borderColor: theme.palette.divider,
            borderRadius: "5px",
            borderStyle: "solid",
            height: "100%",
            overflow: "scroll",
          }}
        >
          <TeamList
            teams={data?.data}
            onSelect={(t) => {
              history.push(`/users?team=${t.id}`);
              onClose();
            }}
            onEdit={(t) => {
              setShowEditTeamDialog(t);
            }}
            onClone={(t) => {
              setShowCloneTeamDialog(t);
            }}
            onDelete={(t) => {
              openDeleteConfirmationDialog(
                `Are you sure you wish delete team "${t.name}"?`,
                () => {
                  // deleteMeetingType(t.id).catch(() => {
                  //   addGeneralNotification("Failed to delete meeting type");
                  // });
                }
              );
            }}
          />
        </Box>
      </Drawer>
    </>
  );
}

export default TeamDrawer;
