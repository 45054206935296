import { useEffect, useState } from "react";

import { UnauthorizedError, toErrorLogInfo } from "../error";
import {
  buildRequest,
  buildUrl,
  fetchWithErrorHandling,
} from "../utils/fetchV2";
import { API } from "../props";
import { useActingAs, useIsDefaultUser } from "../auth";
import { ROLE_LEVELS } from "../auth/roles";

export function useSearchTeams() {
  const isDefaultUser = useIsDefaultUser();
  // TODO: Refactor this to return response and stop signal
  return async function searchTeams(filter) {
    // default users do not have access to teams data
    if (isDefaultUser) {
      return;
    }
    try {
      const query = [
        ["limit", 100],
        ["offset", 0],
      ];
      if (filter) {
        query.push(["qry", filter]);
      }
      const request = buildRequest(buildUrl(API.teams.default(), query));
      const response = await fetchWithErrorHandling(request);
      const { status } = response;
      if (status === 401) {
        throw new UnauthorizedError(
          await response.text(),
          toErrorLogInfo("UnauthorizedError", request)
        );
      }
      if (status === 204) {
        return {
          data: [],
          error: null,
        };
      }
      if (status !== 200) {
        throw new Error(await response.text());
      }
      return {
        data: await response.json(),
        error: null,
      };
    } catch (error) {
      return {
        data: null,
        error,
      };
    }
  };
}

function useTeamMetadata(teamId = null) {
  const [team, setTeam] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const isDefaultUser = useIsDefaultUser();
  const [actingAs] = useActingAs();

  useEffect(() => {
    // default users cannot access teams
    if (isDefaultUser || actingAs?.role === ROLE_LEVELS.DEFAULT) {
      setLoading(false);
      setTeam(null);
      return;
    }

    async function run() {
      try {
        if (teamId === null) {
          setLoading(false);
          setTeam(null);
          setError(null);
          return;
        }
        setLoading(true);
        const request = buildRequest(API.teams.default(teamId));
        const response = await fetchWithErrorHandling(request);
        const { status } = response;
        if (status === 401) {
          throw new UnauthorizedError(
            await response.text(),
            toErrorLogInfo("UnauthorizedError", request)
          );
        }
        if (status === 204) {
          return {
            data: [],
            error: null,
          };
        }
        if (status !== 200) {
          throw new Error(await response.text());
        }
        setTeam(await response.json());
        setError(null);
      } catch (error) {
        setError(error);
        setTeam(null);
      } finally {
        setLoading(false);
      }
    }

    run();
  }, [actingAs, isDefaultUser, teamId]);
  return {
    data: team,
    error,
    loading,
  };
}

function useGlobalTeamId() {
  const [team, setTeam] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isDefaultUser = useIsDefaultUser();
  const [actingAs] = useActingAs();

  useEffect(() => {
    // default user cannot access global team information
    if (isDefaultUser || actingAs?.role === ROLE_LEVELS.DEFAULT) {
      setLoading(false);
      return;
    }
    async function run() {
      try {
        setLoading(true);
        const request = buildRequest(API.teams.global);
        const response = await fetchWithErrorHandling(request);
        const { status } = response;
        if (status === 401) {
          throw new UnauthorizedError(
            await response.text(),
            toErrorLogInfo("UnauthorizedError", request)
          );
        }
        if (status === 204) {
          return {
            data: [],
            error: null,
          };
        }
        if (status !== 200) {
          throw new Error(await response.text());
        }
        const globalTeam = await response.json();
        setTeam(globalTeam);
        setError(null);
      } catch (error) {
        setError(error);
        setTeam(null);
      } finally {
        setLoading(false);
      }
    }
    run();
  }, [isDefaultUser, actingAs]);
  return {
    data: team?.id || null,
    error,
    loading,
  };
}

export function useTeamRepository() {
  return {
    useGlobalTeamId,
    useTeamMetadata,
  };
}
