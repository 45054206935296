import { ResponseError } from "superagent";

import { useLogout } from "./useLogout";

export function useErrorHandler() {
  const logout = useLogout();

  return function (err: ResponseError) {
    if (err) {
      if (err.status === 401) {
        logout();
      } else {
        throw err;
      }
    }
  };
}
