import { Delete } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Loading } from "src/componentsV2/Loading";
import { PageLayout } from "src/componentsV2/PageLayout";
import { TagList } from "src/componentsV2/TagList";
import { PrimaryButton } from "src/componentsV2/buttons/PrimaryButton";
import SecondaryButton from "src/componentsV2/buttons/SecondaryButton";
import TagManagementDialog from "src/componentsV2/dialogs/TagManagementDialog";
import { useConfirmationDialog, useSnackbar } from "src/hooks";
import { TagIcon } from "src/icons";
import {
  useDeleteContacts,
  useTagContacts,
  useUpdateContact,
} from "src/mutations";
import ErrorPage from "src/pages/ErrorPage";
import { useContact } from "src/queries";
import { ContactWithDetails } from "src/types";

function Contact({ data }: { data: ContactWithDetails }) {
  const history = useHistory();

  const [openSnackbar] = useSnackbar();
  const tagContacts = useTagContacts();
  const deleteContacts = useDeleteContacts();
  const updateContact = useUpdateContact();
  const openConfirmationDialog = useConfirmationDialog();

  const [tab, setTab] = useState(0);
  const [showTagManagementDialog, setShowTagManagementDialog] = useState(false);

  const {
    getValues,
    reset,
    register,
    formState: { isDirty },
  } = useForm<ContactWithDetails>({
    defaultValues: Object.assign(
      {
        account: "",
        customField1: "",
        customField10: "",
        customField2: "",
        customField3: "",
        customField4: "",
        customField5: "",
        customField6: "",
        customField7: "",
        customField8: "",
        customField9: "",
        email: "",
        firstName: "",
        id: 0,
        lastMeetingActivity: "",
        lastName: "",
        leadScore: "",
        logicField: "",
        meetingDefinitions: null,
        meetings: null,
        phone: "",
        profilePic: "",
        routingField: "",
        state: "",
        tags: [],
        unsubscribed: false,
        zip: "",
      },
      data
    ),
  });

  return (
    <>
      {showTagManagementDialog && (
        <TagManagementDialog
          label="Update Contact Tags"
          open
          initialTags={data.tags}
          onClose={() => {
            setShowTagManagementDialog(false);
          }}
          onSubmit={async (tags) => {
            try {
              await tagContacts(
                [data.id],
                tags.map((tag) => tag.id)
              );
              openSnackbar("Successfully updated tags");
              setShowTagManagementDialog(false);
            } catch {
              openSnackbar("Failed to update contact");
            }
          }}
        />
      )}
      <PageLayout
        title="Contact Details"
        breadcrumbs={[
          { name: "Contacts", route: "/contacts" },
          { name: data.id.toString(), route: `/contacts/${data.id}` },
        ]}
        actions={
          <>
            <IconButton
              disabled={data.unsubscribed}
              onClick={() => {
                setShowTagManagementDialog(true);
              }}
            >
              <TagIcon />
            </IconButton>
            <IconButton
              disabled={data.unsubscribed}
              onClick={async () => {
                openConfirmationDialog(
                  "Delete Confirmation",
                  `Are you sure you want to delete ${data.email}?`,
                  async () => {
                    try {
                      await deleteContacts([data.id]);
                      openSnackbar("Contact succesfully deleted");
                      history.push("/contacts");
                    } catch {
                      openSnackbar("Failed to delete contact");
                    }
                  }
                );
              }}
            >
              <Delete />
            </IconButton>
          </>
        }
      >
        <Stack spacing={1}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ width: "fit-content" }}
            >
              <Avatar
                src=""
                alt={`${data.firstName} ${data.lastName}`}
                sx={{ width: "120px", height: "120px" }}
              />
              <Stack>
                <Typography variant="h4">
                  {data.firstName} {data.lastName}
                </Typography>
                <Typography>{data.email}</Typography>
                <Typography variant="caption">
                  Source: {data.source || "Unknown"}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              {data.unsubscribed && <Chip size="small" label="Unsubscribed" />}
              <SecondaryButton
                onClick={() => {
                  history.push(
                    `/instances?query=${data.email}&categories=Guest+Email`
                  );
                }}
              >
                Meetings
              </SecondaryButton>
            </Stack>
          </Stack>
          {data.tags.length > 0 && (
            <Stack sx={{ flexDirection: "row", gap: 1 }}>
              <Typography component="span" fontWeight="bold">
                Tags:{" "}
              </Typography>
              <TagList tags={data.tags} />
            </Stack>
          )}
          <Box>
            <Tabs value={tab} onChange={(_, value) => setTab(value)}>
              <Tab label="Basic Info" sx={{ textTransform: "none" }} />
              <Tab label="Custom Fields" sx={{ textTransform: "none" }} />
            </Tabs>
            <Divider />
            <Box
              sx={{
                pt: 2,
                minHeight: "500px",
              }}
            >
              {tab === 0 && (
                <Stack spacing={2}>
                  <TextField label="First Name" {...register("firstName")} />
                  <TextField label="Last Name" {...register("lastName")} />
                  <TextField label="Email" disabled {...register("email")} />
                  <TextField label="Account" {...register("account")} />
                  <TextField
                    label="Logic Field 1"
                    {...register("logicField")}
                  />
                  <TextField label="Logic Field 2" {...register("leadScore")} />
                  <TextField label="Phone" {...register("phone")} />
                  <TextField
                    label="Routing Field"
                    {...register("routingField")}
                  />
                  <TextField label="State" {...register("state")} />
                  <TextField label="Zip" {...register("zip")} />
                </Stack>
              )}
              {tab === 1 && (
                <Stack spacing={2}>
                  <TextField
                    label="Custom Field 1"
                    {...register("customField1")}
                  />
                  <TextField
                    label="Custom Field 2"
                    {...register("customField2")}
                  />
                  <TextField
                    label="Custom Field 3"
                    {...register("customField3")}
                  />
                  <TextField
                    label="Custom Field 4"
                    {...register("customField4")}
                  />
                  <TextField
                    label="Custom Field 5"
                    {...register("customField5")}
                  />
                  <TextField
                    label="Custom Field 6"
                    {...register("customField6")}
                  />
                  <TextField
                    label="Custom Field 7"
                    {...register("customField7")}
                  />
                  <TextField
                    label="Custom Field 8"
                    {...register("customField8")}
                  />
                  <TextField
                    label="Custom Field 9"
                    {...register("customField9")}
                  />
                  <TextField
                    label="Custom Field 10"
                    {...register("customField10")}
                  />
                </Stack>
              )}
            </Box>
          </Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: 2 }}
          >
            <SecondaryButton disabled={!isDirty} onClick={() => reset()}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              disabled={!isDirty}
              onClick={() => {
                updateContact(getValues())
                  .then(() => {
                    openSnackbar("Successfully updated contact");
                  })
                  .catch(() => {
                    openSnackbar("Failed to update contact");
                  });
              }}
            >
              Save
            </PrimaryButton>
          </Stack>
        </Stack>
      </PageLayout>
    </>
  );
}

export default function Page() {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useContact(id);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  if (error) {
    return <ErrorPage status={error.status} />;
  }

  if (loading) {
    return <Loading />;
  }

  return <Contact data={data} />;
}
