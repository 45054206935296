import { Appcues } from "./AppCues";
import { useConfiguration } from "../configuration";
import { getUserDetails } from "src/utils/jwtToken";

export function Monitoring(props: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const {
    // ENVIRONMENT,
    APPCUES_ACCOUNT_ID,
  } = useConfiguration();

  const user = getUserDetails();

  return (
    <>
      <Appcues accountId={APPCUES_ACCOUNT_ID || ""} user={user} />
      {props.children}
    </>
  );
}
