import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { Chip, ListItem, ListItemText } from "@mui/material";

import { useDebounce } from "../../hooks";
import { useMeetingTypes } from "../../queries";
import { MeetingDefinition } from "../../types";
import { INVITE_STYLE } from "src/meetingTypes/invite/props";

export function MeetingTypeSelectInput({
  meetingType,
  onMeetingTypeSelect,
}: {
  meetingType: MeetingDefinition | null;
  onMeetingTypeSelect: (value: MeetingDefinition | null) => void;
}) {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");

  const query = useDebounce(input, 750);

  // TODO(vovan): we don't support 'link-first' meeting types yet.
  const inviteStyleFilter = [
    INVITE_STYLE.CALENDAR_FIRST,
    INVITE_STYLE.CUSTOM_INVITE,
  ];

  const { data, loading } = useMeetingTypes(100, 0, query, inviteStyleFilter);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Autocomplete
      open={open}
      // disablePortal
      onOpen={handleOpen}
      onClose={handleClose}
      inputValue={input}
      onInputChange={(_, value) => {
        setInput(value);
      }}
      onChange={(_, value) => {
        onMeetingTypeSelect(value);
      }}
      value={meetingType}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <ListItem key={option.id} {...props} dense>
          <ListItemText
            primary={option.name + (!option.active ? " (Inactive)" : "")}
          />
          {option.inviteStyle === "calendar_first" && (
            <Chip size="small" label="Kronologic Invite" />
          )}
          {option.inviteStyle === "custom" && (
            <Chip size="small" label="Kronologic Email" />
          )}
        </ListItem>
      )}
      getOptionDisabled={(option) => !option.active}
      options={data?.data || []}
      loading={loading}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          {...params}
          label="Meeting Template"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </>
            ),
          }}
        />
      )}
    />
  );
}
