import React from "react";
import Slider from "rc-slider";

import Flexbox from "../../components/flexbox/FlexboxV2";
import { P1 } from "../../components/typography";
import { useCanEdit, useGap } from "../context";
import { XSmallSpacing, SmallSpacing } from "../../components/spacing";
import useRailColor from "./useRailColor";
import style from "../style.module.scss";

export default function Gap() {
  const [gap, setGap] = useGap();
  const canEditMeetingType = useCanEdit();
  const { railColor } = useRailColor();
  return (
    <Flexbox.Column>
      <div className={style.meetingType__slider}>
        <Slider
          disabled={!canEditMeetingType}
          onChange={setGap}
          step={15}
          min={0}
          max={45}
          defaultValue={gap}
          railStyle={{
            backgroundColor: railColor,
          }}
        />
      </div>
      <SmallSpacing />
      <Flexbox.Row>
        <XSmallSpacing />
        <P1 small>{`${gap} minutes`}</P1>
      </Flexbox.Row>
    </Flexbox.Column>
  );
}
