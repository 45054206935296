import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";

import Flexbox from "../../components/flexbox/FlexboxV2";
import { H2 } from "../../components/typography";
import { JumboSpacing } from "../../components/spacing";
import useNewMeetingTemplate from "./useNewMeetingTemplate";

export default function CreateMeetingTemplate({ onCreate }) {
  const { build } = useNewMeetingTemplate();
  const history = useHistory();
  const createNewMeetingTemplate = useCallback(async () => {
    const newMeetingTemplate = build();
    const createdMeetingTemplate = await onCreate(newMeetingTemplate);
    history.push(`/meetingTypes/library/${createdMeetingTemplate.id}`);
  }, [build, history, onCreate]);
  useEffect(() => {
    createNewMeetingTemplate();
  }, [createNewMeetingTemplate]);
  return (
    <Flexbox.Column alignItems="center" justifyContent="center">
      <Icon path={mdiLoading} size={8} spin={1} />
      <JumboSpacing />
      <H2>Creating new meeting template...</H2>
    </Flexbox.Column>
  );
}
