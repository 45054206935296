import useSWR from "swr";

import { useUserService } from "../services";
import { useErrorHandler, useTokenRefreshHandler } from "../hooks";
import { MeetingLog } from "../types";
import dayjs from "dayjs";

export interface LinkFirstMeetingRequestData {
  meetingId?: string | null;
  startTime?: dayjs.Dayjs | null;
  isValid(): boolean;
}

export function useOneClickScheduling(
  email: string | null,
  token: string | null,
  linkFirstInviteData: LinkFirstMeetingRequestData | null
) {
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const errorHandler = useErrorHandler();

  let shouldExecuteQuery = !!(email && token);
  if (linkFirstInviteData) {
    shouldExecuteQuery = shouldExecuteQuery && linkFirstInviteData.isValid();
  }

  const meetingId = linkFirstInviteData?.meetingId;
  const startTime = linkFirstInviteData?.startTime;

  const { data, error, isValidating } = useSWR(
    shouldExecuteQuery ? `/api/meeting/scheduling` : null,
    (url: string) =>
      service
        .post(url)
        .send({ email, token, meetingId, startTime })
        .then(tokenRefreshHandler)
        .then((res: Response) => res.body)
        .catch(errorHandler),
    {
      refreshInterval: 0,
      shouldRetryOnError: false,
    }
  );

  return {
    data: data as { total: number; data: MeetingLog } | undefined,
    error,
    loading: isValidating,
  };
}
