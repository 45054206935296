const ELEMENT_NODE = 1;

let cache = null;
let inputCache = null;

export function findEditableHost(node = cache?.startContainer || null) {
  if (node === null) {
    return null;
  }
  const { nodeType } = node;
  if (nodeType === ELEMENT_NODE) {
    const isContentEditable = node.contentEditable === "true";
    if (isContentEditable) {
      return node;
    }
  }
  const { parentNode } = node;
  return findEditableHost(parentNode);
}

function setSelectedInput() {
  const selection = window.getSelection();
  if (selection.anchorNode?.dataset?.refId) {
    inputCache = selection.anchorNode;
  }
}

function setSelectionRange() {
  const selection = window.getSelection();
  if (selection.rangeCount < 1) {
    return null;
  }
  const range = selection.getRangeAt(0);
  const { endContainer, startContainer } = range;
  const startEditableHost = findEditableHost(startContainer);
  // non-editable start host detected
  if (startEditableHost === null) {
    return;
  }
  const endEditableHost = findEditableHost(endContainer);
  // non-editable end host detected
  if (endEditableHost === null) {
    return;
  }
  // Selection out of bounds of a single editable host
  if (startEditableHost !== endEditableHost) {
    return;
  }
  cache = range;
}

// addEventListener version
document.addEventListener("selectionchange", () => {
  setSelectionRange();
  setSelectedInput();
});

export function insertTextV2(text, refs) {
  if (inputCache !== null) {
    const refId = inputCache.dataset?.refId;
    const ref = refs[refId] || null;
    if (ref === null) {
      return;
    }
    const { selectionStart, selectionEnd, value } = ref.current;
    const startSubString = value.substring(0, selectionStart);
    const endSubString = value.substring(selectionEnd, value.length);
    ref.current.value = `${startSubString}${text}${endSubString}`;
  }
}
