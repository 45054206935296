import React, {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Icon from "@mdi/react";
import { mdiLoading, mdiPlus, mdiTrashCanOutline } from "@mdi/js";
import { useHistory } from "react-router-dom";
import Asterik from "./Asterik";
import { ButtonContainer } from "../components/button";
import Flexbox from "../components/flexbox/FlexboxV2";
import { Input } from "../components/input";
import {
  JumboSpacing,
  LargeSpacing,
  MediumSpacing,
  SmallSpacing,
  XLargeSpacing,
  XXLargeSpacing,
} from "../components/spacing";
import { Switch } from "../components/switch";
import { P1, H6 } from "../components/typography";
import {
  useMeetingTypeId,
  useEmail,
  useEmails,
  useEmailSubject,
  useEmailRefs,
  useEmailBody,
  useAddEmail,
  useSelectedEmailIndex,
  useRemoveEmail,
  useDeclineEmail,
  useDeclineEmailRefs,
  useDeclineSubject,
  useDeclineBody,
  useReminderEmailRefs,
  useMeetingReminderSubject,
  useMeetingReminderBody,
  useCanEdit,
  useMeetingInviteStyle,
  useMeetingReminderAllowRescheduling,
} from "./context";
import Step from "./Step";
import QuillWrapper from "./wysiwygEditor/QuillWrapper";
import { validateEmail } from "./useValidateMeetingType";
import StepComplete from "./StepComplete";
import StepIncomplete from "./StepIncomplete";
import { isWebinar, isCustomInvite } from "./invite/props";
import style from "./style.module.scss";
import useGeneralNotifications from "../hooks/useGeneralNotifications";
import useOpenAiCompletion from "../mutations/useOpenAiCompletion";
import { useOpenAiIntegration } from "../features";
import { Stack, Typography, Checkbox, Chip } from "@mui/material";
import { mdiHelpCircleOutline } from "@mdi/js";
import { useReschedulingFromReminder } from "../features";
import { cleanRichTextHTML } from "src/componentsV2/TipTap/EmailRichtextEditor";

// TODO(Matt): Organize this file everything is out-of-order
const EmailSubject = forwardRef(function EmailSubjectWithRef(
  { isEditable, setEmailSubject, name = null },
  ref
) {
  const updateTitle = useCallback(() => {
    setEmailSubject(ref.current.value || "");
  }, [setEmailSubject, ref]);
  return (
    <Flexbox.Column data-ref-id="email.subject">
      <P1>
        Email Subject
        <Asterik />
      </P1>
      <SmallSpacing />
      <Input
        className={style.meetingType__inviteInput}
        disabled={!isEditable}
        inputRef={ref}
        type="text"
        name={name}
        placeholder="Subject"
        onInput={updateTitle}
      />
    </Flexbox.Column>
  );
});

const OpenAiTextInput = ({ onAiResponse }) => {
  const fetchOpenAiCompletion = useOpenAiCompletion();
  const { addError } = useGeneralNotifications();
  const [prompt, setPrompt] = useState(
    "Write a sales email for cross selling SD-WAN to our customers who only purchase our internet and networking services."
  );
  const [isLoading, setIsLoading] = useState(false);

  const onClick = async () => {
    setIsLoading(true);
    fetchOpenAiCompletion(prompt)
      .then((data) => {
        onAiResponse(data.text);
      })
      .catch((err) => {
        addError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Flexbox.Column>
      <div className={style.chipContainer}>
        Enter instructions and AI will write your email
        <Chip className={style.chip} label="Beta" size="small" />
      </div>
      <SmallSpacing />
      <Flexbox.Row>
        <Input
          className={style.meetingType__inviteInput}
          type="text"
          value={prompt}
          onInput={(e) => setPrompt(e.target.value)}
        />
        {isLoading ? (
          <div style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
            <Icon size={1} path={mdiLoading} spin={4} />
          </div>
        ) : (
          <ButtonContainer transparent onClick={onClick}>
            <Flexbox.Row alignItems="center">
              <MediumSpacing />
              <P1 className={style.meetingType__emailButtonTitle} bold>
                Submit
              </P1>
            </Flexbox.Row>
          </ButtonContainer>
        )}
      </Flexbox.Row>
    </Flexbox.Column>
  );
};

const PLURAL_RULES = new Intl.PluralRules("en-US", {
  type: "ordinal",
});
const SUFFIXES = new Map([
  ["one", "st"],
  ["two", "nd"],
  ["few", "rd"],
  ["other", "th"],
]);

function useOrdinalRanking(n) {
  const rule = PLURAL_RULES.select(n);
  const suffix = SUFFIXES.get(rule);
  return `${n}${suffix}`;
}

const EmailBody = forwardRef(function EmailBodyWithRef(
  { body, isEditable, setBody },
  ref
) {
  // const [body, setBody] = useEmailBody();
  const updateBody = useCallback(() => {
    const { current } = ref;
    // Quill.js fires onEditorChange before it is initialized.
    // Ignore it if the reference is not connected
    if (current === null) {
      return null;
    }
    const editor = current.getEditor();
    const unprivilegedEditor = current.makeUnprivilegedEditor(editor);
    const newBody = unprivilegedEditor.getHTML();
    setBody(newBody);
  }, [ref, setBody]);
  return (
    <Flexbox.Column>
      <QuillWrapper
        ref={ref}
        value={body}
        readOnly={!isEditable}
        onChange={updateBody}
      />
    </Flexbox.Column>
  );
});

function EmailButton({ children, onClick, text, name = null }) {
  return (
    <Flexbox.Row alignItems="center">
      {/* TODO(Matt): need to scroll to this e-mail after adding */}
      <ButtonContainer transparent name={name} onClick={onClick}>
        <Flexbox.Row alignItems="center">
          <XXLargeSpacing />
          <div className={style.meetingType__emailButtonSign}>{children}</div>
          <MediumSpacing />
          <P1 className={style.meetingType__emailButtonTitle} bold>
            {text}
          </P1>
        </Flexbox.Row>
      </ButtonContainer>
    </Flexbox.Row>
  );
}

function EmailContent({ isEditable, name }) {
  const { body: bodyRef, subject: subjectRef } = useEmailRefs();
  const [subject, setEmailSubject] = useEmailSubject();
  const [body, setBody] = useEmailBody();
  const reset = useCallback(() => {
    subjectRef.current.value = subject;
  }, [subject, subjectRef]);
  const [shouldReloadEmail, setShouldReloadEmail] = useState(false);
  const openAiEnabled = useOpenAiIntegration();

  useEffect(() => {
    if (shouldReloadEmail) {
      reset();
      setShouldReloadEmail(false);
    }
  }, [reset, shouldReloadEmail]);

  useEffect(() => {
    setShouldReloadEmail(true);
  }, []);

  return (
    <Flexbox.Row>
      <XXLargeSpacing />
      <XXLargeSpacing />
      <Flexbox.Row className={style.meetingType__invite}>
        <LargeSpacing />
        <Flexbox.Column>
          <XLargeSpacing />
          <EmailSubject
            isEditable={isEditable}
            ref={subjectRef}
            setEmailSubject={setEmailSubject}
            name={`${name}email_subject`}
          />
          <LargeSpacing />
          <EmailBody
            body={body}
            isEditable={isEditable}
            ref={bodyRef}
            setBody={setBody}
          />
          <XLargeSpacing />
          {openAiEnabled && (
            <>
              <OpenAiTextInput
                onAiResponse={(text) => {
                  setBody(cleanRichTextHTML(text));
                }}
              />
              <XLargeSpacing />
            </>
          )}
        </Flexbox.Column>
        <LargeSpacing />
      </Flexbox.Row>
    </Flexbox.Row>
  );
}

function AttemptEmail({ index, isEditable, path }) {
  const removeEmail = useRemoveEmail();
  const history = useHistory();
  const stepNumber = index + 1;
  const ranking = useOrdinalRanking(stepNumber);
  const [meetingInviteStyle] = useMeetingInviteStyle();
  const goToEmail = () => {
    history.push(`${path}/${stepNumber}`);
  };
  const isOpen = index === useSelectedEmailIndex();
  const [email] = useEmail(index);
  const {
    summary: { complete: emailCompletetion, description: emailDescription },
  } = validateEmail(email);
  const onRemoveEmailClick = () => {
    removeEmail(index);
  };

  const displayText = useMemo(() => {
    if (isOpen) {
      if (isWebinar(meetingInviteStyle)) {
        return "What will guests see in their email?";
      }

      return `What will guests see in their ${ranking} email?`;
    }

    if (isWebinar(meetingInviteStyle)) {
      return "Email";
    }

    return `${ranking} Email`;
  }, [isOpen, meetingInviteStyle, ranking]);

  return (
    <Flexbox.Column>
      <Flexbox.Row>
        <EmailButton
          name={`/meetingtypes/configuration/attempts/${ranking}_email`}
          onClick={goToEmail}
          text={displayText}
        >
          {stepNumber}
        </EmailButton>
        <Flexbox.Row alignItems="center" justifyContent="flex-end">
          {index !== 0 && (
            <ButtonContainer
              name={`/meetingtypes/configuration/attempts/${ranking}_email/remove`}
              onClick={onRemoveEmailClick}
              transparent
            >
              <Icon
                className={style.meetingType__removeEmailIcon}
                path={mdiTrashCanOutline}
                size={1}
              />
            </ButtonContainer>
          )}
          <MediumSpacing />
          <P1 small>{emailDescription}</P1>
          <MediumSpacing />
          {emailCompletetion === 100 && <StepComplete />}
          {emailCompletetion < 100 && <StepIncomplete />}
          <MediumSpacing />
        </Flexbox.Row>
      </Flexbox.Row>
      <XLargeSpacing />
      {isOpen && (
        <EmailContent
          isEditable={isEditable}
          name="/meetingtypes/configuration/attempts/"
        />
      )}
    </Flexbox.Column>
  );
}

export function AttemptEmails({
  isAddingDynamicVariable,
  isEditable = true,
  onAddDynamicFieldClick,
  path,
}) {
  const meetingTypeId = useMeetingTypeId();
  const [meetingInviteStyle] = useMeetingInviteStyle();
  const history = useHistory();
  const selectedEmailIndex = useSelectedEmailIndex();
  const [emails] = useEmails();
  const addEmail = useAddEmail();
  const onAddEmailClick = () => {
    const nextOrder = addEmail();
    history.push(`${path}/${nextOrder}`);
  };
  return (
    <Flexbox.Column height="100%">
      <LargeSpacing />
      <Flexbox.Row>
        <H6>{`Email${isWebinar(meetingInviteStyle) ? "" : "s"}`}</H6>
        {isEditable && (
          <Flexbox.Row flex={1} justifyContent="flex-end">
            {isAddingDynamicVariable && (
              <ButtonContainer
                large
                name="/meetingtypes/configuration/attempts/hide_dynamic_variables"
                onClick={onAddDynamicFieldClick}
                primary
              >
                Hide Dynamic Variables
              </ButtonContainer>
            )}
            {!isAddingDynamicVariable && (
              <ButtonContainer
                large
                name="/meetingtypes/configuration/attempts/show_dynamic_variables"
                onClick={onAddDynamicFieldClick}
                primary
              >
                Show Dynamic Variables
              </ButtonContainer>
            )}
          </Flexbox.Row>
        )}
      </Flexbox.Row>
      <XXLargeSpacing />
      <Flexbox.Row flex={1} overflow="scroll">
        <Flexbox.Column>
          {/* webinars can only have one attempt email */}
          {isWebinar(meetingInviteStyle) && emails.length > 0 && (
            <Fragment
              key={`meetingtype_${meetingTypeId}-email_${emails[0].order}`}
            >
              <AttemptEmail
                index={0}
                isEditable={isEditable}
                isOpen
                path={path}
              />
              <XLargeSpacing />
            </Fragment>
          )}

          {/* non-webinars can have multiple attempt emails */}
          {!isWebinar(meetingInviteStyle) && (
            <>
              {emails.map((email, index) => (
                <Fragment
                  key={`meetingtype_${meetingTypeId}-email_${email.order}`}
                >
                  <AttemptEmail
                    index={index}
                    isEditable={isEditable}
                    isOpen={selectedEmailIndex === index}
                    path={path}
                  />
                  <XLargeSpacing />
                </Fragment>
              ))}
              <EmailButton
                name="/meetingtypes/configuration/attempts/add_email"
                onClick={onAddEmailClick}
                text="Add Email"
              >
                <Icon path={mdiPlus} size={0.8} />
              </EmailButton>
            </>
          )}
          <JumboSpacing />
        </Flexbox.Column>
      </Flexbox.Row>
    </Flexbox.Column>
  );
}

function DeclineEmailContent({ isEditable = true }) {
  const { body: bodyRef, subject: subjectRef } = useDeclineEmailRefs();
  const [subject, setEmailSubject] = useDeclineSubject();
  const [body, setBody] = useDeclineBody();
  const reset = useCallback(() => {
    subjectRef.current.value = subject;
  }, [subject, subjectRef]);
  const [shouldReloadEmail, setShouldReloadEmail] = useState(false);
  useEffect(() => {
    if (shouldReloadEmail) {
      reset();
      setShouldReloadEmail(false);
    }
  }, [reset, shouldReloadEmail]);
  useEffect(() => {
    setShouldReloadEmail(true);
  }, []);
  return (
    <Flexbox.Row>
      <XXLargeSpacing />
      <Flexbox.Row className={style.meetingType__invite}>
        <LargeSpacing />
        <Flexbox.Column>
          <XLargeSpacing />
          <EmailSubject
            isEditable={isEditable}
            ref={subjectRef}
            setEmailSubject={setEmailSubject}
            name="/meetingtypes/configuration/decline/decline_email_subject"
          />
          <LargeSpacing />
          <EmailBody
            isEditable={isEditable}
            body={body}
            ref={bodyRef}
            setBody={setBody}
          />
          <XLargeSpacing />
        </Flexbox.Column>
        <LargeSpacing />
      </Flexbox.Row>
    </Flexbox.Row>
  );
}

function DeclineEmailSummary() {
  const [email] = useDeclineEmail();
  const {
    summary: { complete: emailCompletetion, description: emailDescription },
  } = validateEmail(email);
  return (
    <Flexbox.Row>
      <Step
        description="What will guests see in an email when they decline an invite?"
        stepNumber={2}
      />
      <Flexbox.Row alignItems="center" justifyContent="flex-end">
        <MediumSpacing />
        <P1 small>{emailDescription}</P1>
        <MediumSpacing />
        {emailCompletetion === 100 && <StepComplete />}
        {emailCompletetion < 100 && <StepIncomplete />}
        <MediumSpacing />
      </Flexbox.Row>
    </Flexbox.Row>
  );
}

export function DeclineEmail({
  isAddingDynamicVariable,
  onAddDynamicFieldClick,
}) {
  const [email, setEmail] = useDeclineEmail();
  const canEditMeetingType = useCanEdit();
  const isOn = email !== null;
  const onToggle = () => {
    if (isOn) {
      setEmail(null);
      return;
    }
    setEmail({
      body: "",
      title: "",
    });
  };
  return (
    <Flexbox.Column height="100%">
      <LargeSpacing />
      <Flexbox.Row>
        <H6>Decline Email</H6>
        {isOn && (
          <Flexbox.Row flex={1} justifyContent="flex-end">
            {isAddingDynamicVariable && (
              <ButtonContainer
                large
                name="/meetingtypes/configuration/decline/hide_dynamic_variables"
                onClick={onAddDynamicFieldClick}
                primary
              >
                Hide Dynamic Variables
              </ButtonContainer>
            )}
            {!isAddingDynamicVariable && (
              <ButtonContainer
                large
                name="/meetingtypes/configuration/decline/show_dynamic_variables"
                onClick={onAddDynamicFieldClick}
                primary
              >
                Show Dynamic Variables
              </ButtonContainer>
            )}
          </Flexbox.Row>
        )}
      </Flexbox.Row>
      <XXLargeSpacing />
      <Flexbox.Column flex={1} overflow="scroll">
        <Flexbox.Row height="100%">
          <XXLargeSpacing />
          <Flexbox.Column>
            <Flexbox.Row>
              <Step
                description="Will guests see an email when they decline an invite?"
                stepNumber={1}
              />
            </Flexbox.Row>
            <XXLargeSpacing />
            <Flexbox.Row alignItems="center" justifyContent="center">
              <P1>No</P1>
              <LargeSpacing />
              <Switch
                isOn={email !== null}
                disabled={!canEditMeetingType}
                handleToggle={onToggle}
                name="/meetingtypes/configuration/decline/toggle_yes_no"
              />
              <LargeSpacing />
              <P1>Yes</P1>
            </Flexbox.Row>
            {isOn && (
              <>
                <JumboSpacing />
                <DeclineEmailSummary />
                <JumboSpacing />
                <DeclineEmailContent isEditable={canEditMeetingType} />
              </>
            )}
          </Flexbox.Column>
        </Flexbox.Row>
      </Flexbox.Column>
    </Flexbox.Column>
  );
}

export function ReminderEmailContent({ isEditable = true }) {
  const { body: bodyRef, subject: subjectRef } = useReminderEmailRefs();
  const [subject, setEmailSubject] = useMeetingReminderSubject();
  const [body, setBody] = useMeetingReminderBody();
  const [allowRescheduling, setAllowRescheduling] =
    useMeetingReminderAllowRescheduling();
  const reschedulingFeatureEnabled = useReschedulingFromReminder();
  const [meetingInviteStyle] = useMeetingInviteStyle();
  const reset = useCallback(() => {
    subjectRef.current.value = subject;
  }, [subject, subjectRef]);
  const [shouldReloadEmail, setShouldReloadEmail] = useState(false);
  useEffect(() => {
    if (shouldReloadEmail) {
      reset();
      setShouldReloadEmail(false);
    }
  }, [reset, shouldReloadEmail]);
  useEffect(() => {
    setShouldReloadEmail(true);
  }, []);

  return (
    <Flexbox.Row>
      <XXLargeSpacing />
      <Flexbox.Row className={style.meetingType__invite}>
        <LargeSpacing />
        <Flexbox.Column>
          <XLargeSpacing />
          <EmailSubject
            isEditable={isEditable}
            ref={subjectRef}
            setEmailSubject={setEmailSubject}
            name="/meetingtypes/configuration/reminders/reminder_email_subject"
          />
          <LargeSpacing />
          <EmailBody
            body={body}
            isEditable={isEditable}
            ref={bodyRef}
            setBody={setBody}
          />
          <LargeSpacing />
          {reschedulingFeatureEnabled && isCustomInvite(meetingInviteStyle) && (
            <Stack direction="row" alignItems="center">
              <Stack direction="row">
                <Typography>Allow new meeting time proposal</Typography>
                <Icon
                  path={mdiHelpCircleOutline}
                  size={0.5}
                  data-rh="Checking this option will include a link in the reminder that a guest can use to reschedule the meeting (Kronologic Email invite style only)."
                  data-rh-at="right"
                />
              </Stack>
              <Checkbox
                checked={allowRescheduling}
                onChange={(_, v) => setAllowRescheduling(v)}
              />
            </Stack>
          )}
          <XLargeSpacing />
        </Flexbox.Column>
        <LargeSpacing />
      </Flexbox.Row>
    </Flexbox.Row>
  );
}
