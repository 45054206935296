import React from "react";
import dayjs from "dayjs";
import Flexbox from "../../flexbox/FlexboxV2";
import { useTimezones } from "../../timezone";
import { P1, H6 } from "../../typography";
import { MediumSpacing, SmallSpacing } from "../../spacing";

import style from "./meetingTypeDetailsHeader.module.scss";

function MeetingTypeDetailsHeader({ invite, name }) {
  const { browserTimezone } = useTimezones();

  const now = new Date();
  return (
    <Flexbox.Row className={style.meetingTypeDetails__headerContainer}>
      <Flexbox.Column flex="1 1 100%">
        <MediumSpacing />
        <Flexbox.Row justifyContent="center">
          <H6>{name}</H6>
        </Flexbox.Row>
        <SmallSpacing />
        <Flexbox.Row justifyContent="center">
          <P1 small>{invite.location}</P1>
        </Flexbox.Row>
      </Flexbox.Column>
      <div className={style.meetingTypeDetails__startTimeButtonContainer}>
        <MediumSpacing />
        <Flexbox.Column>
          <P1>{dayjs(now).format("MM/DD/YYYY")}</P1>
          <P1>
            {dayjs(now).format("HH:mm")}
            {` ${browserTimezone}`}
          </P1>
        </Flexbox.Column>
      </div>
    </Flexbox.Row>
  );
}

export default MeetingTypeDetailsHeader;
