import React, { useMemo } from "react";
import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";

import Flexbox from "../../components/flexbox/FlexboxV2";
import InitialsIcon from "../../components/initials";
import {
  MediumSpacing,
  TinySpacing,
  XLargeSpacing,
  XXLargeSpacing,
} from "../../components/spacing";
import { P1, H6 } from "../../components/typography";
import { formatCurrency, formatThousands } from "../../utils/format";
import { ROLE_LEVELS } from "../../auth/roles";
import {
  useIsDefaultUserMeetingType,
  useSelectedMeetingType,
} from "../context";
import { useActingAs, useIsDefaultUser } from "../../auth";
import PreviewHeaderDefaultUser from "./PreviewHeaderDefaultUser";
import style from "../style.module.scss";
import { isWebinar } from "../invite/props";

const SMALL = { small: true };
const MEDIUM = { medium: true };

function getInitial(name) {
  try {
    return name.charAt(0);
  } catch (error) {
    return "";
  }
}

function getInitials(member) {
  return {
    key: member.id,
    value: `${getInitial(member?.firstName)}${getInitial(member?.lastName)}`,
  };
}

export default function PreviewHeader({
  areTeamMembersLoading = false,
  teamError = null,
  teamMembers = null,
  teamName = null,
}) {
  const [meetingType] = useSelectedMeetingType();
  const isDefaultUser = useIsDefaultUser();
  const isDefaultUserMeetingType = useIsDefaultUserMeetingType();
  const [actingAs] = useActingAs();

  const shownTeamMemberInitials = useMemo(() => {
    if (teamMembers === null || teamMembers.length === 0) {
      return null;
    }
    let slice = teamMembers;
    if (teamMembers.length > 3) {
      slice = slice.slice(0, 2);
    }
    return slice.map(getInitials);
  }, [teamMembers]);

  const showDefaultUserHeader = useMemo(() => {
    return (
      isDefaultUser ||
      actingAs?.role === ROLE_LEVELS.DEFAULT ||
      isDefaultUserMeetingType
    );
  }, [actingAs, isDefaultUser, isDefaultUserMeetingType]);

  const isWebinarType = useMemo(() => {
    if (meetingType === null || !meetingType.inviteStyle) {
      return false;
    }

    return isWebinar(meetingType.inviteStyle);
  }, [meetingType]);

  return (
    !isWebinarType && (
      <Flexbox.Row className={style.meetingType__team} flex={1}>
        {showDefaultUserHeader && (
          <PreviewHeaderDefaultUser cost={meetingType?.cost} />
        )}
        {!showDefaultUserHeader && (
          <>
            <Flexbox.Column flex={0}>
              <XXLargeSpacing />
              <Flexbox.Row>
                <XLargeSpacing />
                <Flexbox.Column>
                  <P1 small whiteSpace="nowrap">
                    Handled By
                  </P1>
                  <TinySpacing />
                  <H6>{teamName || "No Team Selected"}</H6>
                </Flexbox.Column>
              </Flexbox.Row>
              <XXLargeSpacing />
            </Flexbox.Column>
            <MediumSpacing />
            <Flexbox.Column flex={1}>
              <XXLargeSpacing />
              {teamError !== null && (
                <span>
                  Unable to load team members currently. Please try again later
                </span>
              )}
              {areTeamMembersLoading && (
                <Icon size={1} path={mdiLoading} spin={4} />
              )}
              {teamError === null && !areTeamMembersLoading && (
                <>
                  {(teamMembers === null || teamMembers.length === 0) && (
                    <span>No team members found</span>
                  )}
                  {teamMembers !== null && teamMembers.length > 0 && (
                    <Flexbox.Row justifyContent="center">
                      {shownTeamMemberInitials.map((initials) => {
                        return (
                          <InitialsIcon
                            key={initials.key}
                            initials={initials.value}
                            height="42px"
                            width="42px"
                            size={MEDIUM}
                          />
                        );
                      })}
                      {teamMembers.length > 3 && (
                        <InitialsIcon
                          height="44px"
                          width="44px"
                          initials={`${
                            formatThousands(teamMembers.length - 2, 1)[2]
                          }+`}
                          size={SMALL}
                        />
                      )}
                    </Flexbox.Row>
                  )}
                </>
              )}
              <XXLargeSpacing />
            </Flexbox.Column>
            <XLargeSpacing />
            <Flexbox.Column flex={0}>
              <XXLargeSpacing />
              <P1 small whiteSpace="nowrap">
                Meeting Value
              </P1>
              <TinySpacing />
              <H6>{`${formatCurrency(meetingType?.cost)}`}</H6>
              <XXLargeSpacing />
            </Flexbox.Column>
            <XLargeSpacing />
          </>
        )}
      </Flexbox.Row>
    )
  );
}
