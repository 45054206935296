import { useMemo } from "react";
import { BLACK, WHITE } from "../../components/colors";
import { useTheme } from "../../components/theme";

const useGuestStatusSelectTheme = () => {
  const { isDark } = useTheme();

  return useMemo(
    () => ({
      container: (provided, { selectProps: { statusColor } }) => {
        return {
          ...provided,
          backgroundColor: statusColor,
          border: "none",
          width: "100%",
        };
      },
      control: (provided) => ({
        ...provided,
        backgroundColor: "transparent",
        border: "none",
        height: "100%",
      }),
      indicatorSeparator: () => null,
      menu: (provided) => ({ ...provided, margin: 0, top: 38 }),
      menuList: (provided, { selectProps: { statusColor } }) => ({
        ...provided,
        backgroundColor: "hsl(0, 0%, 7%)",
        border: `1px solid ${statusColor}`,
        padding: 0,
      }),
      option: (provided, { isFocused, isSelected }) => {
        let backgroundColor;

        if (isDark) {
          backgroundColor =
            isFocused || isSelected ? "rgba(121, 125, 149, 0.7)" : "#414564";
        }

        if (!isDark) {
          backgroundColor = isFocused || isSelected ? "#F1FAFF" : WHITE;
        }

        return {
          ...provided,
          backgroundColor,
          color: isDark ? WHITE : BLACK,
        };
      },
      placeholder: (provided) => ({
        ...provided,
        border: "none",
        color: "hsl(0, 0%, 90%)",
        position: "absolute",
      }),
      singleValue: (provided, { selectProps: { value } }) => ({
        ...provided,
        color: value.value === "staging" ? BLACK : WHITE,
      }),
      valueContainer: (provided) => ({
        ...provided,
        alignItems: "center",
        border: "none",
        display: "flex",
        fontSize: "15px",
        justifyContent: "center",
        overflow: "unset",
      }),
    }),
    [isDark]
  );
};

export default useGuestStatusSelectTheme;
