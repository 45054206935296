import { mdiClose, mdiFilterOutline, mdiMinus, mdiSignCaution } from "@mdi/js";
import Icon from "@mdi/react";
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { ButtonContainer } from "../components/button";
import Flexbox from "../components/flexbox/FlexboxV2";
import { Input } from "../components/input";
import { useDefaultSelectTheme } from "../components/select";
import {
  JumboSpacing,
  LargeSpacing,
  SmallSpacing,
  XLargeSpacing,
  XXLargeSpacing,
} from "../components/spacing";
import { P1, H6 } from "../components/typography";
import { Select } from "../inputs";
import {
  useRouting,
  useSelectedMeetingType,
  useUserConditions,
} from "./context";
import useUserId from "./useUserId";
import style from "./style.module.scss";
import {
  EmptyViewContainer,
  EmptyViewIcon,
  EmptyViewRow,
} from "../components/emptyView/EmptyView";

const conditionTypes = {
  CONTAINS: "contains",
  IS: "is",
  NOT: "is_not",
  NOT_CONTAIN: "does_not_contain",
};

const mappings = {
  [conditionTypes.CONTAINS]: "contains",
  [conditionTypes.IS]: "is",
  [conditionTypes.NOT]: "is not",
  [conditionTypes.NOT_CONTAIN]: "does not contain",
};

const FIELD_OPTIONS = [
  "account",
  "email",
  "first_name",
  "last_name",
  "lead_score",
  "logic_field",
  "phone",
  "routing_field",
  "state",
  "zip_code",
].map((o) => ({
  label: o,
  value: o,
}));

const MAPPING_OPTIONS = Object.keys(mappings).map((key) => ({
  label: mappings[key],
  value: key,
}));

export default function UserFilters() {
  const [routingType] = useRouting();
  const [components, theme] = useDefaultSelectTheme();
  const history = useHistory();
  const [{ id: selectedMeetingTypeId }] = useSelectedMeetingType();
  const userId = useUserId();
  const [conditions, setUserConditions] = useUserConditions(userId);
  if (conditions === null) {
    return (
      <Flexbox.Column>
        <Flexbox.Row alignItems="center">
          <H6>User Routing</H6>
          <div style={{ flex: 1 }} />
          <ButtonContainer
            className={style.meetingType__userRoutingCloseButton}
            transparent
            small
            onClick={() => {
              history.push(
                `/meetingTypes/user/${selectedMeetingTypeId}/general/behavior/routing`
              );
            }}
          >
            <Icon path={mdiClose} size={1.25} />
          </ButtonContainer>
        </Flexbox.Row>
        <JumboSpacing />
        <EmptyViewContainer className={style.meetingType__emptyView}>
          <EmptyViewRow>
            <EmptyViewIcon icon={mdiSignCaution} />
          </EmptyViewRow>
          <EmptyViewRow>
            No conditions found for user. User is probably disabled. To add/view
            conditions, please enable the user.
          </EmptyViewRow>
        </EmptyViewContainer>
      </Flexbox.Column>
    );
  }
  if (routingType !== "custom") {
    return (
      <Flexbox.Column>
        <Flexbox.Row alignItems="center">
          <H6>User Routing</H6>
          <div style={{ flex: 1 }} />
          <ButtonContainer
            className={style.meetingType__userRoutingCloseButton}
            transparent
            small
            onClick={() => {
              history.push(
                `/meetingTypes/user/${selectedMeetingTypeId}/general/behavior/routing`
              );
            }}
          >
            <Icon path={mdiClose} size={1.25} />
          </ButtonContainer>
        </Flexbox.Row>
        <JumboSpacing />
        <EmptyViewContainer className={style.meetingType__emptyView}>
          <EmptyViewRow>
            <EmptyViewIcon icon={mdiSignCaution} />
          </EmptyViewRow>
          <EmptyViewRow>
            Routing is not of custom type. You cannot add filters to users on
            any other type of routing.
          </EmptyViewRow>
        </EmptyViewContainer>
      </Flexbox.Column>
    );
  }
  return (
    <Flexbox.Column>
      <Flexbox.Row alignItems="center">
        <H6>User Routing</H6>
        <div style={{ flex: 1 }} />
        <ButtonContainer
          primary
          small
          name="/meetingtypes/configuration/behavior/routing/team_routing/filter/add"
          onClick={() => {
            setUserConditions([
              ...conditions,
              {
                field: FIELD_OPTIONS[1].value,
                operator: conditionTypes.IS,
                value: "",
              },
            ]);
          }}
        >
          Add
        </ButtonContainer>
        <ButtonContainer
          className={style.meetingType__userRoutingCloseButton}
          transparent
          small
          name="/meetingtypes/configuration/behavior/routing/team_routing/filter/close"
          onClick={() => {
            history.push(
              `/meetingTypes/user/${selectedMeetingTypeId}/general/behavior/routing`
            );
          }}
        >
          <Icon path={mdiClose} size={1.25} />
        </ButtonContainer>
      </Flexbox.Row>
      <XXLargeSpacing />
      {conditions !== null && conditions.length === 0 && (
        <Flexbox.Column alignItems="center">
          <Icon path={mdiFilterOutline} size={8} />
          <P1>No routing conditions found for current user.</P1>
          <XLargeSpacing />
          <ButtonContainer
            primary
            large
            name="/meetingtypes/configuration/behavior/routing/team_routing/filter/add"
            onClick={() => {
              setUserConditions([
                ...conditions,
                {
                  field: FIELD_OPTIONS[1].value,
                  operator: conditionTypes.IS,
                  value: "",
                },
              ]);
            }}
          >
            Add
          </ButtonContainer>
        </Flexbox.Column>
      )}
      {conditions !== null &&
        conditions.map((condition, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={`condition-${index}`}>
              <Flexbox.Row>
                <Select
                  components={components}
                  customTheme={theme}
                  name="/meetingtypes/configuration/behavior/routing/team_routing/filter/logic_field_per_user"
                  onChange={(option) => {
                    const copy = [...conditions];
                    copy[index] = {
                      ...copy[index],
                      field: option.value,
                    };
                    setUserConditions(copy);
                  }}
                  value={FIELD_OPTIONS.find((o) => o.value === condition.field)}
                  options={FIELD_OPTIONS}
                  placeholder={<div>Select Field</div>}
                />
                <SmallSpacing />
                <Select
                  components={components}
                  customTheme={theme}
                  name="/meetingtypes/configuration/behavior/routing/team_routing/filter/mapping_per_user"
                  onChange={(option) => {
                    const copy = [...conditions];
                    copy[index] = {
                      ...copy[index],
                      operator: option.value,
                    };
                    setUserConditions(copy);
                  }}
                  value={MAPPING_OPTIONS.find(
                    (o) => o.value === condition.operator
                  )}
                  options={MAPPING_OPTIONS}
                  placeholder={<div>Select Operator</div>}
                />
                <SmallSpacing />
                <Input
                  large
                  name="/meetingtypes/configuration/behavior/routing/team_routing/filter/user_routing_value"
                  onChange={(event) => {
                    const copy = [...conditions];
                    copy[index] = {
                      ...copy[index],
                      value: event.target.value,
                    };
                    setUserConditions(copy);
                  }}
                  placeholder="Value"
                  type="text"
                  value={condition.value}
                />
                <SmallSpacing />
                <ButtonContainer
                  secondary
                  name="/meetingtypes/configuration/behavior/routing/team_routing/filter/remove"
                  onClick={() => {
                    const copy = [...conditions];
                    copy.splice(index, 1);
                    setUserConditions(copy);
                  }}
                >
                  <Icon path={mdiMinus} size={1} />
                </ButtonContainer>
              </Flexbox.Row>
              <LargeSpacing />
            </Fragment>
          );
        })}
    </Flexbox.Column>
  );
}
