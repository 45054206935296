import React, { useCallback } from "react";

import { TagManager } from "../components/tagManager";
import { useMeetingTypeTagsRepository, useTagsRepository } from "../repository";

export default function MeetingTypeTagManager({
  close,
  meetingTypeId,
  setMeetingTypes,
  tags,
}) {
  const repository = useTagsRepository();
  const { updateTag } = repository;
  const { saveMeetingTypeTags } = useMeetingTypeTagsRepository();
  const onSave = useCallback(
    async (selectedTags) => {
      const selectedTagIds = selectedTags.map((t) => t.id);
      await saveMeetingTypeTags([meetingTypeId], selectedTagIds);
      setMeetingTypes((prev) => {
        return prev.map((meetingType) => {
          if (meetingType.id === meetingTypeId) {
            return {
              ...meetingType,
              tags: selectedTags,
            };
          }
          return meetingType;
        });
      });
    },
    [meetingTypeId, saveMeetingTypeTags, setMeetingTypes]
  );
  const onEdit = useCallback(
    async (tagId, tagUpdate) => {
      setMeetingTypes((prev) => {
        return prev.map((meetingType) => {
          const { tags: meetingTypeTags = [] } = meetingType;
          const meetingTypeTagIds = meetingTypeTags.map((mtt) => mtt.id);
          if (meetingTypeTagIds.includes(tagId)) {
            return {
              ...meetingType,
              tags: meetingTypeTags.map((tag) => {
                if (tag.id === tagId) {
                  return {
                    ...tag,
                    ...tagUpdate,
                  };
                }
                return tag;
              }),
            };
          }
          return meetingType;
        });
      });
      await updateTag(tagId, tagUpdate);
    },
    [setMeetingTypes, updateTag]
  );
  const onRemove = useCallback(
    async (tagIds) => {
      setMeetingTypes((prev) => {
        return prev.map((meetingType) => {
          const { tags: meetingTypeTags = [] } = meetingType;
          return {
            ...meetingType,
            tags: meetingTypeTags.filter((mtt) => !tagIds.includes(mtt.id)),
          };
        });
      });
    },
    [setMeetingTypes]
  );
  return (
    <TagManager
      close={close}
      initialSelectedTags={tags}
      onEdit={onEdit}
      onRemove={onRemove}
      onSave={onSave}
      repository={repository}
      title="Manage Tags"
    />
  );
}
