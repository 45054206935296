import useSWR from "swr";

import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useDedupeInterval } from "../configuration";
import { useErrorHandler, useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { MeetingReport } from "../types";

export function useMeetingReportV2(query: any) {
  const accessToken = getUserToken();
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const errorHandler = useErrorHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const interval = useDedupeInterval();

  const { data, error } = useSWR(
    [`/api/meetings/instances/reportV2`, query],
    (url: string, body: object) =>
      service
        .post(url)
        .set(headers)
        .send(body)
        .then(tokenRefreshHandler)
        .then((res: Response) => res.body)
        .catch(errorHandler),
    { dedupingInterval: interval, refreshInterval: 60000 }
  );

  return {
    data: data as MeetingReport | undefined,
    error,
    loading: !error && !data,
  };
}

export default useMeetingReportV2;
