import { getUserToken } from "src/utils/jwtToken";
import useSWR from "swr";
import { useActingAsOverrideHeader } from "../auth";
import { useErrorHandler, useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { PersonalAccessToken } from "../types/PersonalAccessToken";

// useListPATs is the route used for a user to view their existing PATs
export function useListPATs() {
  const accessToken = getUserToken();
  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const errorHandler = useErrorHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const { data, error } = useSWR("/api/pat", (url: string) =>
    service
      .get(url)
      .set(headers)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler)
  );

  return {
    data: data as PersonalAccessToken[] | undefined,
    error,
    loading: !error && !data,
  };
}
