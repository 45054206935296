import React, { useState } from "react";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { CancelOutlined, Check } from "@mui/icons-material";
import {
  DataGridPro,
  GridEditInputCell,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

import { ContactRow, ContactUpload } from "./ContactUpload";
import { ContactWithDetails } from "src/types";
import { validateEmailAddress } from "src/utils/helpers";
import ImportFooter from "../ImportFooter";

function isValidContact(contact: Partial<ContactWithDetails>): boolean {
  return validateEmailAddress(contact.email?.trim());
}

type Row = ContactRow & { error: boolean };

export function Validation({
  contacts,
  onPrev = () => null,
  onNext = () => null,
}: {
  contacts: ContactUpload;
  onPrev: () => void;
  onNext: (contacts: ContactUpload) => void;
}) {
  const apiRef = useGridApiRef();

  const [data] = useState<Row[]>(
    contacts.map((contact, index) => ({
      ...contact,
      firstName: contact.firstName?.trim(),
      lastName: contact.lastName?.trim(),
      email: contact.email?.trim(),
      row: index,
      error: !isValidContact(contact),
    }))
  );

  const [errors, setErrors] = useState(
    data
      .filter((row) => row.error)
      .reduce((prev, current) => {
        prev[current.row] = current;
        return prev;
      }, {} as { [index: number]: ContactUpload[0] })
  );

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const numErrors = Object.entries(errors).length;

  return (
    <>
      <Box sx={{ width: "100%", minHeight: "400px" }}>
        <Stack spacing={2}>
          {numErrors > 0 ? (
            <Stack spacing={1}>
              <Typography>
                It looks like there are a few issues with some of the data.
                Please fix the {numErrors} errors shown below.
              </Typography>
            </Stack>
          ) : (
            <Typography>Your upload looks ready to go!</Typography>
          )}

          <Paper elevation={0}>
            <DataGridPro
              apiRef={apiRef}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    row: true,
                    status: true,
                    firstName: true,
                    lastName: true,
                    email: true,
                    account: data.some((row) => row.account),
                    phone: data.some((row) => row.phone),
                    zip: data.some((row) => row.zip),
                    state: data.some((row) => row.state),
                    logicField: data.some((row) => row.logicField),
                    leadScore: data.some((row) => row.leadScore),
                    routingField: data.some((row) => row.routingField),
                    customField1: data.some((row) => row.customField1),
                    customField2: data.some((row) => row.customField2),
                    customField3: data.some((row) => row.customField3),
                    customField4: data.some((row) => row.customField4),
                    customField5: data.some((row) => row.customField5),
                    customField6: data.some((row) => row.customField6),
                    customField7: data.some((row) => row.customField7),
                    customField8: data.some((row) => row.customField8),
                    customField9: data.some((row) => row.customField9),
                    customField10: data.some((row) => row.customField10),
                  },
                },
              }}
              sx={{ height: 54 * (pageSize + 2) }}
              rowHeight={54}
              pagination
              rowsPerPageOptions={[10, 25, 100]}
              editMode="row"
              experimentalFeatures={{
                newEditingApi: true,
                // preventCommitWhileValidating: false,
              }}
              page={page}
              onPageChange={setPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
              columns={[
                {
                  field: "row",
                  valueGetter: ({ row }) => row.row + 1,
                  headerName: "Row",
                  editable: false,
                  minWidth: 20,
                  hideable: false,
                },
                {
                  field: "error",
                  headerName: "Valid",
                  editable: false,
                  minWidth: 40,
                  hideable: true,
                  renderCell: (params) =>
                    params.value ? (
                      <CancelOutlined color="error" />
                    ) : (
                      <Check color="success" />
                    ),
                },
                {
                  field: "firstName",
                  headerName: "First Name",
                  editable: true,
                  minWidth: 150,
                  hideable: false,
                },
                {
                  field: "lastName",
                  headerName: "Last Name",
                  editable: true,
                  minWidth: 150,
                  hideable: false,
                },
                {
                  field: "email",
                  headerName: "Email",
                  editable: true,
                  minWidth: 250,
                  hideable: false,
                  renderCell: (props) => {
                    const error = !validateEmailAddress(props.value);
                    return (
                      <Typography color={error ? "error" : undefined}>
                        {props.value}
                      </Typography>
                    );
                  },
                  renderEditCell: (props) => {
                    return <GridEditInputCell {...props} />;
                  },
                },
                {
                  field: "account",
                  headerName: "Account",
                  editable: true,
                  minWidth: 150,
                },
                {
                  field: "phone",
                  headerName: "Phone",
                  editable: true,
                  minWidth: 150,
                },
                {
                  field: "zip",
                  headerName: "Zip",
                  editable: true,
                  minWidth: 150,
                },
                {
                  field: "state",
                  headerName: "State",
                  editable: true,
                  minWidth: 150,
                },
                {
                  field: "logicField",
                  headerName: "Logic Field 1",
                  editable: true,
                  minWidth: 150,
                },
                {
                  field: "leadScore",
                  headerName: "Logic Field 2",
                  editable: true,
                  minWidth: 150,
                },
                {
                  field: "routingField",
                  headerName: "Routing Field",
                  editable: true,
                  minWidth: 150,
                },
                {
                  field: "customField1",
                  headerName: "Custom Field 1",
                  editable: true,
                  minWidth: 150,
                },
                {
                  field: "customField2",
                  headerName: "Custom Field 2",
                  editable: true,
                  minWidth: 150,
                },
                {
                  field: "customField3",
                  headerName: "Custom Field 3",
                  editable: true,
                  minWidth: 150,
                },
                {
                  field: "customField4",
                  headerName: "Custom Field 4",
                  editable: true,
                  minWidth: 150,
                },
                {
                  field: "customField5",
                  headerName: "Custom Field 5",
                  editable: true,
                  minWidth: 150,
                },
                {
                  field: "customField6",
                  headerName: "Custom Field 6",
                  editable: true,
                  minWidth: 150,
                },
                {
                  field: "customField7",
                  headerName: "Custom Field 7",
                  editable: true,
                  minWidth: 150,
                },
                {
                  field: "customField8",
                  headerName: "Custom Field 8",
                  editable: true,
                  minWidth: 150,
                },
                {
                  field: "customField9",
                  headerName: "Custom Field 9",
                  editable: true,
                  minWidth: 150,
                },
                {
                  field: "customField10",
                  headerName: "Custom Field 10",
                  editable: true,
                  minWidth: 150,
                },
              ]}
              rows={data}
              getRowId={(row) => row.row}
              processRowUpdate={(row) => {
                row.error = !isValidContact(row);

                if (row.error) {
                  setErrors({
                    ...errors,
                    [row.row]: row,
                  });
                } else {
                  const err = { ...errors };
                  delete err[row.row];
                  setErrors(err);
                }

                return row;
              }}
            />
          </Paper>
        </Stack>{" "}
      </Box>
      <ImportFooter
        onLeftButtonClick={onPrev}
        onRightButtonClick={() => {
          onNext(
            Object.values(
              apiRef.current.state.rows.idRowsLookup
            ) as ContactUpload
          );
        }}
        rightButtonDisabled={numErrors !== 0}
      />
    </>
  );
}

export default Validation;
