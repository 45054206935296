import React from "react";
import cx from "classnames";
import Flexbox from "../components/flexbox/FlexboxV2";
import { MediumSpacing } from "../components/spacing";
import { P1 } from "../components/typography";
import style from "./style.module.scss";
import StepComplete from "./StepComplete";

export default function Step({
  complete = false,
  description,
  stepNumber,
  warn: isWarn = false,
}) {
  return (
    <Flexbox.Row alignItems="center">
      <div
        className={cx(style.meetingType__stepCount, {
          [style["meetingType__stepCount--standard"]]: !isWarn,
          [style["meetingType__stepCount--warn"]]: isWarn,
        })}
      >
        <P1>{stepNumber}</P1>
      </div>
      <MediumSpacing />
      <P1
        bold
        className={cx(style.meetingType__stepTitle, {
          [style["meetingType__stepTitle--standard"]]: !isWarn,
          [style["meetingType__stepTitle--warn"]]: isWarn,
        })}
      >
        {description}
      </P1>
      {complete && (
        <>
          <MediumSpacing />
          <StepComplete />
        </>
      )}
    </Flexbox.Row>
  );
}
