import React from "react";
import { useHistory } from "react-router-dom";
import { FixedSizeList as List } from "react-window";
import cx from "classnames";
import {
  mdiChevronDown,
  mdiChevronUp,
  mdiFilterOutline,
  mdiFilterPlusOutline,
  mdiLoading,
} from "@mdi/js";
import { Icon } from "@mdi/react";

import {
  EmptyViewContainer,
  EmptyViewIcon,
  EmptyViewRow,
} from "../components/emptyView/EmptyView";
import { Switch } from "../components/switch";
import useGenerateRoutingLogic, {
  generateSingleMappingLogic,
} from "./useGenerateRoutingLogic";
import useOrderedUsers from "./useOrderedUsers";
import {
  useCanEdit,
  useRouting,
  useRoutingLogic,
  useSelectedMeetingType,
  useSubTypeDetailsFieldValue,
  useTeamMembersContext,
} from "./context";
import Flexbox from "../components/flexbox/FlexboxV2";
import style from "./style.module.scss";
import InitialsIcon from "../components/initials";
import { getInitials } from "../meetings/instanceDetails/util/helpers";
import { ButtonContainer } from "../components/button";
import {
  LargeSpacing,
  MediumSpacing,
  XXLargeSpacing,
} from "../components/spacing";
import useUserId from "./useUserId";
import { P1 } from "../components/typography";

const MEDIUM = { medium: true };

function TeamListItem({ index, user, users }) {
  const [{ id: meetingTypeId }] = useSelectedMeetingType();
  const selectedUserId = useUserId();
  const history = useHistory();
  const { mappingLogic } = user;
  const [subTypeValueField] = useSubTypeDetailsFieldValue();
  const [routing] = useRouting();
  const [, setRoutingLogic] = useRoutingLogic();
  const generateRoutingLogic = useGenerateRoutingLogic();
  const canEditMeetingType = useCanEdit();
  const { isEnabled, email, id: userId } = user;
  const isTop = index === 0;
  const isBottom = index + 1 === users.length || !users[index + 1].isEnabled;
  const toggleActive = () => {
    const newEnabled = !isEnabled;
    const newRoutingLogic = generateRoutingLogic(
      routing,
      subTypeValueField,
      true
    );
    const { custom } = newRoutingLogic;
    custom[userId] = {
      ...custom[userId],
      enabled: newEnabled,
    };
    if (!newEnabled) {
      delete custom[userId].mapping_logic;
    }
    if (newEnabled && subTypeValueField !== null) {
      custom[userId].mapping_logic = generateSingleMappingLogic(
        subTypeValueField,
        email
      );
    }
    const { sequential } = newRoutingLogic;
    const { order } = sequential;
    if (!newEnabled) {
      order.splice(index, 1);
    } else {
      order.push(userId);
    }
    setRoutingLogic(newRoutingLogic);
  };

  // TODO(Matt): Figure out why this doesn't work immeadiately after changing routing type
  // Ideas: (Not re-rendered in time, need cancel on auto-save)
  const updateOrder = (dir) => {
    const newRoutingLogic = generateRoutingLogic(routing);
    const { sequential } = newRoutingLogic;
    const { order: currentOrderIds } = sequential;
    const iA = currentOrderIds[index];
    const iB = currentOrderIds[index + dir];
    currentOrderIds[index + dir] = iA;
    currentOrderIds[index] = iB;
    setRoutingLogic(newRoutingLogic);
  };
  return (
    <Flexbox.Column height="100%">
      <Flexbox.Row
        alignItems="center"
        className={cx(style.meetingType__teamListItem, {
          [style["meetingType__teamListItem--highlighted"]]:
            selectedUserId === userId,
        })}
        height="100%"
        justifyContent="space-between"
      >
        <LargeSpacing />
        <Flexbox.Row flex={0}>
          <InitialsIcon
            initials={getInitials(user)}
            height="42px  "
            width="42px "
            size={MEDIUM}
          />
        </Flexbox.Row>
        <Flexbox.Row flex={1} />

        <div className={style.meetingType__teamListItem_email}>
          <P1>{user.email}</P1>
        </div>
        <Flexbox.Row flex={1} />
        <Flexbox.Row alignItems="center" flex={0}>
          {routing === "sequential" && (
            <div className={style.order}>
              <ButtonContainer
                className={style.meetingType__orderButton}
                disabled={isTop || !isEnabled || !canEditMeetingType}
                transparent
                name="/meetingtypes/configuration/behavior/routing/move_order_up"
                onClick={() => {
                  updateOrder(-1);
                }}
              >
                <Icon
                  className={style.meetingType__orderButtonIcon}
                  path={mdiChevronUp}
                  size={1}
                />
              </ButtonContainer>
              <ButtonContainer
                className={style.meetingType__orderButton}
                disabled={isBottom || !isEnabled || !canEditMeetingType}
                transparent
                name="/meetingtypes/configuration/behavior/routing/move_order_down"
                onClick={() => {
                  updateOrder(1);
                }}
              >
                <Icon
                  className={style.meetingType__orderButtonIcon}
                  path={mdiChevronDown}
                  size={1}
                />
              </ButtonContainer>
            </div>
          )}
          <MediumSpacing />
          {routing === "custom" && (
            <>
              <ButtonContainer
                transparent
                name="/meetingtypes/configuration/behavior/routing/team_routing/filter"
                onClick={() => {
                  history.push(
                    `/meetingTypes/user/${meetingTypeId}/general/behavior/routing/${user.id}`
                  );
                }}
              >
                <Icon
                  className={cx(style.meetingType__teamListItemIcon, {
                    [style["meetingType__teamListItemIcon--blue"]]:
                      mappingLogic?.length > 0,
                  })}
                  path={
                    mappingLogic?.length > 0
                      ? mdiFilterPlusOutline
                      : mdiFilterOutline
                  }
                  size={1.5}
                />
              </ButtonContainer>
              <MediumSpacing />
            </>
          )}
          <Switch
            size="small"
            isOn={isEnabled}
            name="/meetingtypes/configuration/behavior/routing/toggle_team_routing_on_off"
            onClick={toggleActive}
            disabled={!canEditMeetingType}
          />
        </Flexbox.Row>
        <XXLargeSpacing />
      </Flexbox.Row>
    </Flexbox.Column>
  );
}

export default function TeamList() {
  const { areTeamMembersLoading } = useTeamMembersContext();
  const [routing] = useRouting();
  const orderedUsers = useOrderedUsers();
  const teamListItem = ({ data, index, style: teamListItemStyle }) => {
    const user = data[index];
    return (
      <div style={teamListItemStyle}>
        <TeamListItem
          key={`${user.email}-${index}`}
          index={index}
          routing={routing}
          user={user}
          users={data}
        />
      </div>
    );
  };
  if (areTeamMembersLoading) {
    return (
      <EmptyViewContainer className={style.meetingType__emptyView}>
        <EmptyViewRow>
          <EmptyViewIcon
            className={style.meetingType__emptyViewLoading}
            icon={mdiLoading}
            spin={2}
          />
        </EmptyViewRow>
        <EmptyViewRow>Loading Team Members</EmptyViewRow>
      </EmptyViewContainer>
    );
  }
  return (
    <List
      height={700}
      itemCount={orderedUsers.length}
      itemData={orderedUsers}
      itemSize={100}
      layout="vertical"
      overscanCount={3}
    >
      {teamListItem}
    </List>
  );
}
