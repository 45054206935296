import dayjs from "dayjs";
import { getUserToken } from "src/utils/jwtToken";
import { useSWRConfig } from "swr";
import { useActingAsOverrideHeader } from "../auth";
import { useErrorHandler, useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";

// useCreatePAT is the route used for a user to create a personal access token
// it returns the created PAT
export function useCreatePAT() {
  const accessToken = getUserToken();
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const errorHandler = useErrorHandler();
  const override = useActingAsOverrideHeader();
  const tokenRefreshHandler = useTokenRefreshHandler();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (
    description: string,
    expiresAt: dayjs.Dayjs | null
  ): Promise<{ token: string }> =>
    service
      .post("/api/pat")
      .set(headers)
      .send({
        description: description,
        expiresAt: expiresAt,
      })
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .then((res: ReadableStream<Uint8Array> | null) => {
        // @ts-ignore - the type for `cache` does not explicitly define `keys`
        const keys: IterableIterator<string> = cache.keys();

        // invalidate PAT queries and force refresh
        Array.from(keys)
          .filter((route: string) => route.includes("/api/pat"))
          .forEach((route: string) => {
            mutate(route);
          });
        return res;
      })
      .catch(errorHandler);
}
