import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { ListItem, ListItemText } from "@mui/material";

import { useDebounce } from "../../hooks";

import { useUsers } from "../../queries";
import { User } from "../../types";

export function HostSelectInput({
  required,
  error,
  helperText,
  host,
  onHostSelect,
  forMeetingTransfer = false,
}: {
  required?: boolean;
  error?: boolean;
  helperText?: string;
  host: User | null;
  onHostSelect: (value: User | null) => void;
  forMeetingTransfer?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");

  const query = useDebounce(input, 750);

  const { data, loading } = useUsers(100, 0, { query, forMeetingTransfer });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Autocomplete
      open={open}
      disablePortal
      onOpen={handleOpen}
      onClose={handleClose}
      inputValue={input}
      onInputChange={(_, value) => {
        setInput(value);
      }}
      onChange={(_, value) => {
        onHostSelect(value);
      }}
      value={host}
      isOptionEqualToValue={(option, value) => option.email === value.email}
      getOptionLabel={(option) => option.email}
      renderOption={(props, option) => (
        <ListItem key={option.id} {...props} dense>
          <ListItemText
            primary={`${option.firstName} ${option.lastName}`}
            secondary={option.email}
          />
        </ListItem>
      )}
      options={data?.data || []}
      loading={loading}
      renderInput={(params) => (
        <TextField
          required={required}
          error={error}
          helperText={helperText}
          variant="outlined"
          {...params}
          label="Host"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </>
            ),
          }}
        />
      )}
    />
  );
}
