import React, { useMemo } from "react";
import Icon from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";
import { WHITE, BLACK } from "../../components/colors";
import Flexbox from "../../components/flexbox";
import { useTheme } from "../../components/theme";

function useHostTheme(hasHost, textColor = null) {
  const { isDark } = useTheme();
  return useMemo(
    () => ({
      container: (base, { isFocused }) => {
        let backgroundColor = "inherit";

        if (isFocused) {
          backgroundColor = isDark ? "#484B68" : WHITE;
        }

        return {
          ...base,
          backgroundColor,
          borderRadius: "3px",
          color: isFocused ? WHITE : BLACK,
          height: "100%",
          justifyContent: "flex-start",
          width: !hasHost && isFocused ? "calc(41% - 60px)" : "100%",
        };
      },
      control: (base) => ({
        ...base,
        alignItems: "center",
        backgroundColor: "inherit",
        border: 0,
        boxShadow: 0,
        cursor: "pointer",
        display: "flex",
        flexDirection: "row-reverse",
        fontSize: "10px",
        height: "100%",
        justifyContent: "flex-start",
        padding: "0px",
      }),
      indicatorSeparator: (base) => ({
        ...base,
        backgroundColor: "inherit",
      }),
      indicatorsContainer: (base) => ({
        ...base,
        position: "absolute",
        right: "0",
        visibility: "hidden",
      }),
      input: (base) => ({
        ...base,
        alignItems: "center",
        backgroundColor: "inherit",
        color: isDark ? WHITE : BLACK,
      }),
      loadingIndicator: (base) => ({
        ...base,
        color: isDark ? WHITE : BLACK,
        position: "absolute",
        right: "0",
      }),
      menu: (base) => ({
        ...base,
        marginTop: "0",
      }),
      menuList: (base) => ({
        ...base,
        border: `0.5px solid ${isDark ? "#191C3A" : "#A8B1BD"}`,
        borderRadius: 0,
        marginTop: "0px",
        padding: "0px",
      }),
      option: (base, { isFocused }) => {
        let backgroundColor;

        if (isDark) {
          backgroundColor = isFocused ? "rgba(121, 125, 149, 0.7)" : "#414564";
        }

        if (!isDark) {
          backgroundColor = isFocused ? "#F1FAFF" : WHITE;
        }

        return {
          ...base,
          backgroundColor,
          border: `0.5px solid ${isDark ? "#191C3A" : "#A8B1BD"}`,
          color: isDark ? WHITE : BLACK,
          cursor: "pointer",
          paddingBottom: "7px",
          paddingTop: "7px",
        };
      },
      singleValue: (base) => {
        if (textColor !== null) {
          return {
            ...base,
            color: textColor,
          };
        }
        return isDark ? WHITE : BLACK;
      },
      valueContainer: (base) => {
        if (textColor !== null) {
          return {
            ...base,
            color: textColor,
            fontSize: "13px",
            fontWeight: "500",
            height: "100%",
            padding: "0px",
          };
        }
        return {
          ...base,
          color: isDark ? WHITE : BLACK,
          fontSize: "13px",
          fontWeight: "500",
          height: "100%",
          padding: "0px",
        };
      },
    }),
    [isDark, hasHost, textColor]
  );
}

export default useHostTheme;
