import useSWR from "swr";

import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useErrorHandler, useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { Calendar } from "../types";
import { buildQueryString } from "../utils";

export type Team = {
  id: number;
  name: string;
  users: number[];
  isDefault: boolean;
  calendars: Calendar[] | null;
  tags: {
    id: number;
    name: string;
  }[];
};

export function useTeams(
  limit: number,
  offset: number,
  query?: { qry: string; sortBy?: string }
) {
  const service = useUserService();
  const accessToken = getUserToken();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const errorHandler = useErrorHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const q = buildQueryString({
    limit,
    offset,
    qry: query?.qry,
    sortBy: query?.sortBy,
  });

  const { data, error } = useSWR(`/api/teams?${q}`, (u) =>
    service
      .get(u)
      .set(headers)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler)
  );

  return {
    data: data as
      | {
          data: Team[];
          total: number;
        }
      | undefined,
    error,
    loading: !error && !data,
  };
}
