function useCreationData({ meetingType = null }) {
  if (!meetingType) {
    return null;
  }

  const { creationData } = meetingType;

  if (!creationData) {
    return null;
  }

  const { creatorData } = creationData;

  if (!creatorData) {
    return null;
  }

  const { createdAt } = creationData;

  const { userId, userLastName, userRole, userEmail } = creatorData;
  let { userFirstName } = creatorData;

  if (userFirstName.length < 1) {
    userFirstName = "No Data";
  }

  return {
    createdAt,
    userEmail,
    userFirstName,
    userId,
    userLastName,
    userRole,
  };
}

export default useCreationData;
