import React, { useCallback } from "react";
import { Checkbox } from "@mui/material";
import { useHasMeetingLinkProvider } from "../context";
import style from "./style.module.scss";

function MeetingLinkProvider({ disabled = false }) {
  const [hasMeetingLinkProvider = true, setHasMeetingLinkProvider] =
    useHasMeetingLinkProvider();

  const onChange = useCallback(
    (event) => {
      const {
        target: { checked },
      } = event;

      setHasMeetingLinkProvider(checked);
    },
    [setHasMeetingLinkProvider]
  );

  return (
    <div className={style.meetingLinkProvider}>
      <div className={style.meetingLinkProvider_title}>
        Generate meeting link if available:
      </div>
      <Checkbox
        disabled={disabled}
        onChange={onChange}
        checked={hasMeetingLinkProvider}
      />
    </div>
  );
}

export default MeetingLinkProvider;
