import React, { useCallback } from "react";

import { TagManager } from "../components/tagManager";
import { useTagsRepository } from "../repository";
import { useMeetingsTagAutomation } from "./context";

export default function AutomationContactsTagManager({
  close,
  setMeetingTypes,
}) {
  const repository = useTagsRepository();
  const { updateTag } = repository;
  const [tags, setMeetingsTagAutomation] = useMeetingsTagAutomation();
  const onSave = useCallback(
    async (selectedTags) => {
      setMeetingsTagAutomation(selectedTags);
    },
    [setMeetingsTagAutomation]
  );
  const onEdit = useCallback(
    async (tagId, tagUpdate) => {
      setMeetingTypes((prev) => {
        return prev.map((meetingType) => {
          const { tags: meetingTypeTags = [] } = meetingType;
          const meetingTypeTagIds = meetingTypeTags.map((mtt) => mtt.id);
          if (meetingTypeTagIds.includes(tagId)) {
            const performTagUpdate = (tag) => {
              if (tag.id === tagId) {
                return {
                  ...tag,
                  ...tagUpdate,
                };
              }
              return tag;
            };
            return {
              ...meetingType,
              tagAutomation: {
                tagContacts:
                  meetingTypeTags.tagAutomation.tagContacts.map(
                    performTagUpdate
                  ),
                tagMeetings:
                  meetingTypeTags.tagAutomation.tagMeetings.map(
                    performTagUpdate
                  ),
                tagUsers:
                  meetingTypeTags.tagAutomation.tagUsers.map(performTagUpdate),
              },
              tags: meetingTypeTags.map(performTagUpdate),
            };
          }
          return meetingType;
        });
      });
      // TODO: Delete from tag automation
      await updateTag(tagId, tagUpdate);
    },
    [setMeetingTypes, updateTag]
  );
  const onRemove = useCallback(
    async (tagIds) => {
      setMeetingTypes((prev) => {
        return prev.map((meetingType) => {
          const { tags: meetingTypeTags = [] } = meetingType;
          const filterTags = (mtt) => !tagIds.includes(mtt.id);
          return {
            ...meetingType,
            tagAutomation: {
              tagContacts:
                meetingTypeTags.tagAutomation.tagContacts.filter(filterTags),
              tagMeetings:
                meetingTypeTags.tagAutomation.tagMeetings.filter(filterTags),
              tagUsers:
                meetingTypeTags.tagAutomation.tagUsers.filter(filterTags),
            },
            tags: meetingTypeTags.filter((mtt) => !tagIds.includes(mtt.id)),
          };
        });
      });
      // TODO: Delete from tag automation
    },
    [setMeetingTypes]
  );
  return (
    <TagManager
      close={close}
      initialSelectedTags={tags}
      onEdit={onEdit}
      onRemove={onRemove}
      onSave={onSave}
      repository={repository}
      title="Manage Tags"
    />
  );
}
