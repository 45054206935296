import React from "react";

import Flexbox from "../components/flexbox/FlexboxV2";
import {
  JumboSpacing,
  MediumSpacing,
  SmallSpacing,
  XXLargeSpacing,
} from "../components/spacing";
import Step from "./Step";
import {
  useCanEdit,
  useOutOfOfficeProcessingEnabled,
  useTimeNegotiationEnabled,
} from "./context";
import { Switch } from "../components/switch";
import { P1 } from "../components/typography";

export default function AI() {
  const [timeNegotiationEnabled, setTimeNegotiationEnabled] =
    useTimeNegotiationEnabled();
  const [outOfOfficeProcessingEnabled, setOutOfOfficeProcessingEnabled] =
    useOutOfOfficeProcessingEnabled();
  const canEditMeetingType = useCanEdit();
  return (
    <Flexbox.Row>
      <XXLargeSpacing />
      <Flexbox.Column>
        <Step
          description="Should Kronologic's A.I negotiate with contacts for scheduling meetings?"
          stepNumber={1}
        />
        <MediumSpacing />
        <Flexbox.Row alignItems="center">
          <XXLargeSpacing />
          <P1>No</P1>
          <SmallSpacing />
          <Switch
            disabled={!canEditMeetingType}
            handleToggle={() => {
              setTimeNegotiationEnabled(!timeNegotiationEnabled);
            }}
            isOn={timeNegotiationEnabled}
            name="/meetingtypes/configuration/behavior/ai/negotiate_contacts_toggle_on_off"
          />
          <SmallSpacing />
          <P1>Yes</P1>
        </Flexbox.Row>
        <JumboSpacing />
        <Step
          description="Should Kronologic's A.I. detect if we recieved an out-of-office response on attempts?"
          stepNumber={2}
        />
        <MediumSpacing />
        <Flexbox.Row alignItems="center">
          <XXLargeSpacing />
          <P1>No</P1>
          <SmallSpacing />
          <Switch
            disabled={!canEditMeetingType}
            handleToggle={() => {
              setOutOfOfficeProcessingEnabled(!outOfOfficeProcessingEnabled);
            }}
            isOn={outOfOfficeProcessingEnabled}
            name="/meetingtypes/configuration/behavior/ai/out_of_office_toggle_yes_no"
          />
          <SmallSpacing />
          <P1>Yes</P1>
        </Flexbox.Row>
        <JumboSpacing />
      </Flexbox.Column>
    </Flexbox.Row>
  );
}
