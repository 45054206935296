import React from "react";

import Flexbox from "../components/flexbox/FlexboxV2";
import { Input } from "../components/input";
import {
  JumboSpacing,
  LargeSpacing,
  XXLargeSpacing,
} from "../components/spacing";
import { H6 } from "../components/typography";
import { useBcc, useCanEdit } from "./context";
import Step from "./Step";

export default function Bcc() {
  const [bcc, setBcc] = useBcc();
  const canEditMeetingType = useCanEdit();
  return (
    <Flexbox.Column>
      <LargeSpacing />
      <H6>Blind Carbon Copy</H6>
      <XXLargeSpacing />
      <Flexbox.Row>
        <XXLargeSpacing />
        <Step
          stepNumber={1}
          description="Who will be blind carbon copied on attempts?"
        />
      </Flexbox.Row>
      <XXLargeSpacing />
      <Flexbox.Row>
        <XXLargeSpacing />
        <Flexbox.Row>
          <XXLargeSpacing />
          <Input
            disabled={!canEditMeetingType}
            large
            placeholder="Bcc"
            name="/meetingtypes/configuration/bcc_text"
            onChange={(event) => {
              const newValue = event.target.value;
              setBcc(newValue);
            }}
            value={bcc}
          />
        </Flexbox.Row>
        <Flexbox.Row />
      </Flexbox.Row>
      <JumboSpacing />
    </Flexbox.Column>
  );
}
