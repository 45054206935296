import React from "react";

import Dropdown, { DropdownProps } from "src/componentsV2/Dropdown";

export function ContactCSVHeaderDropdown({
  value,
  onChange,
}: {
  value: string;
  onChange: DropdownProps["onChange"];
}) {
  return (
    <Dropdown
      value={value || "select"}
      options={[
        {
          label: "Select",
          value: "select",
        },
        {
          label: "First Name",
          value: "firstName",
        },
        {
          label: "Last Name",
          value: "lastName",
        },
        {
          label: "Email",
          value: "email",
        },
        {
          label: "Account",
          value: "account",
        },
        {
          label: "Phone",
          value: "phone",
        },
        {
          label: "Zip",
          value: "zip",
        },
        {
          label: "State",
          value: "state",
        },
        {
          label: "Logic Field 1",
          value: "logicField",
        },
        {
          label: "Logic Field 2",
          value: "leadScore", // FIXME: should rename this to logicField2
        },
        {
          label: "Routing Field",
          value: "routingField",
        },
        {
          label: "Custom Field 1",
          value: "customField1",
        },
        {
          label: "Custom Field 2",
          value: "customField2",
        },
        {
          label: "Custom Field 3",
          value: "customField3",
        },
        {
          label: "Custom Field 4",
          value: "customField4",
        },
        {
          label: "Custom Field 5",
          value: "customField5",
        },
        {
          label: "Custom Field 6",
          value: "customField6",
        },
        {
          label: "Custom Field 7",
          value: "customField7",
        },
        {
          label: "Custom Field 8",
          value: "customField8",
        },
        {
          label: "Custom Field 9",
          value: "customField9",
        },
        {
          label: "Custom Field 10",
          value: "customField10",
        },
      ]}
      onChange={onChange}
    />
  );
}

export default ContactCSVHeaderDropdown;
