import { useMemo } from "react";

import {
  PRIMARY_BLUE,
  BLACK,
  WHITE,
  NEUTRAL_500,
  NEUTRAL_700,
} from "../../../../components/colors";
import {
  ClearIndicator,
  DropdownIndicator,
} from "../../../../components/select";
import { useTheme } from "../../../../components/theme";

export function useConditionSelectTheme({
  left: isLeft = false,
  right: isRight = false,
} = {}) {
  const { isDark, useThemeShadows } = useTheme();
  const { MD } = useThemeShadows();
  const borderRadius = useMemo(() => {
    if (isLeft) {
      return "4px 0 0 4px";
    }
    if (isRight) {
      return "0 4px 4px 0";
    }
    return "0px";
  }, [isLeft, isRight]);

  return useMemo(() => {
    const optionBackgroundColor = isDark ? BLACK : WHITE;
    const optionTextColor = isDark ? WHITE : BLACK;
    // TODO: Do nicer disable appearences
    const additionalContainerProps = {};
    if (!isDark) {
      additionalContainerProps.border = `1px solid ${NEUTRAL_500}`;
    } else {
      additionalContainerProps.border = `1px solid ${BLACK}`;
    }
    const opacity = isDark ? "0.1" : "0.4";
    return [
      { ClearIndicator, DropdownIndicator },
      {
        container: (base, { isDisabled }) => ({
          ...base,
          ...additionalContainerProps,
          backgroundColor: isDark ? BLACK : WHITE,
          borderRadius,
          maxWidth: isRight ? null : "225px",
          minWidth: "225px",
          opacity: isDisabled ? opacity : null,
          width: "100%",
        }),
        control: (base) => ({
          ...base,
          backgroundColor: isDark ? BLACK : WHITE,
          border: 0,
          borderRadius,
          height: "38px",
        }),
        indicatorSeparator: (base) => ({
          ...base,
          marginBottom: "10px",
          marginTop: "10px",
        }),
        menu: (base) => ({
          ...base,
          backgroundColor: null,
          boxShadow: MD,
          marginTop: "10px",
        }),
        menuList: (base) => ({
          ...base,
          backgroundColor: optionBackgroundColor,
          borderRadius: "4px",
          boxShadow: "none",
          padding: "0px",
          width: "fit-content",
        }),
        option: (base, { isFocused }) => ({
          ...base,
          backgroundColor: isFocused ? PRIMARY_BLUE : optionBackgroundColor,
          color: isFocused ? WHITE : optionTextColor,
          cursor: "pointer",
          overflow: "hidden",
          padding: "7px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }),
        placeholder: (base) => ({
          ...base,
          color: isDark ? NEUTRAL_700 : NEUTRAL_500,
        }),
        singleValue: (base) => ({
          ...base,
          color: isDark ? WHITE : BLACK,
          width: "100%",
        }),
        valueContainer: (base) => ({
          ...base,
          // height: '100%',
          justifyContent: "center",
          margin: "0 0 0 0.6em",
        }),
      },
    ];
  }, [borderRadius, isDark, isRight, MD]);
}
