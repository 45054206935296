import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";

import Flexbox from "../../components/flexbox/FlexboxV2";
import { H2 } from "../../components/typography";
import { JumboSpacing } from "../../components/spacing";

export default function CopyMeetingTemplate({ meetingTemplate, onCreate }) {
  const history = useHistory();
  const copyNewMeetingTemplate = useCallback(async () => {
    const copy = {
      ...meetingTemplate,
      enabled: false,
      name: `${meetingTemplate.name} Clone`,
    };
    delete copy.id;
    const copiedMeetingTemplate = await onCreate(copy);
    history.push(
      `/meetingTypes/library/${copiedMeetingTemplate.id}/general/invite`
    );
  }, [meetingTemplate, history, onCreate]);
  useEffect(() => {
    copyNewMeetingTemplate();
  }, [copyNewMeetingTemplate]);
  return (
    <Flexbox.Column alignItems="center" justifyContent="center">
      <Icon path={mdiLoading} size={8} spin={1} />
      <JumboSpacing />
      <H2>Copying meeting template...</H2>
    </Flexbox.Column>
  );
}
