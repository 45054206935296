import React, { useCallback } from "react";
import { mdiInformationOutline } from "@mdi/js";
import Icon from "@mdi/react";

import Flexbox from "../../../components/flexbox";
import { Switch } from "../../../components/switch";
import { P1 } from "../../../components/typography";
import { MeetingActivationSwitch } from "../../../components/meetingActivationSwitch";
import { useMeetingTypeState, useMeeting } from "../state";
import useGeneralNotifications from "../../../hooks/useGeneralNotifications";
import DisplayFailedInstances from "../../notifications/DisplayFailedInstances";
import style from "./controls.module.scss";
import { useUpdateMeetings } from "../../../mutations";

function Controls() {
  const { addError } = useGeneralNotifications();
  const [meeting] = useMeeting();
  const { meetingType } = useMeetingTypeState();

  const updateMeetings = useUpdateMeetings();

  const handleOnToggle = useCallback(async () => {
    const { failures } = await updateMeetings([
      {
        enabled: !meeting.enabled,
        id: meeting.id,
      },
    ]).catch(() => {
      addError("Failed to activate meeting");
    });

    // report failures
    if (failures && failures.length > 0) {
      addError(<DisplayFailedInstances failures={failures} />);
    }
  }, [addError, updateMeetings, meeting]);

  return (
    <Flexbox>
      {({ Column, Row }) => (
        <Column className={style.controls} width="50%">
          <Row height="15%" justifyContent="start">
            <P1 small bold>
              Controls
            </P1>
          </Row>

          <Column>
            <Row>
              <MeetingActivationSwitch
                name="/instanceDetails/activate_switch"
                meeting={meeting}
                handleToggle={handleOnToggle}
              />
            </Row>

            {meeting.meetingType && !meetingType.active && (
              <Row>
                <P1 small>*This meeting template is currently disabled</P1>
              </Row>
            )}

            <Row height="50%">
              <Row justifyContent="flex-start">
                <P1 className={style.controls_body_ain__text} extraSmall>
                  AI Negotiation
                </P1>
                <Icon path={mdiInformationOutline} size={0.5} />
              </Row>

              <Switch
                disabled
                name="/instanceDetails/ai_negotiation_switch"
                size="xsmall"
                isOn={meetingType?.timeNegotiationEnabled}
              />
            </Row>
          </Column>
        </Column>
      )}
    </Flexbox>
  );
}

export default Controls;
