import React, { useState, useEffect } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import {
  Container,
  Stack,
  Typography,
  useTheme,
  Button,
  Paper,
  useMediaQuery,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

import useGeneralNotifications from "../../hooks/useGeneralNotifications";
import AvailabilityPicker from "../../componentsV2/AvailabilityPicker";

import { GuestAction as GuestActionType, GuestActionSource } from "../../types";
import {
  useGuestDecline,
  useGuestAccept,
  useGuestProposeNewTime,
} from "../../mutations";
import {
  useValidateGuestDeclineAction,
  useHostAvailability,
} from "../../queries";
import { Loading } from "../../componentsV2/Loading";
import InvalidUrl from "../InvalidUrl";
import { useUrlSearch } from "../../components/urlSearch";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";
import SecondaryButton from "src/componentsV2/buttons/SecondaryButton";
import { AddIcon } from "src/icons";

function Accept({
  orgId,
  meetingId,
  contactId,
}: {
  orgId: string;
  meetingId: string;
  contactId: string;
}) {
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const guestAccept = useGuestAccept();

  useEffect(
    () => {
      guestAccept(contactId, meetingId, orgId)
        .catch((err) => {
          if (err?.status === 409) {
            history.push("/pending");
          } else {
            history.push("/error");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    // We only want this to run if the ids change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contactId, meetingId, orgId]
  );

  if (loading) {
    return <Loading />;
  }

  return <Redirect to="/accepted" />;
}

function Decline({
  orgId,
  meetingId,
  contactId,
}: {
  orgId: string;
  meetingId: string;
  contactId: string;
}) {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const theme = useTheme();

  const guestDecline = useGuestDecline();

  const { error } = useValidateGuestDeclineAction(orgId, meetingId, contactId);
  if (error) {
    return <Redirect to="/error" />;
  }

  const handleConfirmationClick = () => {
    setLoading(true);
    setConfirmed(true);
    guestDecline(contactId, meetingId, orgId)
      .catch((err) => {
        if (err?.status === 409) {
          history.push("/pending");
        } else {
          history.push("/error");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleProposeTime = () => {
    const query = new URLSearchParams(search);
    query.set("a", "propose_new_time");
    const url = `${pathname}?${query.toString()}`;

    history.push(url);
  };

  if (loading) {
    return <Loading />;
  }

  if (confirmed) {
    return <Redirect to="/declined" />;
  }

  return (
    <Container maxWidth="xs">
      <Stack
        alignItems="center"
        spacing={2}
        sx={{ minHeight: "100vh", paddingTop: theme.spacing(8) }}
      >
        <Typography>Are you sure you want to decline this meeting?</Typography>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <SecondaryButton nowrap onClick={handleConfirmationClick}>
            Decline
          </SecondaryButton>
          <PrimaryButton icon={<AddIcon />} nowrap onClick={handleProposeTime}>
            Propose Time
          </PrimaryButton>
        </Stack>
      </Stack>
    </Container>
  );
}

function ProposeNewTime({
  orgId,
  meetingId,
  contactId,
  source,
}: {
  orgId: string;
  meetingId: string;
  contactId: string;
  source: GuestActionSource | null;
}) {
  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.up("md"));

  const [proposedTime, setProposedTime] = useState<dayjs.Dayjs | null>(null);
  const [selection, setSelection] = useState<dayjs.Dayjs | null>(null);
  const [confirmed, setConfirmed] = useState(false);

  const { addError } = useGeneralNotifications();

  const guestProposeNewTime = useGuestProposeNewTime();

  const { data, loading, error } = useHostAvailability(
    orgId,
    meetingId,
    contactId
  );

  const handleMeetingGuestAction = () => {
    guestProposeNewTime(
      contactId,
      meetingId,
      orgId,
      proposedTime as Dayjs,
      source
    )
      .then(() => {
        setConfirmed(true);
      })
      .catch((error: { message: string }) => {
        addError(`could not submit time proposal: ${error.message}`);
      });
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    switch (error.status) {
      case 409:
        if (error.response?.body?.message) {
          return <Redirect to="/pending" />;
        }
        return <Redirect to="/error" />;
      case 400:
        return <InvalidUrl />;
      default:
        return <Redirect to="/error" />;
    }
  }

  if (!data) {
    return <Redirect to="/error" />;
  }

  if (confirmed) {
    return <Redirect to="/accepted" />;
  }

  if (data.availableTimes.length === 0) {
    return (
      <Container maxWidth="sm">
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "100vh" }}
        >
          <Typography variant="h4" align="center">
            The meeting host has no availability at this time
          </Typography>
        </Stack>
      </Container>
    );
  }

  return (
    <Stack alignItems="center" sx={{ marginTop: theme.spacing(4) }}>
      <Paper
        elevation={0}
        sx={{ maxWidth: "fit-content", padding: theme.spacing(4) }}
      >
        <Typography variant="h5">Select a date and time</Typography>
        <AvailabilityPicker
          variant={sm ? "horizontal" : "vertical"}
          date={proposedTime}
          selection={selection}
          availableSlots={data.availableTimes.map((d) => dayjs(d)) || []}
          onDateChange={setProposedTime}
          onSelectionChange={(dt) => {
            setSelection(dt);
            setProposedTime(dt);
          }}
        />
        <Stack
          direction="row"
          justifyContent="flex-end"
          paddingTop={theme.spacing(2)}
        >
          <PrimaryButton
            onClick={handleMeetingGuestAction}
            disabled={selection == null}
          >
            Confirm
          </PrimaryButton>
        </Stack>
      </Paper>
    </Stack>
  );
}

export default function Page() {
  document.title = "Processing Meeting Guest Action";

  const query = useUrlSearch();

  // Get the Org's UUID
  const org = query.get("o");
  // Get the Meeting's UUID
  const meeting = query.get("m");
  // Get the Contact's UUID
  const contact = query.get("c");
  // Get the Contact's Action
  const action = query.get("a") as GuestActionType | null;
  // Get the Contact's Action Source
  const actionSource = query.get("s") as GuestActionSource | null;

  if (
    org == null ||
    meeting == null ||
    contact == null ||
    action == null ||
    !["propose_new_time", "accept", "decline"].includes(action)
  ) {
    return <InvalidUrl />;
  }

  switch (action) {
    case "accept":
      return <Accept orgId={org} meetingId={meeting} contactId={contact} />;
    case "decline":
      return <Decline orgId={org} meetingId={meeting} contactId={contact} />;
    case "propose_new_time":
      return (
        <ProposeNewTime
          orgId={org}
          meetingId={meeting}
          contactId={contact}
          source={actionSource}
        />
      );
    default:
      return <InvalidUrl />;
  }
}
