import { createContext, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useActingAs, useIsDefaultUser } from "../auth";
import { ROLE_LEVELS } from "../auth/roles";
import useCreationData from "./useCreationData";

export const MeetingTypesContext = createContext();
export const MeetingTypeRefsContext = createContext();
export const TeamMembersContext = createContext();

// TODO(Matt): Can you rename this to something?!
export function useTeamMembersContext() {
  return useContext(TeamMembersContext);
}

export function useInviteRefs() {
  const { current } = useContext(MeetingTypeRefsContext);
  const { invite } = current;
  return invite.current;
}

export function useDeclineEmailRefs() {
  const { current } = useContext(MeetingTypeRefsContext);
  const { decline } = current;
  return decline.current;
}

export function useEmailRefs() {
  const { current } = useContext(MeetingTypeRefsContext);
  const { emails } = current;
  return emails.current;
}

export function useReminderEmailRefs() {
  const { current } = useContext(MeetingTypeRefsContext);
  const { reminder } = current;
  return reminder.current;
}

function useMeetingTypesContext() {
  return useContext(MeetingTypesContext);
}

export function useSaveMeetingType() {
  const { meetingType, saveMeetingType } = useMeetingTypesContext();
  return () => {
    saveMeetingType({
      meetingTypeId: meetingType?.id,
      newMeetingType: meetingType,
      showNotification: true,
    });
  };
}

export function useSelectedMeetingType() {
  const { meetingType, updateMeetingType } = useMeetingTypesContext();
  const setMeetingType = (newMeetingType) => {
    updateMeetingType(meetingType?.id, newMeetingType);
  };
  return [meetingType, setMeetingType];
}

export function useMeetingTypeId() {
  const [meetingType] = useSelectedMeetingType();
  return meetingType?.id;
}

export function useActive() {
  const [meetingType, setMeetingType] = useSelectedMeetingType();
  const { active } = meetingType;
  const setActive = (newActive) => {
    setMeetingType({
      ...meetingType,
      active: newActive,
    });
  };
  return [active, setActive];
}

export function useEmails() {
  const [meetingType, setMeetingType] = useSelectedMeetingType();
  const { emailTemplates } = meetingType;
  const setEmailTemplates = (newEmailTemplates) => {
    setMeetingType({
      ...meetingType,
      emailTemplates: newEmailTemplates,
    });
  };
  return [emailTemplates, setEmailTemplates];
}

export function useAddEmail() {
  const [emails, setEmails] = useEmails();
  return () => {
    const newEmails = [...emails];
    const nextOrder = newEmails.length + 1;
    newEmails.push({
      body: "",
      order: nextOrder,
      title: "",
    });

    setEmails(newEmails);
    return nextOrder;
  };
}

export function useRemoveEmail() {
  const [emails, setEmails] = useEmails();
  return (index) => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);
    return newEmails.length;
  };
}

export function useSelectedEmailIndex() {
  const { emailId: index } = useParams();
  return parseInt(index) - 1;
}

export function useEmail(index) {
  const [emails, setEmails] = useEmails();
  const setEmailTemplate = (newEmailTemplate) => {
    const newEmailTemplates = [...emails];
    newEmailTemplates[index] = newEmailTemplate;
    setEmails(newEmailTemplates);
  };
  if (!(index in emails)) {
    return [null, setEmailTemplate];
  }
  return [emails[index], setEmailTemplate];
}

function useSelectedEmail() {
  return useEmail(useSelectedEmailIndex());
}

export function useEmailSubject() {
  const [email, setEmail] = useSelectedEmail();
  const setSubject = (newEmailSubject) => {
    setEmail({
      ...email,
      title: newEmailSubject,
    });
  };
  if (email === null) {
    return [null, setSubject];
  }
  const { title: subject } = email;
  return [subject, setSubject];
}

export function useEmailBody() {
  const [email, setEmail] = useSelectedEmail();
  const setBody = (newBody, newText) => {
    setEmail({
      ...email,
      body: newBody,
      text: newText,
    });
  };
  if (email === null) {
    return [null, setBody];
  }
  const { body } = email;
  return [body, setBody];
}

export function useInvite() {
  const [meetingType, setMeetingType] = useSelectedMeetingType();
  const { inviteTemplates } = meetingType;
  const [invite] = inviteTemplates;
  const setInvite = (newInvite) => {
    setMeetingType({
      ...meetingType,
      inviteTemplates: [newInvite],
    });
  };
  return [invite, setInvite];
}

export function useInviteTitle() {
  const [invite, setInvite] = useInvite();
  const { title } = invite;
  const setTitle = (newTitle) => {
    setInvite({
      ...invite,
      title: newTitle,
    });
  };
  return [title, setTitle];
}

export function useInviteLocation() {
  const [invite, setInvite] = useInvite();
  const { location } = invite;
  const setLocation = (newLocation) => {
    setInvite({
      ...invite,
      location: newLocation,
    });
  };
  return [location, setLocation];
}

export function useInviteNotes() {
  const [invite, setInvite] = useInvite();
  const { notes } = invite;
  const setNotes = (newNotes) => {
    setInvite({
      ...invite,
      notes: newNotes,
    });
  };
  return [notes, setNotes];
}

export function useTeam() {
  const [meetingType, setMeetingType] = useSelectedMeetingType();
  const { team } = meetingType;
  const setTeam = (newTeam, newRoutingLogic) => {
    const newMeetingType = {
      ...meetingType,
      team: newTeam,
    };
    if (newRoutingLogic !== null) {
      newMeetingType.routing_logic = newRoutingLogic;
    }
    setMeetingType(newMeetingType);
  };

  return [team, setTeam];
}

export function useDistribution() {
  const [meetingType, setMeetingType] = useSelectedMeetingType();
  const { distribution } = meetingType;
  const setDistribution = (newDistribution) => {
    setMeetingType({
      ...meetingType,
      distribution: newDistribution,
    });
  };
  return [distribution, setDistribution];
}

export function useRoutingLogic() {
  const [meetingType, setMeetingType] = useSelectedMeetingType();
  const { routing_logic: routingLogic } = meetingType;
  const setRoutingLogic = (newRoutingLogic) => {
    setMeetingType({
      ...meetingType,
      routing_logic: newRoutingLogic,
    });
  };
  return [routingLogic, setRoutingLogic];
}

export function useUserConditions(userId) {
  const [routingLogic, setRoutingLogic] = useRoutingLogic();
  const { custom = null } = routingLogic;
  const setUserConditions = (userConditions) => {
    setRoutingLogic({
      ...routingLogic,
      custom: {
        ...routingLogic.custom,
        [userId]: {
          ...routingLogic.custom[userId],
          mapping_logic: userConditions,
        },
      },
    });
  };
  if (userId === null) {
    return [null, setUserConditions];
  }
  if (custom === null) {
    return [null, setUserConditions];
  }
  if (!(userId in custom)) {
    return [null, setUserConditions];
  }
  return [custom[userId].mapping_logic || null, setUserConditions];
}

export function useRoutingSubType() {
  const [routingLogic] = useRoutingLogic();
  return [routingLogic?.metadata?.subtypeDetails?.subtype || null];
}

export function useIsEmailBasedRouting() {
  const [routingLogic] = useRoutingLogic();
  return routingLogic?.metadata?.subtypeDetails?.subtype === "email";
}

export function useRouting() {
  const [meetingType, setMeetingType] = useSelectedMeetingType();
  const { routing } = meetingType;
  const setRouting = (newRouting, newRoutingLogic) => {
    const newMeetingType = {
      ...meetingType,
      routing: newRouting,
    };
    if (newRoutingLogic !== null) {
      newMeetingType.routing_logic = newRoutingLogic;
    }
    setMeetingType(newMeetingType);
  };
  return [routing, setRouting];
}

export function useSubTypeDetailsFieldValue() {
  const [routing] = useRouting();
  const [routingLogic] = useRoutingLogic();
  if (routing !== "custom") {
    return [null];
  }
  return [routingLogic?.metadata?.subtypeDetails?.field || null];
}

function useProperties() {
  const [meetingType, setMeetingType] = useSelectedMeetingType();
  const { properties } = meetingType;
  const setProperties = (newProperties) => {
    setMeetingType({
      ...meetingType,
      properties: newProperties,
    });
  };
  return [properties, setProperties];
}

export function useIsRoutingOnActivation() {
  const [properties, setProperties] = useProperties();
  const { routeOnActivation: isRoutingOnActivation } = properties;
  const setIsRoutingOnActivation = (newIsRoutingOnActivation) => {
    setProperties({
      ...properties,
      routeOnActivation: newIsRoutingOnActivation,
    });
  };
  return [isRoutingOnActivation, setIsRoutingOnActivation];
}

export function useGap() {
  const [properties, setProperties] = useProperties();
  const { gap } = properties;
  const setGap = (newGap) => {
    setProperties({
      ...properties,
      gap: newGap,
    });
  };
  return [gap, setGap];
}

export function useDuration() {
  const [properties, setProperties] = useProperties();
  const { duration } = properties;
  const setDuration = (newDuration) => {
    setProperties({
      ...properties,
      duration: newDuration,
    });
  };
  return [duration, setDuration];
}

export function useDayRange() {
  const [properties, setProperties] = useProperties();
  const { dayRange } = properties;
  const setDayRange = ({ from, to }) => {
    setProperties({
      ...properties,
      dayRange: {
        from,
        to,
      },
    });
  };
  return [dayRange, setDayRange];
}

export function useHasMeetingLinkProvider() {
  const [properties, setProperties] = useProperties();
  const { hasMeetingLinkProvider } = properties;
  const setHasMeetingLinkProvider = (value) => {
    setProperties({
      ...properties,
      hasMeetingLinkProvider: value,
    });
  };

  return [hasMeetingLinkProvider, setHasMeetingLinkProvider];
}

function useBufferDurationMinutes() {
  const [meetingType, setMeetingType] = useSelectedMeetingType();
  const { buffer_duration_mins: bufferDurationMinutes, properties } =
    meetingType;
  const setBufferDurationMinutes = (
    newBufferDurationMinutes,
    sameDayBuffer = null
  ) => {
    const newMeetingType = {
      ...meetingType,
      buffer_duration_mins: newBufferDurationMinutes,
    };
    if (sameDayBuffer !== null) {
      newMeetingType.properties = {
        ...properties,
        sameDayBuffer,
      };
    }
    setMeetingType(newMeetingType);
  };
  return [bufferDurationMinutes, setBufferDurationMinutes];
}

const MINUTES_PER_HOUR = 60;

export function useBufferDurationHours() {
  const [bufferDurationMinutes, setBufferDurationMinutes] =
    useBufferDurationMinutes();
  const setBufferDurationHours = (
    newBufferDurationHours,
    sameDayBuffer = null
  ) => {
    setBufferDurationMinutes(
      newBufferDurationHours * MINUTES_PER_HOUR,
      sameDayBuffer
    );
  };
  const bufferDurationHours = Math.ceil(
    bufferDurationMinutes / MINUTES_PER_HOUR
  );
  return [bufferDurationHours, setBufferDurationHours];
}

export function useSameDayBuffer() {
  const [properties, setProperties] = useProperties();
  const { sameDayBuffer } = properties;
  const setSameDayBuffer = (newSameDayBuffer) => {
    setProperties({
      ...properties,
      sameDayBuffer: newSameDayBuffer,
    });
  };
  return [sameDayBuffer, setSameDayBuffer];
}

export function useCost() {
  const [meetingType, setMeetingType] = useSelectedMeetingType();
  const { cost } = meetingType;
  const setCost = (newCost) => {
    setMeetingType({
      ...meetingType,
      cost: newCost,
    });
  };
  return [cost, setCost];
}

export function useName() {
  const [meetingType, setMeetingType] = useSelectedMeetingType();
  const { name } = meetingType;
  const setName = (newName) => {
    setMeetingType({
      ...meetingType,
      name: newName,
    });
  };
  return [name, setName];
}

export function useDeclineEmail() {
  const [properties, setProperties] = useProperties();
  const { cleanDeclineRule = null } = properties;
  const setDeclineEmail = (newDeclineEmail) => {
    if (newDeclineEmail === null) {
      const copy = {
        ...properties,
      };
      delete copy.cleanDeclineRule;
      setProperties(copy);
      return;
    }
    const { title: newTitle, body: newBody } = newDeclineEmail;
    if (cleanDeclineRule === null) {
      setProperties({
        ...properties,
        cleanDeclineRule: {
          action: {
            meta: {
              body: newBody,
              title: newTitle,
            },
            type: "sendEmail",
          },
          trigger: {
            field: "status",
            object: "meeting",
            values: ["declined"],
          },
        },
      });
      return;
    }
    const { action, meta, trigger } = cleanDeclineRule;
    setProperties({
      ...properties,
      cleanDeclineRule: {
        action: {
          ...action,
          meta: {
            ...meta,
            body: newBody,
            title: newTitle,
          },
        },
        trigger,
      },
    });
  };
  if (cleanDeclineRule === null) {
    return [null, setDeclineEmail];
  }
  const title = cleanDeclineRule?.action?.meta.title;
  const body = cleanDeclineRule?.action?.meta.body;
  return [
    {
      body,
      title,
    },
    setDeclineEmail,
  ];
}

export function useDeclineSubject() {
  const [email, setDeclineEmail] = useDeclineEmail();
  const setDeclineSubject = (newSubject) => {
    setDeclineEmail({
      ...email,
      title: newSubject,
    });
  };
  if (email === null) {
    return [null, setDeclineSubject];
  }
  return [email.title, setDeclineSubject];
}

export function useDeclineBody() {
  const [email, setDeclineEmail] = useDeclineEmail();
  const setDeclineBody = (newBody) => {
    setDeclineEmail({
      ...email,
      body: newBody,
    });
  };
  if (email === null) {
    return [null];
  }
  return [email.body, setDeclineBody];
}

export function useOutOfOfficeProcessingEnabled() {
  const [meetingType, setMeetingType] = useSelectedMeetingType();
  const { oooProcessingEnabled } = meetingType;
  const setOutOfOfficeProcesingEnabled = (newOutOfOfficeProcessingEnabled) => {
    setMeetingType({
      ...meetingType,
      oooProcessingEnabled: newOutOfOfficeProcessingEnabled,
    });
  };
  return [oooProcessingEnabled, setOutOfOfficeProcesingEnabled];
}

export function useMeetingInviteStyle() {
  const [meetingType, setMeetingType] = useSelectedMeetingType();
  const { inviteStyle: meetingInviteStyle } = meetingType;
  const setMeetingInviteStyle = (newMeetingInviteStyle) => {
    setMeetingType({
      ...meetingType,
      inviteStyle: newMeetingInviteStyle,
    });
  };
  return [meetingInviteStyle, setMeetingInviteStyle];
}

export function useTimeNegotiationEnabled() {
  const [meetingType, setMeetingType] = useSelectedMeetingType();
  const { timeNegotiationEnabled } = meetingType;
  const setTimeNegotiationEnabled = (newTimeNegotiationEnabled) => {
    setMeetingType({
      ...meetingType,
      timeNegotiationEnabled: newTimeNegotiationEnabled,
    });
  };
  return [timeNegotiationEnabled, setTimeNegotiationEnabled];
}

export function useUnsubscribeConfig() {
  const [meetingType, setMeetingType] = useSelectedMeetingType();
  let { unsubscribeConfig } = meetingType;
  if (!unsubscribeConfig) {
    unsubscribeConfig = {
      body: "Click here",
      enabled: false,
      hyperlinkText: "",
    };
  }

  const setUnsubscribeConfig = ({ body, enabled, hyperlinkText }) => {
    setMeetingType({
      ...meetingType,
      unsubscribeConfig: {
        body,
        enabled,
        hyperlinkText,
      },
    });
  };

  return [unsubscribeConfig, setUnsubscribeConfig];
}

export function useMeetingReminder() {
  const [properties, setProperties] = useProperties();
  const { meetingReminder = {} } = properties;
  const setMeetingReminder = (newMeetingReminder) => {
    setProperties({
      ...properties,
      meetingReminder: newMeetingReminder,
    });
  };
  return [meetingReminder, setMeetingReminder];
}

export function useBcc() {
  const [properties, setProperties] = useProperties();
  const { bcc } = properties;
  const setBcc = (newBcc) => {
    setProperties({
      ...properties,
      bcc: newBcc,
    });
  };
  return [bcc, setBcc];
}

export function useMeetingReminderEmail() {
  const [meetingReminder, setMeetingReminder] = useMeetingReminder();
  const {
    body = "",
    title = "",
    allowRescheduling = false,
  } = meetingReminder || {
    body: "",
    title: "",
    allowRescheduling: false,
  };
  const setEmail = (newEmail) => {
    setMeetingReminder({
      ...meetingReminder,
      body: newEmail.body,
      title: newEmail.title,
      allowRescheduling: newEmail.allowRescheduling,
    });
  };
  return [{ body, title, allowRescheduling }, setEmail];
}

export function useTags() {
  const [meetingType, setMeetingType] = useSelectedMeetingType();
  const { tags } = meetingType;
  const setTags = (newTags) => {
    setMeetingType({
      ...meetingType,
      tags: newTags,
    });
  };
  return [tags, setTags];
}

export function useMeetingReminderSubject() {
  const [email, setReminderEmail] = useMeetingReminderEmail();
  const setReminderSubject = (newSubject) => {
    setReminderEmail({
      ...email,
      title: newSubject,
    });
  };
  if (email === null) {
    return [null, setReminderSubject];
  }
  return [email.title, setReminderSubject];
}

export function useMeetingReminderAllowRescheduling() {
  const [email, setReminderEmail] = useMeetingReminderEmail();
  const setAllowRescheduling = (allowRescheduling) => {
    setReminderEmail({
      ...email,
      allowRescheduling: allowRescheduling,
    });
  };
  if (email === null) {
    return [null, setAllowRescheduling];
  }
  return [email.allowRescheduling, setAllowRescheduling];
}

export function useMeetingReminderBody() {
  const [email, setReminderEmail] = useMeetingReminderEmail();
  const setReminderBody = (newBody) => {
    setReminderEmail({
      ...email,
      body: newBody,
    });
  };
  if (email === null) {
    return [null, setReminderBody];
  }
  return [email.body, setReminderBody];
}

function useTagAutomation() {
  const [meetingType, setMeetingType] = useSelectedMeetingType();
  const { tagAutomation } = meetingType;
  const setTagAutomation = (newTagAutomation) => {
    setMeetingType({
      ...meetingType,
      tagAutomation: newTagAutomation,
    });
  };
  return [tagAutomation, setTagAutomation];
}

export function useContactsTagAutomation() {
  const [tagAutomation, setTagAutomation] = useTagAutomation();
  const { tagContacts } = tagAutomation;
  const setTagContacts = (newTagContacts) => {
    setTagAutomation({
      ...tagAutomation,
      tagContacts: newTagContacts,
    });
  };
  return [tagContacts, setTagContacts];
}

export function useMeetingsTagAutomation() {
  const [tagAutomation, setTagAutomation] = useTagAutomation();
  const { tagMeetings } = tagAutomation;
  const setTagMeetings = (newTagMeetings) => {
    setTagAutomation({
      ...tagAutomation,
      tagMeetings: newTagMeetings,
    });
  };
  return [tagMeetings, setTagMeetings];
}

export function useUsersTagAutomation() {
  const [tagAutomation, setTagAutomation] = useTagAutomation();
  const { tagUsers } = tagAutomation;
  const setTagUsers = (newTagUsers) => {
    setTagAutomation({
      ...tagAutomation,
      tagUsers: newTagUsers,
    });
  };
  return [tagUsers, setTagUsers];
}

export function useCanEdit() {
  const [meetingType] = useSelectedMeetingType();
  const creationData = useCreationData({ meetingType });
  const isDefaultUser = useIsDefaultUser();
  const actingAs = useActingAs();

  return useMemo(() => {
    if (
      creationData === null &&
      !isDefaultUser &&
      actingAs?.role !== ROLE_LEVELS.DEFAULT
    ) {
      return true;
    }

    // anyone who can see a default user's meeting templates can edit them
    if (creationData.userRole === ROLE_LEVELS.DEFAULT) {
      return true;
    }

    // if a default user is viewing an org-wide meeting template, they cannot edit those
    if (isDefaultUser || actingAs?.role === ROLE_LEVELS.DEFAULT) {
      return false;
    }

    return true;
  }, [actingAs, creationData, isDefaultUser]);
}

export function useIsDefaultUserMeetingType() {
  const [meetingType] = useSelectedMeetingType();
  const creationData = useCreationData({ meetingType });
  return useMemo(() => {
    if (creationData === null) {
      return false;
    }

    return creationData.userRole === ROLE_LEVELS.DEFAULT;
  }, [creationData]);
}
