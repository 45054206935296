import { useCallback, useMemo } from "react";
import { useShadows } from "../shadows";
import { LIGHT_BACKGROUND_COLOR, LIGHT_BORDER_COLOR } from "./theme";

// Can only be used within the ThemeContext provider
const useTheme = () => {
  const context = {
    isDark: false,
    toggleTheme: () => null,
  };
  if (!context) {
    throw new Error("useTheme must be used within ThemeProvider");
  }
  const { toggleTheme } = context;
  const backgroundColor = useMemo(() => LIGHT_BACKGROUND_COLOR, []);
  const borderColor = useMemo(() => LIGHT_BORDER_COLOR, []);
  const color = useMemo(() => "#000000", []);
  const shadows = useShadows();
  const useThemeShadows = useCallback(() => {
    return {
      LG: shadows.DARK_SHADOW_LG,
      MD: shadows.DARK_SHADOW_MD,
      SM: shadows.DARK_SHADOW_SM,
      XL: shadows.DARK_SHADOW_XL,
      XS: shadows.DARK_SHADOW_XS,
    };
  }, [shadows]);

  return useMemo(
    () => ({
      backgroundColor,
      borderColor,
      color,
      isDark: false,
      toggleTheme,
      useThemeShadows,
    }),
    [backgroundColor, borderColor, color, toggleTheme, useThemeShadows]
  );
};

export { useTheme };
