import React, {
  useMemo,
  useCallback,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import ReactModal from "react-modal";
import cx from "classnames";
import { ModalStateContext, ChannelsStateContext } from "../ChannelsState";
import { post } from "../../utils/fetch";
import { API } from "../../props";
import MeetingInstanceCard from "./cards/MeetingInstanceCard";
import { IntegrationsCard } from "./cards/IntegrationsCard";
import ConditionCards from "./cards/ConditionCards";
import { formatConditions } from "./helpers";
import { DEFAULT_STATE } from "./cards/cardProps";
import SampleImportData from "./cards/SampleImportData";
import Loader from "./Loader";
import { CHANNEL_TYPES } from "../props";
import { Button } from "../../components/button";
import useSampleImportData from "./useSampleImportData";
import FieldMappingCard from "./cards/FieldMappingCard";
import { useHasOrgAdminPermissions } from "../../auth";
import ChannelTagAutomation from "./cards/tagAutomation/ChannelTagAutomation";
import style from "./style.module.scss";
import {
  CHANNELS_ADD_NEW,
  MEETING_TYPE_ADD_NEW_CHANNEL,
  useCreationMeta,
} from "../../components/meetingCreationMethods";
import { INTEGRATION_TYPES } from "../../integrations/props";

const ERROR = "There was a problem retrieving sample data";

function AddModal({
  channelType,
  onClose,
  onChannelAdded,
  presetMeetingDef = null,
  meetingTypes: { isLoading: meetingsIsLoading, data: meetingTypes },
}) {
  const {
    clearSampleData,
    hasError,
    isRetrievingSampleData,
    runSampleImportData,
    sampleImportData,
  } = useSampleImportData();
  const hasOrgAdminPermissions = useHasOrgAdminPermissions();
  const scrollRef = useRef(null);
  const [
    {
      [channelType]: { add },
    },
    dispatchModalState,
  ] = useContext(ModalStateContext);
  const [{ [`${channelType}Fields`]: channelFields }] =
    useContext(ChannelsStateContext);
  const [state, dispatch] = useContext(ChannelsStateContext);
  const [canCreate, setCanCreate] = useState(false);
  const [tempModalState, setTempModalState] = useState(DEFAULT_STATE);
  const [showInvalidFields, setShowInvalidFields] = useState(false);
  const [meetingInstanceCardIsValid, setMeetingInstanceCardIsValid] =
    useState(false);
  const [integrationsCardIsValid, setIntegrationsCardIsValid] = useState(false);
  const [conditionCardsAreValid, setConditionCardsAreValid] = useState(false);

  const [meetingDef, setMeetingDef] = useState(
    presetMeetingDef || { id: null }
  );

  const integrations = useMemo(
    () => [...state[`${channelType}Integrations`].values()],
    [state, channelType]
  );

  const meetingTypeOptions = useMemo(
    () =>
      meetingTypes.map((m) => ({
        id: m.id,
        name: m.name,
      })),
    [meetingTypes]
  );

  const canShowConditionsCard = useMemo(
    () =>
      state[`${channelType}Error`] !== "fieldObjectError" &&
      state[`${channelType}Error`] === null &&
      integrations.length > 0 &&
      tempModalState.fieldObjectType.type &&
      tempModalState.selectedIntegration.id,
    [
      state,
      channelType,
      integrations.length,
      tempModalState.fieldObjectType.type,
      tempModalState.selectedIntegration.id,
    ]
  );

  const isOutreach = useMemo(() => {
    if (tempModalState.selectedIntegration.id !== null) {
      return (
        tempModalState.selectedIntegration.name === INTEGRATION_TYPES.OUTREACH
      );
    }

    return false;
  }, [tempModalState]);

  const config = useMemo(() => {
    switch (channelType) {
      case CHANNEL_TYPES.IMPORT:
        return {
          checkAssociatedObjects: tempModalState.checkAssociatedObjects,
          fieldMappings: tempModalState.fieldMappings,
          filters: formatConditions[channelType](tempModalState.conditions),
          meetingStatus: tempModalState.channelStatus.value,
          object: tempModalState.fieldObjectType.type,
        };
      case CHANNEL_TYPES.INTERCEPT:
        return {
          triggers: formatConditions[channelType](
            tempModalState.conditions,
            tempModalState.fieldObjectType?.type
          )[0],
        };
      case CHANNEL_TYPES.UPDATE:
        return {
          triggers: formatConditions[channelType](
            tempModalState.conditions,
            tempModalState.fieldObjectType?.type,
            tempModalState.meetingStatus
          )[0],
        };
      default:
        throw new Error(`Channel Type '${channelType}' is invalid`);
    }
  }, [channelType, tempModalState]);

  const handleOnClose = useCallback(() => {
    dispatchModalState({
      payload: {
        channelType,
        value: tempModalState,
      },
      type: "SET_ADD_STATE",
    });

    clearSampleData();
    onClose();
  }, [
    clearSampleData,
    dispatchModalState,
    channelType,
    onClose,
    tempModalState,
  ]);

  // bring testing area into view when test import begins
  // this is here because in the event of a "long" channel (i.e., a lot of conditions), the test area
  // will be hidden
  useEffect(() => {
    if (isRetrievingSampleData) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isRetrievingSampleData]);
  const meta = useCreationMeta(
    presetMeetingDef === null ? CHANNELS_ADD_NEW : MEETING_TYPE_ADD_NEW_CHANNEL
  );
  const handleOnCreate = useCallback(
    async (isSample) => {
      if (!canCreate) {
        setShowInvalidFields(true);
        return;
      }

      const channelObject = {
        advanced: tempModalState.advanced,
        config,
        description: "",
        enabled: tempModalState.enabled,
        integration: tempModalState.selectedIntegration.id,
        meeting: meetingDef.id,
        meta,
        name: tempModalState.name,
        tagAutomation: {
          tagContacts: tempModalState.tagAutomation.tagContacts.map(
            (t) => t.id
          ),
          tagMeetings: tempModalState.tagAutomation.tagMeetings.map(
            (t) => t.id
          ),
        },
        type: channelType,
      };

      // if this is a channel test run
      // explicit truth test as `isSample` will not be empty even if not testing
      if (isSample === true) {
        runSampleImportData(channelObject);
        return;
      }

      const response = await post(
        API.channels[channelType](),
        null,
        channelObject
      ).then((res) => res.json());

      if (response && typeof response.id === "number") {
        onChannelAdded(channelObject.name);

        dispatch({
          payload: {
            channelType,
            channels: [
              {
                ...channelObject,
                advanced: tempModalState.advanced,
                id: response.id,
                integration: tempModalState.selectedIntegration,
                meeting: meetingDef,
                tagAutomation: tempModalState.tagAutomation,
              },
            ],
          },
          type: "SET_INTEGRATION_CHANNEL",
        });
      }
    },
    [
      canCreate,
      channelType,
      config,
      dispatch,
      meetingDef,
      meta,
      onChannelAdded,
      runSampleImportData,
      tempModalState,
    ]
  );

  const handleOnSample = useCallback(() => {
    handleOnCreate(true);
  }, [handleOnCreate]);

  useEffect(() => {
    setTempModalState(add);
  }, [add]);

  useEffect(() => {
    if (meetingTypeOptions.length > 0 && meetingDef.id === null) {
      setMeetingDef(meetingTypeOptions[0]);
    }
  }, [meetingTypeOptions, meetingDef.id]);

  useEffect(() => {
    if (
      meetingInstanceCardIsValid &&
      integrationsCardIsValid &&
      conditionCardsAreValid
    ) {
      setCanCreate(true);
      return;
    }
    setCanCreate(false);
  }, [
    meetingInstanceCardIsValid,
    integrationsCardIsValid,
    conditionCardsAreValid,
  ]);

  return (
    <ReactModal
      shouldCloseOnEsc
      isOpen={tempModalState.isOpen}
      onRequestClose={handleOnClose}
      className={cx("modal--content")}
      overlayClassName="modal--overlay"
      shouldCloseOnOverlayClick={false}
    >
      <MeetingInstanceCard
        showInvalidFields={showInvalidFields}
        setMeetingInstanceCardIsValid={setMeetingInstanceCardIsValid}
        channelType={channelType}
        canCreate={canCreate}
        active={tempModalState.enabled}
        setTempModalState={setTempModalState}
        meetingDef={meetingDef}
        setMeetingDef={setMeetingDef}
        meetingTypes={meetingTypes}
        meetingTypeOptions={meetingTypeOptions}
        presetMeetingDef={presetMeetingDef}
        isLoading={meetingsIsLoading}
        handleOnClose={handleOnClose}
        selectedIntegration={tempModalState.selectedIntegration}
        meetingStatus={tempModalState.meetingStatus}
        channelStatus={tempModalState.channelStatus}
        name={tempModalState.name}
      />

      <div className={style.card_wrapper}>
        <IntegrationsCard
          showInvalidFields={showInvalidFields}
          setIntegrationsCardIsValid={setIntegrationsCardIsValid}
          channelType={channelType}
          setTempModalState={setTempModalState}
          handleOnCreate={handleOnCreate} // Is this even used inside IntegrationsCard?
          conditions={tempModalState.conditions}
          fieldObjectType={tempModalState.fieldObjectType}
          isOpen={tempModalState.isOpen}
          integrations={integrations}
          selectedIntegration={tempModalState.selectedIntegration}
          fieldObjectsDropdownData={tempModalState.fieldObjectsDropdownData}
          checkAssociatedObjects={tempModalState.checkAssociatedObjects}
        />

        {canShowConditionsCard && isOutreach && (
          <div>
            {channelType === CHANNEL_TYPES.IMPORT && !isOutreach && (
              <FieldMappingCard
                fieldMappings={tempModalState.fieldMappings}
                fieldObjectType={tempModalState.fieldObjectType}
                fields={channelFields}
                selectedIntegration={tempModalState.selectedIntegration}
                setTempModalState={setTempModalState}
              />
            )}

            {channelType !== CHANNEL_TYPES.UPDATE && (
              <div className={style.card_wrapper__filter_by}>When Sequence</div>
            )}

            {channelType === CHANNEL_TYPES.UPDATE && (
              <div className={style.card_wrapper__filter_by}>
                Start Sequence
              </div>
            )}

            <div className={style.card_wrapper__condition_cards_wrapper}>
              <ConditionCards
                isAddModal
                showInvalidFields={showInvalidFields}
                setConditionCardsAreValid={setConditionCardsAreValid}
                channelType={channelType}
                setTempModalState={setTempModalState}
                handleOnCreate={handleOnCreate} // Is this even used inside ConditionCards?
                conditions={tempModalState.conditions}
                fieldObjectType={tempModalState.fieldObjectType}
                selectedIntegration={tempModalState.selectedIntegration}
              />
            </div>
          </div>
        )}

        {canShowConditionsCard && !isOutreach && (
          <div>
            {channelType === CHANNEL_TYPES.IMPORT && (
              <FieldMappingCard
                fieldMappings={tempModalState.fieldMappings}
                fieldObjectType={tempModalState.fieldObjectType}
                fields={channelFields}
                selectedIntegration={tempModalState.selectedIntegration}
                setTempModalState={setTempModalState}
              />
            )}

            {channelType !== CHANNEL_TYPES.UPDATE && (
              <div className={style.card_wrapper__filter_by}>Filtered By</div>
            )}

            {channelType === CHANNEL_TYPES.UPDATE && (
              <div className={style.card_wrapper__filter_by}>Updated As</div>
            )}

            <div className={style.card_wrapper__condition_cards_wrapper}>
              <ConditionCards
                isAddModal
                showInvalidFields={showInvalidFields}
                setConditionCardsAreValid={setConditionCardsAreValid}
                channelType={channelType}
                setTempModalState={setTempModalState}
                handleOnCreate={handleOnCreate} // Is this even used inside ConditionCards?
                conditions={tempModalState.conditions}
                fieldObjectType={tempModalState.fieldObjectType}
                selectedIntegration={tempModalState.selectedIntegration}
              />
            </div>
          </div>
        )}
      </div>
      <div className={style.card_wrapper}>
        <ChannelTagAutomation
          channelType={channelType}
          setTempModalState={setTempModalState}
          tagAutomation={tempModalState.tagAutomation}
        />
      </div>
      <div className={style.channel_actions}>
        {channelType === CHANNEL_TYPES.IMPORT && hasOrgAdminPermissions && (
          <div className={style.sampleButtons}>
            <Button
              primary
              large
              name="/channels/import/add_channel/sample"
              onClick={handleOnSample}
              disabled={isRetrievingSampleData}
            >
              <span>Sample</span>
            </Button>

            {(hasError || sampleImportData !== null) && (
              <Button
                primary
                large
                name="/channels/import/add_channel/clear_sample"
                onClick={clearSampleData}
                className={style.clearSampleButton}
              >
                <span>Clear sample data</span>
              </Button>
            )}
          </div>
        )}
        <Button
          primary
          large
          name={`/channels/${channelType}/add_channel/create`}
          disabled={tempModalState.selectedIntegration.orphaned}
          className={style.conditions__card_actions_create}
          onClick={handleOnCreate}
        >
          <span>Create</span>
        </Button>
      </div>
      {isRetrievingSampleData && <Loader />}
      {hasError && (
        <div className={style.errorRetrievingSampleData}>{ERROR}</div>
      )}

      {sampleImportData !== null && (
        <SampleImportData importData={sampleImportData} isNewChannel />
      )}
      <div ref={scrollRef} />
    </ReactModal>
  );
}

export default AddModal;
