import useSWR from "swr";

import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useErrorHandler, useTokenRefreshHandler } from "../hooks";
import { useReportingService } from "../services";

export function useOrgReportingCompanyWide() {
  const service = useReportingService();
  const accessToken = getUserToken();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const errorHandler = useErrorHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  const url = `/api/reporting/cw`;

  const { data, error } = useSWR(url, (u) =>
    service
      .get(u)
      .set(headers)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .catch(errorHandler)
  );

  return {
    data: data as {
      totals: {
        users: number;
        macsConsumed: number;
        instancesProvisioned: number;
        importChannels: number;
        updateChannels: number;
        instancesInFinalState: number;
        meetingsOnCalendar: number;
        periodData: {
          rolling: number;
          current: number;
          previous: number;
          meetingsOnCalendarRolling: number;
          meetingsOnCalendarCurrent: number;
        };
      };
      averages: {
        macsConsumed: number;
        importChannels: number;
        updateChannels: number;
        macsUsedPerDay: number;
        instancesProvisioned: number;
        instancesInFinalState: number;
        acceptRate: number;
        meetingsOnCalendar: number;
        periodData: {
          rolling: number;
          current: number;
          previous: number;
          meetingsOnCalendarRolling: number;
          meetingsOnCalendarCurrent: number;
        };
        meetingTypePerformances: {
          high: number;
          low: number;
        };
      };
    },
    error,
    loading: !error && !data,
  };
}
