import React, { useEffect, useState } from "react";

import Flexbox from "../components/flexbox/FlexboxV2";
import { Input, InputGroup } from "../components/input";
import {
  JumboSpacing,
  LargeSpacing,
  XSmallSpacing,
  XXLargeSpacing,
} from "../components/spacing";
import { P1, H6 } from "../components/typography";
import { useCost } from "./context";

import style from "./style.module.scss";

export default function Monetization({ isEditable = true }) {
  const [cost, setCost] = useCost();
  const [avgDealSize, setAvgDealSize] = useState("");
  const [mtgOppRate, setMtgOppRate] = useState("");
  const [oppWinRate, setOppWinRate] = useState("");
  const [shouldUpdateCost, setShouldUpdateCost] = useState(false);
  const [valuePerMeeting, setValuePerMeeting] = useState("");
  useEffect(() => {
    if (!avgDealSize) {
      return;
    }
    if (!mtgOppRate) {
      return;
    }
    if (!oppWinRate) {
      return;
    }
    const newValuePerMeeting = Math.ceil(
      parseFloat(avgDealSize) *
        (parseFloat(mtgOppRate) / 100) *
        (parseFloat(oppWinRate) / 100)
    );
    if (shouldUpdateCost) {
      setCost(newValuePerMeeting);
      setShouldUpdateCost(false);
    }

    setValuePerMeeting(newValuePerMeeting);
  }, [avgDealSize, mtgOppRate, oppWinRate, setCost, shouldUpdateCost]);
  return (
    <Flexbox.Column height="100%" overflow="scroll">
      <LargeSpacing />
      <H6>Set Meeting Value [Manual]</H6>
      <XXLargeSpacing />
      <Flexbox.Column>
        <Flexbox.Row>
          <XXLargeSpacing />
          <P1>Per Meeting Value: </P1>
          <XXLargeSpacing />
        </Flexbox.Row>
        <Flexbox.Row>
          <XXLargeSpacing />
          <InputGroup large left>
            $
          </InputGroup>
          <Input
            className={style.meetingType__costInput}
            disabled={!isEditable}
            large
            placeholder="1400"
            name="/meetingtypes/configuration/monetization/per_meeting_value"
            onChange={(event) => {
              const newValue = event.target.value;
              setAvgDealSize("");
              setMtgOppRate("");
              setOppWinRate("");
              setValuePerMeeting("");
              if (newValue === "") {
                setCost("");
                return;
              }
              const newCost = parseInt(newValue);
              if (Number.isNaN(newCost)) {
                return;
              }
              setCost(newCost);
            }}
            value={cost}
          />
        </Flexbox.Row>
      </Flexbox.Column>
      <JumboSpacing />
      <H6>- OR -</H6>
      <JumboSpacing />
      <H6>Calculate Meeting Value [Custom]</H6>
      <JumboSpacing vertical />
      <Flexbox.Row
        alignItems="center"
        className={style.meetingType__monetizationRow}
      >
        <Flexbox.Column className={style.meetingType__monetizationColumn}>
          <P1 className={style.meetingType__monetizationText} small>
            Avg deal size
          </P1>
          <XSmallSpacing />
          <Flexbox.Row>
            <InputGroup large left>
              $
            </InputGroup>
            <Input
              disabled={!isEditable}
              large
              placeholder="20000"
              name="/meetingtypes/configuration/monetization/avg_deal_size_value"
              onChange={(event) => {
                const newValue = event.target.value;
                if (newValue === "") {
                  setAvgDealSize("");
                  return;
                }
                setAvgDealSize(newValue);
                setShouldUpdateCost(true);
              }}
              type="number"
              min="1"
              value={avgDealSize}
            />
          </Flexbox.Row>
        </Flexbox.Column>
        <LargeSpacing />
        <Flexbox.Row
          alignItems="center"
          className={style.meetingType__monetizationColumn}
        >
          <H6>X</H6>
          <LargeSpacing />
          <Flexbox.Column>
            <P1 className={style.meetingType__monetizationText} small>
              Opp win rate
            </P1>
            <XSmallSpacing />
            <Flexbox.Row>
              <InputGroup large left>
                %
              </InputGroup>
              <Input
                disabled={!isEditable}
                large
                placeholder="20"
                name="/meetingtypes/configuration/monetization/opp_win_rate_value"
                onChange={(event) => {
                  const newValue = event.target.value;
                  if (newValue === "") {
                    setMtgOppRate("");
                    return;
                  }
                  setMtgOppRate(newValue);
                  setShouldUpdateCost(true);
                }}
                type="number"
                min="1"
                max="100"
                value={mtgOppRate}
              />
            </Flexbox.Row>
          </Flexbox.Column>
        </Flexbox.Row>
        <LargeSpacing />
        <Flexbox.Row
          alignItems="center"
          className={style.meetingType__monetizationColumn}
        >
          <H6>X</H6>
          <LargeSpacing />
          <Flexbox.Column>
            <P1 className={style.meetingType__monetizationText} small>
              Mtg to Opp rate
            </P1>
            <XSmallSpacing />
            <Flexbox.Row>
              <InputGroup large left>
                %
              </InputGroup>
              <Input
                disabled={!isEditable}
                large
                placeholder="50"
                name="/meetingtypes/configuration/monetization/mtg_to_opp_rate_value"
                onChange={(event) => {
                  const newValue = event.target.value;
                  if (newValue === "") {
                    setOppWinRate("");
                    return;
                  }
                  setOppWinRate(newValue);
                  setShouldUpdateCost(true);
                }}
                type="number"
                min="1"
                max="100"
                value={oppWinRate}
              />
            </Flexbox.Row>
          </Flexbox.Column>
        </Flexbox.Row>
        <LargeSpacing />
        <Flexbox.Row alignItems="center">
          <H6>=</H6>
          <LargeSpacing />
          <Flexbox.Column className={style.meetingType__monetizationColumn}>
            <P1 className={style.meetingType__monetizationText} small>
              Value per Meeting
            </P1>
            <XSmallSpacing />
            <Flexbox.Row>
              <InputGroup large left>
                $
              </InputGroup>
              <Input
                readOnly
                large
                placeholder="2000"
                name="/meetingtypes/configuration/monetization/value_per_meeting"
                value={valuePerMeeting}
              />
            </Flexbox.Row>
          </Flexbox.Column>
        </Flexbox.Row>
      </Flexbox.Row>
    </Flexbox.Column>
  );
}
