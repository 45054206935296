import { Container } from "@mui/material";
import { Breadcrumb } from "./Breadcrumbs";

import PageHeader from "./PageHeader";

export function PageLayout(props: {
  title: string;
  breadcrumbs?: Breadcrumb[];
  actions?: React.ReactNode | React.ReactNode[];
  children?: React.ReactNode | React.ReactNode[];
}) {
  return (
    <Container maxWidth="xl" disableGutters sx={{ p: 2 }}>
      <PageHeader
        title={props.title}
        breadcrumbs={props.breadcrumbs}
        actions={props.actions}
      />
      {props.children}
    </Container>
  );
}
