import { useDebounce } from "src/hooks";
import { useMeetingTypes } from "src/queries";
import { MeetingDefinition } from "src/types";

import { FilterAutocomplete } from "./FilterAutocomplete";

export function TemplateFilter(props: {
  search: string;
  onSearchChange: (value: string) => void;
  value?: number[];
  onChange?: (value: MeetingDefinition[]) => void;
}) {
  const s = useDebounce(props.search, 750);

  const { data, loading } = useMeetingTypes(10, 0, s);

  return (
    <FilterAutocomplete
      label="Template"
      value={(data?.data || []).filter((meetingTemplate) =>
        (props.value || []).includes(meetingTemplate.id)
      )}
      onChange={props.onChange}
      loading={loading}
      search={props.search}
      onSearchChange={props.onSearchChange}
      options={data?.data || []}
      getOptionLabel={(template) => template.name}
      getOptionId={(template) => template.id}
    />
  );
}

export default TemplateFilter;
