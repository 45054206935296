import React from "react";
import cx from "classnames";

import { ButtonContainer } from "../components/button";
import { P1 } from "../components/typography";

import style from "./style.module.scss";

export default function MeetingTypeTab({
  name = null,
  onClick,
  selected: isSelected,
  text,
}) {
  return (
    <ButtonContainer
      transparent
      className={cx(style.meetingType__tab, {
        [style["meetingType__tab--selected"]]: isSelected,
      })}
      name={name}
      onClick={onClick}
    >
      <P1>{text}</P1>
      {isSelected && <div className={style.meetingType__tabBar} />}
    </ButtonContainer>
  );
}
