import {
  Box,
  Link,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";
import SecondaryButton from "src/componentsV2/buttons/SecondaryButton";
import { getUserToken } from "src/utils/jwtToken";
import { Loading } from "../../componentsV2/Loading";
import { LoginDisclaimer } from "../../componentsV2/LoginDisclaimer";
import { Logo } from "../../componentsV2/Logo";
import {
  SignInWithGoogleButton,
  SignInWithMicrosoftButton,
} from "../../componentsV2/buttons";
import { useLocalStorage } from "../../hooks";
import { EmailIcon, ErrorIcon } from "../../icons";
import { useAuthUrl } from "../../queries";
import { validateEmailAddress } from "../../utils/helpers";

function LoginContainer({ children }: { children: React.ReactNode }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderColor: theme.palette.divider,
        borderRadius: "8px",
        borderStyle: "solid",
        borderWidth: 1,
        minHeight: 366,
        minWidth: 336,
        paddingBottom: "30px",
        paddingLeft: "25px",
        paddingRight: "25px",
        paddingTop: "30px",
        width: 336,
      }}
    >
      {children}
    </Box>
  );
}

function EmailInputForm({ onSubmit }: { onSubmit: (emaill: string) => void }) {
  const [validatedEmail] = useLocalStorage("email", "");
  const [email, setEmail] = useState(validatedEmail);
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (validateEmailAddress(email)) {
      onSubmit(email);
    } else {
      setError("Please enter a valid email");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <Stack alignItems="center" spacing={1} sx={{ marginTop: "12px" }}>
      <TextField
        label="Email"
        value={email}
        type="email"
        onKeyDown={handleKeyDown}
        onChange={(event) => setEmail(event.target.value.toLowerCase())}
        sx={{ minWidth: "18em" }}
        error={Boolean(error)}
        helperText={error}
      />

      <PrimaryButton
        disabled={!validateEmailAddress(email)}
        onClick={handleSubmit}
      >
        Next
      </PrimaryButton>

      <Typography variant="caption" align="center" sx={{ paddingTop: "16px" }}>
        {`Don't have an account? `}
        <Link href="https://www.kronologic.ai/demo">Request Demo</Link>
      </Typography>
    </Stack>
  );
}

function ErrorText({ children }: { children: React.ReactNode }) {
  return (
    <Typography variant="caption" color="red">
      {children}
    </Typography>
  );
}

type LocationState = {
  from: {
    pathname?: string;
    search?: string;
  };
};

export default function Page() {
  const accessToken = getUserToken();

  const [validatedEmail, setValidatedEmail] = useLocalStorage<string | null>(
    "email",
    null
  );

  const theme = useTheme();

  const [email, setEmail] = useState<string | null>(validatedEmail);

  const location = useLocation<LocationState>();

  const from = location.state?.from;

  // The redirectUrl must be an absolute URL as the backend will override whatever OAUTH_REDIRECT_PATH is configured if
  // a redirect path is supplied. It is the URL that the backend will redirect to once auth is complete. Since all the
  // logic for saving the JWT token is contained in the login page the URL must redirect to the login page and then
  // subsequently reroute the app to the original page.
  const redirectUrl = from
    ? `/login?redirectUrl=${from?.pathname}${from?.search}`
    : "/instances";

  const {
    data: authURLs,
    loading,
    error,
  } = useAuthUrl(
    email,
    btoa(new URL(redirectUrl, window.location.href).toString())
  );

  // If the request for auth urls was success and the email is a valid user then
  // remember the email for next login.
  useEffect(() => {
    if (!loading && !error) {
      setValidatedEmail(email);
    }
  }, [email, loading, error, setValidatedEmail]);

  let content = <></>;
  if (authURLs || error) {
    content = (
      <>
        {error && (
          <Stack alignItems="center" spacing={1}>
            <ErrorIcon sx={{ color: theme.palette.error.main }} />
            <ErrorText>Account does not exist</ErrorText>
          </Stack>
        )}
        <Typography variant="subtitle1" sx={{ mb: "32px" }}>
          {email}
        </Typography>
        <Stack spacing={2}>
          {authURLs && (
            <>
              {authURLs.google && (
                <SignInWithGoogleButton
                  onClick={() => {
                    window.location.href = authURLs.google;
                  }}
                />
              )}
              {authURLs.microsoft && (
                <SignInWithMicrosoftButton
                  onClick={() => {
                    window.location.href = authURLs.microsoft;
                  }}
                />
              )}
            </>
          )}
          <SecondaryButton
            nowrap
            icon={<EmailIcon />}
            onClick={() => setEmail("")}
          >
            Sign in with a different email
          </SecondaryButton>
        </Stack>
      </>
    );
  } else if (!authURLs) {
    content = <EmailInputForm onSubmit={setEmail} />;
  } else if (email && loading) {
    content = <Loading />;
  }

  // If the user is already logged in then bypass the login page.
  if (accessToken) {
    return <Redirect to={"/instances"} />;
  }

  return (
    <Stack alignItems="center" sx={{ paddingTop: theme.spacing(12) }}>
      <LoginContainer>
        <Stack
          alignItems="center"
          sx={{
            maxWidth: "25em",
          }}
        >
          <Box sx={{ marginBottom: "40px" }}>
            <Logo width="275px" />
          </Box>

          <Typography variant="h5" gutterBottom={false}>
            Sign In
          </Typography>

          {content}

          <Box sx={{ marginTop: "48px", textAlign: "center" }}>
            <LoginDisclaimer />
          </Box>
        </Stack>
      </LoginContainer>
    </Stack>
  );
}
