import { Box, Slider, Stack, ToggleButton, useTheme } from "@mui/material";
import dayjs from "dayjs";
import React from "react";

type ToggleButtonProps = React.ComponentProps<typeof ToggleButton>;

function DayButton({
  selected,
  onClick,
  children,
}: {
  selected?: ToggleButtonProps["selected"];
  onClick?: ToggleButtonProps["onClick"];
  children: ToggleButtonProps["value"];
}) {
  return (
    <ToggleButton
      color="primary"
      value={children}
      sx={{ borderRadius: "50%", height: "3em", width: "3em" }}
      selected={selected}
      onClick={onClick}
    >
      {children}
    </ToggleButton>
  );
}

interface DaySelection {
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
}

function DaySelect({
  value = {
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  },
  onChange = () => null,
}: {
  value?: DaySelection;
  onChange?: (v: DaySelection) => void;
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: theme.spacing(0.75),
        justifyContent: "center",
      }}
    >
      <DayButton
        selected={value.sunday}
        onClick={() => onChange({ ...value, sunday: !value.sunday })}
      >
        Su
      </DayButton>
      <DayButton
        selected={value.monday}
        onClick={() => onChange({ ...value, monday: !value.monday })}
      >
        Mo
      </DayButton>
      <DayButton
        selected={value.tuesday}
        onClick={() => onChange({ ...value, tuesday: !value.tuesday })}
      >
        Tu
      </DayButton>
      <DayButton
        selected={value.wednesday}
        onClick={() => onChange({ ...value, wednesday: !value.wednesday })}
      >
        We
      </DayButton>
      <DayButton
        selected={value.thursday}
        onClick={() => onChange({ ...value, thursday: !value.thursday })}
      >
        Th
      </DayButton>
      <DayButton
        selected={value.friday}
        onClick={() => onChange({ ...value, friday: !value.friday })}
      >
        Fr
      </DayButton>
      <DayButton
        selected={value.saturday}
        onClick={() => onChange({ ...value, saturday: !value.saturday })}
      >
        Sa
      </DayButton>
    </Box>
  );
}

function TimeOfDayRange({
  value,
  onChange = () => null,
}: {
  value: [number, number];
  onChange: (v: [number, number]) => void;
}) {
  return (
    <Slider
      value={value}
      onChange={(_, v) => onChange(v as [number, number])}
      valueLabelDisplay="auto"
      valueLabelFormat={(v) =>
        dayjs().startOf("day").minute(v).format("h:mm a")
      }
      min={0}
      max={1440}
      step={15}
    />
  );
}

export interface Availability {
  daysOfWeek: DaySelection;
  // Minutes of the day range
  timeOfDay: [number, number];
}

export const DefaultAvailability: Availability = {
  daysOfWeek: {
    sunday: false,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
  },
  timeOfDay: [
    9 * 60, // 9:00 am
    (12 + 5) * 60, // 5:00 pm
  ],
};

export function AvailabilitySettings({
  value = {
    daysOfWeek: {
      sunday: false,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: false,
    },
    timeOfDay: [
      9 * 60, // 9:00 am
      (12 + 5) * 60, // 5:00 pm
    ],
  },
  onChange = () => null,
}: {
  value?: Availability;
  onChange?: (v: Availability) => void;
}) {
  return (
    <Stack spacing={1}>
      <DaySelect
        value={value.daysOfWeek}
        onChange={(daysOfWeek) => onChange({ ...value, daysOfWeek })}
      />
      <TimeOfDayRange
        value={value.timeOfDay}
        onChange={(timeOfDay) => onChange({ ...value, timeOfDay })}
      />
    </Stack>
  );
}
export default AvailabilitySettings;
