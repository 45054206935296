import { useCallback } from "react";
import { DEFAULT_MEETING_REMINDER_BODY } from "../useNewMeetingType";

const DEFAULT_PER_MEETING_VALUE = 1000;

export default function useNewMeetingTemplate() {
  const build = useCallback(() => {
    return {
      active: false,
      cost: DEFAULT_PER_MEETING_VALUE,
      emailTemplates: [
        {
          body: "",
          order: 1,
          title: "",
        },
      ],
      enabled: false,
      inviteTemplates: [
        {
          id: 0,
          location: "",
          notes: "",
          title: "",
        },
      ],
      metadata: {
        creationTimestamp: Date.now(),
      },
      name: "new meeting template",
      properties: {
        bcc: "",
        meetingReminder: {
          body: DEFAULT_MEETING_REMINDER_BODY,
          days: 0,
          enabled: false,
          hours: 3,
          minutes: 0,
          title: "",
        },
      },
    };
  }, []);
  return {
    build,
  };
}
