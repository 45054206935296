import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { toGuestStatusColor, toGuestStatusLabel } from "../props";
import { guestStatusToOption } from "../../inputs";
import useGuestStatusSelectTheme from "./useGuestStatusSelectTheme";
import useOverrideStatusRules from "./useOverrideStatusRules";

const GuestStatusSelect = ({ meetingStatus, status, setStatus }) => {
  const { overrideOptions } = useOverrideStatusRules();
  const [selectedStatus, setSelectedStatus] = useState({
    label: toGuestStatusLabel(status),
    value: status,
  });
  const selectTheme = useGuestStatusSelectTheme();

  const defaultValue = useMemo(
    () => ({
      label: toGuestStatusLabel(status),
      value: status,
    }),
    [status]
  );

  const options = useMemo(() => {
    const opts = overrideOptions({
      fromStatus: status,
      meetingStatus,
    });
    if (opts === null) {
      return null;
    }

    return opts.map((s) => ({
      label: toGuestStatusLabel(s),
      value: s,
    }));
  }, [meetingStatus, overrideOptions, status]);

  const statusColor = useMemo(
    () => toGuestStatusColor(selectedStatus.value),
    [selectedStatus]
  );

  const onChange = useCallback(
    async (s) => {
      try {
        await setStatus(s.value);
        setSelectedStatus(s);
      } catch (error) {
        throw error;
      }
    },
    [setStatus]
  );

  useEffect(() => {
    setSelectedStatus(guestStatusToOption(status));
  }, [status]);

  return (
    <Select
      name="/instanceDetails/guest_status"
      styles={selectTheme}
      noOptionsMessage="There are no options"
      options={options}
      defaultValue={defaultValue}
      value={selectedStatus}
      onChange={onChange}
      statusColor={statusColor}
      isSearchable={false}
    />
  );
};

export default GuestStatusSelect;
