import React, { useCallback, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";

import { useSize } from "../../size";
import Flexbox from "../../flexbox/FlexboxV2";
import MeetingTypeDetailsHeader from "../meetingTypeDetailsHeader";
import MeetingTypeDetailsGuestList from "../meetingTypeDetailsGuestList";
import { ButtonContainer, ButtonIcon, ButtonText } from "../../button";
import style from "./meetingTypeDetails.module.scss";
import { MediumSpacing } from "../../spacing";

export default function MeetingTypeDetails({ guests, host, invite, name }) {
  const content = useRef();
  const container = useRef();
  const containerSize = useSize(container);
  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpandInviteTemplateOnClick = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const inviteText = useMemo(() => {
    if (isExpanded) {
      return "Collapse invite";
    }
    return "Expand to see full invite";
  }, [isExpanded]);

  const isExpandable = useMemo(() => {
    if (!container.current) {
      return false;
    }
    return container.current.scrollHeight > containerSize?.height;
  }, [containerSize]);

  return (
    <Flexbox.Column
      className={style.meetingTypeDetails}
      height="fit-content"
      justifyContent="start"
    >
      <MeetingTypeDetailsHeader invite={invite} name={name} />
      <MediumSpacing />
      <MeetingTypeDetailsGuestList host={host} guests={guests} />
      <Flexbox.Column
        className={style.meetingTypeDetails__invite}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Flexbox.Column>
          <div
            className={cx(style.meetingTypeDetails_inviteTextArea, {
              [style["meetingTypeDetails_inviteTextArea--minimum"]]:
                !isExpanded,
              [style["meetingTypeDetails_inviteTextArea--full"]]: isExpanded,
            })}
            ref={container}
          >
            <div ref={content}>{invite?.notes}</div>
          </div>
        </Flexbox.Column>
        <Flexbox.Row height="50%" justifyContent="flex-start">
          {(isExpandable || isExpanded) && (
            <ButtonContainer
              className={style.meetingTypeDetails_invite__expand_button}
              onClick={handleExpandInviteTemplateOnClick}
            >
              <ButtonText>{inviteText}</ButtonText>
              <ButtonIcon
                className={style.meetingTypeDetails_invite_expand_button__icon}
                path={isExpanded ? mdiChevronUp : mdiChevronDown}
                size={1}
              />
            </ButtonContainer>
          )}
        </Flexbox.Row>
      </Flexbox.Column>
    </Flexbox.Column>
  );
}
