import { mdiDownload, mdiUpload } from "@mdi/js";
import Icon from "@mdi/react";
import { Grid, Stack, Typography } from "@mui/material";
import { ActionButton } from "../../componentsV2/ActionButton";
import { PageLayout } from "../../componentsV2/PageLayout";
import { RecentExports } from "../../componentsV2/RecentExports";
import { useRecentExports } from "../../queries";

function ImportExport() {
  const { data, loading, error } = useRecentExports();
  return (
    <PageLayout title="Reports">
      <Stack spacing={4}>
        <Grid container justifyContent="center" alignItems="center" spacing={4}>
          <Grid item>
            <ActionButton
              icon={<Icon path={mdiUpload} size={3} />}
              title="Import"
              subtitle="Import Contacts or Users"
              to="/reports/import"
            />
          </Grid>
          <Grid item>
            <ActionButton
              icon={<Icon path={mdiDownload} size={3} />}
              title="Export"
              subtitle="Export Meetings, Users, or Contacts"
              to="/reports/export"
            />
          </Grid>
        </Grid>
        {error ? (
          <Typography color="error" align="center">
            Failed to load recent exports
          </Typography>
        ) : (
          <RecentExports exports={data?.data || []} loading={loading} />
        )}
      </Stack>
    </PageLayout>
  );
}

export default ImportExport;
