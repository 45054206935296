import React from "react";
import Icon from "@mdi/react";
import { mdiAlert } from "@mdi/js";

import style from "./style.module.scss";

export default function StepIncomplete() {
  return (
    <div className={style.meetingType__stepIncomplete}>
      <Icon
        className={style.meetingType__stepIcon}
        path={mdiAlert}
        size={0.6}
      />
    </div>
  );
}
