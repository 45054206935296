/* eslint-disable react/jsx-wrap-multilines */
import React, { useMemo, useRef, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { mdiAccountPlusOutline, mdiLoading, mdiSignCaution } from "@mdi/js";

import {
  EmptyViewContainer,
  EmptyViewRow,
  EmptyViewIcon,
} from "../../components/emptyView/EmptyView";
import { Grid, GridItem } from "../../components/grid";
import { useMeetingTemplates } from "../../repository/meetingTypes";
import MeetingTypeMenuAndList from "../MeetingTypeMenuAndList";
import { useSize } from "../../components/size";
import { DRAWER, useModalV2 } from "../../components/modal";
import style from "./style.module.scss";
import useMeetingTemplateByUri from "./useMeetingTemplateByUri";
import DeleteMeetingTemplate from "./DeleteMeetingTemplate";
import CreateMeetingTemplate from "./CreateMeetingTemplate";
import MeetingTemplate from "./MeetingTemplate";
import { MeetingTypesContext } from "../context";
import CopyMeetingTemplate from "./CopyMeetingTemplate";
import CopyMeetingTypeFromTemplate from "./CreateMeetingTypeFromTemplate";
import { useHasSuperAdminPermissions } from "../../auth";
import MeetingTypesMenuAndListDrawer from "../MeetingTypesMenuAndListDrawer";
import KronologicIcon from "./KronologicIcon";

const MEETING_TYPES_PATH = [
  "/meetingTypes/library/:id/:tab",
  "/meetingTypes/library/:id",
];

export default function MeetingTemplates() {
  const hasSuperAdminPermissions = useHasSuperAdminPermissions();
  const history = useHistory();
  const [query, setQuery] = useState("");
  const {
    create: createMeetingTemplate,
    deleting: isMeetingTemplateDeleting,
    deleteMeetingItem: deleteMeetingTemplate,
    data: meetingTemplates,
    error: meetingTemplatesError,
    loading: isMeetingTemplatesLoading,
    saveError: meetingTemplatesSaveError,
    saving: isUpdating,
    update: updateMeetingTemplate,
  } = useMeetingTemplates({
    query,
  });
  const isSuccessfullyLoaded =
    meetingTemplatesError === null && !isMeetingTemplatesLoading;
  const selectedMeetingTemplate = useMeetingTemplateByUri(
    isMeetingTemplatesLoading,
    meetingTemplates
  );
  const gridRef = useRef(null);
  const { width } = useSize(gridRef);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isListInitiallyOpen = params.has("open");
  const [MeetingTemplatesMenuAndListModal, openMenu, closeMenu] = useModalV2(
    MeetingTypesMenuAndListDrawer,
    DRAWER,
    {
      open: isListInitiallyOpen,
    }
  );
  const context = useMemo(() => {
    return {
      meetingType: selectedMeetingTemplate,
      updateMeetingType: updateMeetingTemplate,
    };
  }, [selectedMeetingTemplate, updateMeetingTemplate]);
  const isBelowWidthThreshold = width < 1780;
  const menuProps = {
    create: () => {
      history.push("/meetingTypes/library/create");
    },
    error: meetingTemplatesError,
    loading: isMeetingTemplatesLoading,
    meetingTypes: meetingTemplates,
    onClickCopy: (id) => {
      history.push(`/meetingTypes/library/${id}/copy`);
    },
    onClickDelete: (id) => {
      history.push(`/meetingTypes/library/${id}/delete`);
    },
    onClickSelect: (id) => {
      history.push(`/meetingTypes/library/${id}`);
    },
    openTags: null,
    placeholder: "Search Meeting Templates",
    renderIcon: () => <KronologicIcon />,
    search: query,
    selectedMeetingTypeId: selectedMeetingTemplate?.id,
    setSearch: setQuery,
    showAddButton: hasSuperAdminPermissions,
    showCopyButton: hasSuperAdminPermissions,
    showDeleteButton: hasSuperAdminPermissions,
    update: updateMeetingTemplate,
  };
  return (
    <MeetingTypesContext.Provider value={context}>
      <Grid className={style.meetingTemplates} ref={gridRef}>
        <GridItem
          className={style.meetingTemplates__content}
          gridArea="content"
        >
          <Grid className={style.meetingTemplates_templateAndList}>
            {isBelowWidthThreshold && (
              <GridItem
                className={style.meetingTypes__listGridItem}
                gridArea="list"
              >
                <MeetingTemplatesMenuAndListModal
                  {...menuProps}
                  close={closeMenu}
                  isListInitiallyOpen={isListInitiallyOpen}
                  showCloseButton
                />
              </GridItem>
            )}
            {!isBelowWidthThreshold && (
              <MeetingTypeMenuAndList {...menuProps} />
            )}
            <GridItem
              className={style.meetingTypes__meetingTypeGridItem}
              gridArea="meetingType"
            >
              <div className={style.meetingType}>
                <Switch>
                  <Route
                    exact
                    path="/meetingTypes/library"
                    render={() => (
                      <>
                        {isSuccessfullyLoaded && meetingTemplates.length === 0 && (
                          <EmptyViewContainer
                            className={style.meetingType__emptyView}
                          >
                            <EmptyViewRow>
                              <EmptyViewIcon icon={mdiAccountPlusOutline} />
                            </EmptyViewRow>
                            <EmptyViewRow>
                              No Meeting Templates found
                            </EmptyViewRow>
                          </EmptyViewContainer>
                        )}
                        {isSuccessfullyLoaded &&
                          meetingTemplates.length > 0 && (
                            <Redirect
                              to={`/meetingTypes/library/${meetingTemplates[0].id}`}
                            />
                          )}
                        {meetingTemplatesError !== null && (
                          <EmptyViewContainer
                            className={style.meetingType__emptyView}
                          >
                            <EmptyViewRow>
                              <EmptyViewIcon icon={mdiSignCaution} />
                            </EmptyViewRow>
                            <EmptyViewRow>
                              Failed to load meeting templates. Please try again
                              later.
                            </EmptyViewRow>
                          </EmptyViewContainer>
                        )}
                      </>
                    )}
                  />

                  <Route
                    path="/meetingTypes/library/:id/toMeetingType"
                    render={() => (
                      <CopyMeetingTypeFromTemplate
                        meetingTemplate={selectedMeetingTemplate}
                      />
                    )}
                  />

                  <Route
                    path="/meetingTypes/library/create"
                    render={() => (
                      <CreateMeetingTemplate onCreate={createMeetingTemplate} />
                    )}
                  />

                  <Route
                    path="/meetingTypes/library/:id/copy"
                    render={() => (
                      <CopyMeetingTemplate
                        meetingTemplate={selectedMeetingTemplate}
                        onCreate={createMeetingTemplate}
                      />
                    )}
                  />

                  <Route
                    path="/meetingTypes/library/:id/delete"
                    render={() => (
                      <DeleteMeetingTemplate
                        deleting={isMeetingTemplateDeleting}
                        onDelete={deleteMeetingTemplate}
                      />
                    )}
                  />

                  <Route
                    path={MEETING_TYPES_PATH}
                    render={() => (
                      <>
                        {isMeetingTemplatesLoading && (
                          <EmptyViewContainer
                            className={style.meetingType__emptyView}
                          >
                            <EmptyViewRow>
                              <EmptyViewIcon icon={mdiLoading} spin={4} />
                            </EmptyViewRow>
                            <EmptyViewRow>
                              Loading meeting templates...
                            </EmptyViewRow>
                          </EmptyViewContainer>
                        )}
                        {isSuccessfullyLoaded &&
                          selectedMeetingTemplate !== null && (
                            <MeetingTemplate
                              isMeetingTemplateUpdating={isUpdating}
                              meetingTemplate={selectedMeetingTemplate}
                              openMenu={openMenu}
                              saveError={meetingTemplatesSaveError}
                              showHamburger={isBelowWidthThreshold}
                            />
                          )}
                      </>
                    )}
                  />

                  <Route
                    path="*"
                    render={() => <Redirect to="/meetingTypes/library" />}
                  />
                </Switch>
              </div>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </MeetingTypesContext.Provider>
  );
}
