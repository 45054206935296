import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";

import Flexbox from "../../components/flexbox/FlexboxV2";
import { H2 } from "../../components/typography";
import { JumboSpacing } from "../../components/spacing";
import useNewMeetingType from "../useNewMeetingType";
import { useCreateMeetingType } from "../../repository/meetingTypes";

export default function CopyMeetingTypeFromTemplate({ meetingTemplate }) {
  const create = useCreateMeetingType();
  const { loading, build } = useNewMeetingType();
  const history = useHistory();
  const createNewMeetingType = useCallback(async () => {
    if (loading) {
      return;
    }
    const newMeetingType = build();
    newMeetingType.name = meetingTemplate.name;
    newMeetingType.cost = meetingTemplate.cost;
    newMeetingType.emailTemplates = meetingTemplate.emailTemplates;
    newMeetingType.inviteTemplates = meetingTemplate.inviteTemplates;
    newMeetingType.properties.bcc = meetingTemplate.properties.bcc;
    newMeetingType.properties.meetingReminder =
      meetingTemplate.properties.meetingReminder;
    const createdMeetingType = await create(newMeetingType);
    history.push(`/meetingTypes/user/${createdMeetingType.id}/general/invite`);
  }, [build, create, history, loading, meetingTemplate]);
  useEffect(() => {
    createNewMeetingType();
  }, [createNewMeetingType]);
  return (
    <Flexbox.Column alignItems="center" justifyContent="center">
      <Icon path={mdiLoading} size={8} spin={1} />
      <JumboSpacing />
      <H2>Creating new meeting template...</H2>
    </Flexbox.Column>
  );
}
