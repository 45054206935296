import { useSWRConfig } from "swr";

import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useErrorHandler } from "../hooks";
import { useUserService } from "../services";

export function useApplyCalendarToUsers() {
  const accessToken = getUserToken();
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const errorHandler = useErrorHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (userIds: number[], calendarIds: number[]): Promise<void> =>
    service
      .put(`/api/calendars/share/users`)
      .set(headers)
      .send({
        calendarIds,
        userIds,
      })
      .then((res: Response) => res.body)
      .then((res: any) => {
        // @ts-ignore
        const keys = cache.keys();

        // Invalidate meeting queries and force refresh.
        Array.from(keys)
          .filter(
            (r: any) => r.includes("/api/calendars") || r.includes("/api/users")
          )
          .forEach((k: any) => {
            mutate(k);
          });

        return res;
      })
      .catch(errorHandler);
}

export default useApplyCalendarToUsers;
