import React from "react";
import Icon from "@mdi/react";
import { mdiDomain, mdiShieldAccountOutline } from "@mdi/js";
import Flexbox from "../components/flexbox/FlexboxV2";
import useCreationData from "./useCreationData";
import { ROLE_LEVELS } from "../auth/roles";

export default function CreatorRoleIcon({ meetingType }) {
  const creationData = useCreationData({ meetingType });
  return (
    <Flexbox.Row justifyContent="flex-end">
      {creationData !== null &&
        creationData.userRole === ROLE_LEVELS.DEFAULT && (
          <Icon
            data-rh="Personal"
            data-rh-at="left"
            path={mdiShieldAccountOutline}
            size={1}
          />
        )}
      {creationData === null ||
        (creationData.userRole !== ROLE_LEVELS.DEFAULT && (
          <Icon
            data-rh="Org-wide"
            data-rh-at="left"
            path={mdiDomain}
            size={1}
          />
        ))}
    </Flexbox.Row>
  );
}
