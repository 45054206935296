import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";
import SecondaryButton from "../../../componentsV2/buttons/SecondaryButton";
import { GoogleIcon, MicrosoftIcon } from "../../../icons";
import { useUsersRepository } from "../../../repository";

type FormData = {
  email: string;
  client: string;
};

function AddNewAccount() {
  const { getAddUserAccountURL } = useUsersRepository();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      const response = await getAddUserAccountURL(data);
      if (response) {
        window.location.href = response;
      }
    } catch {
      console.error("An error occured while adding the account");
    }
  };

  return (
    <Card>
      <CardHeader
        title={<Typography variant="h4">Connect New Account</Typography>}
      />
      <CardContent>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Enter Email"
            {...register("email", {
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            })}
          />
          {errors.email?.type === "required" && (
            <Typography color="error">Email address required</Typography>
          )}
          {errors.email?.type === "pattern" && (
            <Typography color="error">
              Please enter a valid email address
            </Typography>
          )}
        </Box>

        <FormControl>
          <FormLabel>Email provider</FormLabel>
          <Controller
            rules={{ required: true }}
            control={control}
            name="client"
            render={({ field }) => (
              <RadioGroup {...field}>
                <FormControlLabel
                  value={"google"}
                  control={<Radio />}
                  label={
                    <Stack
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <GoogleIcon size="medium" />
                      <Typography>Google</Typography>
                    </Stack>
                  }
                />
                <FormControlLabel
                  value={"microsoft"}
                  control={<Radio />}
                  label={
                    <Stack
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <MicrosoftIcon size="medium" />
                      <Typography>Microsoft</Typography>
                    </Stack>
                  }
                />
              </RadioGroup>
            )}
          />
          {errors.client && (
            <Typography color="error">Please select a mail client</Typography>
          )}
        </FormControl>
      </CardContent>
      <CardActions>
        <Stack
          sx={{
            width: "100%",
            flexDirection: "row",
            gap: 1,
            justifyContent: "flex-end",
          }}
        >
          <SecondaryButton onClick={() => history.push("/settings/accounts")}>
            Cancel
          </SecondaryButton>
          <PrimaryButton onClick={handleSubmit(onSubmit)}>Submit</PrimaryButton>
        </Stack>
      </CardActions>
    </Card>
  );
}

export default AddNewAccount;
