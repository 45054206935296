import React from "react";
import Flexbox from "../../flexbox/FlexboxV2";
import MessagePreview from "../message";

function MessengerBodyPreview({ host, messages, guests }) {
  return (
    <Flexbox.Column
      flex="1 1 100%"
      justifyContent="flex-end"
      overflow="visible"
      width="fit-content"
    >
      {messages.map(
        ({
          guest_id: guestIdOfMessage,
          guest_status: guestStatus,
          occurrence,
          note_type: noteType,
          note,
        }) => (
          <MessagePreview
            key={`${noteType} - ${occurrence}`}
            host={host}
            guest={
              guests.find((guest) => guest.id === guestIdOfMessage) || null
            }
            guestStatus={guestStatus}
            occurrence={occurrence}
            message={note}
            responseType={noteType}
          />
        )
      )}
    </Flexbox.Column>
  );
}

export default MessengerBodyPreview;
