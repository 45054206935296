import React from "react";
import { Avatar, Typography, Stack } from "@mui/material";

function ContactCard({
  firstName,
  lastName,
  email,
  image,
}: {
  firstName: string;
  lastName: string;
  email: string;
  image: string;
}) {
  return (
    <Stack direction="row" spacing={1} sx={{ width: "fit-content" }}>
      <Avatar src={image} alt={`${firstName} ${lastName}`} />
      <Stack>
        <Typography style={{ fontWeight: 600 }}>
          {firstName} {lastName}
        </Typography>
        <Typography variant="caption">{email}</Typography>
      </Stack>
    </Stack>
  );
}

export default ContactCard;
