import React, { forwardRef, useCallback } from "react";
import Asterik from "../Asterik";
import Flexbox from "../../components/flexbox/FlexboxV2";
import { SmallSpacing } from "../../components/spacing";
import { P1 } from "../../components/typography";
import { useInviteTitle } from "../context";
import { Input } from "../../components/input";
import style from "../style.module.scss";

const InviteTitle = forwardRef(function InviteTitleWithRef(
  { isEditable },
  ref
) {
  const [, setTitle] = useInviteTitle();
  const updateTitle = useCallback(() => {
    setTitle(ref.current.value);
  }, [setTitle, ref]);
  return (
    <Flexbox.Column data-ref-id="invite.title">
      <P1>
        Invite Title
        <Asterik />
      </P1>
      <SmallSpacing />
      <Input
        className={style.meetingType__inviteInput}
        disabled={!isEditable}
        inputRef={ref}
        type="text"
        name="/meetingtypes/configuration/invite/invite_title"
        placeholder="Title"
        onInput={updateTitle}
      />
    </Flexbox.Column>
  );
});

export default InviteTitle;
