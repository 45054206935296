import React, { useState } from "react";
import { Stack } from "@mui/material";

import Dialog from "./Dialog";
import Input from "../inputs/Input";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";

export function EditTeamDialog({
  team,
  open,
  onSubmit,
  onClose,
}: {
  team: { id: number; name: string };
  open: boolean;
  onSubmit: (v: { id: number; name: string }) => void;
  onClose: () => void;
}) {
  const [name, setName] = useState(team.name);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Edit Team"
      actions={
        <>
          <PrimaryButton
            onClick={() => onSubmit && onSubmit({ id: team.id, name })}
          >
            Save
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              onClose && onClose();
            }}
          >
            Cancel
          </SecondaryButton>
        </>
      }
    >
      <Stack spacing={1} sx={{ pt: 1 }}>
        <Input
          label="Team Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Stack>
    </Dialog>
  );
}

export default EditTeamDialog;
