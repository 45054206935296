import React, { useEffect, useRef } from "react";

import Flexbox from "../flexbox/FlexboxV2";
import { JumboSpacing } from "../spacing";
import MeetingTypeDetails from "./meetingTypeDetails";
import MessengerBodyPreview from "./messengerBody/MessengerBodyPreview";

import style from "./style.module.scss";

function Messenger({ guests, host, invite, messages, name }) {
  const container = useRef(null);
  useEffect(() => {
    container.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [messages]);

  return (
    <Flexbox.Column
      className={style.messenger__container}
      height="100%"
      overflow="scroll"
    >
      <MeetingTypeDetails
        guests={guests}
        host={host}
        invite={invite}
        name={name}
      />
      <MessengerBodyPreview host={host} guests={guests} messages={messages} />
      <JumboSpacing ref={container} />
    </Flexbox.Column>
  );
}

export default Messenger;
