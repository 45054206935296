import useOverrideStatusRules from "src/meetings/guestStatusSelect/useOverrideStatusRules";
import { GuestStatus, guestStatusOptionFromValue } from "src/types";
import Dropdown from "./Dropdown";

export function GuestStatusDropdown(props: {
  status: string;
  meetingStage: string;
  onChange?: (status: GuestStatus) => void;
}) {
  const option = guestStatusOptionFromValue(props.status || "") || {
    label: "Unknown",
    value: "unknown",
  };

  const { overrideOptions, overridable } = useOverrideStatusRules();

  const allowabledOptionValues = (overrideOptions({
    fromStatus: props.status,
    meetingStatus: props.meetingStage,
  }) || []) as string[];

  const options: any[] = allowabledOptionValues
    .map((o) => guestStatusOptionFromValue(o))
    .filter((option) => option !== null);

  return (
    <Dropdown
      label="Guest Status"
      value={option.value}
      disabled={
        !overridable({
          fromStatus: props.status,
          meetingStatus: props.meetingStage,
        })
      }
      options={options.concat([option])}
      onChange={(event) => props.onChange?.(event?.target.value as GuestStatus)}
    />
  );
}

export default GuestStatusDropdown;
