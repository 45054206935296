import { useSWRConfig } from "swr";

import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useErrorHandler, useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";

interface Query {
  filter: Partial<{
    firstName: string;
    lastName: string;
    email: string;
    account: string;
    tags: number[];
    logicField: string;
    routingField: string;
    meetingID: number;
    meetingTypeName: string;
    meetingStartDate: {
      start: string;
      end: string;
      timezone: string;
    };
    meetingActive: boolean;
    instanceStage: string[];
    guestStatus: string[];
    unsubscribed: boolean;
    createdAfter: string;
  }>;
  sort: {
    field: string;
    order: string;
  };
}

export function useExportContacts() {
  const accessToken = getUserToken();
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const errorHandler = useErrorHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (query: Partial<Query>): Promise<void> =>
    service
      .post(`/api/contact/export/csv`)
      .set(headers)
      .send(query)
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .then((res: any) => {
        // @ts-ignore
        const keys = cache.keys();

        // Invalidate meeting queries and force refresh.
        Array.from(keys)
          .filter((r: any) => r.startsWith("/api/exports"))
          .forEach((k: any) => {
            mutate(k);
          });

        return res;
      })
      .catch(errorHandler);
}
