import React from "react";
import DOMPurify from "dompurify";
import { mdiSignCaution } from "@mdi/js";

import {
  EmptyViewContainer,
  EmptyViewIcon,
  EmptyViewRow,
} from "../../components/emptyView/EmptyView";
import Flexbox from "../../components/flexbox/FlexboxV2";
import {
  JumboSpacing,
  XXLargeSpacing,
  LargeSpacing,
  MediumSpacing,
} from "../../components/spacing";
import { P1, H6 } from "../../components/typography";
import {
  useEmailSubject,
  useEmailBody,
  useDeclineSubject,
  useDeclineBody,
  useMeetingReminderSubject,
  useMeetingReminderBody,
} from "../context";
import useDynamicVariableMappings from "../useDynamicVariableMappings";
import style from "../style.module.scss";

const sanitizer = DOMPurify.sanitize;

function EmailPreview({ title, body }) {
  const compiledTitle = useDynamicVariableMappings(title);
  const compiledBody = useDynamicVariableMappings(body);
  return (
    <Flexbox.Row
      className={style.meetingType__invitePreview}
      flex={1}
      height="fit-content"
    >
      <LargeSpacing />
      {title === null && body === null && (
        <Flexbox.Column>
          <MediumSpacing />
          <Flexbox.Row justifyContent="center">
            <H6>Preview</H6>
          </Flexbox.Row>
          <JumboSpacing />
          <EmptyViewContainer className={style.meetingType__emptyView}>
            <EmptyViewRow>
              <EmptyViewIcon icon={mdiSignCaution} />
            </EmptyViewRow>
            <EmptyViewRow>No Email Content to display.</EmptyViewRow>
          </EmptyViewContainer>
          <XXLargeSpacing />
        </Flexbox.Column>
      )}
      {(title !== null || body !== null) && (
        <Flexbox.Column>
          <MediumSpacing />
          <Flexbox.Row justifyContent="center">
            <H6>Preview</H6>
          </Flexbox.Row>
          <JumboSpacing />
          <H6>{compiledTitle}</H6>
          <XXLargeSpacing />
          <P1>
            <div
              className={style.meetingType__inviteNotesPreview}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: sanitizer(compiledBody),
              }}
            />
          </P1>
          <XXLargeSpacing />
        </Flexbox.Column>
      )}
      <LargeSpacing />
    </Flexbox.Row>
  );
}

export function CampaignEmailPreview({ insertText, isAddingDynamicVariable }) {
  const [title] = useEmailSubject();
  const [body] = useEmailBody();
  return (
    <EmailPreview
      insertText={insertText}
      isAddingDynamicVariable={isAddingDynamicVariable}
      body={body}
      title={title}
    />
  );
}

export function DeclineEmailPreview() {
  const [title] = useDeclineSubject();
  const [body] = useDeclineBody();
  return <EmailPreview body={body} title={title} />;
}

export function ReminderEmailPreview() {
  const [title] = useMeetingReminderSubject();
  const [body] = useMeetingReminderBody();
  return <EmailPreview body={body} title={title} />;
}
