/* eslint-disable no-nested-ternary */
import React, { useMemo } from "react";
import Icon from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";
import { BLACK, WHITE } from "../../components/colors";
import Flexbox from "../../components/flexbox";
import { useTheme } from "../../components/theme";

export const DropdownIndicator = () => {
  return (
    <Flexbox>
      {({ Column }) => (
        <Column>
          <Icon path={mdiChevronDown} size={0.5} />
        </Column>
      )}
    </Flexbox>
  );
};

const useGuestTheme = (hasGuest) => {
  const { isDark } = useTheme();

  return useMemo(
    () => ({
      container: (base, { isFocused }) => {
        let backgroundColor = "inherit";

        if (isFocused) {
          backgroundColor = isDark ? "#484B68" : WHITE;
        }

        return {
          ...base,
          backgroundColor,
          borderRadius: "3px",
          color: isDark ? WHITE : BLACK,
          height: "fit-content",
          width:
            !hasGuest && isFocused ? "calc(((100% / 11) * 5) - 60px)" : "100%",
        };
      },
      control: (base) => ({
        ...base,
        backgroundColor: "inherit",
        border: 0,
        borderRadius: "3px",
        boxShadow: 0,
        cursor: "pointer",
        flexDirection: "row-reverse",
        fontSize: "13px",
        minHeight: "fit-content",
        padding: "0px",
      }),
      indicatorSeparator: (base) => ({
        ...base,
        backgroundColor: "inherit",
      }),
      indicatorsContainer: (base) => ({
        ...base,
        position: "absolute",
        right: "0",
        visibility: "hidden",
      }),
      input: (base) => ({
        ...base,
        alignItems: "center",
        backgroundColor: "inherit",
        color: isDark ? WHITE : BLACK,
      }),
      loadingIndicator: (base) => ({
        ...base,
        color: isDark ? WHITE : BLACK,
        position: "absolute",
        right: "0",
      }),
      menu: (base) => ({
        ...base,
        marginTop: "0",
      }),
      menuList: (base) => ({
        ...base,
        border: `0.5px solid ${isDark ? "#191C3A" : "#A8B1BD"}`,
        borderRadius: 0,
        padding: 0,
      }),
      option: (base, { isFocused }) => {
        let backgroundColor;

        if (isDark) {
          backgroundColor = isFocused ? "rgba(121, 125, 149, 0.7)" : "#414564";
        }

        if (!isDark) {
          backgroundColor = isFocused ? "#F1FAFF" : WHITE;
        }

        return {
          ...base,
          backgroundColor,
          border: `0.5px solid ${isDark ? "#191C3A" : "#A8B1BD"}`,
          color: isDark ? WHITE : BLACK,
          cursor: "pointer",
          paddingBottom: "7px",
          paddingTop: "7px",
        };
      },
      singleValue: (base) => ({
        ...base,
        color: isDark ? WHITE : BLACK,
      }),
      valueContainer: (base) => ({
        ...base,
        fontSize: "13px",
        padding: "0px",
      }),
    }),
    [isDark, hasGuest]
  );
};

export default useGuestTheme;
