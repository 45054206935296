import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";

import Flexbox from "../components/flexbox/FlexboxV2";
import { H2 } from "../components/typography";
import { JumboSpacing } from "../components/spacing";
import { useIsDefaultUser } from "../auth";
import { useTeamRepository } from "../repository/teams";

export default function CopyMeetingType({ meetingType, onCreate }) {
  const history = useHistory();
  const isDefaultUser = useIsDefaultUser();
  const { useGlobalTeamId } = useTeamRepository();
  const { data: globalId, loading: globalIdLoading } = useGlobalTeamId();
  const copyNewMeetingType = useCallback(async () => {
    if (globalIdLoading) {
      return;
    }

    const copy = {
      ...meetingType,
      enabled: false,
      name: `${meetingType.name} Clone`,
    };

    if (isDefaultUser) {
      // a default user copying a meeting template does not need the team id,
      // the default user will always be host
      delete copy.team;
    } else if (!copy.team || copy.team.id < 1) {
      // in the event a non-default user copies a default user's meeting template,
      // the default user's meeting template will not have a valid team, inject global team here
      copy.team = { id: globalId };
    }

    delete copy.id;
    const copiedMeetingtype = await onCreate(copy);
    history.push(`/meetingTypes/user/${copiedMeetingtype.id}/general/invite`);
  }, [
    isDefaultUser,
    globalId,
    globalIdLoading,
    meetingType,
    history,
    onCreate,
  ]);
  useEffect(() => {
    copyNewMeetingType();
  }, [copyNewMeetingType]);
  return (
    <Flexbox.Column alignItems="center" justifyContent="center">
      <Icon path={mdiLoading} size={8} spin={1} />
      <JumboSpacing />
      <H2>Copying meeting template...</H2>
    </Flexbox.Column>
  );
}
