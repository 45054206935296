import { useSWRConfig } from "swr";

import { Role } from "src/types";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useErrorHandler } from "../hooks";
import { useUserService } from "../services";

export function useUpdateUser() {
  const accessToken = getUserToken();
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const errorHandler = useErrorHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (
    id: number | string,
    user: Partial<{
      role: Role;
      firstName?: string;
      lastName?: string;
      email?: string;
      permissions?: number;
      remove_from_teams: number[];
      add_to_teams: number[];
      title: string;
      profileURL: string;
      meetingLink: string;
      location: string;
      org: string;
      tags: { id: number; name: string }[];
    }>
  ): Promise<void> =>
    service
      .patch(`/api/users/${id}`)
      .set(headers)
      .send(user)
      .then((res: Response) => res.body)
      .then((res: any) => {
        // @ts-ignore
        const keys = cache.keys();

        // Invalidate meeting queries and force refresh.
        Array.from(keys)
          .filter((r: any) => r.includes("/api/users"))
          .forEach((k: any) => {
            mutate(k);
          });

        return res;
      })
      .catch(errorHandler);
}

export default useUpdateUser;
