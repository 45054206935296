export type CalendarTokenStatus = "current" | "expired" | "disabled";

export interface CalendarTokenStatusOption {
  label: string;
  value: CalendarTokenStatus;
}

export const CalendarTokenStatusOptions: CalendarTokenStatusOption[] = [
  { label: "Current", value: "current" },
  { label: "Expired", value: "expired" },
  { label: "Disabled", value: "disabled" },
];
