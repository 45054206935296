import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Flexbox from "../components/flexbox/FlexboxV2";
import {
  JumboSpacing,
  LargeSpacing,
  MediumSpacing,
  XLargeSpacing,
  XXLargeSpacing,
} from "../components/spacing";
import { P1 } from "../components/typography";
import Step from "./Step";
import {
  useIsEmailBasedRouting,
  useIsRoutingOnActivation,
  useRouting,
  useRoutingLogic,
  useRoutingSubType,
  useSubTypeDetailsFieldValue,
  useTeam,
} from "./context";
import { AsyncSelect, Select } from "../inputs";
import { useDefaultSelectTheme } from "../components/select";
import { useSearchTeams } from "../repository/teams";
import useGenerateRoutingLogic from "./useGenerateRoutingLogic";
import useOrderedUsers from "./useOrderedUsers";
import { RadioButton } from "../components/radio";
import style from "./style.module.scss";

function toTeamOption(t) {
  try {
    if (Array.isArray(t)) {
      return t.map(toTeamOption);
    }
    return {
      label: t.name,
      value: t,
    };
  } catch (error) {
    return null;
  }
}

function generateMetadata(newRouting, emailBasedFieldValue) {
  const isEmailBased = newRouting === "email";
  if (isEmailBased) {
    return {
      subtypeDetails: {
        field: emailBasedFieldValue,
        subtype: "email",
      },
    };
  }
  return null;
}

// Not a routing option
const EMAIL_KEY = "email";

const ROUTING = {
  CUSTOM: "custom",
  SEQUENTIAL: "sequential",
};

const ROUTING_OPTIONS = [
  {
    label: "Sequential (Default)",
    value: ROUTING.SEQUENTIAL,
  },
  { label: "Custom", value: ROUTING.CUSTOM },
  { label: "Email Based", value: EMAIL_KEY },
];

// TODO(Matt): Rename to options
const EMAIL_BASED_ROUTING_TYPE_DATA = [
  {
    label: "Logic field",
    value: "logic_field",
  },
  {
    label: "Routing field",
    value: "routing_field",
  },
];

const NO_OPTIONS_MESSAGE = () => <Link to="/users/teams">Create team?</Link>;

function useRoutingOption() {
  const [routing] = useRouting();
  const [routingSubType] = useRoutingSubType();
  if (routing === "custom") {
    if (routingSubType === "email") {
      return ROUTING_OPTIONS[2];
    }
    return ROUTING_OPTIONS[1];
  }
  return ROUTING_OPTIONS.find((dt) => dt.value === routing);
}

export default function Routing({
  areTeamMembersLoading,
  teamMembers,
  teamMetadata,
}) {
  const [updateCustom, setUpdateCustom] = useState(false);
  // TODO: Hack should be a reducer for team
  const [haveNewMembersStartedArriving, setHaveNewMembersStartedArriving] =
    useState(true);
  const [, setTeam] = useTeam();
  const [components, theme] = useDefaultSelectTheme();
  const searchTeams = useSearchTeams();
  const [subTypeValueField] = useSubTypeDetailsFieldValue();
  const isEmailBasedRouting = useIsEmailBasedRouting();
  const generateRoutingLogic = useGenerateRoutingLogic({
    areTeamMembersLoading,
    teamMembers,
  });
  const orderedUsers = useOrderedUsers({
    areTeamMembersLoading,
    teamMembers,
  });
  const [isRoutingOnActivation, setIsRoutingOnActivation] =
    useIsRoutingOnActivation();
  const [routing, setRouting] = useRouting();
  const [, setRoutingLogic] = useRoutingLogic();
  const onTeamChange = async (teamSelected) => {
    const newRouting = isEmailBasedRouting ? "email" : routing;
    const metadata = generateMetadata(newRouting, subTypeValueField);
    setTeam(teamSelected.value, {
      custom: {},
      metadata,
      sequential: { order: [] },
    });
    setUpdateCustom(true);
    setHaveNewMembersStartedArriving(true);
  };
  const routingOption = useRoutingOption();
  const onRoutingOptionChange = useCallback(
    (selection) => {
      let { value: newRouting } = selection;
      // TODO(Matt): Move this into a function
      const newSubTypeValueField =
        newRouting === "email"
          ? subTypeValueField || EMAIL_BASED_ROUTING_TYPE_DATA[1].value
          : null;
      const newRoutingLogic = generateRoutingLogic(
        newRouting,
        newSubTypeValueField,
        false
      );
      if (newRouting === "email") {
        newRoutingLogic.metadata = {
          subtypeDetails: {
            field: newSubTypeValueField,
            subtype: EMAIL_KEY,
          },
        };
        newRouting = ROUTING.CUSTOM;
      }
      setRouting(newRouting, newRoutingLogic);
    },
    [generateRoutingLogic, setRouting, subTypeValueField]
  );
  const onEmailBasedRoutingChange = useCallback(
    (field) => {
      const newRoutingLogic = generateRoutingLogic(routing, field.value, false);
      const { custom } = newRoutingLogic;
      orderedUsers.forEach(({ id, email }) => {
        const { enabled } = custom[id];
        if (enabled) {
          custom[id] = {
            enabled: true,
            mapping_logic: [
              {
                field: field.value,
                operator: "is",
                value: email,
              },
            ],
          };
        } else {
          custom[id] = {
            enabled: false,
            mapping_logic: [],
          };
        }
      });
      setRoutingLogic(newRoutingLogic);
    },
    [generateRoutingLogic, orderedUsers, routing, setRoutingLogic]
  );
  useEffect(() => {
    if (areTeamMembersLoading) {
      setHaveNewMembersStartedArriving(true);
    }
  }, [areTeamMembersLoading, setHaveNewMembersStartedArriving]);
  useEffect(() => {
    // Only update custom when the new users have arrived
    if (
      updateCustom &&
      !areTeamMembersLoading &&
      orderedUsers !== null &&
      haveNewMembersStartedArriving
    ) {
      if (isEmailBasedRouting) {
        onEmailBasedRoutingChange({ value: subTypeValueField });
      } else {
        onRoutingOptionChange({ value: routing });
      }
      setUpdateCustom(false);
      setHaveNewMembersStartedArriving(false);
    }
  }, [
    areTeamMembersLoading,
    isEmailBasedRouting,
    onEmailBasedRoutingChange,
    haveNewMembersStartedArriving,
    onRoutingOptionChange,
    orderedUsers,
    routing,
    setUpdateCustom,
    subTypeValueField,
    updateCustom,
  ]);

  return (
    <Flexbox.Column>
      <Flexbox.Row>
        <XXLargeSpacing />
        <Flexbox.Column>
          <Flexbox.Row>
            <Step
              stepNumber={1}
              description="Which team members will be hosts?"
            />
          </Flexbox.Row>
          <LargeSpacing />
          <Flexbox.Row wrap="wrap">
            <Flexbox.Row flex={1}>
              <XXLargeSpacing />
              <Flexbox.Column element="label" htmlFor="team">
                <P1 small bold>
                  Select A Team
                </P1>
                <MediumSpacing />
                <AsyncSelect
                  components={components}
                  customTheme={theme}
                  defaultOptions
                  onChange={onTeamChange}
                  placeholder="Select Team"
                  toOption={toTeamOption}
                  load={searchTeams}
                  name="/meetingtypes/configuration/behavior/routing/select_a_team"
                  noOptionsMessage={NO_OPTIONS_MESSAGE}
                  value={toTeamOption(teamMetadata)}
                />
              </Flexbox.Column>
            </Flexbox.Row>
            <Flexbox.Row flex={1}>
              <XXLargeSpacing />
              <Flexbox.Column element="label" htmlFor="routing">
                <P1 small bold>
                  Team routing
                </P1>
                <MediumSpacing />
                <Select
                  components={components}
                  customTheme={theme}
                  name="/meetingtypes/configuration/behavior/routing/team_routing"
                  onChange={onRoutingOptionChange}
                  value={routingOption}
                  options={ROUTING_OPTIONS}
                  placeholder={<div>Select Routing</div>}
                />
              </Flexbox.Column>
            </Flexbox.Row>
          </Flexbox.Row>
        </Flexbox.Column>
      </Flexbox.Row>
      {routingOption && routingOption.value === EMAIL_KEY && (
        <>
          <JumboSpacing />
          <Flexbox.Row>
            <XXLargeSpacing />
            <Flexbox.Column>
              <Flexbox.Row>
                <Step
                  description="What field is the host email located in?"
                  stepNumber="!"
                  warn
                />
              </Flexbox.Row>
              <MediumSpacing />
              <Flexbox.Row>
                <XXLargeSpacing />
                <Flexbox.Column element="label" htmlFor="distribution">
                  <div className={style.behavior__labelText}>
                    <P1 small bold>
                      Email Based Routing
                    </P1>
                  </div>
                  <MediumSpacing />
                  <Select
                    components={components}
                    customTheme={theme}
                    name="/meetingtypes/configuration/behavior/routing/team_routing/email_based_routing"
                    onChange={onEmailBasedRoutingChange}
                    value={EMAIL_BASED_ROUTING_TYPE_DATA.find((i) => {
                      return i.value === subTypeValueField;
                    })}
                    options={EMAIL_BASED_ROUTING_TYPE_DATA}
                    placeholder={<div>Select Field</div>}
                  />
                </Flexbox.Column>
                <JumboSpacing />
                <Flexbox.Column />
              </Flexbox.Row>
            </Flexbox.Column>
          </Flexbox.Row>
        </>
      )}
      <JumboSpacing />
      <Flexbox.Row>
        <XXLargeSpacing />
        <Flexbox.Column>
          <Flexbox.Row>
            <Step stepNumber={2} description="When should a host be chosen?" />
          </Flexbox.Row>
          <LargeSpacing />
          <Flexbox.Row>
            <XXLargeSpacing />
            <Flexbox.Row>
              <XXLargeSpacing />
              <Flexbox.Row>
                <Flexbox.Column>
                  <Flexbox.Row element="label">
                    <P1 bold>Host Safe Mode</P1>
                    <XLargeSpacing />
                    <RadioButton
                      checked={!isRoutingOnActivation}
                      name="/meetingtypes/configuration/behavior/routing/host_safe_mode"
                      onClick={() => {
                        setIsRoutingOnActivation(false);
                      }}
                    />
                  </Flexbox.Row>
                  <Flexbox.Row>
                    <P1 small>
                      <P1>{`Route on Initialization: `}</P1>
                      <P1 italic>
                        Meetings will be assigned to host when Initialized.
                        Meetings will appear in Host's Meetings view, allowing
                        the Host to Activate at their desire.
                      </P1>
                    </P1>
                  </Flexbox.Row>
                </Flexbox.Column>
                <MediumSpacing />
                <Flexbox.Column>
                  <Flexbox.Row element="label">
                    <P1 bold>Speed to Lead Mode</P1>
                    <XLargeSpacing />
                    <RadioButton
                      checked={isRoutingOnActivation}
                      name="/meetingtypes/configuration/behavior/routing/speed_to_lead_mode"
                      onClick={() => {
                        setIsRoutingOnActivation(true);
                      }}
                    />
                  </Flexbox.Row>
                  <Flexbox.Row>
                    <P1 small>
                      <P1>{`Route on Activation: `}</P1>
                      <P1 italic>
                        Meetings are routed to Host only when activated, giving
                        admin the ability to complete Initialization before
                        activating meetings and consequently being visible in
                        Host's Meetings view.
                      </P1>
                    </P1>
                  </Flexbox.Row>
                </Flexbox.Column>
              </Flexbox.Row>
              <MediumSpacing />
            </Flexbox.Row>
          </Flexbox.Row>
        </Flexbox.Column>
      </Flexbox.Row>
    </Flexbox.Column>
  );
}
