import { ResponseError } from "superagent";
import useSWRImmutable from "swr/immutable";
import { useUserService } from "../services";

export function useHostAvailabilityForOneClick(
  oneClickToken: string | undefined,
  meetingUUID: string | undefined
) {
  const service = useUserService();

  const body = {
    token: oneClickToken,
    meetingId: meetingUUID,
  };

  const { data, error } = useSWRImmutable(
    meetingUUID ? [`/api/meeting/scheduling/host/availability`, body] : null,
    (url: string) =>
      service
        .post(url)
        .send(body)
        .then((res: Response) => res.body)
  );

  return {
    data: data as { availableTimes: string[] } | undefined,
    error: error as ResponseError,
    loading: !error && !data,
  };
}
