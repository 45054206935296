import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DOMPurify from "dompurify";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import Flexbox from "../../../components/flexbox";
import useMeetingHistoryRepository from "../../../repository/meetingHistory";
import MeetingTypeDetailsHeader from "../meetingTypeDetailsHeader";
import MeetingTypeDetailsGuestList from "../meetingTypeDetailsGuestList";
import { useMeeting, useMeetingTypeState } from "../state";
import {
  ButtonContainer,
  ButtonIcon,
  ButtonText,
} from "../../../components/button";
import { cleanMessageAsHTML } from "../util/helpers";
import style from "./meetingTypeDetails.module.scss";

const sanitizer = DOMPurify.sanitize;
const LINE_HEIGHT = 15;
const MIN_ROWS = 4;

const MeetingTypeDetails = forwardRef(
  ({ messenger, messengerHeader, messengerInput }, ref) => {
    const inviteTemplateRef = useRef();
    const [expanded, setExpanded] = useState(false);
    const [meeting] = useMeeting();
    const { meetingType } = useMeetingTypeState();
    const { useCalendarInvite } = useMeetingHistoryRepository();
    const { data: calendarInvite } = useCalendarInvite(meeting.id);

    const invite = useMemo(() => {
      if (calendarInvite !== null && calendarInvite.body !== "") {
        return calendarInvite.body;
      }

      if (meetingType.inviteTemplates.length !== 0) {
        return meetingType.inviteTemplates[0].notes;
      }

      return "Enter Invite Details";
    }, [calendarInvite, meetingType]);

    const textareaHeight = useMemo(() => {
      if (expanded) {
        const messengerHeight = messenger.getBoundingClientRect().height;
        const headerHeight =
          messengerHeader.getBoundingClientRect().height -
          LINE_HEIGHT * MIN_ROWS;
        const inputHeight = messengerInput.getBoundingClientRect().height;
        const textAreaHeight = inviteTemplateRef.current?.scrollHeight;

        const maxTextareaHeight = messengerHeight - headerHeight - inputHeight;

        // We don't want the invite to go over the message input. So,
        // if the textarea height is smaller, use that. Otherwise, we
        // use the maxHeight
        return `${Math.min(textAreaHeight, maxTextareaHeight)}px`;
      }
      return `${LINE_HEIGHT * MIN_ROWS}px`;
    }, [expanded, messenger, messengerHeader, messengerInput]);

    const handleExpandInviteTemplateOnClick = useCallback(() => {
      setExpanded((prev) => !prev);
    }, []);

    const inviteText = useMemo(() => {
      if (expanded) {
        return "Collapse invite";
      }
      return "Expand to see full invite";
    }, [expanded]);

    useEffect(() => {
      if (inviteTemplateRef.current !== null) {
        inviteTemplateRef.current.innerHTML = sanitizer(
          cleanMessageAsHTML(invite)
        );
      }
    }, [invite]);

    return (
      <Flexbox>
        {({ Column, Row }) => (
          <Column
            className={style.meetingTypeDetails}
            height="fit-content"
            justifyContent="start"
            ref={ref}
          >
            <MeetingTypeDetailsHeader calendarInvite={calendarInvite} />

            <Column
              className={style.meetingTypeDetails__invite}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <MeetingTypeDetailsGuestList />

              <Column>
                <div
                  ref={inviteTemplateRef}
                  className={style.meetingTypeDetails_invite__textarea}
                  style={{
                    height: textareaHeight,
                    lineHeight: `${LINE_HEIGHT}px`,
                  }}
                />
              </Column>

              <Row height="50%" justifyContent="flex-start">
                <ButtonContainer
                  className={style.meetingTypeDetails_invite__expand_button}
                  onClick={handleExpandInviteTemplateOnClick}
                >
                  <ButtonText>{inviteText}</ButtonText>
                  <ButtonIcon
                    className={
                      style.meetingTypeDetails_invite_expand_button__icon
                    }
                    path={expanded ? mdiChevronUp : mdiChevronDown}
                    size={1}
                  />
                </ButtonContainer>
              </Row>
            </Column>
          </Column>
        )}
      </Flexbox>
    );
  }
);

export default MeetingTypeDetails;
