import { CalendarTokenStatus, CalendarTokenStatusOptions } from "src/types";
import FilterDropdown from "./FilterDropdown";

export function CalendarConnectionFilter(props: {
  value?: CalendarTokenStatus[];
  onChange?: (value: CalendarTokenStatus[]) => void;
}) {
  return (
    <FilterDropdown
      label="Calendar Connection"
      value={props.value}
      onChange={props.onChange}
      options={CalendarTokenStatusOptions}
    />
  );
}

export default CalendarConnectionFilter;
