import { useState } from "react";
import {
  Stack,
  Typography,
  Divider,
  Checkbox,
  DialogContent,
  DialogActions,
} from "@mui/material";

import RichText, { TipTapPreview } from "../../RichText";
import { Step } from "./Step";
import { State } from "./State";
import { durationToString } from "./SelectAMeeting";
import renderDynamicVariables from "src/componentsV2/renderDynamicVariables";
import dayjs from "dayjs";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";
import { RocketLaunch } from "@mui/icons-material";
import { useCreateMeetings } from "src/mutations";
import { QUICK_LAUNCH } from "src/features/props";
import { useSnackbar } from "src/hooks";

function PreviewAndLaunch({ state, previous, close }: Step<State>) {
  const createMeetings = useCreateMeetings();
  const [openSnackbar] = useSnackbar();

  const [showDynamicVars, setShowDynamicVars] = useState(false);

  const render = (text: string) => {
    return renderDynamicVariables(text, {
      guest_account: state.guest?.account || "",
      guest_first_name: state.guest?.firstName || "",
      guest_last_name: state.guest?.lastName || "",
      guest_logic_field: state.guest?.logicField || "",
      guest_logic_field_2: "",
      guest_phone: state.guest?.phone || "",
      greeting: "Good evening",
      meeting_date_time: "{{meeting_date_time}}",
      meeting_day_of_week: "{{meeting_day_of_week}}",
      meeting_link: "{{meeting_link}}",
      meeting_month_day: "{{meeting_month_day}}",
      meeting_time: "{{meeting_time}}",
      time_casual_day: "{{time_casual_day}}",
      time_number_date: "{{time_number_date}}",
      meeting_duration: "{{meeting_duration}}",
      host_company: "{{host_company}}",
      host_title: state.host?.title || "",
      host_first_name: state.host?.firstName || "",
      host_last_name: state.host?.lastName || "",
      host_location: state.host?.location || "",
      host_signature: "{{host_signature}}",
      guest_custom_field_1: state.guest?.customField1 || "",
      guest_custom_field_2: state.guest?.customField2 || "",
      guest_custom_field_3: state.guest?.customField3 || "",
      guest_custom_field_4: state.guest?.customField4 || "",
      guest_custom_field_5: state.guest?.customField5 || "",
      guest_custom_field_6: state.guest?.customField6 || "",
      guest_custom_field_7: state.guest?.customField7 || "",
      guest_custom_field_8: state.guest?.customField8 || "",
      guest_custom_field_9: state.guest?.customField9 || "",
      guest_custom_field_10: state.guest?.customField10 || "",
    });
  };

  let subject = state.overrides.emailSubjectTemplate || "";
  let email = state.overrides.emailBodyTemplate || "";

  if (!showDynamicVars) {
    subject = render(subject || "");
    email = render(email || "");
  }

  // Replace dynamic variables with something we can parse out later
  email = email.replace(
    /\{\{([a-z_]+)\}\}/g,
    '<span class="dynamic-var">{{$1}}</span>'
  );
  subject = subject.replace(
    /\{\{([a-z_]+)\}\}/g,
    '<span class="dynamic-var">{{$1}}</span>'
  );

  return (
    <>
      <DialogContent>
        <Stack
          spacing={2}
          sx={{
            p: 2,
            height: "600px",
          }}
        >
          <Stack>
            <Typography>
              <strong>Meeting Type</strong>: {state.meetingType?.name}
            </Typography>
            <Typography>
              <strong>Duration</strong>: {state.overrides.duration} minutes
            </Typography>
            <Typography>
              <strong>Minimum Scheduling Notice</strong>:{" "}
              {durationToString(state.overrides.bufferDurationMinutes || 0)}
            </Typography>
            {state.preferredTimeType === "fixed time" ? (
              <Typography>
                <strong>Meeting Time</strong>:{" "}
                {dayjs(state.startTime).format("M/D h:mm a, z")}
              </Typography>
            ) : (
              <Typography>
                <strong>Scheduling Range</strong>:{" "}
                {dayjs()
                  .add(state.overrides.dayRangeFrom, "days")
                  .startOf("day")
                  .format("M/D")}{" "}
                -{" "}
                {dayjs()
                  .add(state.overrides.dayRangeTo, "days")
                  .startOf("day")
                  .format("M/D")}
              </Typography>
            )}
          </Stack>
          <Divider flexItem />
          <Stack justifyContent="center" spacing={1}>
            <Typography>
              <strong>Subject</strong>: <RichText content={subject} />
            </Typography>
            <Typography>
              <strong>Body</strong>:<br />
              <TipTapPreview content={email} />
            </Typography>
          </Stack>
          <Divider flexItem />
          <Stack>
            <Typography variant="caption">
              *Dynamic variables will be auto populated by the system prior to
              send
            </Typography>
            <Stack direction="row" alignItems="center">
              <Checkbox
                checked={showDynamicVars}
                onChange={(_, v) => setShowDynamicVars(v)}
              />
              <Typography>Show dynamic variables</Typography>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row-reverse"
          justifyContent="space-between"
          sx={{ pt: 2, width: "100%" }}
        >
          <PrimaryButton
            icon={<RocketLaunch />}
            onClick={() => {
              createMeetings([
                {
                  contact: state.guest?.id,
                  enabled: true,
                  meetingType: state.meetingType?.id,
                  overrides: {
                    gap: state.overrides.gap,
                    duration: state.overrides.duration,
                    bufferDurationMinutes:
                      state.overrides.bufferDurationMinutes || undefined,
                    dayRangeFrom: state.overrides.dayRangeFrom,
                    dayRangeTo: state.overrides.dayRangeTo,
                    emailSubjectTemplate:
                      state.overrides.emailSubjectTemplate || undefined,
                    emailBodyTemplate:
                      state.overrides.emailBodyTemplate || undefined,
                    inviteTitleTemplate:
                      state.overrides.inviteTitleTemplate || undefined,
                    inviteBodyTemplate:
                      state.overrides.inviteBodyTemplate || undefined,
                  },
                  startTime:
                    state.preferredTimeType === "scheduling range" ||
                    state.startTime === null
                      ? undefined
                      : state.startTime,
                  meta: {
                    platform: "web",
                    source: QUICK_LAUNCH,
                  },
                  user: state.host?.id,
                },
              ])
                .then((res) => {
                  let message = `${res.instances.length} meetings created successfully.`;
                  if (res.failures?.length) {
                    message += ` Failed to create ${res.failures.length} meetings.`;
                  }
                  openSnackbar(message);
                  close();
                })
                .catch(() => {
                  openSnackbar("Failed to create meetings");
                });
            }}
          >
            Launch
          </PrimaryButton>
          <PrimaryButton onClick={() => previous()}>Prev</PrimaryButton>
        </Stack>
      </DialogActions>
    </>
  );
}

export default PreviewAndLaunch;
