import React, { useMemo, useState } from "react";
import cx from "classnames";

import MeetingTypeMenuAndList from "./MeetingTypeMenuAndList";

import style from "./style.module.scss";
import { useTheme } from "../components/theme";

function useSlide(onSlideOut, shouldSkipInitialSlideIn = false) {
  const [skipSlideIn, setSkipSlideIn] = useState(shouldSkipInitialSlideIn);
  const [isSlideIn, setIsSlideIn] = useState(true);
  const slideOut = () => {
    setIsSlideIn(false);
    setTimeout(() => {
      setSkipSlideIn(false);
      onSlideOut();
    }, 500);
  };
  const className = useMemo(() => {
    return cx({
      [style["meetingType__drawer--slidein"]]: isSlideIn && !skipSlideIn,
      [style["meetingType__drawer--slideout"]]: !isSlideIn,
    });
  }, [isSlideIn, skipSlideIn]);
  return { className, slideOut };
}

export default function MeetingTemplatesMenuAndListDrawer(props) {
  const { useThemeShadows } = useTheme();
  const { MD } = useThemeShadows();
  const { close, isListInitiallyOpen } = props;
  const { className: slideClassName, slideOut } = useSlide(
    close,
    isListInitiallyOpen
  );
  return (
    <div className={style.meetingType__drawerContainer}>
      <div
        className={(style.meetingType__drawer, slideClassName)}
        style={{
          boxShadow: MD,
          height: "100%",
          width: "400px",
        }}
      >
        <MeetingTypeMenuAndList {...props} close={slideOut} />
      </div>
      <div
        className={style.meetingType__drawerOverlay}
        onClick={slideOut}
        role="presentation"
      />
    </div>
  );
}
