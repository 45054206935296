// instance status/colors
export const INITIALIZING_STAGE = "initializing";
export const INITIALIZING_FAULT_STAGE = "init_fault";
export const INITIALIZING_READY_TO_ACTIVATE_STAGE = "initialized";
export const SCHEDULING_STAGE = "scheduling";
export const SCHEDULING_QUEUED_STAGE = "queued";
export const SCHEDULED_STAGE = "scheduled";
export const COMPLETED_STAGE = "completed";
export const NO_QUORUM_STAGE = "no_quorum";
export const CANCELLED_STAGE = "cancelled";

const INSTANCE_STAGES = [
  CANCELLED_STAGE,
  COMPLETED_STAGE,
  INITIALIZING_STAGE,
  INITIALIZING_FAULT_STAGE,
  INITIALIZING_READY_TO_ACTIVATE_STAGE,
  NO_QUORUM_STAGE,
  SCHEDULING_STAGE,
  SCHEDULING_QUEUED_STAGE,
  SCHEDULED_STAGE,
];

export const INSTANCE_STAGE_TO_LABEL = {
  [CANCELLED_STAGE]: "Cancelled",
  [COMPLETED_STAGE]: "Completed",
  [INITIALIZING_FAULT_STAGE]: "Initializing Fault",
  [INITIALIZING_READY_TO_ACTIVATE_STAGE]: "Initialized",
  [INITIALIZING_STAGE]: "Initializing",
  [NO_QUORUM_STAGE]: "No Quorum",
  [SCHEDULED_STAGE]: "Scheduled",
  [SCHEDULING_QUEUED_STAGE]: "Queued",
  [SCHEDULING_STAGE]: "Scheduling",
};

export const PROVISIONING_STAGES = [
  INITIALIZING_STAGE,
  INITIALIZING_FAULT_STAGE,
  INITIALIZING_READY_TO_ACTIVATE_STAGE,
];

export const FINAL_INSTANCE_STAGES = [
  CANCELLED_STAGE,
  COMPLETED_STAGE,
  NO_QUORUM_STAGE,
];

const INSTANCE_STAGE_OPTIONS = INSTANCE_STAGES.map((status) => ({
  label: INSTANCE_STAGE_TO_LABEL[status],
  value: status,
}));

export function useInstanceStageOptions() {
  return INSTANCE_STAGE_OPTIONS;
}

export function toInstanceStageLabel(s) {
  if (s in INSTANCE_STAGE_TO_LABEL) {
    return INSTANCE_STAGE_TO_LABEL[s];
  }
  return null;
}

const INSTANCE_STAGE_TO_COLOR = {
  [CANCELLED_STAGE]: "#8d94ao",
  [COMPLETED_STAGE]: "#3e8171",
  [INITIALIZING_FAULT_STAGE]: "#9da8ee",
  [INITIALIZING_READY_TO_ACTIVATE_STAGE]: "#99bbe3",
  [INITIALIZING_STAGE]: "#c1d6ef",
  [NO_QUORUM_STAGE]: "#5f6672",
  [SCHEDULED_STAGE]: "#a3a2d3",
  [SCHEDULING_QUEUED_STAGE]: "#cea8bc",
  [SCHEDULING_STAGE]: "#72a2d8",
};

export function toInstanceStatusColor(s) {
  if (s in INSTANCE_STAGE_TO_COLOR) {
    return INSTANCE_STAGE_TO_COLOR[s];
  }

  return null;
}

export const MEETING_START_TIME = [
  { key: "all_time", value: "All time" },
  { key: "spacing_custom", value: "" },
  { key: "custom", value: "Custom" },
  { key: "spacing_past", value: "" },
  { key: "past", value: "Past" },
  { key: "future", value: "Future" },
  { key: "spacing_year", value: "" },
  { key: "this_year", value: "This Year" },
  { key: "next_year", value: "Next Year" },
  { key: "last_year", value: "Last Year" },
  { key: "year_to_date", value: "Year to Date" },
  { key: "spacing_quarter", value: "" },
  { key: "this_quarter", value: "This Quarter" },
  { key: "next_quarter", value: "Next Quarter" },
  { key: "last_quarter", value: "Last Quarter" },
  { key: "quarter_to_date", value: "Quarter to Date" },
  { key: "spacing_month", value: "" },
  { key: "this_month", value: "This Month" },
  { key: "next_month", value: "Next Month" },
  { key: "last_month", value: "Last Month" },
  { key: "month_to_date", value: "Month to Date" },
  { key: "spacing_week", value: "" },
  { key: "this_week", value: "This Week" },
  { key: "next_week", value: "Next Week" },
  { key: "last_week", value: "Last Week" },
  { key: "week_to_date", value: "Week to Date" },
  { key: "spacing_day", value: "" },
  { key: "today", value: "Today" },
  { key: "tomorrow", value: "Tomorrow" },
  { key: "yesterday", value: "Yesterday" },
  { key: "last_24_hours", value: "Last 24 Hours" },
];

export const MEETING_START_TIME_OPTIONS = MEETING_START_TIME.filter(
  ({ value }) => value !== ""
).map(({ key, value }) => ({ label: value, value: key }));

// guest status/colors
export const ACCEPTED_STATUS = "accepted";
export const ACCEPT_PENDING_QUORUM_STATUS = "accept_pending_quorum";
export const AI_NEGOTIATING_STATUS = "negotiation_in_progress";
export const AWAITING_RESPONSE_STATUS = "waiting_for_first_response";
export const AWAY_STATUS = "away";
export const CANCELLED_STATUS = "cancelled";
export const DECLINED_STATUS = "declined";
export const HOST_INTERVENED_STATUS = "host_intervened";
export const NO_RESPONSE_STATUS = "no_response";
export const PAUSED_STATUS = "paused";
export const REQUIRES_HOST_INTERVENTION_STATUS = "requires_host_intervention";
export const RESPONDED_UNKNOWN_INTENT_STATUS = "responded_unknown_intent";
export const STAGING_STATUS = "staging";
export const UNDELIVERABLE_STATUS = "undeliverable";

export const GUEST_STATUS_TO_LABEL = {
  [ACCEPTED_STATUS]: "Accepted",
  [ACCEPT_PENDING_QUORUM_STATUS]: "Accept Pending Quorum",
  [AI_NEGOTIATING_STATUS]: "AI Negotiating",
  [AWAITING_RESPONSE_STATUS]: "Awaiting Response",
  [AWAY_STATUS]: "Away",
  [CANCELLED_STATUS]: "Cancelled",
  [DECLINED_STATUS]: "Declined",
  [HOST_INTERVENED_STATUS]: "Host Intervened",
  [NO_RESPONSE_STATUS]: "No Response",
  [PAUSED_STATUS]: "Paused",
  [REQUIRES_HOST_INTERVENTION_STATUS]: "Requires Host Intervention",
  [RESPONDED_UNKNOWN_INTENT_STATUS]: "Responded Unknown Intent",
  [STAGING_STATUS]: "Staging",
  [UNDELIVERABLE_STATUS]: "Undeliverable",
};

const GUEST_STATUSES = [
  ACCEPTED_STATUS,
  ACCEPT_PENDING_QUORUM_STATUS,
  AI_NEGOTIATING_STATUS,
  AWAITING_RESPONSE_STATUS,
  AWAY_STATUS,
  CANCELLED_STATUS,
  DECLINED_STATUS,
  HOST_INTERVENED_STATUS,
  NO_RESPONSE_STATUS,
  PAUSED_STATUS,
  RESPONDED_UNKNOWN_INTENT_STATUS,
  REQUIRES_HOST_INTERVENTION_STATUS,
  STAGING_STATUS,
  UNDELIVERABLE_STATUS,
];

export const DM_STAGES = [
  REQUIRES_HOST_INTERVENTION_STATUS,
  HOST_INTERVENED_STATUS,
];

const GUEST_STATUS_OPTIONS = GUEST_STATUSES.map((guestStatus) => ({
  label: GUEST_STATUS_TO_LABEL[guestStatus],
  value: guestStatus,
}));

export function useGuestStatusOptions() {
  return GUEST_STATUS_OPTIONS;
}

export const toGuestStatusLabel = (s) => {
  if (s in GUEST_STATUS_TO_LABEL) {
    return GUEST_STATUS_TO_LABEL[s];
  }

  return null;
};

const GUEST_STATUS_COLOR_MAP = {
  [ACCEPTED_STATUS]: "#1fb17e",
  [ACCEPT_PENDING_QUORUM_STATUS]: "#caddd6",
  [AI_NEGOTIATING_STATUS]: "#6ea4c0",
  [AWAITING_RESPONSE_STATUS]: "#8bbeee",
  [AWAY_STATUS]: "#dbd5ca",
  [CANCELLED_STATUS]: "#7d8397",
  [DECLINED_STATUS]: "#7793a8",
  [HOST_INTERVENED_STATUS]: "#72a2d8",
  [NO_RESPONSE_STATUS]: "#979494",
  [PAUSED_STATUS]: "#adc8e4",
  [REQUIRES_HOST_INTERVENTION_STATUS]: "#62a7ec",
  [RESPONDED_UNKNOWN_INTENT_STATUS]: "#bcd2e4",
  [STAGING_STATUS]: "#c1d6ef",
  [UNDELIVERABLE_STATUS]: "#7d8397",
};

export const toGuestStatusColor = (s) => {
  if (s in GUEST_STATUS_COLOR_MAP) {
    return GUEST_STATUS_COLOR_MAP[s];
  }
  return null;
};

export const MEETING_SORT_TIME = [
  { label: "Last Activity", value: "lastActivity" },
  { label: "Start Time", value: "startTime" },
];

export function isFinalMeetingStage(stage) {
  return [NO_QUORUM_STAGE, COMPLETED_STAGE, CANCELLED_STAGE].includes(stage);
}

export function isMeetingCancelledStage(stage) {
  return stage === CANCELLED_STAGE;
}

export function isMeetingInitFaultStage(stage) {
  return stage === INITIALIZING_FAULT_STAGE;
}
