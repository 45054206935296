import useSWR from "swr";

import { useUserService } from "../services";

export function useUnsubscribeContactData(
  org: string,
  meeting: string,
  contact: string
) {
  const service = useUserService();

  const { data, error } = useSWR(
    `/api/contact/unsubscribe?o=${org}&m=${meeting}&c=${contact}`,
    (url) => service.get(url).then((res: Response) => res.body)
  );

  return {
    data: data as { orgName: string } | undefined,
    error,
    loading: !error && !data,
  };
}
