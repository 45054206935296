import { useCallback, useRef } from "react";

import { useInvite } from "./context";
import { insertTextV2 } from "./insertText";

export default function useInviteRefFactory() {
  const [invite, setInvite] = useInvite();
  const title = useRef(null);
  const location = useRef(null);
  const notes = useRef(null);
  const update = useCallback(() => {
    setInvite({
      ...invite,
      location: location.current.value,
      notes: notes.current.value,
      title: title.current.value,
    });
  }, [invite, setInvite]);
  const refs = useRef({
    location,
    notes,
    title,
  });
  return {
    insertText: useCallback(
      (template) => {
        insertTextV2(template, {
          "invite.location": location,
          "invite.notes": notes,
          "invite.title": title,
        });
        update();
      },
      [update]
    ),
    refs,
    update,
  };
}
