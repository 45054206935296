import React, { useState } from "react";

import Dialog from "./Dialog";
import Input from "../inputs/Input";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import { Stack } from "@mui/material";
import { useTeams } from "src/queries";
import { useDebounce } from "src/hooks";

export function AddTeamDialog({
  open,
  onSubmit,
  onClose,
}: {
  open: boolean;
  onSubmit: ({ name }: { name: string }) => void;
  onClose: () => void;
}) {
  const [name, setName] = useState("");

  const q = useDebounce(name, 750);

  const { data } = useTeams(10, 0, { qry: q, sortBy: "name+asc" });

  const exists =
    name !== "" &&
    data?.data.some((team) => team.name.toLowerCase() === name.toLowerCase());

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Add Team"
      actions={
        <>
          <PrimaryButton
            disabled={name === "" || exists}
            onClick={() => onSubmit({ name })}
          >
            Save
          </PrimaryButton>
          <SecondaryButton
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </SecondaryButton>
        </>
      }
    >
      <Stack spacing={2} sx={{ mt: 1 }}>
        <Input
          label="Team Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={exists}
          helperText={exists ? "team already exists" : ""}
        />
      </Stack>
    </Dialog>
  );
}

export default AddTeamDialog;
