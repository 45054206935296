import { useSWRConfig } from "swr";

import { GuestStatus } from "src/types";
import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useErrorHandler, useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";

export function useGuestStatusOverride() {
  const accessToken = getUserToken();
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const errorHandler = useErrorHandler();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (
    meetingId: number,
    guestId: number,
    status: GuestStatus,
    notifyGuest: boolean
  ): Promise<void> =>
    service
      .patch(`/api/meeting/guest/status`)
      .set(headers)
      .send({
        guestRefId: guestId,
        guestType: "contact",
        meetingId: meetingId,
        notifyGuest,
        status,
      })
      .then(tokenRefreshHandler)
      .then((res: Response) => res.body)
      .then((res: any) => {
        // @ts-ignore
        const keys = cache.keys();

        // Invalidate meeting queries and force refresh.
        Array.from(keys)
          .filter((r: any) => r.includes("/api/meeting"))
          .forEach((k: any) => {
            mutate(k);
          });

        return res;
      })
      .catch(errorHandler);
}

export default useGuestStatusOverride;
