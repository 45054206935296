import { useSWRConfig } from "swr";

import { getUserToken } from "src/utils/jwtToken";
import { useActingAsOverrideHeader } from "../auth";
import { useErrorHandler, useTokenRefreshHandler } from "../hooks";
import { useUserService } from "../services";
import { Contact, ContactWithDetails } from "../types";

export function useCreateContact() {
  const { mutate, cache } = useSWRConfig();

  const service = useUserService();
  const accessToken = getUserToken();
  const tokenRefreshHandler = useTokenRefreshHandler();
  const errorHandler = useErrorHandler();
  const override = useActingAsOverrideHeader();

  const headers: { [index: string]: string } = {
    "JWT-TOKEN": accessToken as string,
  };

  if (override) {
    headers.override = override;
  }

  return (contact: Omit<Contact, "id">): Promise<ContactWithDetails> =>
    service
      .post(`/api/contact`)
      .set(headers)
      .send(contact)
      .then(tokenRefreshHandler)
      .then((res: Response) => {
        // @ts-ignore
        const keys = cache.keys();

        // Invalidate contact queries and force refresh.
        Array.from(keys)
          .filter((r: any) => r.includes("/api/contact"))
          .forEach((k: any) => {
            mutate(k);
          });

        return res;
      })
      .then((res: Response) => res.body)
      .catch(errorHandler);
}
