import React, { useMemo, useState } from "react";
import debounce from "lodash.debounce";
import cx from "classnames";
import { mdiClose, mdiLoading, mdiMagnify, mdiPlusCircle } from "@mdi/js";
import Icon from "@mdi/react";
import { useHistory, useLocation } from "react-router-dom";

import { ButtonContainer } from "../components/button";
import Flexbox from "../components/flexbox/FlexboxV2";
import {
  LargeSpacing,
  MediumSpacing,
  SmallSpacing,
  XLargeSpacing,
} from "../components/spacing";
import { H6 } from "../components/typography";
import MeetingTypesList from "./MeetingTypesList";
import { Input } from "../components/input";
import style from "./style.module.scss";

export default function MeetingTypeMenuAndList({
  close,
  create,
  error,
  loading,
  onClickCopy,
  onClickDelete,
  onClickSelect,
  meetingTypes,
  openTags,
  placeholder = "Search Meeting Templates",
  renderIcon,
  search,
  selectedMeetingTypeId,
  setSearch,
  showAddButton = false,
  showCopyButton = false,
  showCloseButton = false,
  showDeleteButton = false,
  update,
}) {
  const { pathname } = useLocation();
  const history = useHistory();
  const [scrollToItem, setScrollToItem] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [localSearch, setLocalSearch] = useState(search);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSetSearch = useMemo(() => {
    return debounce((value) => {
      setSearch(value);
      setIsSearching(false);
    }, 300);
  }, [setSearch]);
  const onAddMeetingType = () => {
    create();
    setScrollToItem(true);
  };
  const includesLibrary = pathname.includes("library");
  const includesUser = pathname.includes("user");
  return (
    <Flexbox.Row height="100%">
      <Flexbox.Column className={style.meetingTypes__listColumn} flex="1">
        <LargeSpacing />
        <Flexbox.Row>
          <LargeSpacing />
          <H6>Meeting Templates List</H6>
          <div style={{ flex: 1 }} />
          <ButtonContainer
            className={style.meetingTypes__buttonMenu}
            name="/meetingtypes/search_meeting_types"
            onClick={() => {
              setShowSearch(true);
            }}
            transparent
          >
            <Icon path={mdiMagnify} size={1} />
          </ButtonContainer>
          <MediumSpacing />
          {showAddButton && (
            <ButtonContainer
              className={style.meetingTypes__buttonMenu}
              name="/meetingtypes/create_new_meeting_type"
              onClick={onAddMeetingType}
              transparent
            >
              <Icon path={mdiPlusCircle} size={1} />
            </ButtonContainer>
          )}
          {showCloseButton && (
            <>
              <MediumSpacing />
              <ButtonContainer
                className={style.meetingTypes__buttonMenu}
                name="/meetingtypes/close_list"
                onClick={close}
                transparent
              >
                <Icon path={mdiClose} size={1} />
              </ButtonContainer>
            </>
          )}
          <LargeSpacing />
        </Flexbox.Row>
        {showSearch && (
          <>
            <XLargeSpacing />
            <Flexbox.Row>
              <LargeSpacing />
              <Input
                large
                placeholder={placeholder}
                name="/meetingtypes/search_bar"
                onChange={(event) => {
                  setIsSearching(true);
                  setLocalSearch(event.target.value);
                  debouncedSetSearch(event.target.value);
                }}
                value={localSearch}
              />
              <SmallSpacing />
              {!isSearching && (
                <ButtonContainer
                  className={style.meetingTypes__buttonMenu}
                  name="/meetingtypes/search_bar/close"
                  onClick={() => {
                    setSearch("");
                    setShowSearch(false);
                  }}
                  transparent
                >
                  <Icon path={mdiClose} size={1} />
                </ButtonContainer>
              )}
              {isSearching && (
                <ButtonContainer
                  className={style.meetingTypes__buttonMenu}
                  transparent
                >
                  <Icon path={mdiLoading} size={1} spin={4} />
                </ButtonContainer>
              )}
              <LargeSpacing />
            </Flexbox.Row>
          </>
        )}
        <XLargeSpacing />
        <Flexbox.Row
          className={style.meetingTypes__tabMenu}
          alignItems="center"
          justifyContent="center"
        >
          <ButtonContainer
            className={cx(style.meetingType__typeTab, {
              [style["meetingType__typeTab--selected"]]: includesUser,
            })}
            name="/meetingtypes/my_types"
            onClick={() => {
              if (includesLibrary) {
                if (showCloseButton) {
                  history.push("/meetingTypes/user?open=true");
                  return;
                }
                history.push("/meetingTypes/user");
              }
            }}
            transparent
          >
            My Templates
          </ButtonContainer>
          <SmallSpacing />
          <ButtonContainer
            className={cx(style.meetingType__typeTab, {
              [style["meetingType__typeTab--selected"]]: includesLibrary,
            })}
            name="/meetingtypes/library"
            onClick={() => {
              if (includesUser) {
                if (showCloseButton) {
                  history.push("/meetingTypes/library?open=true");
                  return;
                }
                history.push("/meetingTypes/library");
              }
            }}
            transparent
          >
            Library
          </ButtonContainer>
        </Flexbox.Row>
        <MeetingTypesList
          data={meetingTypes}
          error={error}
          loading={loading}
          openTags={openTags}
          onClickCopy={onClickCopy}
          onClickDelete={onClickDelete}
          onClickSelect={onClickSelect}
          renderIcon={renderIcon}
          scrollToItem={scrollToItem}
          selectedMeetingTypeId={selectedMeetingTypeId}
          setScrollToItem={setScrollToItem}
          showCopyButton={showCopyButton}
          showDeleteButton={showDeleteButton}
          updateMeetingType={update}
        />
      </Flexbox.Column>
    </Flexbox.Row>
  );
}
