import React, { useEffect, useMemo, useRef, useState } from "react";

import Flexbox from "../../components/flexbox/FlexboxV2";
import {
  SmallSpacing,
  XLargeSpacing,
  XXLargeSpacing,
} from "../../components/spacing";
import { P1, Bold, H6 } from "../../components/typography";
import DistributionPreviewText from "./DistributionPreviewText";
import DistributionPreviewTick from "./DistributionPreviewTick";

import style from "../style.module.scss";

function DistributionPreviewMeeting({ accepted, column, index, row, refresh }) {
  const setYAnimation = useRef(null);
  const opacityAnimation = useRef(null);
  const yAnimation = useRef(null);
  const x = column * 100 + 60;
  const y = 470 - row * 30;
  const beginTime = `${index * 0.25 + 1.5}s`;
  const startTime = `${index * 0.25}s`;
  useEffect(() => {
    if (refresh) {
      if (setYAnimation.current !== null) {
        setYAnimation.current.endElement();
      }
      setTimeout(() => {
        requestAnimationFrame(() => {
          yAnimation.current.beginElement();
          opacityAnimation.current.beginElement();
        });
      }, index * 250);
    }
  }, [index, refresh]);
  return (
    <>
      <rect
        className={style.meetingType__distributionMeeting}
        width={80}
        height={30}
        x={x}
        y={-35}
        strokeWidth={1}
      >
        <animate
          ref={yAnimation}
          attributeName="y"
          begin={startTime}
          dur="1.0s"
          from={0}
          id={`m${index}_rect_y`}
          to={y}
        />
        <animate
          ref={opacityAnimation}
          attributeName="opacity"
          begin={startTime}
          dur="1.0s"
          from={0}
          id={`m${index}_rect_opacity`}
          to={1}
        />
        <set
          ref={setYAnimation}
          attributeName="y"
          begin={`m${index}_rect_y.end`}
          dur="infinite"
          to={y}
        />
      </rect>
      {accepted && (
        <path
          d={`M ${x + 35} ${y + 15} l 4 5 l 6  -10`}
          fill="none"
          opacity={0}
          stroke="green"
          strokeWidth={3}
        >
          <animate
            attributeName="opacity"
            begin={beginTime}
            dur="1.0s"
            from={0}
            id={`m${index}_checkmark_opacity`}
            to={1}
          />
          <set
            attributeName="opacity"
            begin={`m${index}_checkmark_opacity.end`}
            dur="infinite"
            to={1}
          />
        </path>
      )}
      {!accepted && (
        <>
          <line
            x1={x + 34}
            y1={y + 10}
            x2={x + 45}
            y2={y + 21}
            opacity={0}
            stroke="#949090"
            strokeWidth={3}
          >
            <animate
              attributeName="opacity"
              begin={beginTime}
              dur="1.0s"
              from={0}
              id={`m${index}_checkmark_opacity`}
              to={1}
            />
            <set
              attributeName="opacity"
              begin={`m${index}_checkmark_opacity.end`}
              dur="infinite"
              to={1}
            />
          </line>
          <line
            x1={x + 45}
            y1={y + 10}
            x2={x + 34}
            y2={y + 21}
            opacity={0}
            stroke="#949090"
            strokeWidth={3}
          >
            <animate
              attributeName="opacity"
              begin={beginTime}
              dur="1.0s"
              from={0}
              id={`m${index}_checkmark_opacity`}
              to={1}
            />
            <set
              attributeName="opacity"
              begin={`m${index}_checkmark_opacity.end`}
              dur="infinite"
              to={1}
            />
          </line>
        </>
      )}
    </>
  );
}

const RANDOM_HIGH_VOLUME = [
  {
    accepts: [1],
    meetings: 6,
  },
  {
    accepts: [4, 6],
    meetings: 10,
  },
  {
    accepts: [11],
    meetings: 12,
  },
  {
    accepts: [7],
    meetings: 9,
  },
  {
    accepts: [4],
    meetings: 11,
  },
];

export default function RandomHighVolumePreview() {
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);
  const meetings = useMemo(() => {
    const m = [];
    const validColumns = [0, 1, 2, 3, 4];
    const columnIndexes = [0, 0, 0, 0, 0];
    const columnAccepts = RANDOM_HIGH_VOLUME.map((c) => c.accepts);
    const columnMaxes = RANDOM_HIGH_VOLUME.map((c) => c.meetings);
    const totalMeetings = RANDOM_HIGH_VOLUME.reduce(
      (total, { meetings: meetingsInColumn }) => {
        return total + meetingsInColumn;
      },
      0
    );
    let index = 0;
    for (let i = 0; i < totalMeetings; i += 1) {
      const randomIndex = Math.floor(Math.random() * validColumns.length);
      const column = validColumns[randomIndex];
      const columnMax = columnMaxes[column];
      const currentRow = columnIndexes[column];
      m.push({
        accepted: columnAccepts[column].includes(currentRow),
        column,
        index,
        key: `${column}-${currentRow}-${i}`,
        row: currentRow,
      });
      columnIndexes[column] += 1;
      index += 1;
      if (columnMax === columnIndexes[column]) {
        validColumns.splice(randomIndex, 1);
      }
    }
    return m;
  }, []);
  return (
    <Flexbox.Column
      className={style.meetingType__distributionPreview}
      flex={1}
      justifyContent="center"
    >
      <XXLargeSpacing />
      <Flexbox.Row>
        <XLargeSpacing />
        <H6>High Density</H6>
      </Flexbox.Row>
      <SmallSpacing />
      <Flexbox.Row>
        <XLargeSpacing />
        <P1 className={style.meetingType__distributionExplantionText}>
          The meetings are distributed randomly and are stacked. This type of
          calendar distribution can stack up to 14 meetings at the same time
          slot.
        </P1>
        <XLargeSpacing />
      </Flexbox.Row>
      <SmallSpacing />
      <Flexbox.Row>
        <XLargeSpacing />
        <P1 className={style.meetingType__distributionExplantionText}>
          In case of a double booking a user may have to move the meetings.
        </P1>
        <XLargeSpacing />
      </Flexbox.Row>

      <Flexbox.Row>
        <XLargeSpacing />
        <P1 className={style.meetingType__distributionExplantionText}>
          <Bold> Note: </Bold>
          This type of calendar distribution is helpful for Meeting Templates
          with low acceptance rate or for cold leads that haven't been contacted
          for over 3 months for example.
        </P1>
        <XLargeSpacing />
      </Flexbox.Row>

      <SmallSpacing />
      <svg viewBox="0 0 600 600">
        {meetings.map((props) => (
          <DistributionPreviewMeeting {...props} refresh={refresh} />
        ))}
        <DistributionPreviewText x={60} y={555} text="11:00AM" />
        <DistributionPreviewText x={160} y={555} text="11:30AM" />
        <DistributionPreviewText x={260} y={555} text="12:00PM" />
        <DistributionPreviewText x={365} y={555} text="12:30PM" />
        <DistributionPreviewText x={470} y={555} text="1:00PM" />
        <DistributionPreviewTick x={100} y1={520} y2={530} />
        <DistributionPreviewTick x={200} y1={520} y2={530} />
        <DistributionPreviewTick x={300} y1={520} y2={530} />
        <DistributionPreviewTick x={400} y1={520} y2={530} />
        <DistributionPreviewTick x={500} y1={520} y2={530} />
      </svg>
    </Flexbox.Column>
  );
}
